import { Divider, FormHelperText, makeStyles, Typography } from '@material-ui/core'
import { map } from 'lodash'
import { BoxedShowLayout, RaBox } from 'ra-compact-ui'
import React from 'react'
import {
  ImageField,
  ImageInput,
  InputHelperText,
  SaveButton,
  SelectInput,
  useInput,
} from 'react-admin'
import { ColorInput } from 'react-admin-color-input'
import { DomainThemeFont } from '../../../entity-types'
import { ColorField } from '../../common/color-field'
import { NonInput } from '../../common/non-input'
import { TopToolbar, useToolbarStyles } from '../../common/toolbar'
import { useDomain } from '../../contexts/domain-context'
import { useThisDomainUrls } from '../../hook/use-this-domain-urls'
import { DomainEditForm } from './edit'
import { ThemedButtonSample } from './themed-button-sample'

const fontChoices = map(DomainThemeFont, (val) => ({ id: val, name: val }))

const useStyle = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(1),
  },
}))

export const DomainThemeEdit = (props: any) => {
  const domain = useDomain()
  const classes = useStyle()

  if (!domain) {
    return null
  }

  return (
    <DomainEditForm toolbar={<Toolbar />} id={domain.id} {...props}>
      <BoxedShowLayout style={{ marginTop: 0 }}>
        <NonInput>
          <Typography component="h2" style={{ marginTop: 0 }}>
            Général
          </Typography>
        </NonInput>
        <SelectInput source="theme.font" choices={fontChoices} />
        <MyColorInput source="theme.text_color" />
        <RaBox display="flex" alignItems="center">
          <RaBox>
            <MyColorInput source="theme.primary_color" />
            <MyColorInput source="theme.primary_text_color" />
          </RaBox>
          <RaBox>
            <ThemedButtonSample
              fgSource="theme.primary_text_color"
              bgSource="theme.primary_color"
            />
          </RaBox>
        </RaBox>
        <NonInput>
          <Divider classes={{ root: classes.divider }} />
          <Typography component="h2">Entête</Typography>
        </NonInput>
        <RaBox display="flex" alignItems="center">
          <ImageField source="theme.header_bg_url" />
          <ImageInput
            source="theme.header_bg_url"
            accept="image/*"
            helperText="resources.Domain.helperTexts.theme.header_bg_url"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </RaBox>
        <div style={{ marginTop: 10 }}>
          Note: En l&apos;absence d&apos;image de fond, l&apos;entête utilisera la couleur primaire
          définie ci-dessus
        </div>
        <MyColorInput source="theme.header_fg_color" />
        <NonInput>
          <Divider />
          <Typography component="h2">Pied de page</Typography>
        </NonInput>
        <MyColorInput source="theme.footer_bg_color" />
        <MyColorInput source="theme.footer_fg_color" />
      </BoxedShowLayout>
    </DomainEditForm>
  )
}

const MyColorInput = ({
  source,
  helperText,
  ...props
}: {
  source: string
  helperText?: string
}) => {
  const {
    input: { value },
    meta: { error, submitError, touched },
  } = useInput({
    source,
  })
  return (
    <RaBox>
      <RaBox display="flex" alignItems="center">
        <ColorInput source={source} {...props} />
        <ColorField value={value} />
      </RaBox>
      <FormHelperText>
        <InputHelperText
          touched={touched || false}
          error={error || submitError}
          helperText={helperText}
        />
      </FormHelperText>
    </RaBox>
  )
}

const Toolbar = ({ handleSubmitWithRedirect }: { handleSubmitWithRedirect?: any }) => {
  const classes = useToolbarStyles()
  const domainUrls = useThisDomainUrls()!

  return (
    <TopToolbar layoutType="action">
      <SaveButton
        className={classes.button}
        variant="contained"
        icon={<></>}
        handleSubmitWithRedirect={handleSubmitWithRedirect}
        redirect={domainUrls.theme.show}
      />
    </TopToolbar>
  )
}
