import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { Button, useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'
import { Association, Domain, Mission } from '../../entity-types'
import { URLS } from '../../routes/url-creators'
import { ASSOCIATION_RESOURCE_NAME, DOMAIN_RESOURCE_NAME } from '../constants'
import { useGetOneById } from '../hook/use-get-one-by-id'
import { ShowIcon } from './icons'

/**
 * This component is just like a <ShowButton />.
 * However, because it is used from different paths:
 * - MissionRole landing page with path = /Mission
 * - Association's Activities with path = /Domain/123/Association/456/Mission
 * it cannot simply rely on the props-provided basePath.
 * Instead we fetch the domain/association in order to build a complete url to the given mission.
 */

const useStyles = makeStyles({
  button: {
    textTransform: 'capitalize',
    fontSize: '11px',
  },
})

export const ShowMissionButton = ({ record }: { record?: Mission }) => {
  const classes = useStyles()
  const translate = useTranslate()

  const { data: association } = useGetOneById<Association>(
    ASSOCIATION_RESOURCE_NAME,
    record?.association.id,
  )
  const { data: domain } = useGetOneById<Domain>(DOMAIN_RESOURCE_NAME, association?.domain.id)

  if (!record || !association || !domain) {
    return null
  }

  return (
    <Button
      component={Link}
      className={classes.button}
      label=""
      to={URLS.domain(domain.domain_name).association(association.id).mission(record?.id).show}
      title={translate('buttons.show')}
    >
      <ShowIcon />
    </Button>
  )
}
