import { usePermissions } from 'react-admin'
import { Permission, RoleType } from '../../auth-provider'

export const useIsAtLeastActivityRole = () => {
  const { permissions } = usePermissions() as { permissions?: Permission }
  return isAtLeastActivityRole(permissions?.type)
}

export const isAtLeastActivityRole = (roleType?: RoleType) => {
  if (!roleType) {
    return false
  }
  return [RoleType.ACTIVITY, RoleType.ASSO, RoleType.DOMAIN, RoleType.DEV, RoleType.ADMIN].includes(
    roleType,
  )
}
