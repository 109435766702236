/// <reference types="@types/googlemaps" />
import TextField from '@material-ui/core/TextField'
import React, { Dispatch, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-admin'
import {
  AddressNotFoundError,
  AddressParsingError,
  googleMapsPlaceToAddressRecord,
} from '../../helpers/google-maps-place-to-address-record'
import { useInputsOnChange } from '../hook/use-inputs-variants'

interface Props {
  fullWidth?: boolean
}

type PlaceResult = google.maps.places.PlaceResult

export const PlaceSearchInput = ({ fullWidth }: Props) => {
  const translate = useTranslate()
  const inputRef = useRef<HTMLInputElement>(null)

  const [handlePlaceChange, addressSearchErrorMessage] = useAddressInputs()

  const mapsApiIsLoadedRef = useRef(false)
  useEffect(() => {
    if (mapsApiIsLoadedRef.current) {
      return
    }
    if (typeof google === 'undefined') {
      // eslint-disable-next-line no-console
      console.warn('Google Places was not initialized. LocationSearchBox will not function.')
      return
    }
    if (inputRef.current) {
      const input = inputRef.current
      if (input) {
        const placeAutocomplete = new google.maps.places.Autocomplete(input, {
          fields: ['address_components', 'formatted_address', 'name', 'geometry'],
        })

        placeAutocomplete.addListener('place_changed', () => {
          handlePlaceChange(placeAutocomplete.getPlace())
        })
      }
    }
    mapsApiIsLoadedRef.current = true
  }, [inputRef, handlePlaceChange])

  const showErrorState = addressSearchErrorMessage !== undefined
  const errorMessage =
    showErrorState && addressSearchErrorMessage ? addressSearchErrorMessage : undefined

  return (
    <TextField
      variant="outlined"
      inputRef={inputRef}
      error={showErrorState}
      helperText={errorMessage}
      fullWidth={fullWidth}
      placeholder={translate('resources.Address.placeholder.addressSearch')}
    />
  )
}

const useAddressInputs = (): [Dispatch<PlaceResult>, string | undefined] => {
  const translate = useTranslate()
  const [
    onLatitudeChange,
    onLongitudeChange,
    onCountryChange,
    onCityChange,
    onZipCodeChange,
    onStreetChange,
    onFullAddressChange,
    onFixedAddressChange,
    onComplementChange,
  ] = useInputsOnChange([
    'latitude',
    'longitude',
    'country',
    'city',
    'zipcode',
    'street',
    'full_address',
    'fixed_address',
    'complement',
  ])
  const [addressSearchErrorMessage, setAddressSearchErrorMessage] = useState<string>()
  const handlePlaceChange = useCallback(
    (address: PlaceResult) => {
      let record: ReturnType<typeof googleMapsPlaceToAddressRecord>
      try {
        record = googleMapsPlaceToAddressRecord(address)
      } catch (error) {
        error instanceof AddressNotFoundError
          ? setAddressSearchErrorMessage(
              translate('resources.Address.placeholder.addressNotFoundError'),
            )
          : error instanceof AddressParsingError
          ? setAddressSearchErrorMessage(
              translate('resources.Address.placeholder.addressParsingError'),
            )
          : error instanceof Error
          ? setAddressSearchErrorMessage(error.message)
          : // eslint-disable-next-line no-console
            console.error(error)

        onLatitudeChange(undefined)
        onLongitudeChange(undefined)
        onCountryChange(undefined)
        onCityChange(undefined)
        onZipCodeChange(undefined)
        onStreetChange(undefined)
        onFullAddressChange(undefined)
        onFixedAddressChange(undefined)
        onComplementChange(undefined)
        return
      }
      setAddressSearchErrorMessage(undefined)

      onLatitudeChange(record.latitude)
      onLongitudeChange(record.longitude)
      onCountryChange(record.country)
      onCityChange(record.city)
      onZipCodeChange(record.zipcode)
      onStreetChange(record.street)
      onFullAddressChange(record.fullAddress)
      onFixedAddressChange(record.fullAddress)
      onComplementChange(undefined)
    },
    [
      onLatitudeChange,
      onLongitudeChange,
      onCountryChange,
      onCityChange,
      onZipCodeChange,
      onStreetChange,
      onFullAddressChange,
      onFixedAddressChange,
      onComplementChange,
      translate,
    ],
  )

  return [handlePlaceChange, addressSearchErrorMessage]
}
