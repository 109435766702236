import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import React, { FC, useEffect } from 'react'
import { TopToolbar, useMutation, useNotify, useRefresh, useTranslate } from 'react-admin'
import PortailLinkButton from './portail-link-button'

const useStyles = makeStyles({
  toolbar: {
    paddingTop: '10px',
    paddingRight: '10px',
  },
  button: {
    margin: '5px',
  },
})

interface Props {
  disableModify?: boolean
  data?: Record<string, any>
  resource?: string
}

const ShowToolbar: FC<Props> = (props: Props) => {
  const translate = useTranslate()
  const notify = useNotify()
  const refresh = useRefresh()
  const classes = useStyles()

  const disableModify = props.disableModify === true

  const { data = {}, resource } = props
  const disableMapping: Record<string, string> = {
    Association: 'disabled',
    Activity: 'disabled',
    Contact: 'hidden',
  }
  const fieldname = resource && disableMapping[resource]

  const resourceToIdentifierField: Record<string, string> = {
    Domain: 'domain_name',
  }

  const payload: { id: string; data: Record<string, string | boolean> } = {
    id: data.id,
    data: { id: data.id },
  }

  if (data.domain) {
    payload.data.domain = data.domain.id
  }

  if (data.association) {
    payload.data.association = data.association.id
  }

  if (data.activity) {
    payload.data.activity = data.activity.id
  }

  if (fieldname) {
    payload.data[fieldname] = !data[fieldname]
  }

  const [toogleDisabled, { loading, data: success, error }] = useMutation({
    type: 'update',
    resource,
    payload,
  })

  useEffect(() => {
    if (success) {
      if (fieldname && success[fieldname]) {
        notify(`${translate(`resources.${resource}.name`)} ${translate('texts.disable')}`, 'info')
      } else {
        notify(`${translate(`resources.${resource}.name`)} ${translate('texts.enable')}`, 'info')
      }
      refresh()
    }
    if (error) {
      notify(error, 'warning')
    }
    // eslint-disable-next-line
  }, [success, error])

  return (
    <TopToolbar className={classes.toolbar}>
      {fieldname ? (
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          onClick={toogleDisabled}
          disabled={loading}
        >
          {data[fieldname]
            ? translate(`resources.${resource}.button.enable`)
            : translate(`resources.${resource}.button.disable`)}
        </Button>
      ) : null}
      <PortailLinkButton className={classes.button} variant="outlined" color="primary" />

      {!disableModify && (
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          href={`#/${resource}/${data?.[resourceToIdentifierField[resource || ''] || 'id']}`}
        >
          {translate('buttons.modify')}
        </Button>
      )}
    </TopToolbar>
  )
}

export default ShowToolbar
