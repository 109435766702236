import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { BoxedShowLayout } from 'ra-compact-ui'
import React from 'react'
import {
  DateField,
  ImageField,
  ReferenceField,
  Show,
  TextField,
  UrlField,
  useTranslate,
} from 'react-admin'
import { DbRecord } from '../../../entity-types'
import { useModelStyles } from '../../../helpers/model-styles'
import { NonInput } from '../../common/non-input'
import PortailLinkButton from '../../common/portail-link-button'
import { ToolbarEditButton, TopToolbar } from '../../common/toolbar'
import { useIsAtLeastDomainRole } from '../../hook/use-is-at-least-domain-role'

export const DomainPartnerShow = (props: any) => {
  const translate = useTranslate()
  const classes = useModelStyles()

  if (!useIsAtLeastDomainRole()) {
    return null
  }
  return (
    <Show
      title={translate('resources.DomainPartner.name')}
      {...props}
      actions={<Actions />}
      classes={{ card: classes.card }}
    >
      <BoxedShowLayout className={classes.show}>
        <NonInput>
          <Typography component="h1">{translate('resources.DomainPartner.title')}</Typography>
          <Typography component="h3">{translate('resources.DomainPartner.subtitle')}</Typography>
          <Divider />
        </NonInput>
        <ImageField source="logo_url" />
        <TextField source="name" />
        <UrlField source="url" />
        <ReferenceField
          label={translate('resources.DomainPartner.fields.domain')}
          source="domain.id"
          reference="Domain"
        >
          <TextField source="domain_name" />
        </ReferenceField>
        <DateField source="createdAt" />
      </BoxedShowLayout>
    </Show>
  )
}

const Actions = ({ basePath, data }: { basePath?: string; data?: DbRecord }) => {
  return (
    <TopToolbar>
      <PortailLinkButton />
      <ToolbarEditButton record={data} basePath={basePath} />
    </TopToolbar>
  )
}
