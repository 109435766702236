import { ApolloProvider } from '@apollo/client'
import { createMuiTheme } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import buildGraphQLProvider from 'ra-data-graphql-simple'
import React, { FC, useEffect, useState } from 'react'
import { AdminContext, AdminUI, Loading, Resource, useRedirect } from 'react-admin'
import { useLocation } from 'react-router-dom'
import { client } from './apollo-client'
import './App.css'
import { authProvider } from './auth-provider'
import { getLandingPageForRoleType } from './components/common/landing-page'
import { Layout } from './components/layout/layout'
import LoginPage from './components/login/login'
import { useResourceForPermission } from './components/resources/use-resource-for-permission'
import { uploadDataProvider } from './helpers/upload-data-provider'
import i18nProvider from './i18n/i18n-provider'
import { CUSTOM_ROUTES } from './routes/custom-routes'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#007b8c',
    },
    secondary: {
      main: '#007b8c',
      contrastText: '#ffffff',
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
})

export const App: FC = () => {
  const [dataProvider, setDataProvider] = useState(null)

  const uploadReferences = [
    { resource: 'Domain', fieldnames: ['logo_url', 'home_about_image', 'theme.header_bg_url'] },
    { resource: 'DomainHighlight', fieldnames: ['preview_img_url'] },
    { resource: 'DomainPartner', fieldnames: ['logo_url'] },
    { resource: 'Association', fieldnames: ['logo_url', 'header_url'] },
  ]

  useEffect(() => {
    const onMount = async () => {
      const provider = await buildGraphQLProvider({
        client,
      })
      setDataProvider(() => provider)
    }
    onMount()
  }, [])

  if (!dataProvider) {
    return (
      <Loading
        loadingPrimary="Chargement"
        loadingSecondary="La page est en cours de chargement, merci de bien vouloir patienter."
      />
    )
  }

  return (
    <AdminContext
      dataProvider={uploadDataProvider(dataProvider, uploadReferences)}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
    >
      <ApolloProvider client={client}>
        <AdminAsync
          theme={theme}
          loginPage={LoginPage}
          title="Assolib BO"
          layout={Layout}
          customRoutes={CUSTOM_ROUTES}
        />
      </ApolloProvider>
    </AdminContext>
  )
}

interface AdminAsyncProps {
  title?: string
  layout?: any
  dashboard?: any
  customRoutes?: any
  locale?: string
  loginPage?: any
  theme?: any
}

const AdminAsync = (props: AdminAsyncProps) => {
  const { pathname } = useLocation()
  // console.log(`%cpathname ${pathname}`, 'color: blue')
  const isPathLogin = pathname === '/login'
  if (isPathLogin) {
    return <UnAuthenticatedAdminUI {...props} />
  }
  return <AuthenticatedAdminUI {...props} />
}

const AuthenticatedAdminUI = (props: AdminAsyncProps) => {
  const redirect = useRedirect()
  const { pathname } = useLocation()
  const isPathRoot = pathname === '/'
  const {
    loaded: resourcesLoaded,
    error: resourcesError,
    resources,
    permissions,
  } = useResourceForPermission()

  const mustRedirectToLogin = Boolean(resourcesError)

  useEffect(() => {
    // Error => Redirect to Login
    if (mustRedirectToLogin) {
      if (resourcesError) {
        // eslint-disable-next-line no-console
        console.error('resourcesError', resourcesError)
      }
      // eslint-disable-next-line no-console
      console.warn('REDIRECT TO LOGIN')
      redirect('/login')
    }
  }, [redirect, mustRedirectToLogin, resourcesError])

  useEffect(() => {
    // Root URL => Redirect to landing page
    if (isPathRoot && permissions) {
      const nextPath = getLandingPageForRoleType(permissions.type)
      redirect(nextPath)
    }
  }, [redirect, isPathRoot, permissions])
  if (mustRedirectToLogin || isPathRoot) {
    return null
  }
  if (!resourcesLoaded) {
    return <Loading />
  }
  return (
    <AdminUI {...props}>
      {(resources || []).map((resource) => (
        <Resource key={resource.name} {...resource} />
      ))}
    </AdminUI>
  )
}

const UnAuthenticatedAdminUI = (props: AdminAsyncProps) => {
  // This UI is currently only used for the Login page.
  // ReactAdmin's usePermission() only calls authProvider.getPermission() ONCE,
  // so we must NOT use useResourceForPermission() before the user is properly logged in.
  return <AdminUI {...props}>{[]}</AdminUI>
}
