import React from 'react'
import {
  Datagrid,
  DateField,
  Filter,
  ImageField,
  List,
  Loading,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import { Permission } from '../../../auth-provider'
import { Association, AssociationId } from '../../../entity-types'
import { useModelStyles } from '../../../helpers/model-styles'
import BooleanFieldIntext from '../../common/boolean-field'
import EmptyPage from '../../common/empty-page'
import Pagination from '../../common/pagination'
import PortailLinkButton from '../../common/portail-link-button'
import { ShowAssociationButton } from '../../common/show-association-button'
import { TagReferenceField } from '../../common/tag-reference-field'
import { ToolbarCreateButton, TopToolbar } from '../../common/toolbar'
import { useDomain } from '../../contexts/domain-context'
import { useIsAtLeastDomainRole } from '../../hook/use-is-at-least-domain-role'
import { XlsAssociationExportButton } from './xls-association-export-button'

const AssociationListFilter = (props: unknown) => {
  const classes = useModelStyles()
  return (
    <Filter {...props} classes={{ form: classes.filter }} variant="outlined">
      <TextInput source="q" alwaysOn />
    </Filter>
  )
}

export const AssociationList = ({
  permissions,
  ...props
}: {
  permissions?: Permission
  basePath?: string
}) => {
  const domain = useDomain()
  const classes = useModelStyles()

  if (!permissions) {
    return <Loading />
  }

  const actions = <Actions basePath={props.basePath} />

  return (
    <List
      {...props}
      actions={actions}
      bulkActionButtons={false}
      filters={<AssociationListFilter />}
      pagination={<Pagination />}
      filter={Object.assign(
        domain ? { domain: domain.id } : {},
        permissions && permissions.associations ? { ids: permissions.associations } : {},
      )}
      empty={<EmptyPage actions={actions} />}
      classes={{ content: classes.content }}
    >
      <Datagrid className={classes.list}>
        <ImageField className={classes.thumbnail} source="logo_url" title="logo" />
        <TextField source="name" />
        <TextField source="administrative_id" />
        <TextField source="administrative_id_type" />
        <TagReferenceField source="main_tag" />
        <BooleanFieldIntext source="disabled" />
        <DateField source="createdAt" />
        <ShowAssociationButton />
      </Datagrid>
    </List>
  )
}

const Actions = ({
  basePath,
}: {
  basePath?: string
  data?: Record<AssociationId, Association>
}) => {
  const translate = useTranslate()
  const canAddAsso = useIsAtLeastDomainRole()
  return (
    <TopToolbar>
      <XlsAssociationExportButton />
      <PortailLinkButton />
      {canAddAsso && (
        <ToolbarCreateButton
          basePath={basePath}
          label={translate(`resources.Association.button.create`)}
        />
      )}
    </TopToolbar>
  )
}
