// At the moment we disallow editing a contact from an mission.
// So if the user ever get here, he is redirected to the mission.show.

import { useEffect } from 'react'
import { useRedirect } from 'react-admin'
import { useThisMissionUrls } from '../../hook/use-this-mission-urls'

export const MissionContactEdit = () => {
  const missionUrl = useThisMissionUrls()
  const redirect = useRedirect()
  useEffect(() => {
    if (missionUrl) {
      redirect(missionUrl.show)
    }
  }, [missionUrl, redirect])
  return null
}
