import { usePermissions } from 'react-admin'
import { Permission, RoleType } from '../../auth-provider'

export const useIsAtLeastDevRole = () => {
  const { permissions } = usePermissions() as { permissions?: Permission }
  return isAtLeastDevRole(permissions?.type)
}

export const isAtLeastDevRole = (roleType?: RoleType) => {
  if (!roleType) {
    return false
  }
  return [RoleType.DEV].includes(roleType)
}
