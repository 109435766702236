import { makeStyles } from '@material-ui/core'
import { default as DetailsIcon } from '@material-ui/icons/MoreHoriz'
import React, { useMemo } from 'react'
import {
  AutocompleteInput,
  Button,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  ReferenceInput,
  SelectInput,
  TextInput,
  useTranslate,
} from 'react-admin'
import { useModelStyles } from '../../../../helpers/model-styles'
import { ASSOCIATION_RESOURCE_NAME, HISTORY_RESOURCE_NAME } from '../../../constants'
import { useDomain } from '../../../contexts/domain-context'
import { useBooleanState } from '../../../hook/use-boolean-state'
import { useIsAtLeastDomainRole } from '../../../hook/use-is-at-least-domain-role'
import { ActionTypeField, ALL_HISTORY_ACTION_TYPES } from './action-type-field'
import { AssociationField } from './association-field'
import { DiffField } from './diff-field'
import { ALL_HISTORY_TARGET_TYPES, EntityNameField } from './entity-name-field'
import { EntityTypeField } from './entity-type-field'
import { RoleTypeField } from './role-type-field'

export enum HistoryRoleType {
  ASSOLIB = 'assolib',
  DEV = 'dev',
  ADMIN = 'admin',
  DOMAIN = 'domain',
  ASSO = 'association',
  ACTIVITY = 'activity',
  MISSION = 'mission',
}

export const ALL_HISTORY_ROLE_TYPES = [
  HistoryRoleType.ASSOLIB,
  HistoryRoleType.DEV,
  HistoryRoleType.ADMIN,
  HistoryRoleType.DOMAIN,
  HistoryRoleType.ASSO,
  HistoryRoleType.ACTIVITY,
  HistoryRoleType.MISSION,
]

const historyRoleTypeToGraphlQlEnum: Record<HistoryRoleType, string | undefined> = {
  // The backend's graphql uses the enum's keys
  [HistoryRoleType.ASSOLIB]: 'ASSOLIB',
  [HistoryRoleType.DEV]: 'DEV',
  [HistoryRoleType.ADMIN]: 'ADMIN',
  [HistoryRoleType.DOMAIN]: 'DOMAIN',
  [HistoryRoleType.ASSO]: 'ASSO',
  [HistoryRoleType.ACTIVITY]: 'ACTIVITY',
  [HistoryRoleType.MISSION]: 'MISSION',
}

const sort = { field: 'createdAt', order: 'DESC' }

export const DomainHistoryList = () => {
  const domain = useDomain()
  const filter = useMemo(() => ({ domain: domain?.id }), [domain])
  const classes = useModelStyles()

  if (!useIsAtLeastDomainRole() || !domain) {
    return null
  }

  return (
    <List
      basePath=""
      filters={<Filters />}
      filter={filter}
      actions={false}
      resource={HISTORY_RESOURCE_NAME}
      sort={sort}
      classes={{ content: classes.content }}
      bulkActionButtons={false}
      perPage={5}
    >
      <Datagrid>
        <EntityNameField source="name" />
        <DateField source="createdAt" showTime={true} />
        <EntityTypeField source="target_type" />
        <AssociationField source="association" />
        <RoleTypeField source="role_type" />
        <ActionTypeField source="action" />
        <ExpandableDiffField source="diff" />
      </Datagrid>
    </List>
  )
}

const ExpandableDiffField = (props: { source: string } & unknown) => {
  const [expanded, expand] = useBooleanState(false)

  if (!expanded) {
    return (
      <Button onClick={expand} label="">
        <DetailsIcon />
      </Button>
    )
  }

  return <DiffField {...props} />
}

const useFilterStyles = makeStyles({
  root: {
    marginBottom: '1rem',
    marginLeft: '2rem',
  },
})

const useTargetTypeChoices = () => {
  const translate = useTranslate()
  return useMemo(
    () =>
      ALL_HISTORY_TARGET_TYPES.map((type) => ({
        id: type,
        name: translate(`resources.History.targetTypes.${type}`),
      })),
    [translate],
  )
}

const useRoleTypeChoices = () => {
  const translate = useTranslate()
  return useMemo(
    () =>
      ALL_HISTORY_ROLE_TYPES.map((type) => ({
        id: historyRoleTypeToGraphlQlEnum[type],
        name: translate(`historyRoleTypes.${type}`),
      })),
    [translate],
  )
}

const useActionTypeChoices = () => {
  const translate = useTranslate()
  return useMemo(
    () =>
      ALL_HISTORY_ACTION_TYPES.map((type) => ({
        id: type,
        name: translate(`resources.History.actionTypes.${type}`),
      })),
    [translate],
  )
}

const Filters = (props: unknown) => {
  const classes = useFilterStyles()
  const targetTypeChoices = useTargetTypeChoices()
  const roleTypeChoices = useRoleTypeChoices()
  const actionTypeChoices = useActionTypeChoices()
  return (
    <Filter {...props} variant="outlined" classes={{ form: classes.root }}>
      <TextInput source="q" alwaysOn />
      <SelectInput source="target_type" choices={targetTypeChoices} alwaysOn />
      <SelectInput source="role_type" choices={roleTypeChoices} alwaysOn />
      <ReferenceInput source="association" reference={ASSOCIATION_RESOURCE_NAME} alwaysOn>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <SelectInput source="action" choices={actionTypeChoices} alwaysOn />
      <DateInput source="date_min" alwaysOn />
      <DateInput source="date_max" alwaysOn />
    </Filter>
  )
}
