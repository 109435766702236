import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import {
  Create,
  HandleSubmitWithRedirect,
  ReactAdminComponentProps,
  required,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import { UsefulLink } from '../../../entity-types'
import { modelStyles } from '../../../helpers/model-styles'
import { NonInput } from '../../common/non-input'
import { StatusToggleInput } from '../../common/status-toggle-input'
import { ToolbarSaveButton, TopToolbar } from '../../common/toolbar'
import { useDomain } from '../../contexts/domain-context'
import { useIsAtLeastDomainRole } from '../../hook/use-is-at-least-domain-role'
import { useCreateEditFormsStyles, usefulLinkDocCreateTransform } from './useful-link'

const useModelStyles = makeStyles(modelStyles)

export const UsefulLinkCreate = (props: ReactAdminComponentProps) => {
  const domain = useDomain()
  const translate = useTranslate()
  const modelClasses = useModelStyles()
  const classes = useCreateEditFormsStyles()

  if (!useIsAtLeastDomainRole() || !domain) {
    return null
  }
  return (
    <Create
      {...props}
      classes={{ card: modelClasses.card }}
      transform={usefulLinkDocCreateTransform}
    >
      <SimpleForm
        toolbar={<Actions />}
        redirect="list"
        className={modelClasses.edit}
        initialValues={{ domain: domain.id, disabled: false }}
      >
        <NonInput>
          <Typography component="h1">{translate('resources.DomainUsefullink.title')}</Typography>
          <Divider />
        </NonInput>
        <TextInput variant="outlined" source="name" validate={[required()]} />
        <TextInput
          variant="outlined"
          multiline
          source="descriptionText"
          fullWidth
          InputProps={{ classes: { input: classes.descriptionTextArea } }}
        />
        <TextInput variant="outlined" source="urlText" validate={[required()]} />
        <TextInput variant="outlined" source="url" validate={[required()]} type="url" />
        <StatusToggleInput source="disabled" />
      </SimpleForm>
    </Create>
  )
}

const Actions = ({
  basePath,
  data,
  handleSubmitWithRedirect,
}: {
  basePath?: string
  data?: UsefulLink
  handleSubmitWithRedirect?: HandleSubmitWithRedirect
}) => {
  return (
    <TopToolbar layoutType="action">
      <ToolbarSaveButton
        record={data}
        basePath={basePath}
        handleSubmitWithRedirect={handleSubmitWithRedirect}
      />
    </TopToolbar>
  )
}
