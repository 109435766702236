import {
  FormControlLabel,
  FormGroup,
  FormGroupProps,
  FormHelperText,
  Switch,
  SwitchProps,
} from '@material-ui/core'
import { rest } from 'lodash'
import React, { useCallback } from 'react'
import {
  FieldTitle,
  InputHelperText,
  InputProps,
  sanitizeInputRestProps,
  useInput,
  useTranslate,
} from 'react-admin'
import { xor } from '../../helpers/xor'

/**
 * Just like BooleanInput but with additional feature:
 * - Automatically translated status with gender management.
 * - Always ON for "enable" and OFF for "disabled", regardless of whether the internal field
 *   is `disabled` or `enabled`
 */

export interface StatusFieldExtraProps {
  source: string
  labelIfTrue?: string
  labelIfFalse?: string
  gender?: 'masculine' | 'feminine'
  reverse?: boolean
}

type StatusToggleInputProps = InputProps<SwitchProps> &
  Omit<FormGroupProps, 'defaultValue' | 'onChange' | 'onBlur' | 'onFocus'> &
  StatusFieldExtraProps

const sourceFieldToReverseNeed: Record<string, boolean> = {
  enabled: false,
  disabled: true,
  enable: false,
  disable: true,
  on: false,
  off: true,
}

export const useRealStatusBooleanMustReverse = (
  source: string,
  reverse?: boolean,
): boolean | undefined => {
  const mustReverse: boolean | undefined =
    reverse !== undefined ? reverse : sourceFieldToReverseNeed[source]

  if (mustReverse === undefined) {
    throw Error(
      `Could not interpret the field name "${source}". Make sure it is available in sourceFieldToReverseNeed, or set the 'reverse' props manually`,
    )
  }
  return mustReverse
}

export const StatusToggleInput = (props: StatusToggleInputProps) => {
  const {
    source,
    gender,
    reverse,
    disabled,
    options,
    resource,
    helperText,
    labelIfTrue,
    labelIfFalse,
  } = props
  const translate = useTranslate()
  const {
    input: { value, onChange },
    isRequired,
    meta: { error, submitError, touched },
  } = useInput(props)

  const mustReverse = useRealStatusBooleanMustReverse(source, reverse)
  const isEnabled = xor(value, mustReverse)

  const handleChange = useCallback(() => {
    onChange(xor(!isEnabled, mustReverse))
  }, [isEnabled, mustReverse, onChange])

  const label =
    value && labelIfTrue !== undefined
      ? labelIfTrue
      : !value && labelIfFalse !== undefined
      ? labelIfFalse
      : translate(
          `StatusToggleInput.${gender || 'masculine'}.${isEnabled ? 'enabled' : 'disabled'}`,
        )

  // The JSX is almost a copy/paste of BooleanInput
  return (
    <FormGroup {...sanitizeInputRestProps(rest)}>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            onChange={handleChange}
            checked={isEnabled}
            {...options}
            disabled={disabled}
          />
        }
        label={
          <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
        }
      />
      <FormHelperText error={!!(error || submitError)}>
        <InputHelperText
          touched={touched ?? false}
          error={error || submitError}
          helperText={helperText}
        />
      </FormHelperText>
    </FormGroup>
  )
}
