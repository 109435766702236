import { Divider, makeStyles, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { get } from 'lodash'
import { BoxedShowLayout, RaBox } from 'ra-compact-ui'
import React from 'react'
import {
  ImageField,
  Labeled,
  Show,
  TextField,
  TopToolbar,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { useModelStyles } from '../../../helpers/model-styles'
import { ColorField } from '../../common/color-field'
import { NonInput } from '../../common/non-input'
import { useDomain } from '../../contexts/domain-context'
import { useThisDomainUrls } from '../../hook/use-this-domain-urls'

const useStyle = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(1),
  },
  colorField: {
    display: 'flex',
    alignItems: 'center',
  },
  colorFieldText: {
    fontFamily: 'monospace',
  },
}))

export const DomainThemeShow = (props: any) => {
  const translate = useTranslate()
  const modelClasses = useModelStyles()
  const classes = useStyle()

  const domain = useDomain()
  const domainUrls = useThisDomainUrls()!

  if (!domain) {
    return null
  }

  return (
    <Show
      title={translate('resources.Domain.name')}
      {...props}
      id={domain.id}
      actions={
        <NonInput>
          <TopToolbar>
            <Button variant="contained" color="primary" href={`#${domainUrls.theme.edit}`}>
              {translate('buttons.modify')}
            </Button>
          </TopToolbar>
        </NonInput>
      }
      classes={{ card: modelClasses.card }}
    >
      <BoxedShowLayout className={modelClasses.show}>
        <NonInput>
          <Typography component="h2" style={{ marginTop: 0 }}>
            Général
          </Typography>
        </NonInput>
        <TextField source="theme.font" />
        <MyColorField source="theme.text_color" />
        <RaBox display="flex" alignItems="center">
          <RaBox>
            <MyColorField source="theme.primary_color" />
            <MyColorField source="theme.primary_text_color" />
          </RaBox>
        </RaBox>
        <NonInput>
          <Divider classes={{ root: classes.divider }} />
          <Typography component="h2">Entête</Typography>
        </NonInput>
        <RaBox display="flex" alignItems="center">
          <ImageField source="theme.header_bg_url" />
        </RaBox>
        <MyColorField source="theme.header_fg_color" />
        <NonInput>
          <Divider />
          <Typography component="h2">Pied de page</Typography>
        </NonInput>
        <MyColorField source="theme.footer_bg_color" />
        <MyColorField source="theme.footer_fg_color" />
      </BoxedShowLayout>
    </Show>
  )
}

const MyColorField = ({ source, resource }: { source: string; resource?: string }) => {
  const classes = useStyle()
  const { record } = useShowContext()
  const value = get(record, source)
  return (
    <Labeled source={source} resource={resource}>
      {value ? (
        <div className={classes.colorField}>
          <Typography className={classes.colorFieldText}>{value}</Typography>
          <ColorField value={value} />
        </div>
      ) : (
        <span>-</span>
      )}
    </Labeled>
  )
}
