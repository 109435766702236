import React, { ReactElement } from 'react'
import { useRecordContext } from 'react-admin'
import { DomainHistory } from '../../../../entity-types'
import { AssociationNameField } from './entity-name-field'

export const AssociationField = ({ source: _ }: { source: string }): ReactElement | null => {
  const { associationId } = useRecordContext() as DomainHistory
  if (!associationId) {
    return null
  }
  return <AssociationNameField associationId={associationId} />
}
