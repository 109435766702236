import React, { useMemo } from 'react'
import { SortPayload, TextField, TextInput } from 'react-admin'
import { EntityReferenceListInput } from '../../../common/entity-reference-list-input'
import { TagReferenceArrayField } from '../../../common/tag-reference-array-field'
import { TagReferenceInput } from '../../../common/tag-reference-input'
import { ACTIVITY_RESOURCE_NAME } from '../../../constants'
import { useDomain } from '../../../contexts/domain-context'
import { TagType } from '../../tag'
import { SelectionEntityListFilter } from './selection-entity-list-filter'

const activityListSort: SortPayload = { field: 'name', order: 'ASC' }

export const DomainHighlightSelectionByActivitiesBuilder = () => {
  const domain = useDomain()
  const domainId = domain?.id

  const filter = useMemo(
    () => ({
      domain: domainId,
    }),
    [domainId],
  )

  if (!domainId) {
    return null
  }

  return (
    <EntityReferenceListInput
      source="activities"
      referenceResource={ACTIVITY_RESOURCE_NAME}
      referenceFilter={filter}
      referenceSort={activityListSort}
      translationPrefix="resources.DomainHighlight.ActivityListInput"
      filters={<ActivityListFilters />}
    >
      <TextField source="title" />
      <TagReferenceArrayField source="tags" />
    </EntityReferenceListInput>
  )
}

const tagTypes: TagType[] = [TagType.Main, TagType.Normal]

const ActivityListFilters = (props: unknown) => {
  return (
    <SelectionEntityListFilter {...props}>
      <TextInput source="q" alwaysOn />
      <TagReferenceInput
        source="tags"
        alwaysOn
        label="EntityReferenceListInput.tagFilterPlaceholder"
        tagTypes={tagTypes}
      />
    </SelectionEntityListFilter>
  )
}
