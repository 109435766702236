import Button from '@material-ui/core/Button'
import React, { FC } from 'react'
import { useTranslate } from 'react-admin'
import { getLinkFront } from '../../helpers/get-link-front'
import { useActivityContext } from '../contexts/activity-context'
import { useAssociationContext } from '../contexts/association-context'
import { useDomain } from '../contexts/domain-context'
import { useMissionContext } from '../contexts/mission-context'
import { useToolbarStyles } from './toolbar'

interface Props {
  className?: string
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'inherit' | 'default' | 'primary' | 'secondary'
}

const PortailLinkButton: FC<Props> = (props: Props) => {
  const classes = useToolbarStyles()
  // Replace with proper contexts
  const { association, isMatch: isAssociationMatch } = useAssociationContext()
  const { activity, isMatch: isActivityMatch } = useActivityContext()
  const { mission, isMatch: isMissionMatch } = useMissionContext()

  const isActivity = isActivityMatch
  const isMission = isMissionMatch
  const isAssociation = isAssociationMatch && !isActivity && !isMission
  const isDomain = !isAssociation && !isActivity && !isMission

  const domain = useDomain()
  const translate = useTranslate()

  return (
    <Button
      {...props}
      href={getLinkFront({ domain, association, activity, mission })}
      className={classes.button}
      target="_blank"
      variant="outlined"
      color="primary"
    >
      {isActivity && translate('buttons.showActivityLink')}
      {isMission && translate('buttons.showMissionLink')}
      {isAssociation && translate('buttons.showAssoLink')}
      {isDomain && translate('buttons.showDomainLink')}
    </Button>
  )
}

export default PortailLinkButton
