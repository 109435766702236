import {
  ACTIVITY_RESOURCE_NAME,
  ACTIVITY_SCHEDULE_RESOURCE_NAME,
  ADDRESS_RESOURCE_NAME,
  ASSOCIATION_RESOURCE_NAME,
  CONTACT_RESOURCE_NAME,
  DOMAIN_HIGHLIGHT_RESOURCE_NAME,
  DOMAIN_PARTNER_RESOURCE_NAME,
  DOMAIN_RESOURCE_NAME,
  MISSION_RESOURCE_NAME,
  USEFUL_LINK_RESOURCE_NAME,
} from '../components/constants'
import { ActivityScheduleCreate } from '../components/models/activity-schedule/create'
import { ActivityScheduleEdit } from '../components/models/activity-schedule/edit'
import { ActivityScheduleList } from '../components/models/activity-schedule/list'
import { ActivityScheduleShow } from '../components/models/activity-schedule/show'
import { ActivityAddressEdit } from '../components/models/activity/address-edit'
import { ActivityAddressList } from '../components/models/activity/address-list'
import { ActivityAddressSelectOrCreate } from '../components/models/activity/address-select-or-create'
import { ActivityAddressShow } from '../components/models/activity/address-show'
import { ActivityContactEdit } from '../components/models/activity/contact-edit'
import { ActivityContactList } from '../components/models/activity/contact-list'
import { ActivityContactSelectOrCreate } from '../components/models/activity/contact-select-or-create'
import { ActivityContactShow } from '../components/models/activity/contact-show'
import { ActivityCreate } from '../components/models/activity/create'
import { ActivityEdit } from '../components/models/activity/edit'
import { ActivityListFromAssociation } from '../components/models/activity/list'
import { ActivityShow } from '../components/models/activity/show'
import { AssoRoleHome } from '../components/models/asso-role-home'
import { AssociationAddressCreate } from '../components/models/association/address-create'
import { AssociationAddressEdit } from '../components/models/association/address-edit'
import { AssociationAddressList } from '../components/models/association/address-list'
import { AssociationAddressShow } from '../components/models/association/address-show'
import { AssociationContactEdit } from '../components/models/association/contact-edit'
import { AssociationContactList } from '../components/models/association/contact-list'
import { AssociationContactSelectOrCreate } from '../components/models/association/contact-select-or-create'
import { AssociationContactShow } from '../components/models/association/contact-show'
import { AssociationCreate } from '../components/models/association/create'
import { AssociationEdit } from '../components/models/association/edit'
import { AssociationList } from '../components/models/association/list'
import { AssociationShow } from '../components/models/association/show'
import { DomainHighlightCreate } from '../components/models/domain-highlight/create'
import { DomainHighlightEdit } from '../components/models/domain-highlight/edit'
import { DomainHighlightList } from '../components/models/domain-highlight/list'
import { DomainHighlightShow } from '../components/models/domain-highlight/show'
import { DomainPartnerCreate } from '../components/models/domain-partner/create'
import { DomainPartnerEdit } from '../components/models/domain-partner/edit'
import { DomainPartnerList } from '../components/models/domain-partner/list'
import { DomainPartnerShow } from '../components/models/domain-partner/show'
import { DomainAddressCreate } from '../components/models/domain/address-create'
import { DomainAddressEdit } from '../components/models/domain/address-edit'
import { DomainAddressList } from '../components/models/domain/address-list'
import { DomainAddressShow } from '../components/models/domain/address-show'
import { DomainContactCreate } from '../components/models/domain/contact-create'
import { DomainContactEdit } from '../components/models/domain/contact-edit'
import { DomainContactList } from '../components/models/domain/contact-list'
import { DomainContactShow } from '../components/models/domain/contact-show'
import { DomainCreate } from '../components/models/domain/create'
import { Dashboard } from '../components/models/domain/dashboard'
import { DomainEdit } from '../components/models/domain/edit'
import { EditoEdit } from '../components/models/domain/edito-edit'
import { EditoShow } from '../components/models/domain/edito-show'
import { ImportExport } from '../components/models/domain/import-export'
import { DomainList } from '../components/models/domain/list'
import { MissionSettings } from '../components/models/domain/mission-settings'
import { DomainThemeEdit } from '../components/models/domain/theme-edit'
import { DomainThemeShow } from '../components/models/domain/theme-show'
import { MissionAddressEdit } from '../components/models/mission/address-edit'
import { MissionAddressList } from '../components/models/mission/address-list'
import { MissionAddressSelectOrCreate } from '../components/models/mission/address-select-or-create'
import { MissionAddressShow } from '../components/models/mission/address-show'
import { MissionContactEdit } from '../components/models/mission/contact-edit'
import { MissionContactList } from '../components/models/mission/contact-list'
import { MissionContactSelectOrCreate } from '../components/models/mission/contact-select-or-create'
import { MissionContactShow } from '../components/models/mission/contact-show'
import { MissionCreate } from '../components/models/mission/create'
import { MissionEdit } from '../components/models/mission/edit'
import { MissionListFromAssociation } from '../components/models/mission/list'
import { MissionShow } from '../components/models/mission/show'
import { UsefulLinkCreate } from '../components/models/useful-link/create'
import { UsefulLinkEdit } from '../components/models/useful-link/edit'
import { UsefulLinkList } from '../components/models/useful-link/list'
import { UsefulLinkShow } from '../components/models/useful-link/show'
import { buildCustomRoute, PathKind } from './build-custom-route'
import { URLS } from './url-creators'

const DOMAIN_PATH = URLS.domain(':domain_name')
const ASSOCIATION_PATH = DOMAIN_PATH.association(':association_id')
const DOMAIN_HIGHLIGHT_PATH = DOMAIN_PATH.highlight(':domain_hightlight_id')
const DOMAIN_PARTNER_PATH = DOMAIN_PATH.partner(':partner_id')
const DOMAIN_CONTACT_PATH = DOMAIN_PATH.contact(':contact_id')
const DOMAIN_ADDRESS_PATH = DOMAIN_PATH.address(':address_id')
const USEFUL_LINK_PATH = DOMAIN_PATH.usefulLink(':useful_link_id')
const ACTIVITY_PATH = ASSOCIATION_PATH.activity(':activity_id')
const MISSION_PATH = ASSOCIATION_PATH.mission(':mission_id')
const ASSOCIATION_CONTACT_PATH = ASSOCIATION_PATH.contact(':contact_id')
const ASSOCIATION_ADDRESS_PATH = ASSOCIATION_PATH.address(':address_id')
const ACTIVITY_CONTACT_PATH = ACTIVITY_PATH.contact(':contact_id')
const ACTIVITY_ADDRESS_PATH = ACTIVITY_PATH.address(':address_id')
const ACTIVITY_SCHEDULE_PATH = ACTIVITY_PATH.schedule(':activity_schedule_id')
const MISSION_CONTACT_PATH = MISSION_PATH.contact(':contact_id')
const MISSION_ADDRESS_PATH = MISSION_PATH.address(':address_id')

/**
 * The recommended order is [Create, List, Show, Edit], so that the path is matched correctly.
 * e.g.
 *  Create /Domain/123/Association/Create
 *  List /Domain/123/Association
 *  Show /Domain/123/Association/456/Show
 *  Edit /Domain/123/Association/456
 * If that order is not respected, there are chances it will mismatch the wrong path.
 */

export const CUSTOM_ROUTES = [
  // Association Role Home
  buildCustomRoute(AssoRoleHome, URLS.assoRoleHome, PathKind.actionPath, {
    wrapInResourceContext: USEFUL_LINK_RESOURCE_NAME,
  }),

  // Domain - Create
  buildCustomRoute(DomainCreate, URLS.domain.create, PathKind.actionPath, {
    wrapInResourceContext: DOMAIN_RESOURCE_NAME,
  }),
  // Domain - List
  buildCustomRoute(DomainList, URLS.domain.list, PathKind.basePath, {
    wrapInResourceContext: DOMAIN_RESOURCE_NAME,
  }),
  // Domain - Edit
  buildCustomRoute(DomainEdit, DOMAIN_PATH.edit, PathKind.idPath, {
    wrapInResourceContext: DOMAIN_RESOURCE_NAME,
  }),

  // Domain - Dashboard
  buildCustomRoute(Dashboard, DOMAIN_PATH.dashboard, PathKind.idPath),

  // Domain - Import / Export
  buildCustomRoute(ImportExport, DOMAIN_PATH.importExport, PathKind.idPath),

  // Domain - Mission Settings
  buildCustomRoute(MissionSettings, DOMAIN_PATH.missionSettings, PathKind.idPath),

  // Domain - Edito - Show
  buildCustomRoute(EditoShow, DOMAIN_PATH.edito.show, PathKind.idPath, {
    wrapInResourceContext: DOMAIN_RESOURCE_NAME,
  }),
  // Domain - Edito - Edit
  buildCustomRoute(EditoEdit, DOMAIN_PATH.edito.edit, PathKind.idPath, {
    wrapInResourceContext: DOMAIN_RESOURCE_NAME,
  }),
  // Domain - Theme - Show
  buildCustomRoute(DomainThemeShow, DOMAIN_PATH.theme.show, PathKind.idPath, {
    wrapInResourceContext: DOMAIN_RESOURCE_NAME,
  }),
  // Domain - Theme - Edit
  buildCustomRoute(DomainThemeEdit, DOMAIN_PATH.theme.edit, PathKind.idPath, {
    wrapInResourceContext: DOMAIN_RESOURCE_NAME,
  }),

  // Domain - Contacts - Create
  buildCustomRoute(DomainContactCreate, DOMAIN_PATH.contact.create, PathKind.actionPath, {
    wrapInResourceContext: CONTACT_RESOURCE_NAME,
  }),
  // Domain - Contacts - List
  buildCustomRoute(DomainContactList, DOMAIN_PATH.contact.list, PathKind.basePath, {
    wrapInResourceContext: CONTACT_RESOURCE_NAME,
  }),
  // Domain - Contacts - Show
  buildCustomRoute(DomainContactShow, DOMAIN_CONTACT_PATH.show, PathKind.idPath, {
    wrapInResourceContext: CONTACT_RESOURCE_NAME,
  }),
  // Domain - Contacts - Edit
  buildCustomRoute(DomainContactEdit, DOMAIN_CONTACT_PATH.edit, PathKind.idPath, {
    wrapInResourceContext: CONTACT_RESOURCE_NAME,
  }),

  // Domain - Address - Create
  buildCustomRoute(DomainAddressCreate, DOMAIN_PATH.address.create, PathKind.actionPath, {
    wrapInResourceContext: ADDRESS_RESOURCE_NAME,
  }),
  // Domain - Address - List
  buildCustomRoute(DomainAddressList, DOMAIN_PATH.address.list, PathKind.basePath, {
    wrapInResourceContext: ADDRESS_RESOURCE_NAME,
  }),
  // Domain - Address - Show
  buildCustomRoute(DomainAddressShow, DOMAIN_ADDRESS_PATH.show, PathKind.idPath, {
    wrapInResourceContext: ADDRESS_RESOURCE_NAME,
  }),
  // Domain - Address - Edit
  buildCustomRoute(DomainAddressEdit, DOMAIN_ADDRESS_PATH.edit, PathKind.idPath, {
    wrapInResourceContext: ADDRESS_RESOURCE_NAME,
  }),

  // Domain - DomainHighlight - Create
  buildCustomRoute(DomainHighlightCreate, DOMAIN_PATH.highlight.create, PathKind.actionPath, {
    wrapInResourceContext: DOMAIN_HIGHLIGHT_RESOURCE_NAME,
  }),
  // Domain - DomainHighlight - List
  buildCustomRoute(DomainHighlightList, DOMAIN_PATH.highlight.list, PathKind.basePath, {
    wrapInResourceContext: DOMAIN_HIGHLIGHT_RESOURCE_NAME,
  }),
  // Domain - DomainHighlight - Show
  buildCustomRoute(DomainHighlightShow, DOMAIN_HIGHLIGHT_PATH.show, PathKind.idPath, {
    wrapInResourceContext: DOMAIN_HIGHLIGHT_RESOURCE_NAME,
  }),
  // Domain - DomainHighlight - Edit
  buildCustomRoute(DomainHighlightEdit, DOMAIN_HIGHLIGHT_PATH.edit, PathKind.idPath, {
    wrapInResourceContext: DOMAIN_HIGHLIGHT_RESOURCE_NAME,
  }),

  // Domain - DomainPartner - Create
  buildCustomRoute(DomainPartnerCreate, DOMAIN_PATH.partner.create, PathKind.actionPath, {
    wrapInResourceContext: DOMAIN_PARTNER_RESOURCE_NAME,
  }),
  // Domain - DomainPartner - List
  buildCustomRoute(DomainPartnerList, DOMAIN_PATH.partner.list, PathKind.basePath, {
    wrapInResourceContext: DOMAIN_PARTNER_RESOURCE_NAME,
  }),
  // Domain - DomainPartner - Show
  buildCustomRoute(DomainPartnerShow, DOMAIN_PARTNER_PATH.show, PathKind.idPath, {
    wrapInResourceContext: DOMAIN_PARTNER_RESOURCE_NAME,
  }),
  // Domain - DomainPartner - Edit
  buildCustomRoute(DomainPartnerEdit, DOMAIN_PARTNER_PATH.edit, PathKind.idPath, {
    wrapInResourceContext: DOMAIN_PARTNER_RESOURCE_NAME,
  }),

  // Domain - UsefulLink - Create
  buildCustomRoute(UsefulLinkCreate, DOMAIN_PATH.usefulLink.create, PathKind.actionPath, {
    wrapInResourceContext: USEFUL_LINK_RESOURCE_NAME,
  }),
  // Domain - UsefulLink - List
  buildCustomRoute(UsefulLinkList, DOMAIN_PATH.usefulLink.list, PathKind.basePath, {
    wrapInResourceContext: USEFUL_LINK_RESOURCE_NAME,
  }),
  // Domain - UsefulLink - Show
  buildCustomRoute(UsefulLinkShow, USEFUL_LINK_PATH.show, PathKind.idPath, {
    wrapInResourceContext: USEFUL_LINK_RESOURCE_NAME,
  }),
  // Domain - UsefulLink - Edit
  buildCustomRoute(UsefulLinkEdit, USEFUL_LINK_PATH.edit, PathKind.idPath, {
    wrapInResourceContext: USEFUL_LINK_RESOURCE_NAME,
  }),

  // Domain - Association - Create
  buildCustomRoute(AssociationCreate, DOMAIN_PATH.association.create, PathKind.actionPath, {
    wrapInResourceContext: ASSOCIATION_RESOURCE_NAME,
  }),
  // Domain - Association - List
  buildCustomRoute(AssociationList, DOMAIN_PATH.association.list, PathKind.basePath, {
    wrapInResourceContext: ASSOCIATION_RESOURCE_NAME,
  }),
  // Domain - Association - Show
  buildCustomRoute(AssociationShow, ASSOCIATION_PATH.show, PathKind.idPath, {
    wrapInResourceContext: ASSOCIATION_RESOURCE_NAME,
  }),
  // Domain - Association - Edit
  buildCustomRoute(AssociationEdit, ASSOCIATION_PATH.edit, PathKind.idPath, {
    wrapInResourceContext: ASSOCIATION_RESOURCE_NAME,
  }),

  // Domain - Association - Contacts - Create
  buildCustomRoute(
    AssociationContactSelectOrCreate,
    ASSOCIATION_PATH.contact.create,
    PathKind.actionPath,
    {
      wrapInResourceContext: CONTACT_RESOURCE_NAME,
    },
  ),
  // Domain - Association - Contacts - List
  buildCustomRoute(AssociationContactList, ASSOCIATION_PATH.contact.list, PathKind.basePath, {
    wrapInResourceContext: ASSOCIATION_RESOURCE_NAME,
  }),
  // Domain - Association - Contacts - Show
  buildCustomRoute(AssociationContactShow, ASSOCIATION_CONTACT_PATH.show, PathKind.idPath, {
    wrapInResourceContext: CONTACT_RESOURCE_NAME,
  }),
  // Domain - Association - Contacts - Edit
  buildCustomRoute(AssociationContactEdit, ASSOCIATION_CONTACT_PATH.edit, PathKind.idPath, {
    wrapInResourceContext: CONTACT_RESOURCE_NAME,
  }),

  // Domain - Association - Address - Create
  buildCustomRoute(AssociationAddressCreate, ASSOCIATION_PATH.address.create, PathKind.actionPath, {
    wrapInResourceContext: ADDRESS_RESOURCE_NAME,
  }),
  // Domain - Association - Address - List
  buildCustomRoute(AssociationAddressList, ASSOCIATION_PATH.address.list, PathKind.basePath, {
    wrapInResourceContext: ADDRESS_RESOURCE_NAME,
  }),
  // Domain - Association - Address - Show
  buildCustomRoute(AssociationAddressShow, ASSOCIATION_ADDRESS_PATH.show, PathKind.idPath, {
    wrapInResourceContext: ADDRESS_RESOURCE_NAME,
  }),
  // Domain - Association - Address - Edit
  buildCustomRoute(AssociationAddressEdit, ASSOCIATION_ADDRESS_PATH.edit, PathKind.idPath, {
    wrapInResourceContext: ADDRESS_RESOURCE_NAME,
  }),

  // Domain - Association - Activity - Create
  buildCustomRoute(ActivityCreate, ASSOCIATION_PATH.activity.create, PathKind.actionPath, {
    wrapInResourceContext: ACTIVITY_RESOURCE_NAME,
  }),
  // Domain - Association - Activity - List
  buildCustomRoute(ActivityListFromAssociation, ASSOCIATION_PATH.activity.list, PathKind.basePath, {
    wrapInResourceContext: ACTIVITY_RESOURCE_NAME,
  }),
  // Domain - Association - Activity - Show
  buildCustomRoute(ActivityShow, ACTIVITY_PATH.show, PathKind.idPath, {
    wrapInResourceContext: ACTIVITY_RESOURCE_NAME,
  }),
  // Domain - Association - Activity - Edit
  buildCustomRoute(ActivityEdit, ACTIVITY_PATH.edit, PathKind.idPath, {
    wrapInResourceContext: ACTIVITY_RESOURCE_NAME,
  }),

  // Domain - Association - Activity - Contacts - Create
  buildCustomRoute(
    ActivityContactSelectOrCreate,
    ACTIVITY_PATH.contact.create,
    PathKind.actionPath,
    {
      wrapInResourceContext: CONTACT_RESOURCE_NAME,
    },
  ),
  // Domain - Association - Activity - Contacts - List
  buildCustomRoute(ActivityContactList, ACTIVITY_PATH.contact.list, PathKind.basePath, {
    wrapInResourceContext: ACTIVITY_RESOURCE_NAME,
  }),
  // Domain - Association - Activity - Contacts - Show
  buildCustomRoute(ActivityContactShow, ACTIVITY_CONTACT_PATH.show, PathKind.idPath, {
    wrapInResourceContext: ACTIVITY_RESOURCE_NAME,
  }),
  // Domain - Association - Activity - Contacts - Edit
  buildCustomRoute(ActivityContactEdit, ACTIVITY_CONTACT_PATH.edit, PathKind.idPath, {
    wrapInResourceContext: ACTIVITY_RESOURCE_NAME,
  }),

  // Domain - Association - Activity - Address - Create
  buildCustomRoute(
    ActivityAddressSelectOrCreate,
    ACTIVITY_PATH.address.create,
    PathKind.actionPath,
    {
      wrapInResourceContext: ADDRESS_RESOURCE_NAME,
    },
  ),
  // Domain - Association - Activity - Address - List
  buildCustomRoute(ActivityAddressList, ACTIVITY_PATH.address.list, PathKind.basePath, {
    wrapInResourceContext: ACTIVITY_RESOURCE_NAME,
  }),

  // Domain - Association - Activity - Address - Show
  buildCustomRoute(ActivityAddressShow, ACTIVITY_ADDRESS_PATH.show, PathKind.idPath, {
    wrapInResourceContext: ADDRESS_RESOURCE_NAME,
  }),
  // Domain - Association - Activity - Address - Edit
  buildCustomRoute(ActivityAddressEdit, ACTIVITY_ADDRESS_PATH.edit, PathKind.idPath, {
    wrapInResourceContext: ADDRESS_RESOURCE_NAME,
  }),

  // Domain - Association - Activity - ActivitySchedule - Create
  buildCustomRoute(ActivityScheduleCreate, ACTIVITY_PATH.schedule.create, PathKind.actionPath, {
    wrapInResourceContext: ACTIVITY_SCHEDULE_RESOURCE_NAME,
  }),
  // Domain - Association - Activity - ActivitySchedule - List
  buildCustomRoute(ActivityScheduleList, ACTIVITY_PATH.schedule.list, PathKind.basePath, {
    wrapInResourceContext: ACTIVITY_SCHEDULE_RESOURCE_NAME,
  }),
  // Domain - Association - Activity - ActivitySchedule - Show
  buildCustomRoute(ActivityScheduleShow, ACTIVITY_SCHEDULE_PATH.show, PathKind.idPath, {
    wrapInResourceContext: ACTIVITY_SCHEDULE_RESOURCE_NAME,
  }),
  // Domain - Association - Activity - ActivitySchedule - Edit
  buildCustomRoute(ActivityScheduleEdit, ACTIVITY_SCHEDULE_PATH.edit, PathKind.idPath, {
    wrapInResourceContext: ACTIVITY_SCHEDULE_RESOURCE_NAME,
  }),

  // Domain - Association - Mission - Create
  buildCustomRoute(MissionCreate, ASSOCIATION_PATH.mission.create, PathKind.actionPath, {
    wrapInResourceContext: MISSION_RESOURCE_NAME,
  }),
  // Domain - Association - Mission - List
  buildCustomRoute(MissionListFromAssociation, ASSOCIATION_PATH.mission.list, PathKind.basePath, {
    wrapInResourceContext: MISSION_RESOURCE_NAME,
  }),
  // Domain - Association - Mission - Show
  buildCustomRoute(MissionShow, MISSION_PATH.show, PathKind.idPath, {
    wrapInResourceContext: MISSION_RESOURCE_NAME,
  }),
  // Domain - Association - Mission - Edit
  buildCustomRoute(MissionEdit, MISSION_PATH.edit, PathKind.idPath, {
    wrapInResourceContext: MISSION_RESOURCE_NAME,
  }),

  // Domain - Association - Mission - Contacts - Create
  buildCustomRoute(MissionContactSelectOrCreate, MISSION_PATH.contact.create, PathKind.actionPath, {
    wrapInResourceContext: CONTACT_RESOURCE_NAME,
  }),
  // Domain - Association - Mission - Contacts - List
  buildCustomRoute(MissionContactList, MISSION_PATH.contact.list, PathKind.basePath, {
    wrapInResourceContext: MISSION_RESOURCE_NAME,
  }),
  // Domain - Association - Mission - Contacts - Show
  buildCustomRoute(MissionContactShow, MISSION_CONTACT_PATH.show, PathKind.idPath, {
    wrapInResourceContext: MISSION_RESOURCE_NAME,
  }),
  // Domain - Association - Mission - Contacts - Edit
  buildCustomRoute(MissionContactEdit, MISSION_CONTACT_PATH.edit, PathKind.idPath, {
    wrapInResourceContext: MISSION_RESOURCE_NAME,
  }),

  // Domain - Association - Mission - Address - Create
  buildCustomRoute(MissionAddressSelectOrCreate, MISSION_PATH.address.create, PathKind.actionPath, {
    wrapInResourceContext: ADDRESS_RESOURCE_NAME,
  }),
  // Domain - Association - Mission - Address - List
  buildCustomRoute(MissionAddressList, MISSION_PATH.address.list, PathKind.basePath, {
    wrapInResourceContext: MISSION_RESOURCE_NAME,
  }),
  // Domain - Association - Mission - Address - Show
  buildCustomRoute(MissionAddressShow, MISSION_ADDRESS_PATH.show, PathKind.idPath, {
    wrapInResourceContext: ADDRESS_RESOURCE_NAME,
  }),
  // Domain - Association - Mission - Address - Edit
  buildCustomRoute(MissionAddressEdit, MISSION_ADDRESS_PATH.edit, PathKind.idPath, {
    wrapInResourceContext: ADDRESS_RESOURCE_NAME,
  }),
]
