import { Divider, makeStyles, Typography } from '@material-ui/core'
import { chain, sortBy } from 'lodash'
import React, { ComponentType, ReactElement, useCallback, useMemo } from 'react'
import {
  Button,
  DataProvider,
  EditControllerProps,
  Loading,
  ResourceContextProvider,
  TopToolbar,
  useDataProvider,
  useEditController,
  useTranslate,
} from 'react-admin'
import { Link } from 'react-router-dom'
import { DbRecord, LooseDbRecord } from '../../entity-types'
import { commonEditStyles } from '../../helpers/common-edit-styles'
import {
  PAGINATION_FILTER_IDS_SORT,
  PAGINATION_FILTER_IDS_SORT_FIELD,
} from '../../helpers/paginatedFilterIdsSort'
import { CustomDeleteButton } from '../custom-delete-button'
import { useSameObjectIfEqual } from '../hook/use-same-object-if-equal'
import { NonInput } from './non-input'
import { useToolbarStyles } from './toolbar'
import { XlsExportButton, XlsExportFieldOption } from './xls-export-button'

/**
 * list editing for an entity that holds a unique `primaryFooo` and multiple `fooos` references.
 *
 * e.g. 'Association' owns a `primary_contact` and `contacts`.
 */

export interface ReferencedEntityListComponentProps {
  sort: { field: string; order: 'ASC' | 'DESC' }
  // Filter of the list. Pass it to the <List> element.
  filter: { [key: string]: unknown }
  // Resource of the list. Pass it to the <List> element.
  resource: string
  // Actions of the list. Pass it to the <List> element.
  actions?: ReactElement | false
  // Title of the list. Pass it to the <List> element.
  title?: string
  // BasePath of the referenced entities. Use it on features in your list whichh deal with the referenced entities.
  referencedEntitiesBasePath: string
  // Columns to be inserted within the begining of your list.
  extraColumnsStart?: ReactElement[]
  // Columns to be inserted within the middle of your list.
  extraColumnsMid?: ReactElement[]
  // Columns to be inserted within the end of your list.
  extraColumnsEnd?: ReactElement[]
  // Alter the ids provided from the <List> to the <Datagrid>. Use with a <ReorderPropsIds /> element (see its documentation).
  datagridIdSort?: (ids: string[]) => string[]
  // Show a button to the reference edit page. Default 'true'.
  edit?: boolean
}

export interface ReferencedEntityEditComponentProps {
  // BasePath of the referenced entities. Pass it to the <Edit> element.
  basePath: string
  // ID of the referenced entity. Pass it to the <Edit> element.
  id: string
  // Pass it to the <Edit> element. This triggers the closing of the edit sidebar.
  onCancel: () => void
  // Pass it to the <Edit> element.
  className?: string
}

interface PrimarySecondaryReferenceListInputProps {
  // Resource of the container entity.
  parentResource: string
  // ID of the container entity.
  id: string
  // BasePath of the container entity.
  basePath?: string
  // Resource of the referenced entities.
  referencedEntityResource: string
  // BasePath of the referenced entities.
  referencedEntitiesBasePath: string
  // Key of the primary reference in the container entity.
  primaryReferencedEntityField: string
  // Key of the references' array in the container entity.
  allReferencedEntityField: string
  // Pre-save transformation for the container entity.
  editTransform?: (val: any) => any
  // Show a button to the reference edit page. Default 'true'.
  editReference?: boolean
  // ReactAdmin-style List of the referenced resource that will contain the referenced entities.
  referencedEntityListComponent: ComponentType<ReferencedEntityListComponentProps>
  xlsExportFieldOptions?: XlsExportFieldOption[]
  xlsExportTransform?: (records: any[], dataProvider: DataProvider) => Promise<any[]> | any[]
  prefixComponent?: ReactElement
}
export const PrimarySecondaryReferenceListInput = ({
  parentResource,
  referencedEntityResource,
  id,
  basePath,
  referencedEntitiesBasePath,
  primaryReferencedEntityField,
  allReferencedEntityField,
  editTransform,
  referencedEntityListComponent,
  xlsExportFieldOptions,
  xlsExportTransform,
  editReference,
  prefixComponent,
}: PrimarySecondaryReferenceListInputProps) => {
  const controllerProps = useEditController({
    basePath,
    resource: parentResource,
    id,
    title: ' ',
    transform: editTransform,
    mutationMode: 'pessimistic',
    redirect: false,
  })
  // console.log('controllerProps', JSON.stringify(controllerProps, null, 2))
  const { record, save } = controllerProps as EditControllerProps<LooseDbRecord>

  const primaryReferencedEntityId = record?.[primaryReferencedEntityField]?.id

  const saveRecord = useCallback(
    (newRecord: DbRecord) => {
      save(newRecord, false)
    },
    [save],
  )

  const togglePrimarySecondary = useCallback(
    (referencedEntity: DbRecord) => {
      if (!record) return
      const newRecord: DbRecord = {
        ...record,
        // There is something wrong with the way we handle the cache.
        // It crashes if `record[allReferencedEntityField]` stays the same. So we clone the array for now, hoping this will be solved later.
        [allReferencedEntityField]: [...(record[allReferencedEntityField] || [])],
        [primaryReferencedEntityField]:
          referencedEntity.id === primaryReferencedEntityId
            ? undefined
            : { id: referencedEntity.id },
      }
      saveRecord(newRecord)
    },
    [
      record,
      allReferencedEntityField,
      primaryReferencedEntityField,
      primaryReferencedEntityId,
      saveRecord,
    ],
  )

  const removeReferencedEntity = useCallback(
    (idToRemove: string) => {
      if (!record) return
      const newRecord = {
        ...record,
        [allReferencedEntityField]: (record[allReferencedEntityField] || []).filter(
          ({ id }: DbRecord) => id !== idToRemove,
        ),
        [primaryReferencedEntityField]:
          idToRemove === primaryReferencedEntityId
            ? undefined
            : record[primaryReferencedEntityField],
      }
      saveRecord(newRecord)
    },
    [
      record,
      allReferencedEntityField,
      primaryReferencedEntityField,
      primaryReferencedEntityId,
      saveRecord,
    ],
  )

  if (!record) {
    return <Loading />
  }

  return (
    <ReferencedEntityListInContext
      resource={parentResource}
      referencedEntityResource={referencedEntityResource}
      formData={record}
      referencedEntitiesBasePath={referencedEntitiesBasePath}
      primaryReferencedEntityField={primaryReferencedEntityField}
      allReferencedEntityField={allReferencedEntityField}
      togglePrimarySecondary={togglePrimarySecondary}
      removeReferencedEntity={removeReferencedEntity}
      referencedEntityListComponent={referencedEntityListComponent}
      xlsExportFieldOptions={xlsExportFieldOptions}
      xlsExportTransform={xlsExportTransform}
      editReference={editReference}
      prefixComponent={prefixComponent}
    />
  )
}

const ReferencedEntityListInContext = ({
  resource,
  referencedEntityResource,
  referencedEntitiesBasePath,
  primaryReferencedEntityField,
  allReferencedEntityField,
  formData,
  togglePrimarySecondary,
  removeReferencedEntity,
  referencedEntityListComponent,
  xlsExportFieldOptions,
  xlsExportTransform,
  editReference,
  prefixComponent,
}: {
  resource: string
  referencedEntityResource: string
  referencedEntitiesBasePath: string
  primaryReferencedEntityField: string
  allReferencedEntityField: string
  formData: LooseDbRecord
  togglePrimarySecondary: (entity: { id: string }) => void
  removeReferencedEntity: (idToRemove: string) => void
  referencedEntityListComponent: PrimarySecondaryReferenceListInputProps['referencedEntityListComponent']
  xlsExportFieldOptions?: XlsExportFieldOption[]
  xlsExportTransform?: (records: any[], dataProvider: DataProvider) => Promise<any[]> | any[]
  editReference?: boolean
  prefixComponent?: ReactElement
}) => {
  const currentRecordValue = useSameObjectIfEqual(formData) // formData keeps changing, even tho the content is the same.
  const allReferencedEntities = useMemo(
    () => (currentRecordValue[allReferencedEntityField] || []) as DbRecord[],
    [allReferencedEntityField, currentRecordValue],
  )
  const primaryReferencedEntity = currentRecordValue[primaryReferencedEntityField]
  const primaryReferencedEntityId = primaryReferencedEntity?.id

  const putPrimaryIdFirst = useCallback(
    // Guarantee that the primary referenced entity is the first on the list.
    (entities: { id: string }[]) =>
      sortBy(entities, ({ id }) => (id === primaryReferencedEntityId ? 0 : 1)),
    [primaryReferencedEntityId],
  )

  const allSortedReferencedEntities = useMemo(() => {
    if (allReferencedEntities.length === 0) {
      return []
    }
    if (!primaryReferencedEntityId) {
      return allReferencedEntities
    }
    return putPrimaryIdFirst(allReferencedEntities)
  }, [allReferencedEntities, primaryReferencedEntityId, putPrimaryIdFirst])

  const extraColumnsMid = useMemo(
    () => [
      <TogglePrimarySecondaryButton
        parentResource={resource}
        referencedEntityResource={referencedEntityResource}
        source={PAGINATION_FILTER_IDS_SORT_FIELD}
        key="primarySecondaryToggle"
        onClick={togglePrimarySecondary}
        primaryReferencedEntityId={primaryReferencedEntityId}
      />,
    ],
    [resource, referencedEntityResource, togglePrimarySecondary, primaryReferencedEntityId],
  )

  const datagridIdSort = useCallback(
    // Guarantee that the primary entity is the first on the list
    // when we are sorting by primary/secondary
    (ids: string[], currentSort?: { field: string }) => {
      if (primaryReferencedEntityId && currentSort?.field === PAGINATION_FILTER_IDS_SORT_FIELD) {
        return sortBy(ids, (id) => (id === primaryReferencedEntityId ? 0 : 1))
      }
      return ids
    },
    [primaryReferencedEntityId],
  )

  const allReferencedEntityIds = useMemo(
    () => allSortedReferencedEntities.map((c: DbRecord) => c.id),
    [allSortedReferencedEntities],
  )
  return (
    <ReferencedEntityListField
      parentResource={resource}
      referencedEntityResource={referencedEntityResource}
      referencedEntitiesBasePath={referencedEntitiesBasePath}
      primaryReferencedEntityId={primaryReferencedEntityId}
      ids={allReferencedEntityIds}
      onRemoveClick={removeReferencedEntity}
      extraColumnsMid={extraColumnsMid}
      datagridIdSort={datagridIdSort}
      referencedEntityListComponent={referencedEntityListComponent}
      xlsExportFieldOptions={xlsExportFieldOptions}
      xlsExportTransform={xlsExportTransform}
      editReference={editReference}
      prefixComponent={prefixComponent}
    />
  )
}

const ReferencedEntityListField = ({
  parentResource,
  referencedEntityResource,
  referencedEntitiesBasePath,
  primaryReferencedEntityId,
  ids,
  onRemoveClick,
  extraColumnsMid,
  datagridIdSort,
  referencedEntityListComponent: ReferencedEntityListComponent,
  xlsExportFieldOptions,
  xlsExportTransform,
  editReference,
  prefixComponent,
}: {
  parentResource: string
  referencedEntityResource: string
  referencedEntitiesBasePath: string
  primaryReferencedEntityId?: string
  ids: string[]
  onRemoveClick?: (entityId: string) => void
  extraColumnsMid?: ReactElement[]
  datagridIdSort?: (ids: string[]) => string[]
  referencedEntityListComponent: PrimarySecondaryReferenceListInputProps['referencedEntityListComponent']
  xlsExportFieldOptions?: XlsExportFieldOption[]
  xlsExportTransform?: (records: any[], dataProvider: DataProvider) => Promise<any[]> | any[]
  editReference?: boolean
  prefixComponent?: ReactElement
}) => {
  const toolbarClasses = useToolbarStyles()
  const classes = useStyles()
  const translate = useTranslate()
  const filter = useMemo(() => ({ ids }), [ids])

  const handleRemove = useCallback(({ id }: DbRecord) => onRemoveClick?.(id), [onRemoveClick])

  const extraColumnsEnd = useMemo(
    () => [
      <CustomDeleteButton
        key="delete"
        onClick={handleRemove}
        confirmationDialogTitle={translate(
          `resources.${parentResource}.${referencedEntityResource}.delete.title`,
        )}
        confirmationDialogText={translate(
          `resources.${parentResource}.${referencedEntityResource}.delete.text`,
        )}
        confirmationDialogButtonOkLabel={translate(
          `resources.${parentResource}.${referencedEntityResource}.delete.okButtonLabel`,
        )}
      />,
    ],
    [translate, parentResource, referencedEntityResource, handleRemove],
  )
  return (
    <div className={classes.contactListRoot}>
      <Typography component="h1">
        {translate(`resources.${parentResource}.${referencedEntityResource}.title`)}
      </Typography>
      <Typography component="h3">
        {translate(`resources.${parentResource}.${referencedEntityResource}.subtitle`)}
      </Typography>
      <Divider />
      {prefixComponent}
      <ResourceContextProvider value={referencedEntityResource}>
        <ReferencedEntityListComponent
          referencedEntitiesBasePath={referencedEntitiesBasePath}
          sort={PAGINATION_FILTER_IDS_SORT}
          filter={filter}
          resource={referencedEntityResource}
          datagridIdSort={datagridIdSort}
          edit={editReference}
          actions={
            <NonInput>
              <TopToolbar>
                <ExportReferencedEntityButton
                  xlsExportFieldOptions={xlsExportFieldOptions}
                  xlsExportTransform={xlsExportTransform}
                  primaryReferencedEntityId={primaryReferencedEntityId}
                />
                <Button
                  className={toolbarClasses.button}
                  label={translate(
                    `resources.${parentResource}.${referencedEntityResource}.addReferencedEntityButtonLabel`,
                  )}
                  to={`${referencedEntitiesBasePath}/create`}
                  component={Link}
                  variant="contained"
                />
              </TopToolbar>
            </NonInput>
          }
          extraColumnsMid={extraColumnsMid}
          extraColumnsEnd={extraColumnsEnd}
          title=" "
        />
      </ResourceContextProvider>
    </div>
  )
}

const TogglePrimarySecondaryButton = ({
  parentResource,
  referencedEntityResource,
  onClick,
  record,
  primaryReferencedEntityId,
  ...props
}: {
  parentResource: string
  referencedEntityResource: string
  onClick?: (entity: DbRecord) => void
  record?: DbRecord
  primaryReferencedEntityId?: string
  source?: string // Used for the Datagrid parent to generate a <th>
}) => {
  const translate = useTranslate()
  return (
    <ReferencedEntityButton
      record={record}
      label={translate(
        `resources.${parentResource}.${referencedEntityResource}.${
          record?.id === primaryReferencedEntityId
            ? 'setAsSecondaryButtonLabel'
            : 'setAsPrimaryButtonLabel'
        }`,
      )}
      onClick={onClick}
      {...props}
    />
  )
}

const ReferencedEntityButton = ({
  label,
  onClick,
  record,
}: {
  label: string
  onClick?: (entity: DbRecord) => void
  record?: DbRecord
}) => {
  return <Button label={label} onClick={() => record && onClick?.(record)} />
}

const useStyles = makeStyles({
  drawerContent: {
    width: 300,
  },
  contactListRoot: commonEditStyles,
  exportButton: {
    marginRight: '1rem',
  },
  listElement: {
    paddingLeft: 0,
  },
})

const ExportReferencedEntityButton = ({
  primaryReferencedEntityId,
  xlsExportFieldOptions,
  xlsExportTransform,
}: {
  primaryReferencedEntityId?: string
  xlsExportFieldOptions?: XlsExportFieldOption[]
  xlsExportTransform?: (records: any[], dataProvider: DataProvider) => Promise<any[]> | any[]
}) => {
  const dataProvider = useDataProvider()
  const transform = useCallback(
    async (data: LooseDbRecord[]) =>
      chain(await (xlsExportTransform ?? ((x) => x))(data, dataProvider))
        .map((record) => ({
          ...record,
          //  Add a `primary` field
          primary: record.id === primaryReferencedEntityId,
        }))
        // Make the primary record first
        .sortBy((record) => !record.primary)
        .value(),
    [dataProvider, primaryReferencedEntityId, xlsExportTransform],
  )
  return <XlsExportButton transform={transform} fieldOptions={xlsExportFieldOptions} />
}
