import { gql } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import React, { FC, useState } from 'react'
import { useNotify, useRefresh } from 'react-admin'
import { client } from '../../../apollo-client'
import { DbRecord } from '../../../entity-types'
import { DefaultDuplicateButton } from '../../common/default-buttons'

const useStyles = makeStyles({
  button: {
    textTransform: 'capitalize',
    fontSize: '11px',
  },
})

interface Props {
  record?: DbRecord
}

const DuplicateActivityButton: FC<Props> = ({ record }: Props) => {
  const [loading, setLoading] = useState(false)
  const notify = useNotify()
  const refresh = useRefresh()
  const classes = useStyles()

  async function handleClick() {
    if (!record) {
      return
    }

    try {
      setLoading(true)

      const MUTATION = gql`
      mutation {
        duplicateActivity(id: "${record.id}") {
          id
        }
      }`

      const success = await client.mutate({ mutation: MUTATION })
      if (success) {
        notify('texts.duplicateSuccess', 'info')
        refresh()
      }
      setLoading(false)
    } catch (error) {
      notify(String(error), 'warning')
      setLoading(false)
    }
  }

  return (
    <DefaultDuplicateButton className={classes.button} onClick={handleClick} disabled={loading} />
  )
}

export default DuplicateActivityButton
