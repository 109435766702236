import { Permission, RoleType } from '../../../auth-provider'
import { composeFunctions } from '../../../helpers/compose-functions'
import { idEditTransform } from '../../../helpers/id-edit-transform'
import { nullifyUndefinedFields } from '../../../helpers/nullify-undefined-fields'

export const activityDocTransform = composeFunctions(
  nullifyUndefinedFields,
  idEditTransform(
    ['domain', 'association', 'primary_contact', 'primary_address'],
    ['tags', 'addresses', 'contacts'],
  ),
)

export const maxTags = (permissions: Permission | undefined, max: number, text: string) => (
  value: { id: string }[],
) => {
  if (!permissions) {
    return undefined
  }

  if (
    (permissions.type === RoleType.ASSO || permissions.type === RoleType.ACTIVITY) &&
    value &&
    value.length > max
  ) {
    return text
  }

  return undefined
}
