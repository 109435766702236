import { makeStyles } from '@material-ui/core'
import { isArray } from 'lodash'
import React, { useMemo } from 'react'
import { ReferenceArrayInputProps, ReferenceInput, SelectInput } from 'react-admin'
import { TAG_RESOURCE_NAME } from '../constants'
import { TagType } from '../models/tag'

interface TagReferenceInputProps extends Omit<ReferenceArrayInputProps, 'children' | 'reference'> {
  source: string
  label?: string
  helperText?: string
  includeHidden?: boolean
  tagTypes?: TagType | TagType[]
}

const useStyles = makeStyles({
  input: {
    minWidth: '15em',
  },
})

const createTagFilter = (tagTypes: TagType | TagType[] | undefined, includeHidden: boolean) => {
  if (tagTypes === undefined) {
    return undefined
  }
  const includeHiddenFilter = includeHidden ? null : { visible: true }
  if (isArray(tagTypes)) {
    if (tagTypes.length === 0) {
      return undefined
    }
    return { types: tagTypes, ...includeHiddenFilter }
  }
  return { types: [tagTypes], ...includeHiddenFilter }
}

export const useTagFilterToQuery = (
  tagTypes: TagType | TagType[] | undefined,
  includeHidden = false,
) => {
  return useMemo(() => {
    const filter = createTagFilter(tagTypes, includeHidden)
    if (!filter) {
      return undefined
    }
    return (searchText: string) => ({ ...(searchText ? { q: searchText } : {}), ...filter })
  }, [includeHidden, tagTypes])
}

export const TagReferenceInput = ({
  source,
  label,
  helperText,
  tagTypes,
  includeHidden: showHidden,
  ...props
}: TagReferenceInputProps) => {
  const classes = useStyles()
  const filterToQuery = useTagFilterToQuery(tagTypes, showHidden)
  return (
    <ReferenceInput
      {...props}
      classes={classes}
      variant="outlined"
      label={label}
      source={source}
      reference={TAG_RESOURCE_NAME}
      filterToQuery={filterToQuery}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={10000}
      helperText={helperText}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  )
}
