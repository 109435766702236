import React, { FC } from 'react'
import { useTranslate } from 'react-admin'

interface Props {
  source: string
  record?: Record<string, boolean>
}

const BooleanFieldIntext: FC<Props> = ({ source, record = {} }: any) => {
  const translate = useTranslate()

  if (!!record[source]) {
    return <span>{translate('ra.boolean.true')}</span>
  }
  return <span></span>
}

export default BooleanFieldIntext
