import { makeStyles } from '@material-ui/core'
import { useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { Permission, RoleType } from '../../../auth-provider'
import { composeFunctions } from '../../../helpers/compose-functions'
import { idEditTransform } from '../../../helpers/id-edit-transform'
import { nullifyEmptyStringFields } from '../../../helpers/nullify-empty-string-fields'
import { nullifyUndefinedFields } from '../../../helpers/nullify-undefined-fields'
import { MISSION_RECURRENCE_CHOICES } from '../../constants'

export const missionDocEditTransform = composeFunctions(
  nullifyUndefinedFields,
  nullifyEmptyStringFields,
  idEditTransform(
    ['domain', 'association', 'primary_contact', 'primary_address'],
    ['addresses', 'contacts', 'causes', 'skills'],
  ),
)

export const missionDocCreateTransform = composeFunctions(
  nullifyUndefinedFields,
  nullifyEmptyStringFields,
)

export const maxTags = (permissions: Permission, max: number, text: string) => (
  value: { id: string }[],
) => {
  if (
    (permissions.type === RoleType.ASSO || permissions.type === RoleType.ACTIVITY) &&
    value &&
    value.length > max
  ) {
    return text
  }

  return undefined
}

export const useMissionRecurrenceChoices = () => {
  const translate = useTranslate()
  return useMemo(
    () =>
      MISSION_RECURRENCE_CHOICES.map((choice) => ({
        id: choice,
        name: translate(`resources.Mission.recurrence.${choice}`),
      })),
    [translate],
  )
}

export const useCreateEditFormsStyles = makeStyles({
  descriptionTextArea: {
    minHeight: '5rem',
  },
  gridLayout: {
    maxWidth: '60rem',
    padding: 0,
  },
  raBoxRow: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'flex-start',
    justifyItems: 'flex-start',
    justifyContent: 'flex-start',
    '& > * + *': {
      marginLeft: '1rem',
    },
  },
  flexGrow1: {
    flexGrow: 1,
  },
  flexGrow2: {
    flexGrow: 2,
  },
  smallTopMargin: {
    marginTop: '1rem',
  },
})
