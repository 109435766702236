import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import React from 'react'
import {
  ArrayField,
  ArrayInput,
  Datagrid,
  SimpleFormIterator,
  TextField,
  TextInput,
  UrlField,
  useTranslate,
} from 'react-admin'
import { NonInput } from './non-input'

const CustomButtonAddButton = (props: any) => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <Button
      size="small"
      variant="contained"
      color="primary"
      {...props}
      className={classes.customButtonAdd}
    >
      <AddIcon className={classes.customButtonAddLeftIcon} />
      {translate('resources.CustomButton.addButtonLabel')}
    </Button>
  )
}

export const CustomButtonArrayInput = ({ source }: { source: string }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const customButtonListClasses = useCustomButtonListClasses()
  return (
    <>
      <NonInput>
        <Typography component="h2" className={classes.customButtonTitle}>
          {translate('resources.CustomButton.header')}
        </Typography>
        <Typography component="h3" className={classes.customButtonsDescription}>
          {translate(`resources.CustomButton.description`)}
        </Typography>
      </NonInput>
      <ArrayInput source={source} label="">
        <SimpleFormIterator addButton={<CustomButtonAddButton />} classes={customButtonListClasses}>
          <TextInput
            variant="outlined"
            source="title"
            label="resources.CustomButton.fields.title"
          />
          <TextInput
            className={classes.customButtonUrlField}
            variant="outlined"
            source="url"
            label="resources.CustomButton.fields.url"
            helperText="resources.CustomButton.helperTexts.url"
          />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  )
}

export const CustomButtonArrayField = ({ source }: { source: string }) => {
  return (
    <ArrayField source={source}>
      <Datagrid>
        <TextField source="title" label="resources.CustomButton.fields.title" />
        <UrlField source="url" label="resources.CustomButton.fields.url" />
      </Datagrid>
    </ArrayField>
  )
}

const useStyles = makeStyles((theme) => ({
  customButtonsDescription: {
    color: 'grey',
    marginBottom: '0 !important',
  },
  customButtonTitle: {
    marginTop: `${theme.spacing(5)}px !important`,
    marginBottom: `0 !important`,
  },
  customButtonAdd: {
    borderRadius: 20,
    paddingTop: '5px',
    paddingRight: theme.spacing(2),
  },
  customButtonAddLeftIcon: {
    marginRight: theme.spacing(1),
  },
  customButtonUrlField: {
    marginLeft: theme.spacing(1),
  },
}))
const useCustomButtonListClasses = makeStyles({
  root: {
    marginTop: 0,
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
  },
  line: {
    border: 'none',
  },
  action: {
    marginTop: '20px',
  },
})
