import React from 'react'
import { useDomain } from '../contexts/domain-context'

/**
 * Get an url creator associated to the domain provided by the DomainContext.
 * Useful to express urls relative to the current domain.
 */

export const useAreDomainMissionsEnabled = (): boolean => {
  const domain = useDomain()
  return Boolean(domain?.enable_mission)
}

export const MissionsDisabledMessage = () => {
  return <div>Le bénévolat n&apos;est pas activé sur ce domaine.</div>
}
