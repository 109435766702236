import { FormHelperText } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { BoxedShowLayout, RaBox } from 'ra-compact-ui'
import React from 'react'
import {
  BooleanInput,
  Create,
  DateInput,
  HandleSubmitWithRedirect,
  ReactAdminComponentProps,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import { Mission } from '../../../entity-types'
import { modelStyles } from '../../../helpers/model-styles'
import { NonInput } from '../../common/non-input'
import PortailLinkButton from '../../common/portail-link-button'
import { TagReferenceArrayInput } from '../../common/tag-reference-array-input'
import { ToolbarSaveButton, TopToolbar } from '../../common/toolbar'
import { useAssociation } from '../../contexts/association-context'
import {
  MissionsDisabledMessage,
  useAreDomainMissionsEnabled,
} from '../../hook/useAreDomainMissionsEnabled'
import { TagType } from '../tag'
import {
  missionDocCreateTransform,
  useCreateEditFormsStyles,
  useMissionRecurrenceChoices,
} from './mission'

const useModelStyles = makeStyles(modelStyles)

export const MissionCreate = (props: ReactAdminComponentProps) => {
  const areDomainMissionsEnabled = useAreDomainMissionsEnabled()
  const association = useAssociation()
  const translate = useTranslate()
  const modelClasses = useModelStyles()
  const classes = useStyles()
  const formClasses = useCreateEditFormsStyles()

  const recurrenceChoices = useMissionRecurrenceChoices()

  if (!association) {
    // Needed to prevent SimpleForm.initialValues to have an empty association.
    return null
  }
  if (!areDomainMissionsEnabled) {
    return <MissionsDisabledMessage />
  }

  const initialValues = { association: association.id, disabled: false }

  return (
    <Create {...props} classes={{ card: modelClasses.card }} transform={missionDocCreateTransform}>
      <SimpleForm
        toolbar={<Actions />}
        redirect="list"
        className={modelClasses.edit}
        initialValues={initialValues}
      >
        <NonInput>
          <Typography component="h1">{translate('resources.Mission.title')}</Typography>
          <Typography component="h3">{translate('resources.Mission.subtitle')}</Typography>
          <Divider />
        </NonInput>
        <TextInput variant="outlined" source="title" validate={[required()]} fullWidth />
        <TextInput
          variant="outlined"
          multiline
          source="description_text"
          fullWidth
          helperText="resources.Mission.helperTexts.description_text"
          InputProps={{ classes: { input: formClasses.descriptionTextArea } }}
        />
        <BoxedShowLayout className={formClasses.gridLayout}>
          <RaBox className={formClasses.raBoxRow}>
            <RaBox className={formClasses.flexGrow2}>
              <SelectInput
                variant="outlined"
                source="recurrence"
                choices={recurrenceChoices}
                fullWidth
                validate={[required()]}
                defaultValue={'Recurrent'}
              />
            </RaBox>
            <RaBox className={formClasses.flexGrow1}>
              <DateInput source="start_date" fullWidth variant="outlined" />
            </RaBox>
            <RaBox className={formClasses.flexGrow1}>
              <DateInput source="end_date" fullWidth variant="outlined" />
            </RaBox>
          </RaBox>
          <NonInput className={classes.recurrenceHelperText}>
            <FormHelperText>{translate('resources.Mission.helperTexts.recurrence')}</FormHelperText>
          </NonInput>
          <RaBox className={formClasses.raBoxRow}>
            <RaBox className={formClasses.flexGrow2}>
              <TagReferenceArrayInput
                source="causes"
                maxTagCount={6}
                fullWidth
                helperText="resources.Mission.helperTexts.causes"
                tagTypes={TagType.MissionCause}
              />
            </RaBox>
            <RaBox className={formClasses.flexGrow2}>
              <TagReferenceArrayInput
                source="skills"
                maxTagCount={6}
                fullWidth
                helperText="resources.Mission.helperTexts.skills"
                tagTypes={TagType.MissionSkill}
              />
            </RaBox>
          </RaBox>
          <RaBox className={formClasses.smallTopMargin}>
            <BooleanInput source="disabled" helperText="resources.Mission.helperTexts.disabled" />
          </RaBox>
        </BoxedShowLayout>
      </SimpleForm>
    </Create>
  )
}

const Actions = ({
  basePath,
  data,
  handleSubmitWithRedirect,
}: {
  basePath?: string
  data?: Mission
  handleSubmitWithRedirect?: HandleSubmitWithRedirect
}) => {
  return (
    <TopToolbar layoutType="action">
      <PortailLinkButton />
      <ToolbarSaveButton
        record={data}
        basePath={basePath}
        handleSubmitWithRedirect={handleSubmitWithRedirect}
      />
    </TopToolbar>
  )
}

const useStyles = makeStyles({
  layout: {
    width: '100%',
  },
  raBoxRow: {
    width: '100%',
  },
  recurrenceHelperText: {
    marginTop: '-1em',
    marginBottom: '2em',
  },
})
