import React from 'react'
import { Loading } from 'react-admin'
import { Permission } from '../../../auth-provider'
import { ContactListInput } from '../../common/contact-list-input'
import { ACTIVITY_RESOURCE_NAME } from '../../constants'
import { useActivity } from '../../contexts/activity-context'
import { useThisActivityUrls } from '../../hook/use-this-activity-urls'
import { activityDocTransform } from './activity'

export const ActivityContactList = ({
  basePath,
  permissions,
}: {
  basePath: string
  permissions: Permission
}) => {
  const activityUrls = useThisActivityUrls()
  const activity = useActivity()
  const activityId = activity?.id
  if (!activityId || !activityUrls) {
    return null
  }

  if (!permissions) {
    return <Loading />
  }

  return (
    <ContactListInput
      resource={ACTIVITY_RESOURCE_NAME}
      id={activityId}
      basePath={basePath}
      editTransform={activityDocTransform}
      contactBasePath={activityUrls.contact.basePath}
      editContact={false}
    />
  )
}
