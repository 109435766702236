import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { Button, useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'
import { Association, Domain } from '../../entity-types'
import { URLS } from '../../routes/url-creators'
import { DOMAIN_RESOURCE_NAME } from '../constants'
import { useGetOneById } from '../hook/use-get-one-by-id'
import { ShowIcon } from './icons'

/**
 * This component is just like a <ShowButton />.
 * However, because it is used from different paths:
 * - AssociationRole landing page with path = /Association
 * - Association's Activities with path = /Domain/123/Association/456/Association
 * it cannot simply rely on the props-provided basePath.
 * Instead we fetch the domain/association in order to build a complete url to the given association.
 */

const useStyles = makeStyles({
  button: {
    textTransform: 'capitalize',
    fontSize: '11px',
  },
})

export const ShowAssociationButton = ({ record }: { record?: Association }) => {
  const classes = useStyles()
  const translate = useTranslate()

  const { data: domain } = useGetOneById<Domain>(DOMAIN_RESOURCE_NAME, record?.domain.id)

  if (!record || !domain) {
    return null
  }

  return (
    <Button
      label={translate('resources.Domain.button.open')}
      component={Link}
      className={classes.button}
      to={URLS.domain(domain.domain_name).association(record.id).show}
    >
      <ShowIcon />
    </Button>
  )
}
