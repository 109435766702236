import { Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ActivityIcon from '@material-ui/icons/BusinessCenter'
import React, { ComponentType, useMemo } from 'react'
import { useGetMainList, useTranslate } from 'react-admin'
import { useModelStyles } from '../../../helpers/model-styles'
import CountCard from '../../common/count-card'
import { AssociationIcon, ContactIcon, MissionIcon } from '../../common/icons'
import {
  ACTIVITY_RESOURCE_NAME,
  ASSOCIATION_RESOURCE_NAME,
  CONTACT_RESOURCE_NAME,
  MISSION_RESOURCE_NAME,
} from '../../constants'
import { useDomain } from '../../contexts/domain-context'
import { DomainHistoryList } from './history/history'

const useStyles = makeStyles({
  badgeContainer: {
    marginTop: '1rem',
    marginBottom: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export const Dashboard = () => {
  const domain = useDomain()
  const classes = useStyles()
  const modelClasses = useModelStyles()
  const translate = useTranslate()

  if (!domain) {
    return null
  }

  return (
    <div>
      <div className={classes.badgeContainer}>
        <AssociationBadge />
        <ActivityBadge />
        <ContactBadge />
        <MissionBadge />
      </div>
      <div className={modelClasses.show}>
        <Typography component="h1">{translate('resources.History.title')}</Typography>
        <Divider />
      </div>
      <DomainHistoryList />
    </div>
  )
}

const DomainBadge = ({
  resource,
  icon,
  labelKey,
}: {
  resource: string
  icon: ComponentType<any>
  labelKey: string
}) => {
  const translate = useTranslate()
  const domain = useDomain()
  const domainId = domain?.id
  const filter = useMemo(
    () => ({
      domain: domainId,
    }),
    [domainId],
  )
  const isDomainSpecified = Boolean(domainId)
  const options = useMemo(() => ({ enabled: isDomainSpecified }), [isDomainSpecified])
  const { total, loaded } = useGetMainList(
    resource,
    { page: 1, perPage: 1 }, // We dont really need any document, but 0 is interpreted by the backend as "default".
    { field: 'id', order: 'ASC' },
    filter,
    options,
  )
  if (!loaded) {
    return null
  }
  return (
    <CountCard
      Icon={icon}
      bgColor="#007b8c"
      name={translate(`resources.Domain.Dashboard.Cards.${labelKey}`)}
      count={total}
    />
  )
}

const AssociationBadge = () => (
  <DomainBadge resource={ASSOCIATION_RESOURCE_NAME} icon={AssociationIcon} labelKey="Association" />
)

const ActivityBadge = () => (
  <DomainBadge resource={ACTIVITY_RESOURCE_NAME} icon={ActivityIcon} labelKey="Activity" />
)

const ContactBadge = () => (
  <DomainBadge resource={CONTACT_RESOURCE_NAME} icon={ContactIcon} labelKey="Contact" />
)

const MissionBadge = () => (
  <DomainBadge resource={MISSION_RESOURCE_NAME} icon={MissionIcon} labelKey="Mission" />
)
