import { createContext } from 'react'
import { matchPath } from 'react-router-dom'
import { Association } from '../../entity-types'
import { URLS } from '../../routes/url-creators'
import { ASSOCIATION_RESOURCE_NAME } from '../constants'
import { createContextFromUrl } from './create-context-from-url'

interface AssociationContextValue {
  association?: Association
  isLoading: boolean
  isMatch: boolean
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const Context = createContext<AssociationContextValue>(null!)

const getIdFromPathName = (pathname: string) => {
  const basePath = URLS.domain(':domain_name').association(':id').basePath
  const match = matchPath(pathname, { path: basePath })
  return (match?.params as { id?: string })?.id
}

const { useEntityContext, useEntity, EntityContextFromUrl } = createContextFromUrl<Association>(
  ASSOCIATION_RESOURCE_NAME,
  Context,
  getIdFromPathName,
  {
    idParamsBlacklist: ['create'],
  },
)

export const useAssociationContext = () => {
  const { entity, ...others } = useEntityContext()
  return {
    association: entity,
    ...others,
  }
}

export const useAssociation = useEntity

export const AssociationContextFromUrl = EntityContextFromUrl
