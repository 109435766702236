import { gql } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import decodeJwt from 'jwt-decode'
import { client } from './apollo-client'

export const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

export enum RoleType {
  NONE = 'none',
  DEV = 'dev',
  ADMIN = 'admin',
  DOMAIN = 'domain',
  ASSO = 'association',
  ACTIVITY = 'activity',
}

export const ALL_ROLE_TYPES = [
  RoleType.DEV,
  RoleType.ADMIN,
  RoleType.DOMAIN,
  RoleType.ASSO,
  RoleType.ACTIVITY,
]

export const roleTypeToGraphlQlEnum: Record<RoleType, string | undefined> = {
  // The backend's graphql uses the enum's keys
  [RoleType.NONE]: undefined,
  [RoleType.DEV]: 'DEV',
  [RoleType.ADMIN]: 'ADMIN',
  [RoleType.DOMAIN]: 'DOMAIN',
  [RoleType.ASSO]: 'ASSO',
  [RoleType.ACTIVITY]: 'ACTIVITY',
}

export interface Role {
  type: RoleType
  domain?: string
  association?: string
  activity?: string
}

export interface Permission {
  type: RoleType
  domains?: string[]
  associations?: string[]
  activities?: string[]
}

export const authProvider = {
  async login({ username, password }: { username: string; password: string }) {
    const LOGIN_MUTATION = gql`
      mutation {
        login(email: "${username}", password: "${password}")
      }
    `
    const result = await client.mutate({ mutation: LOGIN_MUTATION })

    localStorage.setItem('token', result.data.login)

    const decodedToken: { roles: Role[] } = decodeJwt(result.data.login)
    localStorage.setItem('permissions', JSON.stringify(decodedToken.roles))

    const permissions = await this.getPermissions()

    if (permissions.type === RoleType.NONE) {
      throw new Error('Unauthorized')
    }
  },
  async logout() {
    localStorage.removeItem('token')
    localStorage.removeItem('permissions')
    // React admin redirects to login page after that.
  },
  async checkAuth() {
    const token = localStorage.getItem('token')

    if (!token) {
      throw new Error('Unauthorized')
    }

    if ((await this.getPermissions()).type === RoleType.NONE) {
      throw new Error('Unauthorized')
    }
  },
  async checkError(error: Error) {
    if (
      error.message === 'Token expired' ||
      error.message === 'Invalid token' ||
      error.message.search('Argument') !== -1
    ) {
      authProvider.logout()
      throw error
    }
  },
  async getPermissions(): Promise<Permission> {
    const permissions = localStorage.getItem('permissions')
    if (!permissions) {
      throw new Error('Unauthorized')
    }
    try {
      const objPermissions: Role[] = JSON.parse(permissions)
      if (objPermissions.find((permission) => permission.type === RoleType.DEV)) {
        return { type: RoleType.DEV }
      } else if (objPermissions.find((permission) => permission.type === RoleType.ADMIN)) {
        return { type: RoleType.ADMIN }
      } else if (objPermissions.find((permission) => permission.type === RoleType.DOMAIN)) {
        const domains = objPermissions.reduce((domains: string[], permission) => {
          if (permission.type === RoleType.DOMAIN && permission.domain) {
            domains.push(permission.domain)
          }
          return domains
        }, [])
        return { type: RoleType.DOMAIN, domains }
      } else if (objPermissions.find((permission) => permission.type === RoleType.ASSO)) {
        const associations = objPermissions.reduce((associations: string[], permission) => {
          if (permission.type === RoleType.ASSO && permission.association) {
            associations.push(permission.association)
          }
          return associations
        }, [])
        return { type: RoleType.ASSO, associations }
      } else if (objPermissions.find((permission) => permission.type === RoleType.ACTIVITY)) {
        const activities = objPermissions.reduce((activities: string[], permission) => {
          if (permission.type === RoleType.ACTIVITY && permission.activity) {
            activities.push(permission.activity)
          }
          return activities
        }, [])
        return { type: RoleType.ACTIVITY, activities }
      }
      return { type: RoleType.NONE }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(err)
      throw new Error('Unauthorized')
    }
  },
}
