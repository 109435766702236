import { useEffect } from 'react'
import { usePermissions, useRedirect } from 'react-admin'
import { getLandingPageForRoleType } from '../common/landing-page'

export const useRedirectToLandingPageIfRoot = () => {
  const redirect = useRedirect()
  const { permissions } = usePermissions()

  const hash = window.location.hash
  // console.log("match", match)
  const permissionType = permissions?.type

  useEffect(() => {
    if (hash === '#/' && permissionType) {
      redirect(getLandingPageForRoleType(permissionType))
    }
  }, [permissionType, redirect, hash])
}
