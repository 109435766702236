import { makeStyles } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { BoxedShowLayout } from 'ra-compact-ui'
import React, { useMemo } from 'react'
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  ImageField,
  List,
  ReferenceField,
  Show,
  SingleFieldList,
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin'
import { Activity, Association, DbRecord, DomainHighlight, Mission } from '../../../entity-types'
import { useModelStyles } from '../../../helpers/model-styles'
import { NonInput } from '../../common/non-input'
import PortailLinkButton from '../../common/portail-link-button'
import { TagReferenceArrayField } from '../../common/tag-reference-array-field'
import { EnableDisableButton, ToolbarEditButton, TopToolbar } from '../../common/toolbar'
import {
  ACTIVITY_RESOURCE_NAME,
  ASSOCIATION_RESOURCE_NAME,
  MISSION_RESOURCE_NAME,
} from '../../constants'
import {
  domainHighlightGuessSelectionTypeFromRecord,
  DOMAIN_HIGHLIGHT_SELECTION_TYPE_FIELD,
} from './transform'
export const DomainHighlightShow = (props: any) => {
  const translate = useTranslate()
  const classes = useModelStyles()

  return (
    <Show
      title={translate('resources.DomainHighlight.name')}
      {...props}
      actions={<Actions />}
      classes={{ card: classes.card }}
    >
      <Layout />
    </Show>
  )
}

const Layout = ({ record }: { record?: DomainHighlight }) => {
  const translate = useTranslate()
  const classes = useModelStyles()

  const selectionType = domainHighlightGuessSelectionTypeFromRecord(record)

  return (
    <BoxedShowLayout className={classes.show}>
      <NonInput>
        <Typography component="h1">{translate('resources.DomainHighlight.title')}</Typography>
        <Typography component="h3">{translate('resources.DomainHighlight.subtitle')}</Typography>
        <Divider />
      </NonInput>
      <ImageField source="preview_img_url" />
      <TextField source="name" />
      <ReferenceField
        label={translate('resources.DomainHighlight.fields.domain')}
        source="domain.id"
        reference="Domain"
      >
        <TextField source="domain_name" />
      </ReferenceField>
      <TextField source="description_text" />
      <DateField source="createdAt" />
      {selectionType && (
        <NonInput>
          <Typography component="h2">
            {translate(`resources.DomainHighlight.fields.${DOMAIN_HIGHLIGHT_SELECTION_TYPE_FIELD}`)}
            {': '}
            {translate(`resources.DomainHighlight.selectionTypes.${selectionType}`)}
          </Typography>
        </NonInput>
      )}
      {selectionType === 'criteria' && (
        <ArrayField source="age_sub_groups">
          <SingleFieldList linkType={false}>
            <ReferenceField source="id" reference="AgeSubGroup">
              <ChipField source="name" />
            </ReferenceField>
          </SingleFieldList>
        </ArrayField>
      )}
      {selectionType === 'criteria' && (
        <ArrayField source="schedule_groups">
          <SingleFieldList linkType={false}>
            <ReferenceField source="id" reference="ScheduleGroup">
              <ChipField source="name" />
            </ReferenceField>
          </SingleFieldList>
        </ArrayField>
      )}
      {selectionType === 'criteria' && <TagReferenceArrayField source="tags" addLabel={true} />}
      {selectionType === 'criteria' && <TextField source="days" />}
      {selectionType === 'associations' && <SelectionByAssociations />}
      {selectionType === 'activities' && <SelectionByActivities />}
      {selectionType === 'missions' && <SelectionByMissions />}
    </BoxedShowLayout>
  )
}

const useStyles = makeStyles({
  assoList: {
    marginTop: '1rem',
  },
  thumbnail: {
    '& img': {
      maxHeight: '20px',
      margin: 0,
    },
  },
})

const SelectionByAssociations = () => {
  const record = useRecordContext({})

  const classes = useStyles()
  const filter = useMemo(
    () => ({ ids: record?.associations?.map((asso: Association) => asso.id) }),
    [record],
  )
  return (
    <List
      resource={ASSOCIATION_RESOURCE_NAME}
      filter={filter}
      basePath=""
      bulkActionButtons={false}
      actions={false}
      perPage={1000}
      pagination={false}
      className={classes.assoList}
    >
      <Datagrid>
        <ImageField className={classes.thumbnail} source="logo_url" title="logo" />
        <TextField source="name" />
      </Datagrid>
    </List>
  )
}

const SelectionByActivities = () => {
  const record = useRecordContext({})

  const classes = useStyles()
  const filter = useMemo(() => ({ ids: record?.activities?.map((asso: Activity) => asso.id) }), [
    record,
  ])
  return (
    <List
      resource={ACTIVITY_RESOURCE_NAME}
      filter={filter}
      basePath=""
      bulkActionButtons={false}
      actions={false}
      perPage={1000}
      pagination={false}
      className={classes.assoList}
    >
      <Datagrid>
        <TextField source="title" />
        <TagReferenceArrayField source="tags" />
      </Datagrid>
    </List>
  )
}

const SelectionByMissions = () => {
  const record = useRecordContext({})

  const classes = useStyles()
  const filter = useMemo(() => ({ ids: record?.missions?.map((asso: Mission) => asso.id) }), [
    record,
  ])
  return (
    <List
      resource={MISSION_RESOURCE_NAME}
      filter={filter}
      basePath=""
      bulkActionButtons={false}
      actions={false}
      perPage={1000}
      pagination={false}
      className={classes.assoList}
    >
      <Datagrid>
        <TextField source="title" />
      </Datagrid>
    </List>
  )
}

const Actions = ({
  basePath,
  data,
  resource,
}: {
  basePath?: string
  data?: DbRecord
  resource?: string
}) => {
  return (
    <TopToolbar>
      <EnableDisableButton resource={resource} data={data} />
      <PortailLinkButton />
      <ToolbarEditButton record={data} basePath={basePath} />
    </TopToolbar>
  )
}
