import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { BoxedShowLayout } from 'ra-compact-ui'
import React from 'react'
import {
  DateField,
  NumberField,
  ReferenceField,
  Show,
  TextField,
  TopToolbar,
  useTranslate,
} from 'react-admin'
import { DbRecord } from '../../../entity-types'
import { useModelStyles } from '../../../helpers/model-styles'
import { NonInput } from '../../common/non-input'
import { ToolbarEditButton } from '../../common/toolbar'
import { useActivity } from '../../contexts/activity-context'

interface AddressShowProps {
  basePath?: string
  resource?: string
  id?: string
  ownerType?: 'Association' | 'Activity' | 'Mission' | 'Domain'
  edit?: boolean
}

export const AddressShow = ({ resource, basePath, id, ownerType, edit }: AddressShowProps) => {
  const activity = useActivity()
  const translate = useTranslate()
  const classes = useModelStyles()

  return (
    <Show
      basePath={basePath}
      resource={resource}
      id={id}
      title={translate('resources.Address.name')}
      actions={<Actions edit={edit} />}
      classes={{ card: classes.card }}
    >
      <BoxedShowLayout className={classes.show}>
        <NonInput>
          <Typography component="h1">{translate(`resources.Address.title${ownerType}`)}</Typography>
          <Typography component="h3">
            {translate(`resources.Address.subtitle${ownerType}`)}
          </Typography>
          <Divider />
        </NonInput>
        <TextField source="full_address" />
        <TextField source="complement" />
        <TextField source="street" />
        <TextField source="zipcode" />
        <TextField source="city" />
        <TextField source="country" />
        <NumberField source="latitude" />
        <NumberField source="longitude" />
        {activity ? (
          <ReferenceField
            label={translate('resources.Address.fields.association')}
            source="activity.id"
            reference="Activity"
          >
            <TextField source="title" />
          </ReferenceField>
        ) : null}
        {!activity ? (
          <ReferenceField
            label={translate('resources.Address.fields.association')}
            source="association.id"
            reference="Association"
          >
            <TextField source="name" />
          </ReferenceField>
        ) : null}
        <DateField source="createdAt" />
      </BoxedShowLayout>
    </Show>
  )
}

const Actions = ({
  basePath,
  data,
  edit,
}: {
  basePath?: string
  data?: DbRecord
  edit?: boolean
}) => {
  return (
    <TopToolbar>
      {edit !== false && <ToolbarEditButton record={data} basePath={basePath} />}
    </TopToolbar>
  )
}
