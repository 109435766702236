import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import { Mission } from '../../entity-types'
import { useMission } from '../contexts/mission-context'
import { useThisAssociationUrls } from '../hook/use-this-association-urls'
import { useMissionUrls } from '../hook/use-this-mission-urls'
import { LinkMenuItem } from './menu-item'

export const useStyles = makeStyles({
  root: {
    '& > *': {
      minHeight: '36px',
    },
  },
})

export const MissionMenu = ({
  className,
  mission: missionFromProps,
}: {
  className?: string
  mission?: Mission
}) => {
  const classes = useStyles()
  const missionFromContext = useMission()
  const mission = missionFromProps || missionFromContext
  const thisAssociationUrls = useThisAssociationUrls()
  const thisMissionUrls = useMissionUrls(mission?.id)

  if (!mission || !thisAssociationUrls || !thisMissionUrls) {
    return null
  }

  const thisMissionUrl = thisAssociationUrls.mission(mission.id)
  return (
    <div className={classNames(className, classes.root)}>
      <LinkMenuItem to={thisMissionUrls.show} primaryText={mission.title} subItem />
      <LinkMenuItem
        to={thisMissionUrl.contact.list}
        primaryTextKey="menu.missionMenu.Contact"
        subItem
      />
      <LinkMenuItem
        to={thisMissionUrl.address.list}
        exact={false}
        primaryTextKey="menu.missionMenu.Address"
        subItem
      />
    </div>
  )
}
