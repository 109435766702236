import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Link from '@material-ui/core/Link'
import { makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import { useLogin, useNotify, useSafeSetState, useTranslate } from 'ra-core'
import React, { FunctionComponent } from 'react'
import { Field, Form } from 'react-final-form'
import { useBooleanState } from '../hook/use-boolean-state'

interface Props {
  redirectTo?: string
}

interface FormData {
  username: string
  password: string
  condition: boolean
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    checkboxError: {
      marginTop: '-10px',
    },
  }),
  { name: 'RaLoginForm' },
)

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}: any) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
)

// Used only by devs
const isTermsCheckboxAutoChecked = Boolean(localStorage.getItem('dev_login_terms_autocheck'))

const LoginForm: FunctionComponent<Props> = (props) => {
  const { redirectTo } = props
  const [loading, setLoading] = useSafeSetState(false)
  const login = useLogin()
  const translate = useTranslate()
  const notify = useNotify()
  const classes = useStyles(props)
  const [termsCheck, , , toggleTermsCheck] = useBooleanState(isTermsCheckboxAutoChecked)

  const validate = (values: FormData) => {
    const errors: any = { username: undefined, password: undefined, condition: undefined }

    if (!values.username) {
      errors.username = translate('ra.validation.required')
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required')
    }
    if (!termsCheck) {
      errors.condition = translate('login.conditionHelperText')
    }
    return errors
  }

  const submit = (values: any) => {
    setLoading(true)
    login(values, redirectTo)
      .then(() => {
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          'warning',
          {
            _:
              typeof error === 'string'
                ? error
                : error && error.message
                ? error.message
                : undefined,
          },
        )
      })
  }

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.form}>
            <div className={classes.input}>
              <Field
                autoFocus
                id="username"
                name="username"
                component={Input}
                label={translate('ra.auth.username')}
                disabled={loading}
              />
            </div>
            <div className={classes.input}>
              <Field
                id="password"
                name="password"
                component={Input}
                label={translate('ra.auth.password')}
                type="password"
                disabled={loading}
                autoComplete="current-password"
              />
            </div>
            <div className={classes.input}>
              <Field name="condition">
                {({ meta }) => (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="condition"
                          name="condition"
                          checked={termsCheck}
                          onChange={toggleTermsCheck}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <>
                          {translate('login.conditionPrefix')}
                          <Link href="https://assolib.fr/cgu-asso/" target="_blank">
                            {translate('login.conditionSuffix')}
                          </Link>
                        </>
                      }
                    />
                    {meta.touched && meta.error && (
                      <FormHelperText error={true} className={classes.checkboxError}>
                        {meta.error}
                      </FormHelperText>
                    )}
                  </>
                )}
              </Field>
            </div>
          </div>
          <CardActions>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              className={classes.button}
            >
              {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActions>
        </form>
      )}
    />
  )
}

LoginForm.propTypes = {
  redirectTo: PropTypes.string,
}

export default LoginForm
