import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { BoxedShowLayout } from 'ra-compact-ui'
import React, { useMemo } from 'react'
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  Filter,
  List,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import { modelStyles } from '../../helpers/model-styles'
import { DefaultEditButton, DefaultShowButton } from '../common/default-buttons'
import EditToolbar from '../common/edit-toolbar'
import EmptyPage from '../common/empty-page'
import ImportButton from '../common/import-button'
import ListToolbar from '../common/list-toolbar'
import { NonInput } from '../common/non-input'
import Pagination from '../common/pagination'
import ShowToolbar from '../common/show-toolbar'
import { StatusToggleInput } from '../common/status-toggle-input'

const useStyles = makeStyles(modelStyles)

export enum TagType {
  Main = 'MAIN',
  Normal = 'NORMAL',
  MissionCause = 'MISSION_CAUSE',
  MissionSkill = 'MISSION_SKILL',
}

export const ALL_TAG_TYPES = [
  TagType.Main,
  TagType.Normal,
  TagType.MissionCause,
  TagType.MissionSkill,
]

const useTagTypeChoices = () => {
  const translate = useTranslate()
  return useMemo(
    () =>
      ALL_TAG_TYPES.map((type) => ({
        id: type,
        name: translate(`resources.Tag.values.${type}`),
      })),
    [translate],
  )
}

const TagListFilter = (props: any) => {
  const classes = useStyles()
  return (
    <Filter {...props} classes={{ form: classes.filter }} variant="outlined">
      <TextInput label="Name" source="q" alwaysOn />
    </Filter>
  )
}

export const TagList = (props: any) => {
  const classes = useStyles()

  const actions = <ListToolbar importButton={<ImportButton />} />

  const tagTypeChoices = useTagTypeChoices()

  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<TagListFilter />}
      pagination={<Pagination />}
      empty={<EmptyPage actions={actions} />}
      classes={{ content: classes.content }}
      actions={actions}
    >
      <Datagrid className={classes.list}>
        <TextField source="name" />
        <SelectField source="type" choices={tagTypeChoices} />
        <BooleanField source="visible" />
        <DateField source="createdAt" />
        <DefaultShowButton />
        <DefaultEditButton />
      </Datagrid>
    </List>
  )
}

export const TagCreate = (props: any) => {
  const classes = useStyles()
  const translate = useTranslate()
  const tagTypeChoices = useTagTypeChoices()

  return (
    <Create {...props} classes={{ card: classes.card }}>
      <SimpleForm redirect="list" toolbar={<EditToolbar disableLink />} className={classes.edit}>
        <NonInput>
          <Typography component="h1">{translate('resources.Tag.title')}</Typography>
          <Typography component="h3">{translate('resources.Tag.subtitle')}</Typography>
          <Divider />
        </NonInput>
        <TextInput variant="outlined" source="name" helperText="resources.Tag.helperTexts.name" />
        <SelectInput
          variant="outlined"
          source="type"
          choices={tagTypeChoices}
          helperText="resources.Tag.helperTexts.type"
        />
        <BooleanInput
          source="visible"
          initialValue={true}
          helperText="resources.Tag.helperTexts.visible"
        />
      </SimpleForm>
    </Create>
  )
}

export const TagEdit = (props: any) => {
  const translate = useTranslate()
  const classes = useStyles()
  const tagTypeChoices = useTagTypeChoices()

  return (
    <Edit
      title="Tag edit"
      {...props}
      undoable={false}
      classes={{ card: classes.card }}
      actions={false}
    >
      <SimpleForm toolbar={<EditToolbar />} className={classes.edit}>
        <NonInput>
          <Typography component="h1">{translate('resources.Tag.title')}</Typography>
          <Typography component="h3">{translate('resources.Tag.subtitle')}</Typography>
          <Divider />
        </NonInput>
        <TextInput variant="outlined" source="name" helperText="resources.Tag.helperTexts.name" />
        <SelectInput
          variant="outlined"
          source="type"
          choices={tagTypeChoices}
          helperText="resources.Tag.helperTexts.type"
        />
        <StatusToggleInput
          source="visible"
          reverse={true}
          initialValue={true}
          labelIfFalse="resources.Tag.status.hidden"
          labelIfTrue="resources.Tag.status.hidden"
        />
      </SimpleForm>
    </Edit>
  )
}

export const TagShow = (props: any) => {
  const translate = useTranslate()
  const classes = useStyles()
  const tagTypeChoices = useTagTypeChoices()

  return (
    <Show title="Tag show" {...props} actions={<ShowToolbar />} classes={{ card: classes.card }}>
      <BoxedShowLayout className={classes.show}>
        <NonInput>
          <Typography component="h1">{translate('resources.Tag.title')}</Typography>
          <Typography component="h3">{translate('resources.Tag.subtitle')}</Typography>
          <Divider />
        </NonInput>
        <TextField source="name" />
        <SelectField source="type" choices={tagTypeChoices} />
        <BooleanField source="visible" />
        <DateField source="createdAt" />
      </BoxedShowLayout>
    </Show>
  )
}
