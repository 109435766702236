import { cloneElement, ReactElement, useMemo } from 'react'
import { useListSortContext } from 'react-admin'
import { useSameObjectIfEqual } from '../../components/hook/use-same-object-if-equal'

/**
 * Allow to set a custom sorting function for a ListContext.
 *
 *
 * A classic <List> doesnt allow to sort fields with a custom function.
 *
 * <List>
 *   <Datagrid>
 *     <Field1 />
 *     <Field2 />
 *     <Field3 />
 *   </Datagrid>
 * </List>
 *
 * The <List> is cloning <Datagrid> and sets the loaded `ids` array to its props.
 *
 * The hack is to intercept this, and sort the `ids` array in the between.
 *
 * <List>
 *   <ReorderPropsIds datagridIdSort={(ids) => sortMyIds(ids)}>
 *     <Datagrid>
 *       <Field1 />
 *       <Field2 />
 *       <Field3 />
 *     </Datagrid>
 *   </ReorderPropsIds>
 * </List>
 *
 * NB: <List> will only load one page worth of ids, therefore be sure to use the same sorting at query-time.
 */

export const ReorderPropsIds = ({
  ids,
  datagridIdSort,
  children,
  ...props
}: {
  ids?: string[]
  datagridIdSort?: (ids: string[], currentSort?: { field: string }) => string[]
  children: ReactElement
} & unknown) => {
  const { currentSort } = useListSortContext()

  const resortedIds = useSameObjectIfEqual(
    useMemo(() => (datagridIdSort ? datagridIdSort(ids || [], currentSort) : []), [
      currentSort,
      datagridIdSort,
      ids,
    ]),
  )
  if (!datagridIdSort) {
    return children
  }
  return cloneElement(children, { ids: resortedIds, ...props })
}
