export const commonEditStyles = {
  marginTop: '34px',
  paddingLeft: '24px',
  '& h1': {
    fontWeight: 'bold',
    color: '#141414',
    fontSize: '22px',
    letterSpacing: '1.79px',
  },
  '& h2': {
    fontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '1.25px',
    marginTop: '18px',
    marginBottom: '18px',
  },
  '& h3': {
    fontWeight: '400',
    fontSize: '14px',
    letterSpacing: '1.07px',
    marginTop: '6px',
    marginBottom: '20px',
  },
  '& hr': {
    marginTop: '10px',
  },
  '& .ra-input > *': {
    minWidth: '256px',
    width: 'auto',
  },
  '& .ra-input': {
    marginTop: '20px',
  },
  '& .MuiFormControl-fullWidth': {
    width: '100% !important',
  },
  '& .MuiFormHelperText-root': {
    fontSize: '14px',
    letterSpacing: '0.4px',
    lineHeight: '16px',
  },
  '& .MuiDivider-root': {
    marginTop: '30px',
    marginBottom: '30px',
  },
}
