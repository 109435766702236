import { Typography } from '@material-ui/core'
import React from 'react'
import { Loading, useTranslate } from 'react-admin'
import { useModelStyles } from '../../../helpers/model-styles'
import { useAssociation } from '../../contexts/association-context'
import { useIsAtLeastAssoRole } from '../../hook/use-is-at-least-asso-role'
import { AddressEdit } from '../address/edit'

export const AssociationAddressEdit = ({ basePath, id }: { basePath: string; id: string }) => {
  const translate = useTranslate()
  const modelClasses = useModelStyles()
  const association = useAssociation()
  const associationId = association?.id

  if (!useIsAtLeastAssoRole() || !associationId) {
    return <Loading />
  }

  return (
    <div className={modelClasses.edit}>
      <Typography component="h1">{translate(`resources.Address.titleAssociation`)}</Typography>
      <Typography component="h3">{translate(`resources.Address.subtitleAssociation`)}</Typography>
      <AddressEdit basePath={basePath} id={id} />
    </div>
  )
}
