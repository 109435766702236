import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import {
  Create,
  ImageField,
  ImageInput,
  required,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import { useModelStyles } from '../../../helpers/model-styles'
import EditToolbar from '../../common/edit-toolbar'
import { NonInput } from '../../common/non-input'
import { useDomain } from '../../contexts/domain-context'
import { useIsAtLeastDomainRole } from '../../hook/use-is-at-least-domain-role'

export const DomainPartnerCreate = (props: unknown) => {
  const domain = useDomain()
  const translate = useTranslate()
  const classes = useModelStyles()

  if (!useIsAtLeastDomainRole() || !domain) {
    return null
  }

  return (
    <Create {...props} classes={{ card: classes.card }}>
      <SimpleForm
        redirect="list"
        toolbar={<EditToolbar disableLink />}
        className={classes.edit}
        initialValues={{ domain: domain ? domain.id : null }}
      >
        <NonInput>
          <Typography component="h1">{translate('resources.DomainPartner.title')}</Typography>
          <Typography component="h3">{translate('resources.DomainPartner.subtitle')}</Typography>
          <Divider />
        </NonInput>
        <ImageInput
          source="logo_url"
          accept="image/*"
          validate={[required()]}
          helperText="resources.DomainPartner.helperTexts.logo_url"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput variant="outlined" source="name" validate={[required()]} />
        <TextInput variant="outlined" source="url" validate={[required()]} />
      </SimpleForm>
    </Create>
  )
}
