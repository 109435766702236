import React from 'react'
import { URLS } from '../../routes/url-creators'
import { AssociationIcon, HomeIcon } from '../common/icons'
import { useAssociationContext } from '../contexts/association-context'
import { AssociationMenu } from './association-menu'
import { LinkMenuItem } from './menu-item'
import { MenuItemBack } from './menu-item-back'

export const AssoRoleRootMenu = () => {
  const { isMatch: isAssociationMatch } = useAssociationContext()
  if (isAssociationMatch) {
    return <AssociationSelectedRootMenu />
  }
  return <RootMenu />
}

const AssociationSelectedRootMenu = () => {
  return (
    <>
      <MenuItemBack
        to={URLS.association.list}
        primaryTextKey="menu.associationMenu.BackToAssociations"
      />
      <AssociationMenu />
    </>
  )
}

const RootMenu = () => {
  return (
    <>
      <LinkMenuItem
        to={URLS.assoRoleHome}
        exact={false}
        primaryTextKey="menu.associationMenu.AssoHome"
        leftIcon={<HomeIcon />}
      />
      <LinkMenuItem
        to={URLS.association.list}
        primaryTextKey="menu.domainMenu.Association"
        leftIcon={<AssociationIcon />}
      />
    </>
  )
}
