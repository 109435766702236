import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { BoxedShowLayout } from 'ra-compact-ui'
import React from 'react'
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EmailField,
  Filter,
  FormDataConsumer,
  List,
  ReferenceField,
  ReferenceInput,
  required,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  TextField,
  useTranslate,
} from 'react-admin'
import { idEditTransform } from '../../helpers/id-edit-transform'
import { modelStyles } from '../../helpers/model-styles'
import {
  DefaultDeleteButton,
  DefaultEditButton,
  DefaultShowButton,
} from '../common/default-buttons'
import EditToolbar from '../common/edit-toolbar'
import EmptyPage from '../common/empty-page'
import ListToolbar from '../common/list-toolbar'
import { NonInput } from '../common/non-input'
import Pagination from '../common/pagination'
import ShowToolbar from '../common/show-toolbar'

const useStyles = makeStyles(modelStyles)

const RoleType = [
  { id: 'DEV', name: 'DEV' },
  { id: 'ADMIN', name: 'ADMIN' },
  { id: 'DOMAIN', name: 'DOMAIN' },
  { id: 'ASSO', name: 'ASSO' },
  { id: 'ACTIVITY', name: 'ACTIVITY' },
]

const RoleListFilter = (props: any) => {
  const classes = useStyles()
  return (
    <Filter {...props} classes={{ form: classes.filter }} variant="outlined">
      <ReferenceInput source="domain" reference="Domain" alwaysOn>
        <AutocompleteInput optionText="domain_name" />
      </ReferenceInput>
      <ReferenceInput source="user" reference="User" alwaysOn>
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
    </Filter>
  )
}

export const RoleList = (props: any) => {
  const translate = useTranslate()
  const classes = useStyles()

  const actions = <ListToolbar />

  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<RoleListFilter />}
      pagination={<Pagination />}
      empty={<EmptyPage actions={actions} />}
      classes={{ content: classes.content }}
      actions={actions}
    >
      <Datagrid className={classes.list}>
        <ReferenceField
          label={translate('resources.Role.fields.user')}
          source="user.id"
          reference="User"
        >
          <EmailField source="email" />
        </ReferenceField>
        <SelectField source="type" choices={RoleType} />
        <ReferenceField
          label={translate('resources.Role.fields.domain')}
          source="domain.id"
          reference="Domain"
        >
          <TextField source="domain_name" />
        </ReferenceField>
        <ReferenceField
          label={translate('resources.Role.fields.association')}
          source="association.id"
          reference="Association"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label={translate('resources.Role.fields.activity')}
          source="activity.id"
          reference="Activity"
        >
          <TextField source="title" />
        </ReferenceField>
        <DateField source="createdAt" />
        <DefaultShowButton />
        <DefaultEditButton />
        <DefaultDeleteButton />
      </Datagrid>
    </List>
  )
}

export const RoleCreate = (props: any) => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <Create title="Role create" {...props} classes={{ card: classes.card }}>
      <SimpleForm redirect="list" toolbar={<EditToolbar disableLink />} className={classes.edit}>
        <NonInput>
          <Typography component="h1">{translate('resources.Role.title')}</Typography>
          <Typography component="h3">{translate('resources.Role.subtitle')}</Typography>
          <Divider />
        </NonInput>
        <ReferenceInput
          variant="outlined"
          label={translate('resources.Role.fields.user')}
          source="user"
          reference="User"
          validate={[required()]}
          helperText="resources.Role.helperTexts.user"
        >
          <AutocompleteInput optionText="email" />
        </ReferenceInput>
        <SelectInput
          variant="outlined"
          source="type"
          choices={RoleType}
          validate={[required()]}
          helperText="resources.Role.helperTexts.type"
        />
        <FormDataConsumer>
          {({ formData }: { formData: any }) =>
            formData.type === 'DOMAIN' && (
              <ReferenceInput
                variant="outlined"
                label={translate('resources.Role.fields.domain')}
                source="domain"
                reference="Domain"
                validate={[required()]}
                helperText="resources.Role.helperTexts.domain"
              >
                <AutocompleteInput optionText="domain_name" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }: { formData: any }) =>
            formData.type === 'ASSO' && (
              <ReferenceInput
                label={translate('resources.Role.fields.association')}
                source="association"
                reference="Association"
                validate={[required()]}
                helperText="resources.Role.helperTexts.association"
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }: { formData: any }) =>
            formData.type === 'ACTIVITY' && (
              <ReferenceInput
                variant="outlined"
                label={translate('resources.Role.fields.activity')}
                source="activity"
                reference="Activity"
                validate={[required()]}
                helperText="resources.Role.helperTexts.activity"
              >
                <AutocompleteInput optionText="title" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}

export const RoleEdit = (props: any) => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <Edit
      title="Role edit"
      {...props}
      undoable={false}
      transform={idEditTransform(['user', 'domain', 'association'])}
      classes={{ card: classes.card }}
      actions={false}
    >
      <SimpleForm toolbar={<EditToolbar />} className={classes.edit}>
        <NonInput>
          <Typography component="h1">{translate('resources.Role.title')}</Typography>
          <Typography component="h3">{translate('resources.Role.subtitle')}</Typography>
          <Divider />
        </NonInput>
        <ReferenceInput
          variant="outlined"
          label={translate('resources.Role.fields.user')}
          source="user.id"
          reference="User"
          validate={[required()]}
          helperText="resources.Role.helperTexts.user"
        >
          <AutocompleteInput optionText="email" />
        </ReferenceInput>
        <SelectInput
          variant="outlined"
          source="type"
          choices={RoleType}
          validate={[required()]}
          helperText="resources.Role.helperTexts.type"
        />
        <FormDataConsumer>
          {({ formData }: { formData: any }) =>
            formData.type === 'DOMAIN' && (
              <ReferenceInput
                variant="outlined"
                label={translate('resources.Role.fields.domain')}
                source="domain.id"
                reference="Domain"
                validate={[required()]}
                helperText="resources.Role.helperTexts.domain"
              >
                <AutocompleteInput optionText="domain_name" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }: { formData: any }) =>
            formData.type === 'ASSO' && (
              <ReferenceInput
                variant="outlined"
                label={translate('resources.Role.fields.association')}
                source="association.id"
                reference="Association"
                validate={[required()]}
                helperText="resources.Role.helperTexts.association"
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }: { formData: any }) =>
            formData.type === 'ACTIVITY' && (
              <ReferenceInput
                variant="outlined"
                label={translate('resources.Role.fields.activity')}
                source="activity.id"
                reference="Activity"
                validate={[required()]}
                helperText="resources.Role.helperTexts.activity"
              >
                <AutocompleteInput optionText="title" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  )
}

export const RoleShow = (props: any) => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <Show title="Role show" {...props} actions={<ShowToolbar />} classes={{ card: classes.card }}>
      <BoxedShowLayout className={classes.show}>
        <NonInput>
          <Typography component="h1">{translate('resources.Role.title')}</Typography>
          <Typography component="h3">{translate('resources.Role.subtitle')}</Typography>
          <Divider />
        </NonInput>
        <ReferenceField
          label={translate('resources.Role.fields.user')}
          source="user.id"
          reference="User"
        >
          <EmailField source="email" />
        </ReferenceField>
        <SelectField source="type" choices={RoleType} />
        <ReferenceField
          label={translate('resources.Role.fields.domain')}
          source="domain.id"
          reference="Domain"
        >
          <TextField source="domain_name" />
        </ReferenceField>
        <ReferenceField
          label={translate('resources.Role.fields.association')}
          source="association.id"
          reference="Association"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label={translate('resources.Role.fields.activity')}
          source="activity.id"
          reference="Activity"
        >
          <TextField source="title" />
        </ReferenceField>
        <DateField source="createdAt" />
      </BoxedShowLayout>
    </Show>
  )
}
