import { makeStyles } from '@material-ui/core/styles'
import { commonEditStyles } from './common-edit-styles'
import { commonListStyles } from './common-list-styles'
import { commonShowStyles } from './common-show-styles'

export const modelStyles = {
  thumbnail: {
    '& img': {
      width: '60px',
    },
  },
  show: commonShowStyles,
  edit: commonEditStyles,
  list: commonListStyles,
  card: {
    boxShadow: 'none',
  },
  filter: {
    paddingLeft: '36px',
  },
  content: {
    boxShadow: 'none',
    marginTop: '30px',
    paddingLeft: '36px',
    paddingRight: '16px',
    '& .MuiTablePagination-root': {
      fontSize: '12px',
    },
    '& .MuiTablePagination-caption': {
      fontSize: '12px',
    },
  },
  miniEditForm: {
    '& form': {
      marginTop: 0,
      paddingLeft: 0,
    },
    '& .MuiCardContent-root': {
      padding: 0,
    },
    '& .simple-form': {
      display: 'flex',
    },
    '& .MuiToolbar-root': {
      background: 'none',
      marginTop: 0,
    },
  },
}

export const useModelStyles = makeStyles(modelStyles)
