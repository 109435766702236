import React from 'react'
import { ADDRESS_RESOURCE_NAME } from '../../constants'
import { AddressShow } from '../address/show'

export const AssociationAddressShow = ({ basePath, id }: { basePath: string; id: string }) => {
  return (
    <AddressShow
      id={id}
      basePath={basePath}
      resource={ADDRESS_RESOURCE_NAME}
      ownerType="Association"
      edit={false}
    />
  )
}
