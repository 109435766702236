import { makeStyles } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import React, { FC, ReactElement } from 'react'
import {
  BooleanInput,
  Edit,
  EditProps,
  HandleSubmitWithRedirect,
  ImageField,
  ImageInput,
  RedirectionSideEffect,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  TopToolbar,
  useTranslate,
} from 'react-admin'
import { Domain } from '../../../entity-types'
import { useModelStyles } from '../../../helpers/model-styles'
import { NonInput } from '../../common/non-input'
import PortailLinkButton from '../../common/portail-link-button'
import { useDomain } from '../../contexts/domain-context'
import { useIsAtLeastDomainRole } from '../../hook/use-is-at-least-domain-role'
import { useThisDomainUrls } from '../../hook/use-this-domain-urls'
import { DomainType } from './domain'
import { RecordMiddleware } from './record-middleware'

const stripImageUploadFields = (record: Domain): Domain => {
  // The ImageInput exepects a File object, but the server sends us a URL.
  // We simply remove the fields, so we don't get a warning from ImageInput.
  const { logo_url: _, home_about_image: __, ...otherFields } = record
  return {
    ...otherFields,
  } as Domain
}

export const DomainEditForm = ({
  children,
  redirect,
  toolbar,
  ...props
}: {
  toolbar?: ReactElement
  redirect?: RedirectionSideEffect
  children: ReactElement | ReactElement[]
} & EditProps) => {
  const domain = useDomain()
  const translate = useTranslate()
  const modelClasses = useModelStyles()

  if (!useIsAtLeastDomainRole() || !domain) {
    return null
  }

  return (
    <Edit
      title={translate('resources.Domain.name')}
      {...props}
      id={domain.id}
      undoable={false}
      classes={{ card: modelClasses.card }}
      actions={false}
    >
      <RecordMiddleware<Domain> transform={stripImageUploadFields}>
        <SimpleForm redirect={redirect} toolbar={toolbar} className={modelClasses.edit}>
          {children}
        </SimpleForm>
      </RecordMiddleware>
    </Edit>
  )
}

export const DomainEdit = (props: any) => {
  const domain = useDomain()
  const translate = useTranslate()

  if (!useIsAtLeastDomainRole() || !domain) {
    return null
  }

  return (
    <DomainEditForm redirect="show" toolbar={<Toolbar />} {...props}>
      <NonInput>
        <Typography component="h1">{translate('resources.Domain.title')}</Typography>
        <Typography component="h3">{translate('resources.Domain.subtitle')}</Typography>
        <Divider />
        <Typography component="h2">{translate('resources.Domain.tabs.1')}</Typography>
      </NonInput>
      <ImageInput
        source="logo_url"
        accept="image/*"
        helperText="resources.Domain.helperTexts.logo_url"
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput
        variant="outlined"
        source="domain_name"
        validate={[required()]}
        helperText="resources.Domain.helperTexts.domain_name"
      />
      <TextInput
        variant="outlined"
        source="title"
        validate={[required()]}
        helperText="resources.Domain.helperTexts.title"
      />
      <SelectInput
        variant="outlined"
        source="type"
        choices={DomainType}
        helperText="resources.Domain.helperTexts.type"
      />
      <TextInput variant="outlined" source="outlink_url" validate={[required()]} />
      <TextInput variant="outlined" source="facebook_url" />
      <TextInput variant="outlined" source="twitter_url" />
      <TextInput variant="outlined" source="instagram_url" />
      <TextInput variant="outlined" source="legal_notice_url" />
      <TextInput variant="outlined" source="contact_form_url" />
      <BooleanInput
        source="published"
        initialValue={false}
        helperText="resources.Domain.helperTexts.published"
      />
      <NonInput>
        <Divider />
        <Typography component="h2">{translate('resources.Domain.tabs.2')}</Typography>
      </NonInput>
      <TextInput
        variant="outlined"
        source="home_title"
        validate={[required()]}
        helperText="resources.Domain.helperTexts.home_title"
      />
      <TextInput
        variant="outlined"
        source="home_subtitle"
        validate={[required()]}
        helperText="resources.Domain.helperTexts.home_subtitle"
      />
      <NonInput>
        <Divider />
        <Typography component="h2">{translate('resources.Domain.tabs.3')}</Typography>
      </NonInput>
      <TextInput
        variant="outlined"
        multiline
        source="seo_description"
        fullWidth
        helperText="resources.Domain.helperTexts.seo_description"
      />
      <TextInput
        variant="outlined"
        source="seo_keywords"
        helperText="resources.Domain.helperTexts.seo_keywords"
      />
      <TextInput
        variant="outlined"
        source="ga_key"
        helperText="resources.Domain.helperTexts.ga_key"
      />
    </DomainEditForm>
  )
}

const useToolbarStyles = makeStyles({
  toolbar: {
    position: 'absolute',
    top: '56px',
    right: '24px',
    paddingTop: '10px',
    paddingRight: '10px',
  },
  button: {
    margin: '5px',
  },
})

interface Props {
  disableLink?: boolean
  resource?: string
  handleSubmitWithRedirect?: HandleSubmitWithRedirect
}

const Toolbar: FC<Props> = ({ handleSubmitWithRedirect }: Props) => {
  const classes = useToolbarStyles()
  const domainUrls = useThisDomainUrls()

  return (
    <TopToolbar className={classes.toolbar}>
      <PortailLinkButton className={classes.button} variant="outlined" color="primary" />
      <SaveButton
        className={classes.button}
        variant="contained"
        icon={<></>}
        handleSubmitWithRedirect={handleSubmitWithRedirect}
        redirect={domainUrls?.show}
      />
    </TopToolbar>
  )
}
