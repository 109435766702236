import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { Login } from 'react-admin'
import LoginForm from './loginForm'

const styles = {
  avatar: {
    background: 'url(/assolib-icone-orange.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: 80,
    'background-position': 'center',
  },
  icon: { display: 'none' },
}

const LoginPage = ({ ...props }) => (
  <Login {...props}>
    <LoginForm />
  </Login>
)

export default withStyles(styles)(LoginPage)
