import { difference, isArray, isDate, isEqual, isString, keys, uniq } from 'lodash'
import { useEffect, useState } from 'react'
import { DbRecordId } from '../../../../entity-types'
import {
  ACTIVITY_RESOURCE_NAME,
  ADDRESS_RESOURCE_NAME,
  ASSOCIATION_RESOURCE_NAME,
  CONTACT_RESOURCE_NAME,
  MISSION_RESOURCE_NAME,
  TAG_RESOURCE_NAME,
} from '../../../constants'

export type Diff = {
  field: string
} & (
  | {
      type: 'diff'
      before: string | undefined
      after: string | undefined
    }
  | {
      type: 'boolean-flip'
      newValue: boolean | undefined
    }
  | {
      type: 'id-update'
      resource: string
      before: DbRecordId | undefined
      after: DbRecordId | undefined
    }
  | {
      type: 'id-list-update'
      resource: string
      added: DbRecordId[]
      removed: DbRecordId[]
    }
)

const fieldBlacklist = new Set(['createdAt', 'updatedAt'])

const prettyPrintValue = (value: unknown): string => {
  if (isString(value)) {
    return value
  }
  if (isDate(value)) {
    return value.toLocaleString()
  }
  return JSON.stringify(value, undefined, 2)
}

export const useDiffs = (
  resource: string,
  before?: Record<string, unknown>,
  after?: Record<string, unknown>,
): { diffs: Diff[]; loaded: boolean } => {
  const [result, setResult] = useState<{ diffs: Diff[]; loaded: boolean }>({
    diffs: [],
    loaded: false,
  })

  useEffect(() => {
    ;(async () => {
      const diffs = await computeDiffs(resource, before, after)
      setResult({ diffs, loaded: true })
    })()
  }, [after, before, resource])

  return result
}

const computeDiffs = async (
  resource: string,
  before?: Record<string, unknown>,
  after?: Record<string, unknown>,
): Promise<Diff[]> => {
  if (!before || !after) {
    return []
  }

  const diffs: Diff[] = []
  const allFields = uniq([...keys(before), ...keys(after)])
    .filter((field) => !fieldBlacklist.has(field))
    .sort()

  for (const field of allFields) {
    const beforeValue = before[field]
    const afterValue = after[field]
    const diff = computeDiff(resource, field, beforeValue, afterValue)
    if (diff) {
      diffs.push(diff)
    }
  }
  return diffs
}

const computeDiff = (
  resource: string,
  field: string,
  before?: unknown,
  after?: unknown,
): Diff | undefined => {
  if (isEqual(before, after)) {
    return undefined
  }
  for (const creator of customDiffCreators) {
    const diff = creator(resource, field, before, after)
    if (diff === null) {
      return undefined // This customDiffCreator assert that there are no differences.
    }
    if (diff !== undefined) {
      return diff // This customDiffCreator assert that there are differences.
    }
  }
  return {
    field,
    type: 'diff',
    before: prettyPrintValue(before),
    after: prettyPrintValue(after),
  }
}

export type CustomDiffCreators = (
  resource: string,
  field: string,
  beforeValue: any,
  afterValue: any,
) => Diff | null | undefined

const entityIdCustomDiffCreatorCreator = (
  expectedField: string,
  expectedParentResources: string[],
  referencedEntityResource: string,
): CustomDiffCreators => (
  resource: string,
  field: string,
  beforeValue: any,
  afterValue: any,
): Diff | null | undefined => {
  if (
    field === expectedField &&
    expectedParentResources.includes(resource) &&
    (beforeValue || afterValue)
  ) {
    if (!beforeValue && afterValue) {
      return {
        field,
        type: 'id-list-update',
        resource: referencedEntityResource,
        added: [afterValue],
        removed: [],
      }
    }
    if (beforeValue && !afterValue) {
      return {
        field,
        type: 'id-list-update',
        resource: referencedEntityResource,
        added: [],
        removed: [beforeValue],
      }
    }
    return {
      field,
      type: 'id-update',
      resource: referencedEntityResource,
      before: beforeValue,
      after: afterValue,
    }
  }
  return undefined
}

const entityIdListCustomDiffCreatorCreator = (
  expectedField: string,
  expectedParentResources: string[],
  referencedEntityResource: string,
): CustomDiffCreators => (
  resource: string,
  field: string,
  beforeValue: any,
  afterValue: any,
): Diff | null | undefined => {
  if (
    field === expectedField &&
    expectedParentResources.includes(resource) &&
    isArray(beforeValue) &&
    isArray(afterValue)
  ) {
    if (isEqual(beforeValue.sort(), afterValue.sort())) {
      return null // These are actually equal, even though the order is different.
    }

    return {
      field,
      type: 'id-list-update',
      resource: referencedEntityResource,
      added: difference(afterValue, beforeValue),
      removed: difference(beforeValue, afterValue),
    }
  }
  return undefined
}

const contactListCustomDiffCreator: CustomDiffCreators = entityIdListCustomDiffCreatorCreator(
  'contacts',
  [ASSOCIATION_RESOURCE_NAME, ACTIVITY_RESOURCE_NAME, MISSION_RESOURCE_NAME],
  CONTACT_RESOURCE_NAME,
)

const tagListCustomDiffCreator: CustomDiffCreators = entityIdListCustomDiffCreatorCreator(
  'tags',
  [ASSOCIATION_RESOURCE_NAME, ACTIVITY_RESOURCE_NAME, MISSION_RESOURCE_NAME],
  TAG_RESOURCE_NAME,
)

const addressListCustomDiffCreator: CustomDiffCreators = entityIdListCustomDiffCreatorCreator(
  'addresses',
  [ASSOCIATION_RESOURCE_NAME, ACTIVITY_RESOURCE_NAME, MISSION_RESOURCE_NAME],
  ADDRESS_RESOURCE_NAME,
)

const primaryContactListCustomDiffCreator: CustomDiffCreators = entityIdCustomDiffCreatorCreator(
  'primary_contact',
  [ASSOCIATION_RESOURCE_NAME, ACTIVITY_RESOURCE_NAME, MISSION_RESOURCE_NAME],
  CONTACT_RESOURCE_NAME,
)

const primaryAddressListCustomDiffCreator: CustomDiffCreators = entityIdCustomDiffCreatorCreator(
  'primary_address',
  [ASSOCIATION_RESOURCE_NAME, ACTIVITY_RESOURCE_NAME, MISSION_RESOURCE_NAME],
  ADDRESS_RESOURCE_NAME,
)

const mainTagCustomDiffCreator: CustomDiffCreators = entityIdCustomDiffCreatorCreator(
  'main_tag',
  [ASSOCIATION_RESOURCE_NAME],
  TAG_RESOURCE_NAME,
)

const missionCausesCustomDiffCreator: CustomDiffCreators = entityIdListCustomDiffCreatorCreator(
  'causes',
  [MISSION_RESOURCE_NAME],
  TAG_RESOURCE_NAME,
)

const missionSkillsCustomDiffCreator: CustomDiffCreators = entityIdListCustomDiffCreatorCreator(
  'skills',
  [MISSION_RESOURCE_NAME],
  TAG_RESOURCE_NAME,
)

const booleanFields = ['disabled', 'hidden']

const disabledCustomDiffCreator: CustomDiffCreators = (
  resource: string,
  field: string,
  beforeValue: any,
  afterValue: any,
): Diff | null | undefined => {
  if (booleanFields.includes(field)) {
    return {
      field,
      type: 'boolean-flip',
      newValue: afterValue,
    }
  }
  return undefined
}

const customDiffCreators: CustomDiffCreators[] = [
  contactListCustomDiffCreator,
  addressListCustomDiffCreator,
  primaryContactListCustomDiffCreator,
  primaryAddressListCustomDiffCreator,
  tagListCustomDiffCreator,
  mainTagCustomDiffCreator,
  missionCausesCustomDiffCreator,
  missionSkillsCustomDiffCreator,
  disabledCustomDiffCreator,
]
