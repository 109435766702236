import { Divider, makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import React from 'react'
import {
  Datagrid,
  DateField,
  Filter,
  ImageField,
  List,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  UrlField,
  useTranslate,
} from 'react-admin'
import { useModelStyles } from '../../../helpers/model-styles'
import { domainUrls } from '../../../routes/url-creators'
import {
  DefaultDeleteButton,
  DefaultEditButton,
  DefaultShowButton,
} from '../../common/default-buttons'
import EmptyPage from '../../common/empty-page'
import Pagination from '../../common/pagination'
import PortailLinkButton from '../../common/portail-link-button'
import { ToolbarCreateButton, ToolbarSaveButton, TopToolbar } from '../../common/toolbar'
import { XlsExportButton } from '../../common/xls-export-button'
import { useDomain } from '../../contexts/domain-context'
import { useIsAtLeastDomainRole } from '../../hook/use-is-at-least-domain-role'
import { DomainEditForm } from './edit'

const DomainPartnerListFilter = (props: any) => {
  const classes = useModelStyles()
  return (
    <Filter {...props} classes={{ form: classes.filter }} variant="outlined">
      <TextInput source="q" alwaysOn />
    </Filter>
  )
}

const useStyles = makeStyles({
  partnerBanner: {
    marginLeft: '36px',
    marginBottom: '30px',
  },
})

export const DomainPartnerList = (props: any) => {
  const domain = useDomain()
  const modelClasses = useModelStyles()
  const classes = useStyles()

  if (!useIsAtLeastDomainRole()) {
    return null
  }

  if (!domain) {
    return null
  }

  const actions = <Actions basePath={props.basePath} />

  return (
    <>
      <DomainEditForm
        id={domain?.id}
        className={classnames(modelClasses.miniEditForm, classes.partnerBanner)}
        resource="Domain"
        basePath={props.basePath}
        title=" " // Dont append anything to the page title
      >
        <SimpleForm
          redirect={domainUrls(domain.domain_name).partner.list}
          toolbar={
            <Toolbar>
              <ToolbarSaveButton />
            </Toolbar>
          }
          className={modelClasses.edit}
        >
          <TextInput variant="outlined" source="partner_banner_title" />
        </SimpleForm>
      </DomainEditForm>
      <Divider />
      <List
        {...props}
        bulkActionButtons={false}
        filters={<DomainPartnerListFilter />}
        pagination={<Pagination />}
        filter={domain ? { domain: domain.id } : null}
        empty={<EmptyPage actions={actions} />}
        classes={{ content: modelClasses.content }}
        actions={actions}
      >
        <Datagrid className={modelClasses.list}>
          <ImageField className={modelClasses.thumbnail} source="logo_url" title="logo" />
          <TextField source="name" />
          <UrlField source="url" />
          <DateField source="createdAt" />
          <DefaultShowButton />
          <DefaultEditButton />
          <DefaultDeleteButton />
        </Datagrid>
      </List>
    </>
  )
}

const Actions = ({ basePath }: { basePath?: string }) => {
  const translate = useTranslate()
  return (
    <TopToolbar>
      <XlsExportButton />
      <PortailLinkButton />
      <ToolbarCreateButton
        basePath={basePath}
        label={translate(`resources.DomainPartner.button.create`)}
      />
    </TopToolbar>
  )
}
