import { makeStyles } from '@material-ui/core'
import React, { ReactElement, useMemo } from 'react'
import { SelectInput, useInput, useTranslate } from 'react-admin'
import { useAreDomainMissionsEnabled } from '../../../hook/useAreDomainMissionsEnabled'
import { DOMAIN_HIGHLIGHT_SELECTION_TYPE_FIELD } from '../transform'
import { DomainHighlightSelectionByActivitiesBuilder } from './selection-by-activities-builder'
import { DomainHighlightSelectionByAssociationsBuilder } from './selection-by-associations-builder'
import { DomainHighlightSelectionByCriteriaBuilder } from './selection-by-criteria-builder'
import { DomainHighlightSelectionByMissionsBuilder } from './selection-by-missions-builder'

const ALL_SELECTION_TYPES = ['criteria', 'associations', 'activities', 'missions']

export type DomainHighlightSelectionType = typeof ALL_SELECTION_TYPES[number]

const useStyles = makeStyles({
  selectionTypeSelect: {
    width: '20rem',
  },
})

const useSelectionTypeChoices = () => {
  const areMissionEnabled = useAreDomainMissionsEnabled()
  const translate = useTranslate()
  return useMemo(
    () =>
      ALL_SELECTION_TYPES.filter((value) => areMissionEnabled || value !== 'missions').map(
        (type) => ({
          id: type,
          name: translate(`resources.DomainHighlight.selectionTypes.${type}`),
        }),
      ),
    [areMissionEnabled, translate],
  )
}

interface DomainHighlightSelectionBuilderProps {
  [key: string]: unknown
}

export const DomainHighlightSelectionBuilder = (
  props: DomainHighlightSelectionBuilderProps,
): ReactElement => {
  const translate = useTranslate()
  const classes = useStyles()
  const selectionTypeChoices = useSelectionTypeChoices()
  const {
    input: { value: currentSelectionType },
  } = useInput({ source: DOMAIN_HIGHLIGHT_SELECTION_TYPE_FIELD })

  return (
    <>
      <SelectInput
        label={translate(
          `resources.DomainHighlight.fields.${DOMAIN_HIGHLIGHT_SELECTION_TYPE_FIELD}`,
        )}
        source={DOMAIN_HIGHLIGHT_SELECTION_TYPE_FIELD}
        choices={selectionTypeChoices}
        className={classes.selectionTypeSelect}
      />
      {currentSelectionType === 'criteria' && (
        <DomainHighlightSelectionByCriteriaBuilder {...props} />
      )}
      {currentSelectionType === 'associations' && (
        <DomainHighlightSelectionByAssociationsBuilder {...props} />
      )}
      {currentSelectionType === 'activities' && (
        <DomainHighlightSelectionByActivitiesBuilder {...props} />
      )}
      {currentSelectionType === 'missions' && (
        <DomainHighlightSelectionByMissionsBuilder {...props} />
      )}
    </>
  )
}
