import { isEmpty, keyBy } from 'lodash'
import React from 'react'
import { DataProvider } from 'react-admin'
import { Address, Association, Domain, Tag } from '../../../entity-types'
import { XlsExportButton, XlsExportFieldOption } from '../../common/xls-export-button'
import { ADDRESS_RESOURCE_NAME, DOMAIN_RESOURCE_NAME, TAG_RESOURCE_NAME } from '../../constants'

export const associationExportFieldOptions: XlsExportFieldOption[] = [
  { name: 'name', label: 'Nom' },
  { name: 'administrative_id', label: 'administrative_id' },
  { name: 'administrative_id_type', label: 'administrative_id_type' },
  { name: 'description_text', label: 'Description' },
  { name: 'main_tag', label: "Domaine d'activité principal" },
  { name: 'email', label: 'Email' },
  { name: 'phone', label: 'Téléphone' },
  { name: 'website_url', label: 'Site URL' },
  { name: 'address_street', label: 'Adresse' },
  { name: 'address_zipcode', label: 'Code postal' },
  { name: 'address_city', label: 'Ville' },
  { name: 'facebook_url', label: 'Facebook URL' },
  { name: 'instagram_url', label: 'Instagram URL' },
  { name: 'linkedin_url', label: 'LinkedIn Url' },
  { name: 'twitter_url', label: 'Twitter Url' },
  { name: 'youtube_url', label: 'Youtube Url' },
  { name: 'disabled', label: 'Désactivé' },
  { name: 'domain', label: 'Domaine' },
  { name: 'id', hidden: true },
  { name: 'header_url', hidden: true },
  { name: 'logo_url', hidden: true },
  { name: 'main_tag.id', hidden: true },
  { name: 'primary_contact.id', hidden: true },
  { name: 'contacts', hidden: true },
  { name: 'updatedAt', hidden: true },
  { name: 'createdAt', hidden: true },
  { name: 'domain.id', hidden: true },
]

const fetchAllDomains = async (dataProvider: DataProvider) => {
  const { data: domains } = await dataProvider.getList<Domain>(DOMAIN_RESOURCE_NAME, {
    sort: { field: 'id', order: 'ASC' },
    filter: {},
    pagination: { page: 1, perPage: 1000000 },
  })
  return domains
}

const fetchAllTags = async (dataProvider: DataProvider) => {
  const { data: tags } = (await dataProvider.getList(TAG_RESOURCE_NAME, {
    sort: { field: 'id', order: 'ASC' },
    filter: {},
    pagination: { page: 1, perPage: 1000000 },
  })) as { data: Tag[] }
  return tags
}

const selectAddressIdForAssociation = (association: Association) => {
  if (association.primary_address) {
    return association.primary_address.id
  }
  if (!isEmpty(association.addresses)) {
    return association.addresses![0].id
  }
  return undefined
}

const fetchAddresses = async (addressIds: string[], dataProvider: DataProvider) => {
  const { data: addresses } = await dataProvider.getMany<Address>(ADDRESS_RESOURCE_NAME, {
    ids: addressIds,
  })
  return addresses
}

export const associationXlsExportTransform = async (
  associations: any[],
  dataProvider: DataProvider,
): Promise<any[]> => {
  if (associations.length === 0) {
    return []
  }
  const domains = await fetchAllDomains(dataProvider)
  const domainsPerId = keyBy(domains, (domain) => domain.id)
  const tags = await fetchAllTags(dataProvider)
  const tagNamesPerId = keyBy(tags, (tag) => tag.id)
  const addresses = await fetchAddresses(
    associations.map(selectAddressIdForAssociation).filter(Boolean) as string[],
    dataProvider,
  )
  const addressesPerId = keyBy(addresses, (address) => address.id)
  return associations.map((association) => {
    const addressId = selectAddressIdForAssociation(association)
    const address = addressId ? addressesPerId[addressId] : undefined
    return {
      ...association,
      // Add domain.
      domain: domainsPerId[association.domain.id]?.domain_name,
      // Add main tag.
      main_tag: tagNamesPerId[association.main_tag.id] ?? association.main_tag.id,
      // Add address.
      address_street: address ? address.street : '',
      address_zipcode: address ? address.zipcode : '',
      address_city: address ? address.city : '',
    }
  })
}

export const XlsAssociationExportButton = () => {
  return (
    <XlsExportButton
      fieldOptions={associationExportFieldOptions}
      transform={associationXlsExportTransform}
    />
  )
}
