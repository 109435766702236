import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { authLink } from './auth-provider'
import { config } from './config'

const httpLink = createHttpLink({
  uri: config.graphqlUri,
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})
