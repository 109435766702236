import { ListItemIcon, makeStyles, MenuItem as MuiMenuItem } from '@material-ui/core'
import React, { ReactElement, useMemo } from 'react'
import { MenuItemLink, useTranslate } from 'react-admin'
import { combineStyles } from '../../helpers/combineStyles'
import { IndentMenuItemContainer } from './indent-menu-item-container'
import { useIsSideBarOpen, useMenuItemLinkStyles } from './menu'

interface MenuItemProps {
  primaryTextKey?: string
  primaryText?: string
  leftIcon?: ReactElement
  classes?: any
  subItem?: boolean
}

export interface LinkMenuItemProps extends MenuItemProps {
  to: string
  exact?: boolean
}

export const useSubItemStyles = makeStyles({
  root: {
    // By default, the menu items get capitalized.
    textTransform: 'none !important' as 'lowercase', // "!important" is not part of the typing.
  },
})

export const LinkMenuItem = ({
  to,
  exact,
  primaryText: _primaryText,
  primaryTextKey,
  leftIcon,
  classes,
  subItem,
}: LinkMenuItemProps) => {
  const translate = useTranslate()
  const subItemClasses = useSubItemStyles()
  const menuItemLinkClasses = useMenuItemLinkStyles()
  const isSidebarOpen = useIsSideBarOpen()
  const finalClasses = useMemo(
    () => combineStyles(subItem ? subItemClasses : false, menuItemLinkClasses, classes),
    [classes, menuItemLinkClasses, subItem, subItemClasses],
  )
  const primaryText =
    _primaryText !== undefined
      ? _primaryText
      : primaryTextKey !== undefined
      ? translate(primaryTextKey)
      : undefined

  return (
    <IndentMenuItemContainer indent={subItem} isSidebarOpen={isSidebarOpen}>
      <MenuItemLink
        classes={finalClasses}
        to={to}
        exact={exact !== false}
        primaryText={primaryText}
        sidebarIsOpen={isSidebarOpen}
        leftIcon={isSidebarOpen && subItem ? undefined : leftIcon}
      />
    </IndentMenuItemContainer>
  )
}

const useStaticMenuItemStyles = makeStyles((theme) => ({
  root: {
    opacity: '1 !important',
    color: 'rgba(0, 0, 0, 0.54) !important',
  },
  icon: { minWidth: theme.spacing(5) },
}))

export const StaticMenuItem = ({
  primaryText: _primaryText,
  primaryTextKey,
  leftIcon,
  classes,
  subItem: indent,
}: MenuItemProps) => {
  const translate = useTranslate()
  const menuItemLinkClasses = useMenuItemLinkStyles()
  const isSidebarOpen = useIsSideBarOpen()
  const staticClasses = useStaticMenuItemStyles()

  const finalClasses = useMemo(() => combineStyles(menuItemLinkClasses, classes, staticClasses), [
    classes,
    menuItemLinkClasses,
    staticClasses,
  ])

  const primaryText =
    _primaryText !== undefined
      ? _primaryText
      : primaryTextKey !== undefined
      ? translate(primaryTextKey)
      : undefined

  return (
    <IndentMenuItemContainer indent={indent} isSidebarOpen={isSidebarOpen}>
      <MuiMenuItem disabled className={finalClasses.root}>
        {leftIcon && <ListItemIcon className={staticClasses.icon}>{leftIcon}</ListItemIcon>}
        {primaryText}
      </MuiMenuItem>
    </IndentMenuItemContainer>
  )
}
