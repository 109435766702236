import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { BoxedShowLayout } from 'ra-compact-ui'
import React from 'react'
import { DateField, SelectField, Show, TextField, useTranslate } from 'react-admin'
import { DbRecord } from '../../../entity-types'
import { modelStyles } from '../../../helpers/model-styles'
import { CustomButtonArrayField } from '../../common/custom-button'
import { NonInput } from '../../common/non-input'
import PortailLinkButton from '../../common/portail-link-button'
import { TagReferenceArrayField } from '../../common/tag-reference-array-field'
import { EnableDisableButton, ToolbarEditButton, TopToolbar } from '../../common/toolbar'
import {
  MissionsDisabledMessage,
  useAreDomainMissionsEnabled,
} from '../../hook/useAreDomainMissionsEnabled'
import { useMissionRecurrenceChoices } from './mission'

const useStyles = makeStyles(modelStyles)

export const MissionShow = (props: unknown) => {
  const areDomainMissionsEnabled = useAreDomainMissionsEnabled()
  const translate = useTranslate()
  const classes = useStyles()
  const recurrenceChoices = useMissionRecurrenceChoices()
  if (!areDomainMissionsEnabled) {
    return <MissionsDisabledMessage />
  }
  return (
    <Show
      title={translate('resources.Mission.name')}
      actions={<Actions />}
      classes={{ card: classes.card }}
      {...props}
    >
      <BoxedShowLayout className={classes.show}>
        <NonInput>
          <Typography component="h1">{translate('resources.Mission.title')}</Typography>
          <Typography component="h3">{translate('resources.Mission.subtitle')}</Typography>
          <Divider />
        </NonInput>
        <TextField source="title" />
        <SelectField source="recurrence" choices={recurrenceChoices} />
        <DateField source="start_date" />
        <DateField source="end_date" />
        <TagReferenceArrayField source="causes" />
        <TagReferenceArrayField source="skills" />
        <DateField source="createdAt" />
        <CustomButtonArrayField source="customButtons" />
      </BoxedShowLayout>
    </Show>
  )
}

const Actions = ({
  basePath,
  data,
  resource,
}: {
  basePath?: string
  data?: DbRecord
  resource?: string
}) => {
  return (
    <TopToolbar>
      <EnableDisableButton resource={resource} data={data} />
      <PortailLinkButton />
      <ToolbarEditButton record={data} basePath={basePath} />
    </TopToolbar>
  )
}
