import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { chain } from 'lodash'
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import {
  Button,
  Datagrid,
  List,
  SortPayload,
  useGetMany,
  useInput,
  useRecordSelection,
} from 'react-admin'
import { DbRecord, DbRecordId } from '../../entity-types'
import { useBooleanState } from '../hook/use-boolean-state'
import { usePrintErrorOnConsoleIfDefined } from '../hook/use-print-on-console-if-defined'
import { useTranslateFromRelativeKey } from '../hook/use-translate-from-relative-key'
import { useIconButtonStyles } from './default-buttons'

const useStyles = makeStyles({
  root: {
    minHeight: '5rem',
  },
  listFilterRoot: {
    marginTop: '0.5rem',
    // The <BulkActionButtons /> is supposed to cover the list's <Filters />,
    // but we want to show both.
    // In order to do that, we make the <Filters /> taller, so <BulkActionButtons /> only
    // appear on the bottom, and don't cover <Filters />.
    // If you change this value, make sure you know what you're doing, and test it on a browser.
    height: '9rem',
  },
  listFilterForm: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
})

interface EntityReferenceListInputProps {
  source: string
  referenceResource: string
  children: ReactElement | ReactElement[]
  translationPrefix: string
  referenceSort?: SortPayload
  referenceFilter?: any
  filters?: ReactElement
}

const defaultReferenceListSort: SortPayload = { field: 'id', order: 'ASC' }

export const EntityReferenceListInput = (props: EntityReferenceListInputProps) => {
  const { source, referenceResource, translationPrefix, children, referenceSort } = props
  const [isDialogOpen, openDialog, closeDialog] = useBooleanState(false)
  const translate = useTranslateFromRelativeKey(translationPrefix)
  const classes = useStyles()
  const {
    input: { value: entityIds, onChange },
  } = useInput({ source }) as {
    input: { value: DbRecordId[] | undefined; onChange: (ids: DbRecordId[]) => void }
  }
  const handleSubmit = useCallback(
    (ids: DbRecordId[]) => {
      closeDialog()
      onChange(ids)
    },
    [closeDialog, onChange],
  )
  const handleDelete = useCallback(
    (idToDelete: DbRecordId) => {
      entityIds && onChange(entityIds.filter((id) => id !== idToDelete))
    },
    [entityIds, onChange],
  )
  return (
    <div className={classes.root}>
      <Button label={translate('addButton')} variant="outlined" onClick={openDialog} />
      {entityIds && entityIds.length > 0 && (
        <>
          <Typography component="h2">{translate('listTitle')}</Typography>
          <ReferencedEntityList
            entityIds={entityIds}
            referenceResource={referenceResource}
            referenceSort={referenceSort}
            onDelete={handleDelete}
          >
            {children}
          </ReferencedEntityList>
        </>
      )}
      <EntityPickerDialog
        showDialog={isDialogOpen}
        onCancel={closeDialog}
        {...props}
        onSubmit={handleSubmit}
        selectedIds={entityIds}
      />
    </div>
  )
}

const ReferencedEntityList = ({
  entityIds,
  referenceResource,
  referenceSort,
  children,
  onDelete,
}: {
  children: ReactElement | ReactElement[]
  referenceResource: string
  entityIds: DbRecordId[]
  referenceSort?: SortPayload
  onDelete: (id: DbRecordId) => void
}) => {
  const fields = useMemo(
    () => [
      ...React.Children.toArray(children),
      <RemoveReferenceButton key="_remove" onDelete={onDelete} />,
    ],
    [children, onDelete],
  )

  const { data: entities, error } = useGetMany(referenceResource, entityIds || [], {
    enabled: Boolean(entityIds && entityIds.length > 0),
  })
  usePrintErrorOnConsoleIfDefined(error)

  const entitiesPerId = useMemo(
    () =>
      chain(entities)
        .compact()
        .keyBy((entity) => entity.id)
        .value(),
    [entities],
  )

  return (
    <Datagrid
      resource={referenceResource}
      ids={entityIds}
      data={entitiesPerId}
      currentSort={referenceSort || defaultReferenceListSort}
    >
      {fields}
    </Datagrid>
  )
}

const RemoveReferenceButton = ({
  onDelete,
  record,
}: {
  onDelete: (id: DbRecordId) => void
  record?: DbRecord
}) => {
  const classes = useIconButtonStyles()
  return (
    <Button onClick={() => record && onDelete(record.id)} className={classes.root}>
      <DeleteIcon color="error" />
    </Button>
  )
}

interface EntityPickerDialogProps extends EntityReferenceListInputProps {
  showDialog: boolean
  onSubmit: (ids: string[]) => void
  onCancel?: () => void
  selectedIds?: DbRecordId[]
}

const useSetSelectedRecords = (resource: string, ids: DbRecordId[] | undefined) => {
  const [, { select }] = useRecordSelection(resource)
  useEffect(() => {
    ids && select(ids)
  }, [select, ids])
}

const filterDefaultValue = { disabled: false }

const EntityPickerDialog = ({
  referenceResource,
  referenceFilter,
  referenceSort,
  children,
  translationPrefix,
  showDialog,
  onSubmit,
  onCancel,
  selectedIds,
  filters,
}: EntityPickerDialogProps) => {
  const translate = useTranslateFromRelativeKey(translationPrefix)

  useSetSelectedRecords(referenceResource, selectedIds)

  const fields = React.Children.toArray(children)
  return (
    <Dialog fullWidth open={showDialog} onClose={onCancel} maxWidth="lg">
      <DialogTitle>{translate('dialogTitle')}</DialogTitle>
      <DialogContent>
        <List
          resource={referenceResource}
          filter={referenceFilter}
          basePath=""
          filters={filters}
          filterDefaultValues={filterDefaultValue}
          actions={false}
          sort={referenceSort || defaultReferenceListSort}
          bulkActionButtons={<BulkActionButtons onSubmit={onSubmit} />}
        >
          <Datagrid>{fields}</Datagrid>
        </List>
      </DialogContent>
      <DialogActions>
        <Button label="ra.action.cancel" onClick={onCancel} />
      </DialogActions>
    </Dialog>
  )
}

const BulkActionButtons = ({
  onSubmit,
  selectedIds,
}: {
  onSubmit: (ids: DbRecordId[]) => void
  selectedIds?: DbRecordId[]
}) => {
  const handleSubmit = useCallback(() => {
    selectedIds && onSubmit(selectedIds)
  }, [onSubmit, selectedIds])
  return (
    <>
      <Button onClick={handleSubmit} label="ra.action.confirm" variant="contained" />
    </>
  )
}
