import { createContext } from 'react'
import { matchPath } from 'react-router-dom'
import { Activity } from '../../entity-types'
import { URLS } from '../../routes/url-creators'
import { ACTIVITY_RESOURCE_NAME } from '../constants'
import { createContextFromUrl } from './create-context-from-url'

interface ActivityContextValue {
  activity?: Activity
  isLoading: boolean
  isMatch: boolean
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const Context = createContext<ActivityContextValue>(null!)

const getIdFromPathName = (pathname: string) => {
  const basePath = URLS.domain(':domain_name').association(':association_id').activity(':id')
    .basePath
  const match = matchPath(pathname, { path: basePath })
  return (match?.params as { id?: string })?.id
}

const { useEntityContext, useEntity, EntityContextFromUrl } = createContextFromUrl<Activity>(
  ACTIVITY_RESOURCE_NAME,
  Context,
  getIdFromPathName,
  {
    idParamsBlacklist: ['create'],
  },
)

export const useActivityContext = () => {
  const { entity, ...others } = useEntityContext()
  return {
    activity: entity,
    ...others,
  }
}

export const useActivity = useEntity

export const ActivityContextFromUrl = EntityContextFromUrl
