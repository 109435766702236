import { Mission } from '../../entity-types'
import { MISSION_RESOURCE_NAME } from '../constants'
import { useGetOneById } from './use-get-one-by-id'
import { useAssociationHierarchy } from './useAssociationHierarchy'

export const useMissionHierarchy = (missionId: string | undefined) => {
  const { data: mission, loading, error: missionError } = useGetOneById<Mission>(
    MISSION_RESOURCE_NAME,
    missionId,
  )
  const {
    association,
    domain,
    loading: associationLoading,
    associationError,
    domainError,
  } = useAssociationHierarchy(mission?.association.id)
  return {
    mission,
    association,
    domain,
    loading: loading && associationLoading,
    missionError,
    associationError,
    domainError,
    error: domainError || missionError || associationError,
  }
}
