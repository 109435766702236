import { FormHelperText, makeStyles } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import classnames from 'classnames'
import { BoxedShowLayout, RaBox } from 'ra-compact-ui'
import React from 'react'
import {
  BooleanInput,
  DateInput,
  Edit,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import { Permission } from '../../../auth-provider'
import { Mission } from '../../../entity-types'
import { useModelStyles } from '../../../helpers/model-styles'
import { CustomButtonArrayInput } from '../../common/custom-button'
import EditToolbar from '../../common/edit-toolbar'
import { NonInput } from '../../common/non-input'
import { TagReferenceArrayInput } from '../../common/tag-reference-array-input'
import {
  MissionsDisabledMessage,
  useAreDomainMissionsEnabled,
} from '../../hook/useAreDomainMissionsEnabled'
import { RecordMiddleware } from '../domain/record-middleware'
import { TagType } from '../tag'
import {
  missionDocEditTransform,
  useCreateEditFormsStyles,
  useMissionRecurrenceChoices,
} from './mission'

export const MissionEdit = ({
  permissions,
  id,
  ...props
}: {
  permissions?: Permission
  id?: string
}) => {
  const areDomainMissionsEnabled = useAreDomainMissionsEnabled()
  const classes = useStyles()
  const translate = useTranslate()
  const modelClasses = useModelStyles()
  const formClasses = useCreateEditFormsStyles()

  const recurrenceChoices = useMissionRecurrenceChoices()

  if (!permissions) {
    return null
  }
  if (!areDomainMissionsEnabled) {
    return <MissionsDisabledMessage />
  }

  return (
    <Edit
      id={id}
      title={translate('resources.Mission.name')}
      {...props}
      undoable={false}
      transform={missionDocEditTransform}
      classes={{ card: modelClasses.card }}
      actions={false}
    >
      <RecordMiddleware<Mission> transform={createInitialFormRecord}>
        <SimpleForm redirect="show" toolbar={<EditToolbar />} className={modelClasses.edit}>
          <NonInput>
            <Typography component="h1">{translate('resources.Mission.title')}</Typography>
            <Typography component="h3">{translate('resources.Mission.subtitle')}</Typography>
            <Divider />
          </NonInput>
          <TextInput variant="outlined" source="title" validate={[required()]} fullWidth />
          <TextInput
            variant="outlined"
            multiline
            source="description_text"
            fullWidth
            helperText="resources.Mission.helperTexts.description_text"
            InputProps={{ classes: { input: formClasses.descriptionTextArea } }}
          />
          <BoxedShowLayout className={classnames(formClasses.gridLayout, classes.layout)}>
            <RaBox className={formClasses.raBoxRow}>
              <RaBox className={formClasses.flexGrow2}>
                <SelectInput
                  variant="outlined"
                  source="recurrence"
                  choices={recurrenceChoices}
                  fullWidth
                  validate={[required()]}
                />
              </RaBox>
              <RaBox className={formClasses.flexGrow1}>
                <DateInput source="start_date" fullWidth variant="outlined" />
              </RaBox>
              <RaBox className={formClasses.flexGrow1}>
                <DateInput source="end_date" fullWidth variant="outlined" />
              </RaBox>
            </RaBox>
            <NonInput className={classes.recurrenceHelperText}>
              <FormHelperText>
                {translate('resources.Mission.helperTexts.recurrence')}
              </FormHelperText>
            </NonInput>
            <RaBox className={classnames(formClasses.raBoxRow, classes.raBoxRow)}>
              <RaBox className={formClasses.flexGrow1}>
                <TagReferenceArrayInput
                  source="causes"
                  maxTagCount={6}
                  fullWidth
                  helperText="resources.Mission.helperTexts.causes"
                  tagTypes={TagType.MissionCause}
                />
              </RaBox>
              <RaBox className={formClasses.flexGrow1}>
                <TagReferenceArrayInput
                  source="skills"
                  maxTagCount={6}
                  fullWidth
                  helperText="resources.Mission.helperTexts.skills"
                  tagTypes={TagType.MissionSkill}
                />
              </RaBox>
            </RaBox>
            <RaBox className={formClasses.smallTopMargin}>
              <BooleanInput source="disabled" helperText="resources.Mission.helperTexts.disabled" />
            </RaBox>
          </BoxedShowLayout>
          <CustomButtonArrayInput source="customButtons" />
        </SimpleForm>
      </RecordMiddleware>
    </Edit>
  )
}

const createInitialFormRecord = (record: Mission): Mission => {
  return ({
    ...record,
    causes: (record.causes || []).map((cause) => cause.id),
    skills: (record.skills || []).map((skill) => skill.id),
  } as any) as Mission
}

const useStyles = makeStyles({
  layout: {
    width: '100%',
  },
  raBoxRow: {
    width: '100%',
  },
  recurrenceHelperText: {
    marginTop: '-1em',
    marginBottom: '2em',
  },
})
