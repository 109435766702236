import { Divider, makeStyles, Typography } from '@material-ui/core'
import React, { ComponentType, useCallback, useMemo } from 'react'
import {
  EditControllerProps,
  Loading,
  useEditController,
  useNotify,
  useRedirect,
  useTranslate,
} from 'react-admin'
import { DbRecord, LooseDbRecord } from '../../entity-types'
import { commonEditStyles } from '../../helpers/common-edit-styles'
import { CreateEntityFormProps } from '../models/contact/create'

/**
 * Search / Create a referenced entity and add its reference to a parent entity.
 *
 * e.g. 'Association' owns a `contacts` array of ids.
 */

export interface SelectEntityFormProps {
  // Call this when your component chose an existing entity.
  onSelect?: (entity: any) => void
  // Ids to be excluded from the search.
  searchBlacklistedIds?: string[]
}

interface PrimarySecondaryReferenceSelectOrCreateProps {
  // Resource of the container entity.
  parentResource: string
  // ID of the container entity.
  id: string
  // BasePath of the container entity.
  basePath: string
  // Resource of the referenced entities.
  referencedEntityResource: string
  // Where to go after a successful Select / Create.
  onSuccessRedirectTo?: string
  // Key of the references' array in the container entity.
  allReferencedEntityField: string
  // Pre-save transformation for the container entity.
  editTransform?: (val: any) => any
  // Component to select a referenced entity from an existing list.
  selectFromExistingFormComponent?: ComponentType<SelectEntityFormProps>
  // Component to create a referenced entity on-the-fly.
  referencedEntityCreateComponent: ComponentType<CreateEntityFormProps>
}

export const PrimarySecondaryReferenceSelectOrCreate = ({
  parentResource,
  referencedEntityResource,
  id,
  basePath,
  onSuccessRedirectTo,
  allReferencedEntityField,
  editTransform,
  selectFromExistingFormComponent: SelectFromExistingFormComponent,
  referencedEntityCreateComponent: ReferencedEntityCreateComponent,
}: PrimarySecondaryReferenceSelectOrCreateProps) => {
  const notify = useNotify()
  const classes = useStyles()
  const translate = useTranslate()
  const redirect = useRedirect()
  const i18nPrefix = `resources.${parentResource}.${referencedEntityResource}`
  const controllerProps = useEditController({
    basePath,
    resource: parentResource,
    id,
    title: ' ',
    transform: editTransform,
    mutationMode: 'pessimistic',
    redirect: false,
  })
  const { record, save } = controllerProps as EditControllerProps<LooseDbRecord>

  const referencedEntityIds = useMemo(
    () => ((record?.[allReferencedEntityField] || []) as DbRecord[]).map(({ id }) => id),
    [allReferencedEntityField, record],
  )

  const saveRecord = useCallback(
    (newRecord: DbRecord) => {
      save(newRecord, false)
      onSuccessRedirectTo && redirect(onSuccessRedirectTo)
    },
    [onSuccessRedirectTo, redirect, save],
  )

  const addReferencedEntity = useCallback(
    (referencedEntity: DbRecord) => {
      if (!record) return
      const currentReferencedEntities = (record[allReferencedEntityField] || []) as DbRecord[]
      if (currentReferencedEntities.find((e) => referencedEntity.id === e.id)) {
        notify(`${i18nPrefix}.alreadyReferenced`)
        return // Already exists, we're not adding it twice
      }
      const newRecord: LooseDbRecord = {
        ...record,
        [allReferencedEntityField]: [referencedEntity, ...currentReferencedEntities],
      }
      saveRecord(newRecord)
    },
    [allReferencedEntityField, i18nPrefix, notify, record, saveRecord],
  )

  const handleSelectedExisting = useCallback(
    async (data: any) => {
      addReferencedEntity(data)
    },
    [addReferencedEntity],
  )

  const handleCreated = useCallback(
    async ({ data }: { data: any }) => {
      addReferencedEntity(data)
    },
    [addReferencedEntity],
  )

  if (!record) {
    return <Loading />
  }

  return (
    <div className={classes.root}>
      <Typography component="h1">{translate(`${i18nPrefix}.title`)}</Typography>
      <Typography component="h3">{translate(`${i18nPrefix}.subtitle`)}</Typography>
      <Divider />
      {SelectFromExistingFormComponent && (
        <div className={classes.selectFormContainer}>
          <Typography component="h2">
            {translate(`${i18nPrefix}.selectOrCreate.select.header`)}
          </Typography>
          <SelectFromExistingFormComponent
            onSelect={handleSelectedExisting}
            searchBlacklistedIds={referencedEntityIds}
          />
        </div>
      )}
      <Typography component="h2">
        {translate(
          `${i18nPrefix}.selectOrCreate.create.${
            SelectFromExistingFormComponent ? 'headerIfSelectEnabled' : 'headerIfSelectDisabled'
          }`,
        )}
      </Typography>
      <ReferencedEntityCreateComponent basePath={basePath} onCreated={handleCreated} />
    </div>
  )
}

const useStyles = makeStyles({
  root: commonEditStyles,
  selectFormContainer: {
    marginBottom: '2rem',
  },
})
