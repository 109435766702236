import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { usePermissions } from 'react-admin'
import { useSelector } from 'react-redux'
import { Permission, RoleType } from '../../auth-provider'
import { ActivityRoleRootMenu } from './activity-role-root-menu'
import { AdminRoleRootMenu } from './admin-role-root-menu'
import { AssoRoleRootMenu } from './asso-role-root-menu'
import { DomainRoleRootMenu } from './domain-role-root-menu'

export const useMenuItemLinkStyles = makeStyles({
  root: {
    color: '#007b8c',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '12px',
    minHeight: '38px',
    '&:hover': {
      background: '#52C3D2',
      color: 'white',
      '& svg': {
        color: 'white',
      },
    },
  },
  active: {
    color: 'white',
    background: '#007b8c',
    '&:hover': {
      background: '#52C3D2',
    },
    '& svg': {
      color: 'white',
    },
  },
})

export const useMenuItemBackLinkStyles = makeStyles({
  root: {
    padding: '1rem',
  },
})

export const useMenuStyles = makeStyles({
  backButton: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  first: {
    minHeight: '50px',
  },
  forceInactive: {
    color: '#007b8c',
    background: 'transparent',
    '& svg': {
      color: '#007b8c',
    },
  },
  subItem: {
    textTransform: 'lowercase',
  },
})

export const useIsSideBarOpen = () => useSelector((state: any) => state.admin.ui.sidebarOpen)

export const Menu = () => {
  const { permissions } = usePermissions() as { permissions?: Permission }

  if (!permissions) {
    return null
  }

  if (permissions.type === RoleType.ACTIVITY) {
    return <ActivityRoleRootMenu />
  }

  if (permissions.type === RoleType.ASSO) {
    return <AssoRoleRootMenu />
  }

  if (permissions.type === RoleType.DOMAIN) {
    return <DomainRoleRootMenu />
  }

  if (permissions.type === RoleType.ADMIN) {
    return <AdminRoleRootMenu />
  }

  if (permissions.type === RoleType.DEV) {
    return <AdminRoleRootMenu />
  }

  return null
}
