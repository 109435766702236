import { isEmpty, keyBy } from 'lodash'
import React from 'react'
import { DataProvider } from 'react-admin'
import { Activity, Address } from '../../../entity-types'
import { XlsExportButton, XlsExportFieldOption } from '../../common/xls-export-button'
import { ADDRESS_RESOURCE_NAME } from '../../constants'

export const activityExportFieldOptions: XlsExportFieldOption[] = [
  { name: 'id' },
  { name: 'association.id' },
  { name: 'createdAt' },
  { name: 'updatedAt' },
  { name: 'disabled' },
  { name: 'title' },
  { name: 'description_text' },
  { name: 'age_min' },
  { name: 'age_max' },
  { name: 'tags' },
  { name: 'pricing_text' },
  { name: 'trainer_name' },
  { name: 'activity_schedules' },
  { name: 'address_street', label: 'Adresse' },
  { name: 'address_zipcode', label: 'Code postal' },
  { name: 'address_city', label: 'Ville' },
]

const selectAddressIdForActivity = (activity: Activity) => {
  if (activity.primary_address) {
    return activity.primary_address.id
  }
  if (!isEmpty(activity.addresses)) {
    return activity.addresses![0].id
  }
  return undefined
}

const fetchAddresses = async (addressIds: string[], dataProvider: DataProvider) => {
  const { data: addresses } = await dataProvider.getMany<Address>(ADDRESS_RESOURCE_NAME, {
    ids: addressIds,
  })
  return addresses
}

export const activityXlsExportTransform = async (
  activitys: any[],
  dataProvider: DataProvider,
): Promise<any[]> => {
  if (activitys.length === 0) {
    return []
  }
  const addresses = await fetchAddresses(
    activitys.map(selectAddressIdForActivity).filter(Boolean) as string[],
    dataProvider,
  )
  const addressesPerId = keyBy(addresses, (address) => address.id)
  return activitys.map((activity) => {
    const addressId = selectAddressIdForActivity(activity)
    const address = addressId ? addressesPerId[addressId] : undefined
    return {
      ...activity,
      // Add address.
      address_street: address ? address.street : '',
      address_zipcode: address ? address.zipcode : '',
      address_city: address ? address.city : '',
    }
  })
}

export const XlsActivityExportButton = () => {
  return (
    <XlsExportButton
      fieldOptions={activityExportFieldOptions}
      transform={activityXlsExportTransform}
    />
  )
}
