import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { BoxedShowLayout } from 'ra-compact-ui'
import React from 'react'
import {
  ArrayField,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EmailField,
  Filter,
  List,
  ReferenceField,
  required,
  Show,
  SimpleForm,
  SingleFieldList,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import { idEditTransform } from '../../helpers/id-edit-transform'
import { modelStyles } from '../../helpers/model-styles'
import {
  DefaultDeleteButton,
  DefaultEditButton,
  DefaultShowButton,
} from '../common/default-buttons'
import EditToolbar from '../common/edit-toolbar'
import EmptyPage from '../common/empty-page'
import ListToolbar from '../common/list-toolbar'
import { NonInput } from '../common/non-input'
import Pagination from '../common/pagination'
import ShowToolbar from '../common/show-toolbar'

const useStyles = makeStyles(modelStyles)

const UserListFilter = (props: any) => {
  const classes = useStyles()
  return (
    <Filter {...props} classes={{ form: classes.filter }} variant="outlined">
      <TextInput source="q" alwaysOn />
    </Filter>
  )
}

export const UserList = (props: any) => {
  const classes = useStyles()
  const actions = <ListToolbar />

  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<UserListFilter />}
      pagination={<Pagination />}
      empty={<EmptyPage actions={actions} />}
      classes={{ content: classes.content }}
      actions={actions}
    >
      <Datagrid className={classes.list}>
        <EmailField source="email" />
        <TextField source="firstname" />
        <TextField source="lastname" />
        <ArrayField source="roles">
          <SingleFieldList linkType={false}>
            <ReferenceField source="id" reference="Role">
              <ChipField source="type" />
            </ReferenceField>
          </SingleFieldList>
        </ArrayField>
        <DateField source="last_login_at" />
        <DateField source="createdAt" />
        <DefaultShowButton />
        <DefaultEditButton />
        <DefaultDeleteButton />
      </Datagrid>
    </List>
  )
}

export const UserCreate = (props: any) => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <Create {...props} classes={{ card: classes.card }}>
      <SimpleForm redirect="list" toolbar={<EditToolbar disableLink />} className={classes.edit}>
        <NonInput>
          <Typography component="h1">{translate('resources.User.title')}</Typography>
          <Typography component="h3">{translate('resources.User.subtitle')}</Typography>
          <Divider />
        </NonInput>
        <TextInput
          variant="outlined"
          source="email"
          type="email"
          validate={[required()]}
          helperText="resources.User.helperTexts.email"
        />
        <TextInput
          variant="outlined"
          source="password"
          type="password"
          validate={[required()]}
          helperText="resources.User.helperTexts.password"
        />
        <TextInput
          variant="outlined"
          source="firstname"
          validate={[required()]}
          helperText="resources.User.helperTexts.firstname"
        />
        <TextInput
          variant="outlined"
          source="lastname"
          validate={[required()]}
          helperText="resources.User.helperTexts.lastname"
        />
      </SimpleForm>
    </Create>
  )
}

export const UserEdit = (props: any) => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <Edit
      title={translate('resources.User.name')}
      {...props}
      undoable={false}
      classes={{ card: classes.card }}
      actions={false}
      transform={idEditTransform([], ['roles'])}
    >
      <SimpleForm toolbar={<EditToolbar />} className={classes.edit}>
        <NonInput>
          <Typography component="h1">{translate('resources.User.title')}</Typography>
          <Typography component="h3">{translate('resources.User.subtitle')}</Typography>
          <Divider />
        </NonInput>
        <TextInput
          variant="outlined"
          source="email"
          type="email"
          validate={[required()]}
          helperText="resources.User.helperTexts.email"
        />
        <TextInput
          variant="outlined"
          source="password"
          type="password"
          helperText="resources.User.helperTexts.password"
        />
        <TextInput
          variant="outlined"
          source="firstname"
          validate={[required()]}
          helperText="resources.User.helperTexts.firstname"
        />
        <TextInput
          variant="outlined"
          source="lastname"
          validate={[required()]}
          helperText="resources.User.helperTexts.lastname"
        />
        <DateInput
          variant="outlined"
          source="last_login_at"
          helperText="resources.User.helperTexts.last_login_at"
        />
      </SimpleForm>
    </Edit>
  )
}

export const UserShow = (props: any) => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <Show
      title={translate('resources.User.name')}
      {...props}
      actions={<ShowToolbar />}
      classes={{ card: classes.card }}
    >
      <BoxedShowLayout className={classes.show}>
        <NonInput>
          <Typography component="h1">{translate('resources.User.title')}</Typography>
          <Typography component="h3">{translate('resources.User.subtitle')}</Typography>
          <Divider />
        </NonInput>
        <TextField source="email" />
        <TextField source="firstname" />
        <TextField source="lastname" />
        <ArrayField source="roles">
          <SingleFieldList linkType={false}>
            <ReferenceField source="id" reference="Role">
              <ChipField source="type" />
            </ReferenceField>
          </SingleFieldList>
        </ArrayField>
        <DateField source="last_login_at" />
        <DateField source="createdAt" />
      </BoxedShowLayout>
    </Show>
  )
}
