import { Button, ButtonProps, makeStyles } from '@material-ui/core'
import React, { FC, ReactElement } from 'react'
import { TopToolbar, useListContext, useTranslate } from 'react-admin'
import { Permission, RoleType } from '../../auth-provider'
import PortailLinkButton from './portail-link-button'
import { XlsExportButton } from './xls-export-button'

const useStyles = makeStyles({
  root: {
    padding: '10px',
  },
  button: {
    margin: '5px',
    fontSize: '0.875rem',
    minHeight: '10px',
    padding: '6px 16px',
  },
})

export const ToolbarButton = ({ children, ...props }: ButtonProps) => {
  const classes = useStyles()
  return (
    <Button className={classes.button} variant="contained" color="primary" {...props}>
      {children}
    </Button>
  )
}

interface Props {
  disableLink?: boolean
  disableExport?: boolean
  importButton?: ReactElement
  permissions?: Permission
}

const ListToolbar: FC<Props> = (props: Props) => {
  const classes = useStyles()
  const { disableLink, disableExport, importButton } = props
  const translate = useTranslate()

  const { resource } = useListContext()

  function hideButton() {
    if (
      resource === 'Activity' &&
      props.permissions &&
      props.permissions.type === RoleType.ACTIVITY
    ) {
      return true
    } else if (
      resource === 'Association' &&
      props.permissions &&
      props.permissions.type === RoleType.ASSO
    ) {
      return true
    } else if (
      resource === 'Domain' &&
      props.permissions &&
      props.permissions.type === RoleType.DOMAIN
    ) {
      return true
    }
    return false
  }

  return (
    <TopToolbar className={classes.root}>
      {disableExport ? null : <XlsExportButton />}
      {importButton ? importButton : null}
      {disableLink ? null : (
        <PortailLinkButton className={classes.button} variant="outlined" color="primary" />
      )}
      {hideButton() ? null : (
        <ToolbarButton href={`#/${resource}/create`}>
          {translate(`resources.${resource}.button.create`)}
        </ToolbarButton>
      )}
    </TopToolbar>
  )
}

export default ListToolbar
