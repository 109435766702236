import React, { useMemo } from 'react'
import { Loading } from 'react-admin'
import { Permission } from '../../../auth-provider'
import { ContactReferenceSelectOrCreate } from '../../common/contact-reference-select-or-create'
import { ACTIVITY_RESOURCE_NAME, ASSOCIATION_RESOURCE_NAME } from '../../constants'
import { useActivity } from '../../contexts/activity-context'
import { useAssociation } from '../../contexts/association-context'
import { useThisActivityUrls } from '../../hook/use-this-activity-urls'
import { activityDocTransform } from './activity'

export const ActivityContactSelectOrCreate = ({
  basePath,
  permissions,
}: {
  basePath: string
  permissions: Permission
}) => {
  const thisActivityUrls = useThisActivityUrls()
  const association = useAssociation()
  const activity = useActivity()
  const activityId = activity?.id

  const sourceAssociationIds = useMemo(() => [association?.id], [association])

  if (!association) {
    return null
  }
  if (!activityId) {
    return null
  }

  if (!permissions) {
    return <Loading />
  }

  return (
    <ContactReferenceSelectOrCreate
      resource={ACTIVITY_RESOURCE_NAME}
      id={activityId}
      basePath={basePath}
      editTransform={activityDocTransform}
      onSuccessRedirectTo={thisActivityUrls?.contact.list}
      existingContactSourceResource={ASSOCIATION_RESOURCE_NAME}
      existingContactSourceIds={sourceAssociationIds as string[]}
    />
  )
}
