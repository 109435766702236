import { Identifier, Record, UseDataProviderOptions, useGetOne } from 'react-admin'
import { useHasChangedSinceLastRender } from './use-has-changed-since-last-render'

/**
 * Just like useGetOne, but fixes a nasty bug.
 *
 * In the original useGetOne, when an input has changed, there is one tick where the result is
 *
 * { data = {}, error = undefined, loading = false, loaded = true }
 *
 * This misleads the user because it seems to mean that the request was successful and loaded, but there was no data loaded.
 * This is wrong.
 * When an input changed, the result of the hook should be immediately
 * { data = {}, error = undefined, loading = true, loaded = false }
 *
 * This hook simply overrides `loading` and `loaded`.
 */

export const useStableGetOne = <RecordType extends Record = Record>(
  resource: string,
  id: Identifier,
  options?: UseDataProviderOptions,
): {
  data?: RecordType
  total?: number
  error?: any
  loading: boolean
  loaded: boolean
} => {
  let someInputHasChanged = false

  if (useHasChangedSinceLastRender(resource)) {
    someInputHasChanged = true
  }
  if (useHasChangedSinceLastRender(id)) {
    someInputHasChanged = true
  }
  if (useHasChangedSinceLastRender(options)) {
    someInputHasChanged = true
  }

  const { data, error, loading, loaded } = useGetOne<RecordType>(resource, id, options)

  return {
    data,
    error,
    loading: loading || someInputHasChanged,
    loaded: loaded && !someInputHasChanged,
  }
}
