import { Typography } from '@material-ui/core'
import React, { useMemo } from 'react'
import { Create, SaveButton, SimpleForm, Toolbar, useInput, useTranslate } from 'react-admin'
import { Address } from '../../../entity-types'
import { PlaceSearchInput } from '../../common/place-search-input'
import { ADDRESS_RESOURCE_NAME } from '../../constants'
import { useDomain } from '../../contexts/domain-context'
import { AdressComplementInput } from './address-complement-input'

const formValidate = (record: Partial<Address>) => {
  if (!record.full_address) {
    return 'missing full address'
  }
}

export const AddressCreate = ({
  basePath,
  onCreated,
}: {
  basePath: string
  title?: string
  onCreated?: (address: Address) => void
}) => {
  const domain = useDomain()
  const initialValues = useMemo(
    () => ({
      domain: domain?.id,
    }),
    [domain],
  )
  return (
    <Create basePath={basePath} resource={ADDRESS_RESOURCE_NAME} onSuccess={onCreated}>
      <SimpleForm
        initialValues={initialValues}
        toolbar={
          <Toolbar>
            <SaveButton variant="contained" icon={<></>} />
          </Toolbar>
        }
        validate={formValidate}
      >
        <Inner />
      </SimpleForm>
    </Create>
  )
}

const Inner = () => {
  const translate = useTranslate()
  const {
    input: { value },
  } = useInput({ source: 'full_address' })
  return (
    <>
      <PlaceSearchInput fullWidth />
      {value && (
        <>
          <Typography variant="h2">
            {translate('resources.Address.create.selectedAddress')}
          </Typography>
          {value}
          <AdressComplementInput />
        </>
      )}
    </>
  )
}
