import { BoxedShowLayout, RaBox } from 'ra-compact-ui'
import React from 'react'
import { BooleanInput, Edit, SimpleForm, TextInput } from 'react-admin'
import { CONTACT_RESOURCE_NAME } from '../../constants'
import { useDomain } from '../../contexts/domain-context'
import { contactIdEditTransform } from './contact'
import { useContactCreateFormStyles } from './create'

export interface EditEntityFormProps {
  // BasePath of the referenced entities. Pass it to the <Create> element.
  basePath: string

  id: string
}

export const ContactEdit = ({ basePath, id }: EditEntityFormProps) => {
  const classes = useContactCreateFormStyles()
  const domain = useDomain()
  const domainId = domain?.id
  if (!domainId) {
    return null
  }
  return (
    <Edit
      id={id}
      basePath={basePath}
      resource={CONTACT_RESOURCE_NAME}
      transform={contactIdEditTransform}
    >
      <SimpleForm>
        <BoxedShowLayout>
          <RaBox className={classes.raBoxRow}>
            <RaBox>
              <TextInput
                variant="outlined"
                source="civility"
                helperText="resources.Contact.helperTexts.civility"
              />
            </RaBox>
            <RaBox>
              <TextInput
                variant="outlined"
                source="title"
                helperText="resources.Contact.helperTexts.title"
              />
            </RaBox>
          </RaBox>
          <RaBox className={classes.raBoxRow}>
            <RaBox>
              <TextInput
                variant="outlined"
                source="lastname"
                helperText="resources.Contact.helperTexts.lastname"
              />
            </RaBox>
            <RaBox>
              <TextInput
                variant="outlined"
                source="firstname"
                helperText="resources.Contact.helperTexts.firstname"
              />
            </RaBox>
          </RaBox>
          <RaBox className={classes.raBoxRow}>
            <RaBox>
              <TextInput
                variant="outlined"
                source="phone"
                helperText="resources.Contact.helperTexts.phone"
              />
            </RaBox>
            <RaBox>
              <TextInput
                variant="outlined"
                source="email"
                type="email"
                helperText="resources.Contact.helperTexts.email"
              />
            </RaBox>
          </RaBox>
          <BooleanInput source="hidden" />
        </BoxedShowLayout>
      </SimpleForm>
    </Edit>
  )
}
