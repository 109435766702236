import { URLS } from '../../routes/url-creators'
import { useDomain } from '../contexts/domain-context'

/**
 * Get an url creator associated to the domain provided by the DomainContext.
 * Useful to express urls relative to the current domain.
 */

export const useThisDomainUrls = () => {
  const domain = useDomain()
  return domain ? URLS.domain(domain?.domain_name) : undefined
}
