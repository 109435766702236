import React from 'react'
import { Loading } from 'react-admin'
import { CONTACT_RESOURCE_NAME } from '../../constants'
import { useIsAtLeastDomainRole } from '../../hook/use-is-at-least-domain-role'
import { ContactShow } from '../contact/show'

export const DomainContactShow = ({ basePath, id }: { basePath: string; id: string }) => {
  if (!useIsAtLeastDomainRole()) {
    return <Loading />
  }
  return (
    <ContactShow resource={CONTACT_RESOURCE_NAME} id={id} ownerType="Domain" basePath={basePath} />
  )
}
