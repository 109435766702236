import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { BoxedShowLayout, RaBox } from 'ra-compact-ui'
import React from 'react'
import { EmailField, ImageField, Show, TextField, UrlField, useTranslate } from 'react-admin'
import { DbRecord } from '../../../entity-types'
import { useModelStyles } from '../../../helpers/model-styles'
import { NonInput } from '../../common/non-input'
import PortailLinkButton from '../../common/portail-link-button'
import { TagReferenceArrayField } from '../../common/tag-reference-array-field'
import { TagReferenceField } from '../../common/tag-reference-field'
import { EnableDisableButton, ToolbarEditButton, TopToolbar } from '../../common/toolbar'
import { useIsAtLeastDomainRole } from '../../hook/use-is-at-least-domain-role'
import { useColumnStyles } from './association'

export const AssociationShow = (props: any) => {
  const translate = useTranslate()
  const classes = useModelStyles()
  const columnClasses = useColumnStyles()
  const isAtLeastDomainRole = useIsAtLeastDomainRole()
  return (
    <Show
      actions={<Actions />}
      title={translate('resources.Association.name')}
      classes={{ card: classes.card }}
      {...props}
    >
      <BoxedShowLayout className={classes.show}>
        <NonInput>
          <Typography component="h1">{translate('resources.Association.title')}</Typography>
          <Typography component="h3">{translate('resources.Association.subtitle')}</Typography>
          <Divider />
          <Typography component="h2">{translate('resources.Association.tabs.1')}</Typography>
        </NonInput>
        <RaBox display="flex">
          <RaBox flex="30">
            <ImageField source="logo_url" />
          </RaBox>
          <NonInput>
            <Box flex="5"></Box>
          </NonInput>
          <RaBox flex="70">
            <ImageField source="header_url" />
          </RaBox>
        </RaBox>
        <NonInput>
          <Divider />
          <Typography component="h2">{translate('resources.Association.tabs.2')}</Typography>
        </NonInput>
        <TextField source="name" />
        <TextField source="description_text" />
        <EmailField source="email" />
        <TextField source="phone" />
        <TagReferenceField source="main_tag" />
        {isAtLeastDomainRole && <TagReferenceArrayField source="tags" />}
        <NonInput>
          <Divider />
          <Typography component="h2">{translate('resources.Association.tabs.3')}</Typography>
        </NonInput>
        <RaBox display="flex">
          <RaBox flex="30">
            <UrlField source="website_url" emptyText="-" />
          </RaBox>
          <RaBox flex="30">
            <UrlField source="facebook_url" emptyText="-" />
          </RaBox>
          <RaBox flex="30">
            <UrlField source="instagram_url" emptyText="-" />
          </RaBox>
        </RaBox>
        <RaBox display="flex">
          <RaBox flex="30">
            <UrlField source="twitter_url" emptyText="-" />
          </RaBox>
          <RaBox flex="30">
            <UrlField source="youtube_url" emptyText="-" />
          </RaBox>
          <RaBox flex="30">
            <UrlField source="linkedin_url" emptyText="-" />
          </RaBox>
        </RaBox>
        <NonInput>
          <Divider />
          <Typography component="h2">{translate('resources.Association.tabs.4')}</Typography>
        </NonInput>
        <RaBox display="flex">
          <RaBox flex="30">
            <TextField source="administrative_id" fullWidth />
          </RaBox>
          <RaBox flex="30" className={columnClasses.columnWithLeftMargin}>
            <TextField source="administrative_id_type" fullWidth />
          </RaBox>
        </RaBox>
      </BoxedShowLayout>
    </Show>
  )
}

const Actions = ({
  basePath,
  data,
  resource,
}: {
  basePath?: string
  data?: DbRecord
  resource?: string
}) => {
  const isAtLeastDomainRole = useIsAtLeastDomainRole()
  return (
    <TopToolbar>
      {isAtLeastDomainRole && <EnableDisableButton resource={resource} data={data} />}
      <PortailLinkButton />
      <ToolbarEditButton record={data} basePath={basePath} />
    </TopToolbar>
  )
}
