import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import React from 'react'
import { BooleanInput, TextInput, useInput, useTranslate } from 'react-admin'
import { USE_DESCRIPTION_DUMMY_FIELD } from '../transform'

const useDescriptionInputStyle = makeStyles({
  root: {
    marginTop: '1rem',
  },
  inputContainer: {
    transition: 'max-height 500ms ease-out',
    overflow: 'hidden',
  },
  open: {
    maxHeight: '10rem',
  },
  close: {
    maxHeight: 0,
  },
  textarea: {
    height: '5rem !important',
  },
})

export const DomainHighlightOptionalDescriptionInput = (props: unknown) => {
  const classes = useDescriptionInputStyle()
  const translate = useTranslate()
  const source = 'description_text'

  const {
    input: { value: useDescription },
  } = useInput({ source: USE_DESCRIPTION_DUMMY_FIELD })

  return (
    <div className={classes.root}>
      <BooleanInput
        source={USE_DESCRIPTION_DUMMY_FIELD}
        label={translate('resources.DomainHighlight.descriptionToggleSwitchLabel')}
      />
      <div
        className={classnames(
          classes.inputContainer,
          useDescription ? classes.open : classes.close,
        )}
      >
        <TextInput
          {...props}
          variant="outlined"
          multiline
          source={source}
          fullWidth
          helperText="resources.DomainHighlight.helperTexts.description_text"
          InputProps={{ classes: { input: classes.textarea } }}
        />
      </div>
    </div>
  )
}
