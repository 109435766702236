import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import {
  BooleanInput,
  Create,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import { modelStyles } from '../../../helpers/model-styles'
import { CustomButtonArrayInput } from '../../common/custom-button'
import EditToolbar from '../../common/edit-toolbar'
import { NonInput } from '../../common/non-input'
import { TagReferenceArrayInput } from '../../common/tag-reference-array-input'
import { useAssociation } from '../../contexts/association-context'
import { useIsAtLeastActivityRole } from '../../hook/use-is-at-least-activity-role'
import { useIsAtLeastDevRole } from '../../hook/use-is-at-least-dev-role'
import { TagType } from '../tag'

const useStyles = makeStyles(modelStyles)

const tagTypes = [TagType.Main, TagType.Normal]

export const ActivityCreate = (props: unknown) => {
  const association = useAssociation()
  const translate = useTranslate()
  const classes = useStyles()

  const isAtLeastDev = useIsAtLeastDevRole()

  if (!useIsAtLeastActivityRole()) {
    return null
  }

  return (
    <Create {...props} classes={{ card: classes.card }}>
      <SimpleForm
        redirect="list"
        toolbar={<EditToolbar disableLink />}
        className={classes.edit}
        initialValues={{ association: association ? association.id : null, disabled: false }}
      >
        <NonInput>
          <Typography component="h1">{translate('resources.Activity.title')}</Typography>
          <Typography component="h3">{translate('resources.Activity.subtitle')}</Typography>
          <Divider />
        </NonInput>
        <TextInput
          variant="outlined"
          source="title"
          validate={[required()]}
          helperText="resources.Activity.helperTexts.title"
        />
        <TextInput
          variant="outlined"
          multiline
          source="description_text"
          fullWidth
          helperText="resources.Activity.helperTexts.description_text"
        />
        <TextInput
          variant="outlined"
          multiline
          source="pricing_text"
          helperText="resources.Activity.helperTexts.pricing_text"
        />
        <BooleanInput
          source="disabled"
          initialValue={false}
          helperText="resources.Activity.helperTexts.disabled"
        />
        {isAtLeastDev && (
          <ReferenceInput
            variant="outlined"
            label={translate('resources.Activity.fields.association')}
            source="association"
            reference="Association"
            validate={[required()]}
            helperText="resources.Activity.helperTexts.association"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        )}
        <TextInput
          variant="outlined"
          source="trainer_name"
          helperText="resources.Activity.helperTexts.trainer_name"
        />
        <NumberInput
          variant="outlined"
          source="age_min"
          helperText="resources.Activity.helperTexts.age_min"
        />
        <NumberInput
          variant="outlined"
          source="age_max"
          helperText="resources.Activity.helperTexts.age_max"
        />
        <TagReferenceArrayInput
          source="tags"
          maxTagCount={6}
          fullWidth
          helperText="resources.Activity.helperTexts.tags"
          tagTypes={tagTypes}
        />
        <CustomButtonArrayInput source="customButtons" />
      </SimpleForm>
    </Create>
  )
}
