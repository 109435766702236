import React from 'react'
import {
  Datagrid,
  DateField,
  Filter,
  ImageField,
  List,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import { useModelStyles } from '../../../helpers/model-styles'
import {
  DefaultDeleteButton,
  DefaultEditButton,
  DefaultShowButton,
} from '../../common/default-buttons'
import EmptyPage from '../../common/empty-page'
import Pagination from '../../common/pagination'
import PortailLinkButton from '../../common/portail-link-button'
import { ToolbarCreateButton, TopToolbar } from '../../common/toolbar'
import { XlsExportButton } from '../../common/xls-export-button'
import { useDomain } from '../../contexts/domain-context'
import { useIsAtLeastDomainRole } from '../../hook/use-is-at-least-domain-role'

const DomainHighlightListFilter = (props: any) => {
  const classes = useModelStyles()
  return (
    <Filter {...props} classes={{ form: classes.filter }} variant="outlined">
      <TextInput source="q" alwaysOn />
    </Filter>
  )
}

export const DomainHighlightList = (props: any) => {
  const domain = useDomain()
  const classes = useModelStyles()

  const actions = <Actions basePath={props.basePath} />

  if (!useIsAtLeastDomainRole() || !domain) {
    return null
  }

  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<DomainHighlightListFilter />}
      pagination={<Pagination />}
      filter={{ domain: domain.id }}
      empty={<EmptyPage actions={actions} />}
      classes={{ content: classes.content }}
      actions={actions}
    >
      <Datagrid className={classes.list}>
        <ImageField className={classes.thumbnail} source="preview_img_url" title="preview_img" />
        <TextField source="name" />
        <DateField source="createdAt" />
        <DefaultShowButton />
        <DefaultEditButton />
        <DefaultDeleteButton />
      </Datagrid>
    </List>
  )
}

const Actions = ({ basePath }: { basePath?: string }) => {
  const translate = useTranslate()
  return (
    <TopToolbar>
      <XlsExportButton />
      <PortailLinkButton />
      <ToolbarCreateButton
        basePath={basePath}
        label={translate(`resources.DomainHighlight.button.create`)}
      />
    </TopToolbar>
  )
}
