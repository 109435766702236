import { isArray, isObject } from 'lodash'

export function idEditTransform(fieldnames: string[] = [], arrayFieldnames: string[] = []) {
  return (data: any) => {
    const results = { ...data }
    for (const fieldname of fieldnames) {
      if (isObject(results[fieldname])) {
        results[fieldname] = results[fieldname].id
      }
    }
    for (const fieldname of arrayFieldnames) {
      if (isArray(results[fieldname])) {
        for (let i = 0; i < results[fieldname].length; ++i) {
          if (isObject(results[fieldname][i])) {
            results[fieldname][i] = results[fieldname][i].id
          }
        }
      }
    }
    return results
  }
}

export function parseToObject(values: string[]) {
  return values.map((value: string) => ({ id: value }))
}

export function formatFromObject(values: { id: string }[]) {
  return values.map((value: { id: string }) => value.id)
}
