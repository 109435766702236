import React, { useCallback, useMemo } from 'react'
import { CreateButton, Filter, Loading, SortPayload, TextInput } from 'react-admin'
import EmptyPage from '../../common/empty-page'
import { TopToolbar, useToolbarStyles } from '../../common/toolbar'
import { XlsExportButton } from '../../common/xls-export-button'
import { useDomain } from '../../contexts/domain-context'
import { useIsAtLeastDomainRole } from '../../hook/use-is-at-least-domain-role'
import { useThisDomainUrls } from '../../hook/use-this-domain-urls'
import { addressExportFieldOptions } from '../address/address'
import { AddressList } from '../address/list'

const sortPayload: SortPayload = { field: 'createdAt', order: 'DESC' }

export const DomainAddressList = ({ basePath }: { basePath?: string }) => {
  const domainUrls = useThisDomainUrls()
  const domain = useDomain()
  const domainId = domain?.id

  const filter = useMemo(() => ({ domain: domainId }), [domainId])

  if (!useIsAtLeastDomainRole() || !domainId || !domainUrls) {
    return <Loading />
  }

  const actions = <Toolbar />

  return (
    <AddressList
      sort={sortPayload}
      filter={filter}
      basePath={basePath}
      actions={actions}
      emptyPage={<EmptyPage actions={actions} />}
      filters={
        <Filter variant="outlined">
          <TextInput source="q" alwaysOn />
        </Filter>
      }
      disableDelete={true}
    />
  )
}

const Toolbar = ({ basePath }: { basePath?: string }) => {
  const classes = useToolbarStyles()
  return (
    <TopToolbar>
      <AddressExportButton />
      <CreateButton
        icon={<></>}
        className={classes.button}
        basePath={basePath!}
        variant="contained"
        label="resources.Domain.Address.addButtonLabel"
      />
    </TopToolbar>
  )
}

const AddressExportButton = () => {
  const domain = useDomain()
  const domainName = domain?.domain_name
  const addDomainTransform = useCallback(
    (records: any[]): any[] => records.map((record) => ({ ...record, domain: domainName })),
    [domainName],
  )
  return <XlsExportButton fieldOptions={addressExportFieldOptions} transform={addDomainTransform} />
}
