import React from 'react'
import { URLS } from '../../routes/url-creators'
import { DomainIcon } from '../common/icons'
import { useAssociationContext } from '../contexts/association-context'
import { useDomainContext } from '../contexts/domain-context'
import { useThisDomainUrls } from '../hook/use-this-domain-urls'
import { AssociationMenu } from './association-menu'
import { DomainMenu } from './domain-menu'
import { LinkMenuItem } from './menu-item'
import { MenuItemBack } from './menu-item-back'

export const DomainRoleRootMenu = () => {
  const thisDomainUrls = useThisDomainUrls()
  const { isMatch: isDomainMatch } = useDomainContext()
  const { isMatch: isAssociationMatch } = useAssociationContext()

  if (isAssociationMatch) {
    return (
      <>
        {thisDomainUrls && (
          <MenuItemBack
            to={thisDomainUrls.association.list}
            primaryTextKey="menu.associationMenu.BackToAssociations"
          />
        )}
        <AssociationMenu />
      </>
    )
  }

  if (isDomainMatch) {
    return <DomainMenu />
  }

  return <RootMenu />
}

const RootMenu = () => {
  return (
    <>
      <LinkMenuItem
        to={URLS.domain.list}
        primaryTextKey="menu.rootMenu.Domain"
        leftIcon={<DomainIcon />}
      />
    </>
  )
}
