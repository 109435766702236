import { makeStyles, Typography } from '@material-ui/core'
import React, { ReactElement, useCallback, useMemo } from 'react'
import { Filter, TextInput, TopToolbar, useTranslate } from 'react-admin'
import { useDomain } from '../contexts/domain-context'
import { addressExportFieldOptions } from '../models/address/address'
import { AddressList } from '../models/address/list'
import { DefaultShowButton } from './default-buttons'
import {
  PrimarySecondaryReferenceListInput,
  ReferencedEntityListComponentProps,
} from './primary-secondary-reference-list-input'

/**
 * Address list editing for an entity that holds a unique `primary_address` and multiple `addresses`.
 */

interface AddressListInputProps {
  resource: string
  id: string
  basePath: string
  addressBasePath: string
  editTransform?: (val: any) => any
  prefixComponent?: ReactElement
}

const useAddressListStyles = makeStyles({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
  },
})

export const AddressListInput = ({
  resource,
  id,
  basePath,
  addressBasePath,
  editTransform,
  prefixComponent,
}: AddressListInputProps) => {
  const domain = useDomain()
  const domainName = domain?.domain_name
  const addDomainTransform = useCallback(
    (records: any[]): any[] => records.map((record) => ({ ...record, domain: domainName })),
    [domainName],
  )
  return (
    <PrimarySecondaryReferenceListInput
      parentResource={resource}
      referencedEntityResource="Address"
      id={id}
      basePath={basePath}
      referencedEntitiesBasePath={addressBasePath}
      primaryReferencedEntityField="primary_address"
      allReferencedEntityField="addresses"
      editTransform={editTransform}
      referencedEntityListComponent={MyAddressList}
      xlsExportFieldOptions={addressExportFieldOptions}
      xlsExportTransform={addDomainTransform}
      prefixComponent={prefixComponent}
    />
  )
}

const MyAddressList = ({
  referencedEntitiesBasePath,
  sort,
  filter,
  actions,
  resource,
  extraColumnsMid,
  extraColumnsEnd,
  datagridIdSort,
  title,
}: ReferencedEntityListComponentProps) => {
  const classes = useAddressListStyles()
  const finalExtraColumnsMid = useMemo(
    () => [
      ...(extraColumnsMid || []),
      <DefaultShowButton key="show" basePath={referencedEntitiesBasePath} />,
    ],
    [referencedEntitiesBasePath, extraColumnsMid],
  )
  return (
    <AddressList
      className={classes.root}
      sort={sort}
      filter={filter}
      resource={resource}
      basePath={referencedEntitiesBasePath}
      datagridIdSort={datagridIdSort}
      actions={actions || false}
      emptyPage={<EmptyPage resource={resource} actions={actions} />}
      filters={
        <Filter variant="outlined">
          <TextInput source="q" alwaysOn />
        </Filter>
      }
      disableModify={true}
      disableDelete={true}
      disableShow={true}
      extraColumnsMid={finalExtraColumnsMid}
      extraColumnsEnd={extraColumnsEnd}
      title={title}
    />
  )
}

const EmptyPage = ({ resource, actions }: { resource: string; actions?: ReactElement | false }) => {
  const translate = useTranslate()
  return (
    <>
      <TopToolbar>{actions}</TopToolbar>
      <Typography component="h2">{translate(`resources.${resource}.emptyTitle`)}</Typography>
      <Typography component="h3">{translate(`resources.${resource}.emptySubtitle`)}</Typography>
    </>
  )
}
