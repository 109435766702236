import { Association } from '../../entity-types'
import { ASSOCIATION_RESOURCE_NAME } from '../constants'
import { useGetOneById } from '../hook/use-get-one-by-id'
import { useDomainHierarchy } from './useDomainHierarchy'

export const useAssociationHierarchy = (associationId: string | undefined) => {
  const { data: association, loading, error: associationError } = useGetOneById<Association>(
    ASSOCIATION_RESOURCE_NAME,
    associationId,
  )
  const { domain, loading: domainLoading, domainError } = useDomainHierarchy(association?.domain.id)
  return {
    association,
    domain,
    loading: loading && domainLoading,
    domainError,
    associationError,
    error: associationError || domainError,
  }
}
