import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { FC, ReactElement } from 'react'
import { TopToolbar, useListContext, useTranslate } from 'react-admin'
import { useActivity } from '../contexts/activity-context'
import PortailLinkButton from './portail-link-button'

interface Props {
  actions?: ReactElement
}

const useStyles = makeStyles({
  toolbar: {
    paddingTop: '10px',
    paddingRight: '10px',
  },
  button: {
    margin: '5px',
  },
  content: {
    padding: '16px',
    paddingLeft: '40px',
    '& h1': {
      fontWeight: 'bold',
      color: '#141414',
      fontSize: '20px',
      letterSpacing: '1.79px',
    },
    '& h3': {
      fontWeight: '400',
      fontSize: '12px',
      letterSpacing: '1.07px',
      marginTop: '6px',
      marginBottom: '20px',
    },
    '& hr': {
      marginBottom: '20px',
    },
  },
})

const EmptyPage: FC<Props> = ({ actions }: Props) => {
  const activity = useActivity()
  const { resource } = useListContext()
  const translate = useTranslate()
  const classes = useStyles()

  let suffix = ''
  if (resource === 'Address' || resource === 'Contact') {
    if (activity) {
      suffix = 'Activity'
    } else {
      suffix = 'Association'
    }
  }

  const actionToolbar = actions || (
    <TopToolbar className={classes.toolbar}>
      <PortailLinkButton className={classes.button} variant="outlined" color="primary" />
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        href={`#/${resource}/create`}
      >
        {translate(`resources.${resource}.button.create`)}
      </Button>
    </TopToolbar>
  )

  return (
    <>
      {actionToolbar}
      <Box className={classes.content}>
        <Typography component="h1">{translate(`resources.${resource}.title${suffix}`)}</Typography>
        <Typography component="h3">
          {translate(`resources.${resource}.subtitle${suffix}`)}
        </Typography>
        <Divider />
        <Typography component="h1">{translate(`resources.${resource}.emptyTitle`)}</Typography>
        <Typography component="h3">{translate(`resources.${resource}.emptySubtitle`)}</Typography>
      </Box>
    </>
  )
}

export default EmptyPage
