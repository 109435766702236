import React from 'react'
import { CONTACT_RESOURCE_NAME } from '../../constants'
import {
  MissionsDisabledMessage,
  useAreDomainMissionsEnabled,
} from '../../hook/useAreDomainMissionsEnabled'
import { ContactShow } from '../contact/show'

export const MissionContactShow = ({ basePath, id }: { basePath: string; id: string }) => {
  const areDomainMissionsEnabled = useAreDomainMissionsEnabled()
  if (!areDomainMissionsEnabled) {
    return <MissionsDisabledMessage />
  }
  return (
    <ContactShow
      resource={CONTACT_RESOURCE_NAME}
      id={id}
      ownerType="Mission"
      basePath={basePath}
      edit={false}
    />
  )
}
