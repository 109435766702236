import { Button, ButtonProps, makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import React, { useEffect } from 'react'
import {
  CreateButton,
  CreateButtonProps,
  EditButton,
  EditButtonProps,
  SaveButton,
  SaveButtonProps,
  TopToolbar as ReactAdminTopToolbar,
  useMutation,
  useNotify,
  useRefresh,
  useResourceContext,
  useTranslate,
} from 'react-admin'

export const useToolbarStyles = makeStyles({
  root: {
    right: '24px',
    paddingTop: '10px',
    paddingRight: '10px',
  },
  asActionToolbar: {
    // In ReactAdmin there are two kinds of form: those who require an "Actions" toolbar, and those who require a "Toolbar" toolbar.
    // If its an "Actions" toolbar, it places it on the bottom of the form. We want to override this and have it on the top anyway.
    position: 'absolute',
    top: '56px',
  },
  asListToolbar: {},
  button: {
    margin: '5px',
    fontSize: '0.875rem',
    minHeight: '10px',
    padding: '6px 16px',
  },
})

export const ToolbarButton = ({ children, ...props }: ButtonProps) => {
  const classes = useToolbarStyles()
  return (
    <Button {...props} className={classes.button} variant="contained" color="primary">
      {children}
    </Button>
  )
}

interface TopToolbarProps {
  layoutType?: 'action' | 'toolbar'
  className?: string
  [key: string]: unknown
}

export const TopToolbar = ({ layoutType, className, ...props }: TopToolbarProps) => {
  const classes = useToolbarStyles()
  return (
    <ReactAdminTopToolbar
      {...props}
      className={classnames(
        classes.root,
        layoutType === 'action' && classes.asActionToolbar,
        className,
      )}
    />
  )
}

export const ToolbarCreateButton = (props: CreateButtonProps) => {
  const classes = useToolbarStyles()
  const translate = useTranslate()
  const resource = useResourceContext()
  return (
    <CreateButton
      label={translate(`resources.${props.resource || resource}.button.create`)}
      {...props}
      icon={<></>}
      className={classes.button}
      variant="contained"
      color="primary"
    />
  )
}

export const ToolbarEditButton = (props: EditButtonProps) => {
  const classes = useToolbarStyles()
  return (
    <EditButton
      {...props}
      icon={<></>}
      className={classes.button}
      variant="contained"
      color="primary"
    />
  )
}

export const ToolbarSaveButton = (props: SaveButtonProps) => {
  const classes = useToolbarStyles()
  return (
    <SaveButton
      {...props}
      icon={<></>}
      className={classes.button}
      variant="contained"
      color="primary"
    />
  )
}

export const EnableDisableButton = (props: any) => {
  const translate = useTranslate()
  const notify = useNotify()
  const refresh = useRefresh()
  const classes = useToolbarStyles()

  const { data = {}, resource } = props

  const disableMapping: Record<string, string> = {
    Association: 'disabled',
    Activity: 'disabled',
    Mission: 'disabled',
    DomainUsefullink: 'disabled',
    DomainHighlight: 'disabled',
    Contact: 'hidden',
  }
  const fieldname = resource && disableMapping[resource]

  const payload: { id: string; data: Record<string, string | boolean> } = {
    id: data.id,
    data: { id: data.id },
  }

  if (data.domain) {
    payload.data.domain = data.domain.id
  }

  if (data.association) {
    payload.data.association = data.association.id
  }

  if (data.activity) {
    payload.data.activity = data.activity.id
  }

  if (fieldname) {
    payload.data[fieldname] = !data[fieldname]
  }

  const [toogleDisabled, { loading, data: success, error }] = useMutation({
    type: 'update',
    resource,
    payload,
  })

  useEffect(() => {
    if (success) {
      if (fieldname && success[fieldname]) {
        notify(`${translate(`resources.${resource}.name`)} ${translate('texts.disable')}`, 'info')
      } else {
        notify(`${translate(`resources.${resource}.name`)} ${translate('texts.enable')}`, 'info')
      }
      refresh()
    }
    if (error) {
      notify(error, 'warning')
    }
    // eslint-disable-next-line
  }, [success, error])

  if (!fieldname) {
    return null
  }

  return (
    <Button
      className={classes.button}
      variant="outlined"
      color="primary"
      onClick={toogleDisabled}
      disabled={loading}
    >
      {data[fieldname]
        ? translate(`resources.${resource}.button.enable`)
        : translate(`resources.${resource}.button.disable`)}
    </Button>
  )
}
