import { makeStyles } from '@material-ui/core'
import { composeFunctions } from '../../../helpers/compose-functions'
import { idEditTransform } from '../../../helpers/id-edit-transform'
import { nullifyEmptyStringFields } from '../../../helpers/nullify-empty-string-fields'
import { nullifyUndefinedFields } from '../../../helpers/nullify-undefined-fields'

export const usefulLinkDocEditTransform = composeFunctions(
  nullifyUndefinedFields,
  nullifyEmptyStringFields,
  idEditTransform(['domain', 'association']),
)

export const usefulLinkDocCreateTransform = composeFunctions(
  nullifyUndefinedFields,
  nullifyEmptyStringFields,
)

export const useCreateEditFormsStyles = makeStyles({
  descriptionTextArea: {
    minHeight: '5rem',
  },
  gridLayout: {
    maxWidth: '40rem',
    padding: 0,
  },
  raBoxRow: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'flex-start',
    justifyItems: 'flex-start',
    justifyContent: 'flex-start',
    '& > * + *': {
      marginLeft: '1rem',
    },
  },
  flexGrow1: {
    flexGrow: 1,
  },
  flexGrow2: {
    flexGrow: 2,
  },
  smallTopMargin: {
    marginTop: '1rem',
  },
})
