import { Typography } from '@material-ui/core'
import React from 'react'
import { Loading, useTranslate } from 'react-admin'
import { useModelStyles } from '../../../helpers/model-styles'
import { useDomain } from '../../contexts/domain-context'
import { useIsAtLeastDomainRole } from '../../hook/use-is-at-least-domain-role'
import { AddressCreate } from '../address/create'

export const DomainAddressCreate = ({ basePath }: { basePath: string }) => {
  const translate = useTranslate()
  const modelClasses = useModelStyles()
  const domain = useDomain()
  const domainId = domain?.id

  if (!useIsAtLeastDomainRole() || !domainId) {
    return <Loading />
  }

  return (
    <div className={modelClasses.edit}>
      <Typography component="h1">{translate(`resources.Address.titleDomain`)}</Typography>
      <Typography component="h3">{translate(`resources.Address.subtitleDomain`)}</Typography>
      <AddressCreate basePath={basePath} />
    </div>
  )
}
