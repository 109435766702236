import React, { useMemo } from 'react'
import { SortPayload, TextField, TextInput } from 'react-admin'
import { EntityReferenceListInput } from '../../../common/entity-reference-list-input'
import { TagReferenceArrayField } from '../../../common/tag-reference-array-field'
import { TagReferenceInput } from '../../../common/tag-reference-input'
import { MISSION_RESOURCE_NAME } from '../../../constants'
import { useDomain } from '../../../contexts/domain-context'
import { TagType } from '../../tag'
import { SelectionEntityListFilter } from './selection-entity-list-filter'

const missionListSort: SortPayload = { field: 'name', order: 'ASC' }

export const DomainHighlightSelectionByMissionsBuilder = () => {
  const domain = useDomain()
  const domainId = domain?.id

  const filter = useMemo(
    () => ({
      domain: domainId,
    }),
    [domainId],
  )

  if (!domainId) {
    return null
  }

  return (
    <EntityReferenceListInput
      source="missions"
      referenceResource={MISSION_RESOURCE_NAME}
      referenceFilter={filter}
      referenceSort={missionListSort}
      translationPrefix="resources.DomainHighlight.MissionListInput"
      filters={<MissionListFilters />}
    >
      <TextField source="title" />
      <TagReferenceArrayField source="causes" />
      <TagReferenceArrayField source="skills" />
    </EntityReferenceListInput>
  )
}

const MissionListFilters = (props: unknown) => {
  return (
    <SelectionEntityListFilter {...props}>
      <TextInput source="q" alwaysOn />
      <TagReferenceInput
        source="causes"
        alwaysOn
        label="EntityReferenceListInput.missionCauseFilterPlaceholder"
        tagTypes={TagType.MissionCause}
      />
      <TagReferenceInput
        source="skills"
        alwaysOn
        label="EntityReferenceListInput.missionSkillFilterPlaceholder"
        tagTypes={TagType.MissionSkill}
      />
    </SelectionEntityListFilter>
  )
}
