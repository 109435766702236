import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { BoxedShowLayout, RaBox } from 'ra-compact-ui'
import React, { ReactElement } from 'react'
import {
  Edit,
  EditProps,
  ImageField,
  ImageInput,
  RedirectionSideEffect,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  usePermissions,
  useTranslate,
} from 'react-admin'
import { Permission, RoleType } from '../../../auth-provider'
import { Association } from '../../../entity-types'
import { useModelStyles } from '../../../helpers/model-styles'
import { NonInput } from '../../common/non-input'
import { TagReferenceArrayInput } from '../../common/tag-reference-array-input'
import { TagReferenceInput } from '../../common/tag-reference-input'
import { useIsAtLeastDomainRole } from '../../hook/use-is-at-least-domain-role'
import { RecordMiddleware } from '../domain/record-middleware'
import { TagType } from '../tag'
import {
  ADMINISTRATIVE_TYPE_SELECT_CHOICES,
  associationDocTransform,
  useColumnStyles,
} from './association'

const tagTypes = [TagType.Main, TagType.Normal]

const createInitialFormRecord = ({
  logo_url: _,
  header_url: __,
  tags,
  main_tag,
  ...otherFields
}: Association): Association => {
  const res = ({
    ...otherFields,
    main_tag: main_tag?.id,
    tags: (tags || []).map((tag) => tag.id),
  } as any) as Association
  // The ImageInput exepects a File object, but the server sends us a URL.
  // We simply remove the fields, so we don't get a warning from ImageInput.
  return res
}

export const AssociationEditForm = ({
  children,
  redirect,
  toolbar,
  ...props
}: {
  toolbar?: ReactElement
  redirect?: RedirectionSideEffect
  children: ReactElement | ReactElement[]
} & EditProps) => {
  const translate = useTranslate()
  const modelClasses = useModelStyles()
  const classes = useModelStyles()
  return (
    <Edit
      title={translate('resources.Association.name')}
      undoable={false}
      transform={associationDocTransform}
      classes={{ card: classes.card }}
      actions={false}
      {...props}
    >
      <RecordMiddleware<Association> transform={createInitialFormRecord}>
        <SimpleForm redirect={redirect} toolbar={toolbar} className={modelClasses.edit}>
          {children}
        </SimpleForm>
      </RecordMiddleware>
    </Edit>
  )
}

export const AssociationEdit = (props: any) => {
  const translate = useTranslate()
  const columnClasses = useColumnStyles()
  const { permissions } = usePermissions() as { permissions: Permission }

  const isAtLeastDomainRole = useIsAtLeastDomainRole()

  if (!permissions) {
    return null
  }

  return (
    <AssociationEditForm {...props}>
      <BoxedShowLayout>
        <NonInput>
          <Typography component="h1">{translate('resources.Association.title')}</Typography>
          <Typography component="h3">{translate('resources.Association.subtitle')}</Typography>
          <Divider />
          <Typography component="h2">{translate('resources.Association.tabs.1')}</Typography>
        </NonInput>
        <BoxedShowLayout>
          <RaBox display="flex">
            <RaBox flex="30">
              <ImageInput
                source="logo_url"
                accept="image/*"
                helperText="resources.Association.helperTexts.logo_url"
                {...props}
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            </RaBox>
            <RaBox flex="70" className={columnClasses.columnWithLeftMargin}>
              <ImageInput
                source="header_url"
                accept="image/*"
                helperText="resources.Association.helperTexts.header_url"
                {...props}
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            </RaBox>
          </RaBox>
        </BoxedShowLayout>
        <NonInput>
          <Divider />
          <Typography component="h2">{translate('resources.Association.tabs.2')}</Typography>
        </NonInput>
        <TextInput
          variant="outlined"
          source="name"
          validate={[required()]}
          helperText="resources.Association.helperTexts.name"
        />
        <TextInput
          variant="outlined"
          multiline
          source="description_text"
          fullWidth
          helperText="resources.Association.helperTexts.description_text"
        />
        <TextInput
          variant="outlined"
          source="email"
          type="email"
          helperText="resources.Association.helperTexts.email"
        />
        <TextInput
          variant="outlined"
          source="phone"
          helperText="resources.Association.helperTexts.phone"
        />
        <TagReferenceInput
          source="main_tag"
          label="resources.Association.fields.main_tag"
          tagTypes={TagType.Main}
          fullWidth
          disabled={!isAtLeastDomainRole}
          validate={[required()]}
        />
        {permissions &&
          (permissions.type === RoleType.DEV || permissions.type === RoleType.ADMIN) && (
            <TagReferenceArrayInput
              source="tags"
              maxTagCount={6}
              fullWidth
              label="resources.Association.fields.tags"
              tagTypes={tagTypes}
            />
          )}
        <NonInput>
          <Divider />
          <Typography component="h2">{translate('resources.Association.tabs.3')}</Typography>
        </NonInput>
        <RaBox display="flex">
          <RaBox flex="30">
            <TextInput
              variant="outlined"
              source="website_url"
              helperText="resources.Association.helperTexts.website_url"
            />
          </RaBox>
          <RaBox flex="30">
            <TextInput
              variant="outlined"
              source="facebook_url"
              helperText="resources.Association.helperTexts.facebook_url"
            />
          </RaBox>
          <RaBox flex="30">
            <TextInput
              variant="outlined"
              source="instagram_url"
              helperText="resources.Association.helperTexts.instagram_url"
            />
          </RaBox>
        </RaBox>
        <RaBox display="flex">
          <RaBox flex="30">
            <TextInput
              variant="outlined"
              source="twitter_url"
              helperText="resources.Association.helperTexts.twitter_url"
            />
          </RaBox>
          <RaBox flex="30">
            <TextInput
              variant="outlined"
              source="youtube_url"
              helperText="resources.Association.helperTexts.youtube_url"
            />
          </RaBox>
          <RaBox flex="30">
            <TextInput
              variant="outlined"
              source="linkedin_url"
              helperText="resources.Association.helperTexts.linkedin_url"
            />
          </RaBox>
        </RaBox>
        <NonInput>
          <Divider />
          <Typography component="h2">{translate('resources.Association.tabs.4')}</Typography>
        </NonInput>
        <RaBox display="flex">
          <RaBox flex="30">
            <TextInput variant="outlined" source="administrative_id" fullWidth />
          </RaBox>
          <RaBox flex="30" className={columnClasses.columnWithLeftMargin}>
            <SelectInput
              variant="outlined"
              source="administrative_id_type"
              choices={ADMINISTRATIVE_TYPE_SELECT_CHOICES}
              fullWidth
            />
          </RaBox>
        </RaBox>
      </BoxedShowLayout>
    </AssociationEditForm>
  )
}
