import { Card, makeStyles, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { chain } from 'lodash'
import React, { useMemo, useState } from 'react'
import { Button, Toolbar, useGetMany, useTranslate } from 'react-admin'
import { Contact, DbRecord } from '../../entity-types'
import { CONTACT_RESOURCE_NAME } from '../constants'
import { ContactCreate } from '../models/contact/create'
import {
  PrimarySecondaryReferenceSelectOrCreate,
  SelectEntityFormProps,
} from './primary-secondary-reference-select-or-create'

/**
 * Contact select / create form. Once created, the created id is added to a parent's `contacts` field in a parent entity.
 * This is a common pattern currently used with (Association|Activity)-(Contact|Address).
 */

interface ContactReferenceCreateProps {
  // Parent resource that holds the `contacts` field where the created id is linked in.
  resource: string
  // Id of the parent entity that holds the `contacts` field where the created id is linked in.
  id: string
  // Will be passed to your Create component.
  basePath: string
  // Pre-save transformation for the parent entity.
  editTransform?: (val: any) => any
  // Resource from which to get the existing contacts.
  existingContactSourceResource?: string
  // Ids of the entity from which to get the existing contacts.
  existingContactSourceIds?: string[]
  // Where to go after a successful Select / Create.
  onSuccessRedirectTo?: string
}

export const ContactReferenceSelectOrCreate = ({
  resource,
  id,
  basePath,
  editTransform,
  existingContactSourceResource,
  existingContactSourceIds,
  onSuccessRedirectTo,
}: ContactReferenceCreateProps) => {
  const SelectComponent = useMemo(
    () =>
      existingContactSourceResource && existingContactSourceIds
        ? (props: SelectEntityFormProps) => (
            <SearchForm
              sourceResource={existingContactSourceResource}
              sourceIds={existingContactSourceIds}
              {...props}
            />
          )
        : undefined,
    [existingContactSourceIds, existingContactSourceResource],
  )

  return (
    <PrimarySecondaryReferenceSelectOrCreate
      parentResource={resource}
      referencedEntityResource={CONTACT_RESOURCE_NAME}
      id={id}
      basePath={basePath}
      onSuccessRedirectTo={onSuccessRedirectTo}
      allReferencedEntityField="contacts"
      editTransform={editTransform}
      referencedEntityCreateComponent={ContactCreate}
      selectFromExistingFormComponent={SelectComponent}
    />
  )
}

const useSearchFormStyles = makeStyles({
  root: {
    width: 400,
  },
})

const useAutocompleteInputStyles = makeStyles({
  root: {
    boxSizing: 'border-box',
    padding: '1rem',
  },
})

const SearchForm = ({
  onSelect,
  sourceResource,
  sourceIds,
  searchBlacklistedIds,
}: SelectEntityFormProps & { sourceResource: string; sourceIds: string[] }) => {
  const classes = useSearchFormStyles()
  const autocompleteClasses = useAutocompleteInputStyles()
  const translate = useTranslate()
  const [contact, setContact] = useState<Contact | null>(null)
  const { data: sourceEntities } = (useGetMany(sourceResource, sourceIds) as unknown) as {
    data: (DbRecord & { contacts: Contact[] })[]
  }
  const allContactsIds = useMemo(
    () =>
      chain(sourceEntities)
        .map((sourceEntity) => sourceEntity?.contacts || [])
        .flatten()
        .map((contact) => contact.id)
        .uniqBy((id) => id)
        .filter((id) => !searchBlacklistedIds || !searchBlacklistedIds.includes(id))
        .value(),
    [searchBlacklistedIds, sourceEntities],
  )
  const { data: allContacts } = (useGetMany(CONTACT_RESOURCE_NAME, allContactsIds, {
    enabled: allContactsIds.length > 0,
  }) as unknown) as { data: Contact[] }
  if (!allContacts) {
    return null
  }

  return (
    <Card className={classes.root}>
      <Autocomplete<Contact>
        fullWidth={true}
        classes={autocompleteClasses}
        value={contact}
        options={allContacts}
        getOptionLabel={getContactLabel}
        renderInput={(params) => (
          <TextField
            {...params}
            label={translate(
              `resources.${sourceResource}.Contact.selectOrCreate.select.comboBoxPlaceholder`,
            )}
            variant="outlined"
          />
        )}
        onChange={(_, contact) => {
          setContact(contact)
        }}
      />
      <Toolbar>
        <Button
          variant="contained"
          label={translate(
            `resources.${sourceResource}.Contact.selectOrCreate.select.saveButtonLabel`,
          )}
          disabled={!contact}
          onClick={() => onSelect?.(contact)}
        />
      </Toolbar>
    </Card>
  )
}

const getContactLabel = (contact?: Contact) => {
  if (!contact) {
    return ''
  }
  const parts = []
  if (contact.civility) {
    parts.push(contact.civility)
  }
  if (contact.firstname) {
    parts.push(contact.firstname)
  }
  if (contact.lastname) {
    parts.push(contact.lastname)
  }
  return parts.join(' ')
}
