/* eslint-disable no-redeclare */

import {
  ACTIVITY_RESOURCE_NAME,
  ACTIVITY_SCHEDULE_RESOURCE_NAME,
  ADDRESS_RESOURCE_NAME,
  ASSOCIATION_RESOURCE_NAME,
  CONTACT_RESOURCE_NAME,
  DOMAIN_HIGHLIGHT_RESOURCE_NAME,
  DOMAIN_PARTNER_RESOURCE_NAME,
  DOMAIN_RESOURCE_NAME,
  MISSION_RESOURCE_NAME,
  ROLE_RESOURCE_NAME,
  TAG_RESOURCE_NAME,
  USEFUL_LINK_RESOURCE_NAME,
  USER_RESOURCE_NAME,
} from '../components/constants'
import {
  createUrlCreator,
  defaultIdSpecifiedUrlMap,
  defaultIdUnspecifiedUrlMap,
} from './url-creator-helpers'

/**
 * Generate standard urls, as well as extra custom ones.
 * This allows nested urls with an type-safe and elegant declaration:
 * e.g.
 *  URLS.domain('myDomainId').partner('myPartnerId').show
 *  will return
 *  "/Domain/myDomainId/Partner/myPartnerId/Show"
 */

const userUrls = createUrlCreator(
  `/${USER_RESOURCE_NAME}`,
  defaultIdUnspecifiedUrlMap,
  defaultIdSpecifiedUrlMap,
)

const roleUrls = createUrlCreator(
  `/${ROLE_RESOURCE_NAME}`,
  defaultIdUnspecifiedUrlMap,
  defaultIdSpecifiedUrlMap,
)

const tagUrls = createUrlCreator(
  `/${TAG_RESOURCE_NAME}`,
  defaultIdUnspecifiedUrlMap,
  defaultIdSpecifiedUrlMap,
)

const domainPartnerUrls = createUrlCreator(
  `/${DOMAIN_PARTNER_RESOURCE_NAME}`,
  defaultIdUnspecifiedUrlMap,
  defaultIdSpecifiedUrlMap,
)

const contactUrls = createUrlCreator(
  `/${CONTACT_RESOURCE_NAME}`,
  defaultIdUnspecifiedUrlMap,
  defaultIdSpecifiedUrlMap,
)

const usefulLinkUrls = createUrlCreator(
  `/${USEFUL_LINK_RESOURCE_NAME}`,
  defaultIdUnspecifiedUrlMap,
  defaultIdSpecifiedUrlMap,
)

const addressUrls = createUrlCreator(
  `/${ADDRESS_RESOURCE_NAME}`,
  defaultIdUnspecifiedUrlMap,
  defaultIdSpecifiedUrlMap,
)

const activityScheduleUrls = createUrlCreator(
  `/${ACTIVITY_SCHEDULE_RESOURCE_NAME}`,
  defaultIdUnspecifiedUrlMap,
  defaultIdSpecifiedUrlMap,
)

const activityUrls = createUrlCreator(`/${ACTIVITY_RESOURCE_NAME}`, defaultIdUnspecifiedUrlMap, {
  ...defaultIdSpecifiedUrlMap,
  contact: contactUrls,
  address: addressUrls,
  schedule: activityScheduleUrls,
})

const missionUrls = createUrlCreator(`/${MISSION_RESOURCE_NAME}`, defaultIdUnspecifiedUrlMap, {
  ...defaultIdSpecifiedUrlMap,
  contact: contactUrls,
  address: addressUrls,
})

export const associationUrls = createUrlCreator(
  `/${ASSOCIATION_RESOURCE_NAME}`,
  defaultIdUnspecifiedUrlMap,
  {
    ...defaultIdSpecifiedUrlMap,
    activity: activityUrls,
    contact: contactUrls,
    address: addressUrls,
    mission: missionUrls,
  },
)

export const domainHighlightUrls = createUrlCreator(
  `/${DOMAIN_HIGHLIGHT_RESOURCE_NAME}`,
  defaultIdUnspecifiedUrlMap,
  defaultIdSpecifiedUrlMap,
)

export const domainEditoUrls = createUrlCreator(`/edito`, defaultIdSpecifiedUrlMap, {})

export const domainThemeUrls = createUrlCreator(`/theme`, defaultIdSpecifiedUrlMap, {})

export const domainUrls = createUrlCreator(`/${DOMAIN_RESOURCE_NAME}`, defaultIdUnspecifiedUrlMap, {
  ...defaultIdSpecifiedUrlMap,
  dashboard: '/Dashboard',
  importExport: '/ImportExport',
  missionSettings: '/MissionSettings',
  edito: domainEditoUrls,
  theme: domainThemeUrls,
  highlight: domainHighlightUrls,
  association: associationUrls,
  partner: domainPartnerUrls,
  usefulLink: usefulLinkUrls,
  contact: contactUrls,
  address: addressUrls,
})

export const URLS = {
  domain: domainUrls,
  user: userUrls,
  role: roleUrls,
  tag: tagUrls,
  association: associationUrls, // Only used for association landing page
  activity: activityUrls, // Only used for activity landing page
  assoRoleHome: '/AssoHome',
}
