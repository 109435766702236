export const commonListStyles = {
  '& .MuiTableSortLabel-root': {
    marginTop: '8px',
    textTransform: 'uppercase',
    color: '#8D8D8D',
    fontSize: '12px',
    fontFamily: 'Roboto',
    lineHeight: '16px',
  },
  '& .MuiTableCell-body span': {
    fontSize: '13px',
  },
  '& .MuiButton-label': {
    textTransform: 'capitalize',
    fontSize: '13px',
  },
  '& .MuiDivider-root': {
    marginTop: '30px',
    marginBottom: '30px',
  },
}
