import React from 'react'
import { Loading } from 'react-admin'
import { ADDRESS_RESOURCE_NAME } from '../../constants'
import { useIsAtLeastDomainRole } from '../../hook/use-is-at-least-domain-role'
import { AddressShow } from '../address/show'

export const DomainAddressShow = ({ basePath, id }: { basePath: string; id: string }) => {
  if (!useIsAtLeastDomainRole()) {
    return <Loading />
  }
  return (
    <AddressShow resource={ADDRESS_RESOURCE_NAME} id={id} ownerType="Domain" basePath={basePath} />
  )
}
