import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import React, { ReactElement } from 'react'
import {
  Edit,
  EditProps,
  ImageField,
  ImageInput,
  required,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import { idEditTransform } from '../../../helpers/id-edit-transform'
import { useModelStyles } from '../../../helpers/model-styles'
import EditToolbar from '../../common/edit-toolbar'
import { NonInput } from '../../common/non-input'
import { useIsAtLeastDomainRole } from '../../hook/use-is-at-least-domain-role'

interface DomainEditFormProps extends EditProps {
  children: ReactElement
}

export const DomainEditForm = ({ children, ...props }: DomainEditFormProps) => {
  const translate = useTranslate()
  const classes = useModelStyles()
  return (
    <Edit
      title={translate('resources.DomainPartner.name')}
      undoable={false}
      transform={idEditTransform(['domain'])}
      classes={{ card: classes.card }}
      actions={false}
      {...props}
    >
      {children}
    </Edit>
  )
}

export const DomainPartnerEdit = (props: EditProps) => {
  const translate = useTranslate()
  const classes = useModelStyles()

  if (!useIsAtLeastDomainRole()) {
    return null
  }

  return (
    <DomainEditForm {...props}>
      <SimpleForm toolbar={<EditToolbar />} className={classes.edit}>
        <NonInput>
          <Typography component="h1">{translate('resources.DomainPartner.title')}</Typography>
          <Typography component="h3">{translate('resources.DomainPartner.subtitle')}</Typography>
          <Divider />
        </NonInput>
        <ImageInput
          variant="outlined"
          source="logo_url"
          accept="image/*"
          validate={[required()]}
          helperText="resources.DomainPartner.helperTexts.logo_url"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput variant="outlined" source="name" validate={[required()]} />
        <TextInput variant="outlined" source="url" validate={[required()]} />
      </SimpleForm>
    </DomainEditForm>
  )
}
