import { gql } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { Button, useNotify, useTranslate } from 'react-admin'
import { client } from '../../apollo-client'
import { convertFileToBase64 } from '../../helpers/upload-data-provider'

const useStyles = makeStyles({
  button: {
    margin: '5px',
    fontSize: '0.875rem',
    minHeight: '10px',
    padding: '6px 16px',
    '& .MuiButton-label span': {
      paddingLeft: '0',
    },
  },
})

const ImportButton = () => {
  const [loading, setLoading] = useState(false)
  const notify = useNotify()
  const translate = useTranslate()
  const classes = useStyles()

  async function handleClick() {
    try {
      setLoading(true)

      const input = document.querySelector<HTMLInputElement>(`#import-tag`)
      const content = await convertFileToBase64({ rawFile: input && input.files && input.files[0] })

      const MUTATION = gql`
      mutation {
        importTags(content: "${content}") {
          added
          updated
          ignored
          error
        }
      }`

      const success = await client.mutate({ mutation: MUTATION })
      if (success) {
        const { added, ignored, error } = success.data.importTags

        let info = `${added} tag added\n`
        info += `${ignored} tag ignored\n`
        info += error.join('\n')

        alert(info)
      }
      setLoading(false)
      if (input) {
        input.value = ''
      }
    } catch (error) {
      notify(error instanceof Error ? error.message : String(error), 'warning')
      setLoading(false)
    }
  }

  return (
    <Button
      label={translate('buttons.import')}
      component="label"
      disabled={loading}
      classes={{ button: classes.button } as any}
      variant="outlined"
    >
      <input id="import-tag" type="file" accept=".csv" hidden onChange={handleClick} />
    </Button>
  )
}

export default ImportButton
