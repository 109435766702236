import { useEffect } from 'react'

type ConsoleChannel = 'log' | 'error' | 'warn' | 'info'

export const usePrintOnConsoleIfDefined = (value: unknown, channel: ConsoleChannel = 'log') => {
  useEffect(() => {
    if (value !== undefined && value !== null) {
      // eslint-disable-next-line no-console
      console[channel](value)
    }
  }, [channel, value])
}

export const usePrintErrorOnConsoleIfDefined = (error: unknown) => {
  return usePrintOnConsoleIfDefined(error, 'error')
}
