import React from 'react'
import {
  Datagrid,
  DateField,
  Filter,
  List,
  NumberField,
  Pagination,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin'
import { DefaultShowButton } from '../../common/default-buttons'
import { TagReferenceField } from '../../common/tag-reference-field'

const DomainTagCountListFilter = (props: any) => (
  <Filter {...props}>
    <ReferenceField label="Domain" source="domain.id" reference="Domain">
      <TextField source="domain_name" />
    </ReferenceField>
  </Filter>
)

export const DomainTagCountList = (props: any) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<DomainTagCountListFilter />}
    pagination={<Pagination />}
  >
    <Datagrid>
      <ReferenceField label="Domain" source="domain.id" reference="Domain">
        <TextField source="domain_name" />
      </ReferenceField>
      <TagReferenceField source="tag" isLink={true} />
      <NumberField source="count" />
      <DateField source="createdAt" />
      <DefaultShowButton />
    </Datagrid>
  </List>
)

export const DomainTagCountShow = (props: any) => (
  <Show title="DomainTagCount show" {...props}>
    <SimpleShowLayout>
      <ReferenceField label="Domain" source="domain.id" reference="Domain">
        <TextField source="domain_name" />
      </ReferenceField>
      <TagReferenceField source="tag" isLink={true} />
      <NumberField source="count" />
      <DateField source="createdAt" />
    </SimpleShowLayout>
  </Show>
)
