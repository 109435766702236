import { useMission } from '../contexts/mission-context'
import { useThisAssociationUrls } from './use-this-association-urls'

/**
 * Get an url creator associated to the provided mission.
 */
export const useMissionUrls = (missionId?: string) => {
  const thisAssociationUrls = useThisAssociationUrls()
  return missionId ? thisAssociationUrls?.mission(missionId) : undefined
}

/**
 * Get an url creator associated to the mission provided by the MissionContext.
 * Useful to express urls relative to the current mission.
 */

export const useThisMissionUrls = () => {
  const mission = useMission()
  return useMissionUrls(mission?.id)
}
