import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { BoxedShowLayout } from 'ra-compact-ui'
import React from 'react'
import { DateField, ReferenceField, SelectField, Show, TextField, useTranslate } from 'react-admin'
import { DbRecord } from '../../../entity-types'
import { daysOfWeek } from '../../../helpers/day-of-week'
import { useModelStyles } from '../../../helpers/model-styles'
import { NonInput } from '../../common/non-input'
import PortailLinkButton from '../../common/portail-link-button'
import { ToolbarEditButton, TopToolbar } from '../../common/toolbar'

export const ActivityScheduleShow = (props: unknown) => {
  const translate = useTranslate()
  const classes = useModelStyles()

  return (
    <Show
      title={translate('resources.ActivitySchedule.name')}
      {...props}
      actions={<Actions />}
      classes={{ card: classes.card }}
    >
      <BoxedShowLayout className={classes.show}>
        <NonInput>
          <Typography component="h1">{translate('resources.ActivitySchedule.title')}</Typography>
          <Typography component="h3">{translate('resources.ActivitySchedule.subtitle')}</Typography>
          <Divider />
        </NonInput>
        <TextField source="name" />
        <SelectField source="day_name" choices={daysOfWeek(translate)} />
        <TextField source="start_time" />
        <TextField source="end_time" />
        <ReferenceField
          label={translate('resources.ActivitySchedule.fields.activity')}
          source="activity.id"
          reference="Activity"
        >
          <TextField source="title" />
        </ReferenceField>
        <DateField source="createdAt" />
      </BoxedShowLayout>
    </Show>
  )
}

const Actions = ({ basePath, data }: { basePath?: string; data?: DbRecord }) => {
  return (
    <TopToolbar>
      <PortailLinkButton />
      <ToolbarEditButton record={data} basePath={basePath} />
    </TopToolbar>
  )
}
