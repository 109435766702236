import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import {
  Edit,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import { Permission, RoleType } from '../../../auth-provider'
import { daysOfWeek } from '../../../helpers/day-of-week'
import { idEditTransform } from '../../../helpers/id-edit-transform'
import { useModelStyles } from '../../../helpers/model-styles'
import { validateHourMinute } from '../../../helpers/validate'
import EditToolbar from '../../common/edit-toolbar'
import { NonInput } from '../../common/non-input'

export const ActivityScheduleEdit = ({ permissions, ...props }: { permissions?: Permission }) => {
  const translate = useTranslate()
  const classes = useModelStyles()

  return (
    <Edit
      title={translate('resources.ActivitySchedule.name')}
      {...props}
      undoable={false}
      transform={idEditTransform(['activity'])}
      classes={{ card: classes.card }}
      actions={false}
    >
      <SimpleForm toolbar={<EditToolbar />} className={classes.edit}>
        <NonInput>
          <Typography component="h1">{translate('resources.ActivitySchedule.title')}</Typography>
          <Typography component="h3">{translate('resources.ActivitySchedule.subtitle')}</Typography>
          <Divider />
        </NonInput>
        <TextInput
          variant="outlined"
          source="name"
          helperText="resources.ActivitySchedule.helperTexts.name"
        />
        <SelectInput
          variant="outlined"
          source="day_name"
          choices={daysOfWeek(translate)}
          validate={[required()]}
          helperText="resources.ActivitySchedule.helperTexts.day_name"
        />
        <TextInput
          variant="outlined"
          source="start_time"
          validate={[required(), validateHourMinute]}
          helperText="resources.ActivitySchedule.helperTexts.start_time"
        />
        <TextInput
          variant="outlined"
          source="end_time"
          validate={[required(), validateHourMinute]}
          helperText="resources.ActivitySchedule.helperTexts.end_time"
        />
        {permissions && permissions.type === RoleType.DEV ? (
          <ReferenceInput
            variant="outlined"
            label={translate('resources.ActivitySchedule.fields.activity')}
            source="activity.id"
            reference="Activity"
            validate={[required()]}
            helperText="resources.ActivitySchedule.helperTexts.activity"
          >
            <SelectInput optionText="title" />
          </ReferenceInput>
        ) : null}
      </SimpleForm>
    </Edit>
  )
}
