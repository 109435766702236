import React from 'react'
import { ReferenceArrayInput, SelectArrayInput, useTranslate } from 'react-admin'
import { daysOfWeek } from '../../../../helpers/day-of-week'
import { TagReferenceArrayInput } from '../../../common/tag-reference-array-input'
import { domainHighlightTagTypes } from '../domain-highlight'

export const DomainHighlightSelectionByCriteriaBuilder = () => {
  const translate = useTranslate()
  return (
    <div>
      <div className="ra-input">
        <ReferenceArrayInput
          variant="outlined"
          label={translate('resources.DomainHighlight.fields.age_sub_groups')}
          source="age_sub_groups"
          reference="AgeSubGroup"
          perPage={300}
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
      </div>
      <div className="ra-input">
        <SelectArrayInput
          variant="outlined"
          source="days"
          choices={daysOfWeek(translate)}
          label={translate('resources.DomainHighlight.fields.days')}
        />
      </div>
      <div className="ra-input">
        <ReferenceArrayInput
          variant="outlined"
          label={translate('resources.DomainHighlight.fields.schedule_groups')}
          source="schedule_groups"
          reference="ScheduleGroup"
          perPage={300}
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
      </div>
      <div className="ra-input">
        <TagReferenceArrayInput
          source="tags"
          maxTagCount={6}
          label="resources.DomainHighlight.fields.tags"
          tagTypes={domainHighlightTagTypes}
        />
      </div>
    </div>
  )
}
