import { makeStyles, Typography } from '@material-ui/core'
import { get } from 'lodash'
import React from 'react'
import { useRecordContext, useTranslate } from 'react-admin'

enum HistoryActionTypes {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  DUPLICATE = 'DUPLICATE',
}

export const ALL_HISTORY_ACTION_TYPES = [
  HistoryActionTypes.CREATE,
  HistoryActionTypes.UPDATE,
  HistoryActionTypes.DELETE,
  HistoryActionTypes.DUPLICATE,
]

const useStyles = makeStyles({
  CREATE: {
    color: 'green',
  },
  UPDATE: {},
  DELETE: {
    color: 'red',
  },
  DUPLICATE: {
    color: 'purple',
  },
})

export const ActionTypeField = (props: any) => {
  const classes = useStyles()
  const translate = useTranslate()
  const record = useRecordContext(props)
  const value = get(record, props.source)

  return (
    <Typography component="span" variant="body2" className={classes[value as keyof typeof classes]}>
      {translate(`resources.History.actionTypes.${value}`)}
    </Typography>
  )
}
