import { get } from 'lodash'
import React from 'react'
import { BooleanFieldProps, useTranslate } from 'react-admin'
import { xor } from '../../helpers/xor'
import { StatusFieldExtraProps, useRealStatusBooleanMustReverse } from './status-toggle-input'

/**
 * Just like BooleanInput but with additional feature:
 * - Automatically translated status with gender management.
 * - Always ON for "enable" and OFF for "disabled", regardless of whether the internal field
 *   is `disabled` or `enabled`
 */

type StatusFieldProps = BooleanFieldProps &
  StatusFieldExtraProps & {
    addLabel?: boolean
  }

export const StatusField = ({
  source,
  gender,
  reverse,
  record,
  labelIfTrue,
  labelIfFalse,
}: StatusFieldProps) => {
  const translate = useTranslate()

  const value = get(record, source)

  const mustReverse = useRealStatusBooleanMustReverse(source, reverse)

  const isEnabled = xor(value, mustReverse)

  const label =
    value && labelIfTrue !== undefined
      ? labelIfTrue
      : !value && labelIfFalse !== undefined
      ? labelIfFalse
      : translate(
          `StatusToggleInput.${gender || 'masculine'}.${isEnabled ? 'enabled' : 'disabled'}`,
        )

  return <span>{label}</span>
}

StatusField.defaultProps = {
  addLabel: true,
}
