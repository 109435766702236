import React from 'react'
import { Loading, usePermissions } from 'react-admin'
import { useRouteMatch } from 'react-router-dom'
import { Permission } from '../../../auth-provider'
import { AddressListInput } from '../../common/address-list-input'
import { ACTIVITY_RESOURCE_NAME } from '../../constants'
import { activityDocTransform } from './activity'

export const ActivityAddressList = (props: { basePath: string; permissions: Permission }) => {
  const { basePath } = props
  const { permissions } = usePermissions() as { permissions: Permission }
  const { params, url } = useRouteMatch()
  const activityId = (params as any).activity_id
  if (!activityId) {
    return null
  }

  if (!permissions) {
    return <Loading />
  }

  return (
    <AddressListInput
      resource={ACTIVITY_RESOURCE_NAME}
      id={activityId}
      basePath={basePath}
      addressBasePath={url}
      editTransform={activityDocTransform}
    />
  )
}
