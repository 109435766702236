import { useMemo } from 'react'
import { ResourceProps, usePermissions } from 'react-admin'
import { Permission, RoleType } from '../../auth-provider'
import { isAtLeastActivityRole } from '../hook/use-is-at-least-activity-role'
import { isAtLeastAdminRole } from '../hook/use-is-at-least-admin-role'
import { isAtLeastAssoRole } from '../hook/use-is-at-least-asso-role'
import { isAtLeastDevRole } from '../hook/use-is-at-least-dev-role'
import { isAtLeastDomainRole } from '../hook/use-is-at-least-domain-role'
import {
  activityRoleResources,
  adminRoleResources,
  assoRoleResources,
  devResources,
  domainRoleResources,
  publicResources,
} from './resources-per-role-type'

/**
 * Provide the resources allowed for the current permissions.
 */

class ResourceMap extends Map<string, ResourceProps> {
  addResource(resource: ResourceProps) {
    this.set(resource.name, resource)
  }
  addResources(resources: ResourceProps[]) {
    for (const resource of resources) {
      this.addResource(resource)
    }
  }
}

const getResourcesForRoleType = (roleType: RoleType): ResourceProps[] => {
  const resourcesMap = new ResourceMap()
  resourcesMap.addResources(publicResources)
  if (isAtLeastActivityRole(roleType)) {
    resourcesMap.addResources(activityRoleResources)
  }
  if (isAtLeastAssoRole(roleType)) {
    resourcesMap.addResources(assoRoleResources)
  }
  if (isAtLeastDomainRole(roleType)) {
    resourcesMap.addResources(domainRoleResources)
  }
  if (isAtLeastAdminRole(roleType)) {
    resourcesMap.addResources(adminRoleResources)
  }
  if (isAtLeastDevRole(roleType)) {
    resourcesMap.addResources(devResources)
  }
  return Array.from(resourcesMap.values())
}

export const useResourceForPermission = (): {
  loaded: boolean
  permissions: Permission | undefined
  loading: boolean
  error: Error | undefined
  resources: ResourceProps[]
} => {
  const { loaded, permissions, loading, error } = usePermissions()
  const roleType = permissions?.type
  const resources = useMemo(() => {
    if (!loaded || error) {
      return []
    }
    return getResourcesForRoleType(roleType)
  }, [error, loaded, roleType])
  return { loaded, permissions, loading, error, resources }
}
