import React from 'react'
import { Loading } from 'react-admin'
import { useRouteMatch } from 'react-router-dom'
import { Permission } from '../../../auth-provider'
import { AddressListInput } from '../../common/address-list-input'
import { MISSION_RESOURCE_NAME } from '../../constants'
import {
  MissionsDisabledMessage,
  useAreDomainMissionsEnabled,
} from '../../hook/useAreDomainMissionsEnabled'
import { missionDocEditTransform } from './mission'

export const MissionAddressList = ({
  basePath,
  permissions,
}: {
  basePath: string
  permissions: Permission
}) => {
  // console.log('MissionAddressList');
  const areDomainMissionsEnabled = useAreDomainMissionsEnabled()
  const { params, url } = useRouteMatch()
  const missionId = (params as any).mission_id
  if (!missionId) {
    return null
  }
  if (!areDomainMissionsEnabled) {
    return <MissionsDisabledMessage />
  }

  if (!permissions) {
    return <Loading />
  }

  return (
    <AddressListInput
      resource={MISSION_RESOURCE_NAME}
      id={missionId}
      basePath={basePath}
      addressBasePath={url}
      editTransform={missionDocEditTransform}
    />
  )
}
