import { Typography } from '@material-ui/core'
import { get, isString } from 'lodash'
import React from 'react'
import { useRecordContext, useTranslate } from 'react-admin'

export const RoleTypeField = ({ source }: { source: string }) => {
  const translate = useTranslate()
  const record = useRecordContext()
  const value = get(record, source)
  const strValue = isString(value) ? value.toLowerCase() : value

  return (
    <Typography component="span" variant="body2">
      {translate(`historyRoleTypes.${strValue}`)}
    </Typography>
  )
}
