import { makeStyles } from '@material-ui/core'
import { TagType } from '../tag'

export const domainHighlightTagTypes = [TagType.Main, TagType.Normal]

export const useDomainHighlightFormStyles = makeStyles({
  raBoxRow: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'flex-start',
    justifyItems: 'flex-start',
    justifyContent: 'flex-start',
    '& > * + *': {
      marginLeft: '1rem',
    },
  },
  titleInput: {
    marginTop: '1rem',
  },
})
