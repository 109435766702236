import { Card, makeStyles, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { chain, sortBy } from 'lodash'
import React, { useMemo, useState } from 'react'
import { Button, Toolbar, useGetMany, useTranslate } from 'react-admin'
import { Address, DbRecord } from '../../entity-types'
import { ADDRESS_RESOURCE_NAME } from '../constants'
import { AddressCreate } from '../models/address/create'
import {
  PrimarySecondaryReferenceSelectOrCreate,
  SelectEntityFormProps,
} from './primary-secondary-reference-select-or-create'

/**
 * Address select / create form. Once created, the created id is added to a parent's `addresses` field in a parent entity.
 * This is a common pattern currently used with (Association|Activity)-(Contact|Address).
 */

interface AddressReferenceCreateProps {
  // Parent resource that holds the `addresses` field where the created id is linked in.
  resource: string
  // Id of the parent entity that holds the `addresses` field where the created id is linked in.
  id: string
  // Will be passed to your Create component.
  basePath: string
  // Pre-save transformation for the parent entity.
  editTransform?: (val: any) => any
  // Resource from which to get the existing addresses.
  existingAddressSourceResource?: string
  // Ids of the entity from which to get the existing addresses.
  existingAddressSourceIds?: string[]
  // Where to go after a successful Select / Create.
  onSuccessRedirectTo?: string
}

export const AddressReferenceSelectOrCreate = ({
  resource,
  id,
  basePath,
  editTransform,
  existingAddressSourceResource,
  existingAddressSourceIds,
  onSuccessRedirectTo,
}: AddressReferenceCreateProps) => {
  const SelectComponent = useMemo(
    () =>
      existingAddressSourceResource && existingAddressSourceIds
        ? (props: SelectEntityFormProps) => (
            <SearchForm
              sourceResource={existingAddressSourceResource}
              sourceIds={existingAddressSourceIds}
              {...props}
            />
          )
        : undefined,
    [existingAddressSourceIds, existingAddressSourceResource],
  )
  return (
    <PrimarySecondaryReferenceSelectOrCreate
      parentResource={resource}
      referencedEntityResource={ADDRESS_RESOURCE_NAME}
      id={id}
      basePath={basePath}
      onSuccessRedirectTo={onSuccessRedirectTo}
      allReferencedEntityField="addresses"
      editTransform={editTransform}
      referencedEntityCreateComponent={AddressCreate}
      selectFromExistingFormComponent={SelectComponent}
    />
  )
}

const useSearchFormStyles = makeStyles({
  root: {
    width: 'auto',
  },
})

const useAutocompleteInputStyles = makeStyles({
  root: {
    boxSizing: 'border-box',
    padding: '1rem',
  },
})

const SearchForm = ({
  onSelect,
  sourceResource,
  sourceIds,
  searchBlacklistedIds,
}: SelectEntityFormProps & { sourceResource: string; sourceIds: string[] }) => {
  const classes = useSearchFormStyles()
  const autocompleteClasses = useAutocompleteInputStyles()
  const translate = useTranslate()
  const [address, setAddress] = useState<Address | null>(null)
  const { data: sourceEntities } = (useGetMany(sourceResource, sourceIds) as unknown) as {
    data: (DbRecord & { addresses: Address[] })[]
  }
  const allAddressesIds = chain(sourceEntities)
    .map((sourceEntity) => sourceEntity?.addresses || [])
    .flatten()
    .map((address) => address.id)
    .uniqBy((id) => id)
    .filter((id) => !searchBlacklistedIds || !searchBlacklistedIds.includes(id))
    .value()
  const { data, loaded } = (useGetMany(ADDRESS_RESOURCE_NAME, allAddressesIds, {
    enabled: allAddressesIds.length > 0,
  }) as unknown) as { data: Address[]; loaded: boolean }
  const allAddresses =
    data && loaded
      ? sortBy(data, ({ city, street, complement }) => `${city} ${street} ${complement}`)
      : undefined
  if (!allAddresses) {
    return null
  }

  return (
    <Card className={classes.root}>
      <Autocomplete<Address>
        fullWidth={true}
        classes={autocompleteClasses}
        value={address}
        options={allAddresses}
        getOptionLabel={getAddressLabel}
        renderInput={(params) => (
          <TextField
            {...params}
            label={translate(
              `resources.${sourceResource}.Address.selectOrCreate.select.comboBoxPlaceholder`,
            )}
            variant="outlined"
          />
        )}
        onChange={(_, address) => {
          setAddress(address)
        }}
      />
      <Toolbar>
        <Button
          variant="contained"
          label={translate(
            `resources.${sourceResource}.Address.selectOrCreate.select.saveButtonLabel`,
          )}
          disabled={!address}
          onClick={() => onSelect?.(address)}
        />
      </Toolbar>
    </Card>
  )
}

const getAddressLabel = (address?: Address) => {
  if (!address) {
    return ''
  }
  return `${address.complement ? `${address.complement}, ` : ''}${address.full_address}`
}
