import React from 'react'
import { URLS } from '../../routes/url-creators'
import { ActivityIcon } from '../common/icons'
import { useActivity } from '../contexts/activity-context'
import { ActivityMenu } from './activity-menu'
import { LinkMenuItem } from './menu-item'
import { MenuItemBack } from './menu-item-back'

export const ActivityRoleRootMenu = () => {
  const activity = useActivity()
  // console.log("Menu domain", domain)

  if (activity) {
    return <ActivitySelectedRootMenu />
  }

  return <RootMenu />
}

const ActivitySelectedRootMenu = () => {
  return (
    <>
      <MenuItemBack
        to={URLS.activity.list}
        primaryTextKey="menu.associationMenu.BackToActivities"
      />
      <ActivityMenu />
    </>
  )
}

const RootMenu = () => {
  return (
    <>
      <LinkMenuItem
        to={URLS.activity.list}
        primaryTextKey="menu.associationMenu.Activity"
        leftIcon={<ActivityIcon />}
      />
    </>
  )
}
