import { zipObject } from 'lodash'
import React from 'react'
import { DataProvider } from 'react-admin'
import { Association, Mission } from '../../../entity-types'
import { XlsExportButton, XlsExportFieldOption } from '../../common/xls-export-button'
import {
  ACTIVITY_RESOURCE_NAME,
  ASSOCIATION_RESOURCE_NAME,
  MISSION_RESOURCE_NAME,
} from '../../constants'

export const contactXlsExportFieldOptions: XlsExportFieldOption[] = [
  { name: 'civility' },
  { name: 'firstname' },
  { name: 'lastname' },
  { name: 'title' },
  { name: 'email' },
  { name: 'phone' },
  { name: 'hidden' },
  { name: 'primary' },
  { name: 'association' }, // Name of the association
  { name: 'id' },
  { name: 'createdAt' },
  { name: 'updatedAt' },
  { name: 'domain.id', hidden: true },
  { name: 'domain', hidden: true },
]

const fetchAllAssociationsInDomain = async (domainId: string, dataProvider: DataProvider) => {
  const { data: associations } = (await dataProvider.getList(ASSOCIATION_RESOURCE_NAME, {
    sort: { field: 'id', order: 'ASC' },
    filter: { domain: domainId },
    pagination: { page: 1, perPage: 1000000 },
  })) as { data: Association[] }
  return associations
}

const fetchAllMissionsInDomain = async (domainId: string, dataProvider: DataProvider) => {
  const { data: missions } = (await dataProvider.getList(MISSION_RESOURCE_NAME, {
    sort: { field: 'id', order: 'ASC' },
    filter: { domain: domainId },
    pagination: { page: 1, perPage: 1000000 },
  })) as { data: Mission[] }
  return missions
}

const fetchAllActivitiesInDomain = async (domainId: string, dataProvider: DataProvider) => {
  const { data: activities } = (await dataProvider.getList(ACTIVITY_RESOURCE_NAME, {
    sort: { field: 'id', order: 'ASC' },
    filter: { domain: domainId },
    pagination: { page: 1, perPage: 1000000 },
  })) as { data: Mission[] }
  return activities
}

const fetchAssociationNamePerContact = async (contacts: any[], dataProvider: DataProvider) => {
  if (contacts.length === 0) {
    return {}
  }
  // Assumption: all contacts are from the same domain.
  // Assumption: each contact only belongs to one and only one association.
  const domainId = contacts[0].domain?.id
  const allAssociations = await fetchAllAssociationsInDomain(domainId, dataProvider)
  const allAssociationsNamePerId = zipObject(
    allAssociations.map((asso) => asso.id),
    allAssociations.map((asso) => asso.name),
  )
  // Contacts can belong either to an activity, to a mission, or directly to an activity.
  // In all 3 cases, we need to figure out the association's name.
  const assoNamePerContact: Record<string, string> = {}
  for (const activity of await fetchAllActivitiesInDomain(domainId, dataProvider)) {
    const assoName = allAssociationsNamePerId[activity.association.id]
    for (const contact of activity.contacts || []) {
      assoNamePerContact[contact.id] = assoName
    }
  }
  for (const mission of await fetchAllMissionsInDomain(domainId, dataProvider)) {
    const assoName = allAssociationsNamePerId[mission.association.id]
    for (const contact of mission.contacts || []) {
      assoNamePerContact[contact.id] = assoName
    }
  }
  for (const asso of allAssociations) {
    for (const contact of asso.contacts || []) {
      assoNamePerContact[contact.id] = asso.name
    }
  }
  return assoNamePerContact
}

export const contactXlsExportTransform = async (
  contacts: any[],
  dataProvider: DataProvider,
): Promise<any[]> => {
  const associationNamePerContact = await fetchAssociationNamePerContact(contacts, dataProvider)
  return contacts.map((contact) => ({
    ...contact,
    association: associationNamePerContact[contact.id],
  }))
}

export const XlsContactExportButton = () => {
  return (
    <XlsExportButton
      fieldOptions={contactXlsExportFieldOptions}
      transform={contactXlsExportTransform}
    />
  )
}
