import { makeStyles } from '@material-ui/core'
import { get, isArray } from 'lodash'
import React from 'react'
import {
  ReferenceArrayInput,
  ReferenceArrayInputProps,
  SelectArrayInput,
  usePermissions,
  useRecordContext,
  useTranslate,
} from 'react-admin'
import { Permission } from '../../auth-provider'
import { TAG_RESOURCE_NAME } from '../constants'
import { maxTags } from '../models/activity/activity'
import { TagType } from '../models/tag'
import { useTagFilterToQuery } from './tag-reference-input'

interface TagReferenceArrayInputProps
  extends Omit<ReferenceArrayInputProps, 'children' | 'reference'> {
  source: string
  maxTagCount: number
  label?: string
  helperText?: string
  tagTypes?: TagType | TagType[]
  includeHidden?: boolean
}

const useStyles = makeStyles({
  root: {
    minWidth: '15em',
  },
})

export const TagReferenceArrayInput = ({
  source,
  label,
  maxTagCount,
  tagTypes,
  includeHidden,
  helperText,
  ...props
}: TagReferenceArrayInputProps) => {
  const classes = useStyles()
  const { permissions } = usePermissions() as { permissions: Permission }
  const translate = useTranslate()
  const filterToQuery = useTagFilterToQuery(tagTypes, includeHidden)
  const record = useRecordContext()
  if (record) {
    const value = get(record, source)
    if (value && !isArray(value)) {
      // eslint-disable-next-line no-console
      console.error(
        `record.${source} is supposed to be an array of ids, but found ${JSON.stringify(value)}}`,
      )
      return null
    }
  }
  return (
    <ReferenceArrayInput
      {...props}
      classes={classes}
      variant="outlined"
      label={label && translate(label)}
      helperText={helperText}
      source={source}
      reference={TAG_RESOURCE_NAME}
      filterToQuery={filterToQuery}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={10000}
      validate={[
        maxTags(permissions, maxTagCount, `${maxTagCount} ${translate('texts.maximumTags')}`),
      ]}
    >
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
  )
}
