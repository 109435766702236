import React from 'react'
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
} from 'react-admin'
import { DefaultShowButton } from '../../common/default-buttons'
import Pagination from '../../common/pagination'
import { TagReferenceArrayField } from '../../common/tag-reference-array-field'

const DomainSearchIntentListFilter = (props: any) => (
  <Filter {...props}>
    <ReferenceField label="Domain" source="domain.id" reference="Domain">
      <TextField source="domain_name" />
    </ReferenceField>
  </Filter>
)

export const DomainSearchIntentList = (props: any) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<DomainSearchIntentListFilter />}
    pagination={<Pagination />}
  >
    <Datagrid>
      <ReferenceField label="Domain" source="domain.id" reference="Domain">
        <TextField source="domain_name" />
      </ReferenceField>
      <ReferenceField
        label="Domain Highlight"
        source="domain_hightlight.id"
        reference="DomainSearchIntent"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="q" />
      <TagReferenceArrayField source="tags" isLink={true} />
      <ArrayField source="age_sub_groups">
        <SingleFieldList linkType={false}>
          <ReferenceField source="id" reference="AgeSubGroup">
            <ChipField source="name" />
          </ReferenceField>
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="schedule_groups">
        <SingleFieldList linkType={false}>
          <ReferenceField source="id" reference="ScheduleGroup">
            <ChipField source="name" />
          </ReferenceField>
        </SingleFieldList>
      </ArrayField>
      <TextField source="days" />
      <DateField source="createdAt" />
      <DefaultShowButton />
    </Datagrid>
  </List>
)

export const DomainSearchIntentShow = (props: any) => (
  <Show title="DomainSearchIntent show" {...props}>
    <SimpleShowLayout>
      <ReferenceField label="Domain" source="domain.id" reference="Domain">
        <TextField source="domain_name" />
      </ReferenceField>
      <ReferenceField
        label="Domain Highlight"
        source="domain_hightlight.id"
        reference="DomainSearchIntent"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="q" />
      <TagReferenceArrayField source="tags" isLink={true} />
      <ArrayField source="age_sub_groups">
        <SingleFieldList>
          <ReferenceField source="id" reference="AgeSubGroup">
            <ChipField source="name" />
          </ReferenceField>
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="schedule_groups">
        <SingleFieldList>
          <ReferenceField source="id" reference="ScheduleGroup">
            <ChipField source="name" />
          </ReferenceField>
        </SingleFieldList>
      </ArrayField>
      <TextField source="days" />
      <DateField source="createdAt" />
    </SimpleShowLayout>
  </Show>
)
