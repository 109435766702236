import { makeStyles } from '@material-ui/core'
import React, { useMemo } from 'react'
import { ImageField, SortPayload, TextField, TextInput } from 'react-admin'
import { EntityReferenceListInput } from '../../../common/entity-reference-list-input'
import { TagReferenceField } from '../../../common/tag-reference-field'
import { TagReferenceInput } from '../../../common/tag-reference-input'
import { ASSOCIATION_RESOURCE_NAME } from '../../../constants'
import { useDomain } from '../../../contexts/domain-context'
import { TagType } from '../../tag'
import { SelectionEntityListFilter } from './selection-entity-list-filter'

const useStyles = makeStyles({
  thumbnail: {
    '& img': {
      maxHeight: '20px',
      margin: 0,
    },
  },
})

const associationListSort: SortPayload = { field: 'name', order: 'ASC' }

export const DomainHighlightSelectionByAssociationsBuilder = () => {
  const classes = useStyles()
  const domain = useDomain()
  const domainId = domain?.id

  const filter = useMemo(
    () => ({
      domain: domainId,
    }),
    [domainId],
  )

  if (!domainId) {
    return null
  }

  return (
    <EntityReferenceListInput
      source="associations"
      referenceResource={ASSOCIATION_RESOURCE_NAME}
      referenceFilter={filter}
      referenceSort={associationListSort}
      translationPrefix="resources.DomainHighlight.AssociationListInput"
      filters={<AssociationListFilters />}
    >
      <ImageField className={classes.thumbnail} source="logo_url" title="logo" />
      <TextField source="name" />
      <TagReferenceField source="main_tag" />
    </EntityReferenceListInput>
  )
}

const tagTypes: TagType[] = [TagType.Main, TagType.Normal]

const AssociationListFilters = (props: unknown) => {
  return (
    <SelectionEntityListFilter {...props}>
      <TextInput source="q" alwaysOn />
      <TagReferenceInput
        source="main_tag"
        alwaysOn
        label="EntityReferenceListInput.tagFilterPlaceholder"
        tagTypes={tagTypes}
      />
    </SelectionEntityListFilter>
  )
}
