import React from 'react'
import {
  BooleanField,
  Datagrid,
  DateField,
  Filter,
  ImageField,
  List,
  SelectField,
  TextField,
  TextInput,
  UrlField,
  usePermissions,
} from 'react-admin'
import { Permission } from '../../../auth-provider'
import { useModelStyles } from '../../../helpers/model-styles'
import EmptyPage from '../../common/empty-page'
import ListToolbar from '../../common/list-toolbar'
import Pagination from '../../common/pagination'
import { ShowDomainButton } from '../../common/show-domain-button'
import { useIsAtLeastDomainRole } from '../../hook/use-is-at-least-domain-role'
import { DomainType } from './domain'

const DomainListFilter = (props: unknown) => {
  const classes = useModelStyles()
  return (
    <Filter {...props} classes={{ form: classes.filter }} variant="outlined">
      <TextInput source="q" alwaysOn />
    </Filter>
  )
}

export const DomainList = (props: unknown) => {
  const classes = useModelStyles()
  const { permissions } = usePermissions() as { permissions?: Permission }

  if (!useIsAtLeastDomainRole()) {
    return null
  }

  const actions = <ListToolbar disableLink={true} />

  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<DomainListFilter />}
      pagination={<Pagination />}
      filter={permissions && permissions.domains ? { ids: permissions.domains } : undefined}
      empty={<EmptyPage actions={actions} />}
      classes={{ content: classes.content }}
      actions={actions}
    >
      <Datagrid className={classes.list}>
        <ImageField className={classes.thumbnail} source="logo_url" title="logo" />
        <TextField source="domain_name" />
        <TextField source="title" />
        <SelectField source="type" choices={DomainType} />
        <UrlField source="outlink_url" />
        <BooleanField source="published" />
        <DateField source="createdAt" />
        <ShowDomainButton />
      </Datagrid>
    </List>
  )
}
