import { isEmpty, isPlainObject } from 'lodash'
import { DomainHighlight } from '../../../entity-types'
import { composeFunctions } from '../../../helpers/compose-functions'
import { idEditTransform } from '../../../helpers/id-edit-transform'
import { nullifyEmptyStringFields } from '../../../helpers/nullify-empty-string-fields'
import { nullifyUndefinedFields } from '../../../helpers/nullify-undefined-fields'
import { DomainHighlightSelectionType } from './form-components/selection-builder'

// This boolean field tells us whether we need to use a description field.
// If false, then we strip the 'description_text' at save time.

export const USE_DESCRIPTION_DUMMY_FIELD = '_useDescription'

const handleOptionalDescription = (data: any) => {
  if (!isPlainObject(data)) {
    return data
  }
  const shouldUseDescription = data[USE_DESCRIPTION_DUMMY_FIELD]
  const res = { ...data } as DomainHighlight
  if (shouldUseDescription === false) {
    ;(res as any).description_text = null
  }
  if (shouldUseDescription === true && (res.description_text || '').trim() === '') {
    ;(res as any).description_text = null
  }
  return res
}

// This field tells us which selection type is used.
// Fields used for other types will be deleted at save time.
// Currently we use the following types:
//   - 'criteria' using fields: age_sub_groups schedule_groups days tags
//   - 'associations' using fields: associations
export const DOMAIN_HIGHLIGHT_SELECTION_TYPE_FIELD = '_selectionType'

const handleSelectionType = (data: any) => {
  if (!isPlainObject(data)) {
    return data
  }
  const res = { ...data }
  const selectionType = data[DOMAIN_HIGHLIGHT_SELECTION_TYPE_FIELD] as
    | DomainHighlightSelectionType
    | undefined
  switch (selectionType) {
    case 'criteria':
      res.associations = null
      res.activities = null
      res.missions = null
      return res
    case 'associations':
      res.activities = null
      res.missions = null
      res.age_sub_groups = null
      res.schedule_groups = null
      res.days = null
      res.tags = null
      return res
    case 'activities':
      res.associations = null
      res.missions = null
      res.age_sub_groups = null
      res.schedule_groups = null
      res.days = null
      res.tags = null
      return res
    case 'missions':
      res.associations = null
      res.activities = null
      res.age_sub_groups = null
      res.schedule_groups = null
      res.days = null
      res.tags = null
      return res
    default:
      res.associations = null
      res.activities = null
      res.missions = null
      res.age_sub_groups = null
      res.schedule_groups = null
      res.days = null
      res.tags = null
      return res
  }
}

export const domainHighlightGuessSelectionTypeFromRecord = (
  record: DomainHighlight | undefined,
): DomainHighlightSelectionType | undefined => {
  if (!record) {
    return undefined
  }
  if (!isEmpty(record.associations)) {
    return 'associations'
  }
  if (!isEmpty(record.activities)) {
    return 'activities'
  }
  if (!isEmpty(record.missions)) {
    return 'missions'
  }
  if (
    !isEmpty(record.age_sub_groups) ||
    !isEmpty(record.schedule_groups) ||
    !isEmpty(record.days) ||
    !isEmpty(record.tags)
  ) {
    return 'criteria'
  }
  return undefined
}

export const domainHighlightDocEditTransform = composeFunctions(
  idEditTransform(['domain']),
  nullifyUndefinedFields,
  nullifyEmptyStringFields,
  handleOptionalDescription,
  handleSelectionType,
)

export const domainHighlightDocCreateTransform = composeFunctions(
  nullifyUndefinedFields,
  nullifyEmptyStringFields,
  handleOptionalDescription,
  handleSelectionType,
)
