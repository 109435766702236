import { useAssociation } from '../contexts/association-context'
import { useThisDomainUrls } from './use-this-domain-urls'

/**
 * Get an url creator associated to the association provided by the AssociationContext.
 * Useful to express urls relative to the current association.
 */

export const useThisAssociationUrls = () => {
  const thisDomainUrls = useThisDomainUrls()
  const association = useAssociation()
  return association ? thisDomainUrls?.association(association.id) : undefined
}
