/* eslint-disable @typescript-eslint/ban-types */
/**
 * Returns the same object if the values inside haven't changed.
 * This can prevent unecessary rendering.
 * N: The object is deep-compared.
 */

import { isEqual } from 'lodash'
import { useRef } from 'react'

export const useSameObjectIfEqual = <T extends object>(obj: T): T => {
  const objRef = useRef(obj)
  if (!isEqual(obj, objRef.current)) {
    objRef.current = obj
  }
  return objRef.current
}
