interface AddressRecord {
  latitude: number
  longitude: number
  fullAddress: string
  street: string
  zipcode: string
  city: string
  country: string
}

export class AddressNotFoundError extends Error {}
export class AddressParsingError extends Error {}

export const googleMapsPlaceToAddressRecord = (
  address: google.maps.places.PlaceResult,
): AddressRecord => {
  const { address_components, formatted_address, name, geometry } = address

  if (!formatted_address || !address_components) {
    throw new AddressNotFoundError()
  }

  const fullAddress = formatted_address.includes(name)
    ? formatted_address
    : `${name}, ${formatted_address}`

  let latitude: number | undefined
  let longitude: number | undefined
  let country: string | undefined
  let city: string | undefined
  let zipcode: string | undefined
  let sublocality: string | undefined
  let street: string | undefined
  let streetNumber: string | undefined

  for (const addr of address_components) {
    if (addr.types.includes('country')) {
      country = addr.long_name
    }
    if (addr.types.includes('postal_code')) {
      zipcode = addr.long_name
    }
    if (addr.types.includes('locality')) {
      city = addr.long_name
    }
    if (addr.types.includes('street_number')) {
      streetNumber = addr.long_name
    }
    if (addr.types.includes('route')) {
      street = addr.long_name
    }
    if (addr.types.includes('sublocality')) {
      sublocality = addr.long_name
    }
  }
  if (street === undefined && sublocality !== '') {
    street = sublocality
  }
  if (streetNumber) {
    street = `${streetNumber} ${street}`
  }
  if (geometry && geometry.location) {
    latitude = geometry.location.lat()
    longitude = geometry.location.lng()
  }

  if (latitude === undefined || longitude === undefined) {
    throw new AddressParsingError('Gps data is missing')
  }
  if (country === undefined) {
    throw new AddressParsingError('Country is missing')
  }
  if (city === undefined) {
    throw new AddressParsingError('City is missing')
  }
  if (zipcode === undefined) {
    throw new AddressParsingError('Zipcode is missing')
  }
  if (street === undefined) {
    throw new AddressParsingError('Street is missing')
  }

  return {
    latitude,
    longitude,
    fullAddress,
    country,
    city,
    zipcode,
    street,
  }
}
