import React from 'react'
import {
  ActivityIcon,
  AddressIcon,
  AssociationIcon,
  ContactIcon,
  MissionIcon,
} from '../common/icons'
import { useActivity } from '../contexts/activity-context'
import { useAssociation } from '../contexts/association-context'
import { useMission } from '../contexts/mission-context'
import { useFirstDefinedValue } from '../hook/use-first-defined-value'
import { useLatestDefinedValue } from '../hook/use-latest-defined-value'
import { useThisDomainUrls } from '../hook/use-this-domain-urls'
import { useAreDomainMissionsEnabled } from '../hook/useAreDomainMissionsEnabled'
import { ActivityMenu } from './activity-menu'
import { LinkMenuItem } from './menu-item'
import { MissionMenu } from './mission-menu'

export const AssociationMenu = () => {
  const thisDomainUrls = useThisDomainUrls()
  const association = useAssociation()
  const areDomainMissionsEnabled = useAreDomainMissionsEnabled()

  const activityFromUrl = useActivity()
  const missionFromUrl = useMission()
  const definedSubEntity = useFirstDefinedValue(
    activityFromUrl ? { type: 'activity', doc: activityFromUrl } : undefined,
    missionFromUrl ? { type: 'mission', doc: missionFromUrl } : undefined,
  )
  const latestDefinedSubEntity = useLatestDefinedValue(definedSubEntity)

  const latestDefinedSubEntityType = latestDefinedSubEntity?.type
  const latestDefinedSubEntityDoc = latestDefinedSubEntity?.doc

  if (!association || !thisDomainUrls) {
    return null
  }
  const thisAssociationUrls = thisDomainUrls.association(association.id)
  return (
    <>
      <LinkMenuItem
        to={thisAssociationUrls.show}
        primaryTextKey="menu.associationMenu.MyAssociation"
        leftIcon={<AssociationIcon />}
      />
      <LinkMenuItem
        to={thisAssociationUrls.contact.list}
        exact={false}
        primaryTextKey="menu.associationMenu.Contact"
        leftIcon={<ContactIcon />}
      />
      <LinkMenuItem
        to={thisAssociationUrls.address.list}
        primaryTextKey="menu.associationMenu.Address"
        exact={false}
        leftIcon={<AddressIcon />}
      />
      <LinkMenuItem
        to={thisAssociationUrls.activity.list}
        primaryTextKey="menu.associationMenu.Activity"
        leftIcon={<ActivityIcon />}
      />
      {latestDefinedSubEntityType === 'activity' && (
        <ActivityMenu activity={latestDefinedSubEntityDoc} />
      )}
      {areDomainMissionsEnabled && (
        <LinkMenuItem
          to={thisAssociationUrls.mission.list}
          primaryTextKey="menu.associationMenu.Mission"
          leftIcon={<MissionIcon />}
        />
      )}
      {latestDefinedSubEntityType === 'mission' && (
        <MissionMenu mission={latestDefinedSubEntityDoc} />
      )}
    </>
  )
}
