import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import Typography from '@material-ui/core/Typography'
import React, { ComponentType, FC } from 'react'
import CardIcon from './card-icon'

interface Props {
  Icon: ComponentType<SvgIconProps>
  bgColor: string
  name: string
  count: number | undefined
}

const useStyles = makeStyles({
  main: {
    flex: '1',
    marginRight: '1em',
    marginTop: 20,
    'max-width': '320px',
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: 16,
    minHeight: 52,
  },
  title: {},
})

const CountCard: FC<Props> = ({ Icon, bgColor, name, count }: Props) => {
  const classes = useStyles()
  return (
    <div className={classes.main}>
      <CardIcon Icon={Icon} bgColor={bgColor} />
      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary">
          {name}
        </Typography>
        <Typography variant="h5" component="h2">
          {count}
        </Typography>
      </Card>
    </div>
  )
}

export default CountCard
