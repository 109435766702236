import { Divider, Typography } from '@material-ui/core'
import RichTextInput from 'ra-input-rich-text'
import React, { useEffect } from 'react'
import { Edit, SaveButton, SimpleForm, useInput, useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { useModelStyles } from '../../../helpers/model-styles'
import { NonInput } from '../../common/non-input'
import { StatusToggleInput } from '../../common/status-toggle-input'
import { TopToolbar, useToolbarStyles } from '../../common/toolbar'
import { DOMAIN_RESOURCE_NAME } from '../../constants'
import { useDomain } from '../../contexts/domain-context'
import { useThisDomainUrls } from '../../hook/use-this-domain-urls'

export const MissionSettings = ({ basePath }: { basePath: string }) => {
  const domain = useDomain()
  const translate = useTranslate()
  const modelClasses = useModelStyles()

  if (!domain) {
    return null
  }

  return (
    <Edit
      basePath={basePath}
      resource={DOMAIN_RESOURCE_NAME}
      id={domain.id}
      classes={{ card: modelClasses.card }}
      actions={false}
      mutationMode="pessimistic"
    >
      <SimpleForm toolbar={<Toolbar />} className={modelClasses.edit}>
        <MissionEnabledInput />
        <MissionShowOnFrontendInput />
        <NonInput>
          <Divider />
          <Typography component="h2">
            {translate('resources.Domain.MissionSettings.mission_description')}
          </Typography>
        </NonInput>
        <RichTextInput
          source="mission_description"
          variant="outlined"
          toolbar={[['bold', 'italic', 'underline', 'link']]}
        />
      </SimpleForm>
    </Edit>
  )
}

const MissionEnabledInput = () => {
  const source = 'enable_mission'
  const {
    input: { value },
  } = useInput({ source })
  return (
    <StatusToggleInput
      source={source}
      reverse={false}
      labelIfTrue="resources.Domain.MissionSettings.enableToggle.enabledMessage"
      labelIfFalse="resources.Domain.MissionSettings.enableToggle.disabledMessage"
      helperText={
        value
          ? 'resources.Domain.MissionSettings.enableToggle.enabledPlaceholder'
          : 'resources.Domain.MissionSettings.enableToggle.disabledPlaceholder'
      }
    />
  )
}

const MissionShowOnFrontendInput = () => {
  const source = 'mission_visible_on_frontend'
  const {
    values: { enable_mission: areMissionsEnabled },
  } = useFormState()
  const {
    input: { onChange },
  } = useInput({ source })
  useEffect(() => {
    if (!areMissionsEnabled) {
      onChange(false)
    }
  }, [areMissionsEnabled, onChange])
  return (
    <StatusToggleInput
      disabled={!areMissionsEnabled}
      source={source}
      reverse={false}
      labelIfTrue="resources.Domain.MissionSettings.enableToggle.frontendShowMessage"
      labelIfFalse="resources.Domain.MissionSettings.enableToggle.frontendHideMessage"
    />
  )
}

const Toolbar = ({ handleSubmitWithRedirect }: { handleSubmitWithRedirect?: any }) => {
  const classes = useToolbarStyles()
  const domainUrls = useThisDomainUrls()!

  return (
    <TopToolbar layoutType="action">
      <SaveButton
        className={classes.button}
        variant="contained"
        icon={<></>}
        handleSubmitWithRedirect={handleSubmitWithRedirect}
        redirect={domainUrls.missionSettings}
      />
    </TopToolbar>
  )
}
