import { toLower } from 'lodash'
import slug from 'slugify'
import { config } from '../config'
import { Activity, Association, Domain, Mission } from '../entity-types'

interface Param {
  domain?: Domain | null | undefined
  association?: Association | null | undefined
  activity?: Activity | null | undefined
  mission?: Mission | null | undefined
}

export const slugify = (value: string) =>
  slug(value, {
    lower: true,
    strict: true,
    // Prevent symbols to create an extra word.
    // e.g. we want to avoid "Tennis pour tous !" => "tennis-pour-tous-".
    remove: /[!?]/g,
  })

export const getLinkFront = ({ domain, association, activity, mission }: Param) => {
  if (!domain) {
    return ''
  }
  const baseUri = `${config.frontProtocol}${domain.domain_name}${config.frontUriSuffix}`

  if (activity) {
    const activityPath = activity
      ? `/activite/${activity.id}/${slugify(toLower(activity.title))}?dynamic=true`
      : ''
    return `${baseUri}${activityPath}`
  }
  if (mission) {
    const missionPath = mission
      ? `/mission/${mission.id}/${slugify(toLower(mission.title))}?dynamic=true`
      : ''
    return `${baseUri}${missionPath}`
  }
  if (association) {
    const associationPath = association
      ? `/association/${association.id}/${slugify(toLower(association.name))}?dynamic=true`
      : ''
    return `${baseUri}${associationPath}`
  }
  return `${baseUri}?dynamic=true`
}
