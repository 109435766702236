import { makeStyles } from '@material-ui/core'
import { get, isObject, isString, keyBy } from 'lodash'
import React, { useMemo } from 'react'
import { useGetMany, useRecordContext, useTranslate } from 'react-admin'
import {
  Address,
  AddressId,
  Contact,
  ContactId,
  DbRecord,
  DbRecordId,
  Tag,
  TagId,
} from '../../../../entity-types'
import { ADDRESS_RESOURCE_NAME, CONTACT_RESOURCE_NAME, TAG_RESOURCE_NAME } from '../../../constants'
import { useHistoryTargetResource } from './entity-name-field'
import { Diff, useDiffs } from './use-diffs'

const useStyles = makeStyles({
  diffTr: {
    '& td': {
      padding: '0.2rem 0.5rem',
    },
  },
  diffField: {
    background: '#007b8c',
    color: 'white',
    minWidth: '4rem',
  },
  diffBefore: {
    background: '#eeeeee',
  },
  arrow: {
    fontWeight: 'bold',
    fontSize: '1em',
  },
  diffAfter: {
    background: '#e0e0e0',
  },
  message: {
    background: '#eeeeee',
  },
})

export const DiffField = (props: any) => {
  const resource = useHistoryTargetResource()
  const record = useRecordContext(props)
  const before = get(record, 'before')
  const after = get(record, 'after')

  const { diffs, loaded } = useDiffs(resource, before, after)

  if (!loaded) {
    return null
  }

  return <DiffShow diffs={diffs} parentResource={resource} />
}

const DiffShow = ({ diffs, parentResource }: { diffs: Diff[]; parentResource: string }) => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <table>
      <tbody>
        {diffs.length === 0 && (
          <tr>
            <td>-</td>
          </tr>
        )}
        {diffs.map((diff) => (
          <tr key={diff.field} className={classes.diffTr}>
            <td className={classes.diffField}>
              {translate(`resources.${parentResource}.fields.${diff.field}`)}
            </td>
            <td></td>
            {diff.type === 'diff' && <DiffShowDiffType before={diff.before} after={diff.after} />}
            {diff.type === 'id-list-update' && (
              <DiffShowIdListUpdateType
                resource={diff.resource}
                added={diff.added}
                removed={diff.removed}
              />
            )}
            {diff.type === 'id-update' && (
              <DiffShowIdUpdateType
                resource={diff.resource}
                before={diff.before}
                after={diff.after}
              />
            )}
            {diff.type === 'boolean-flip' && <DiffShowBooleanFlipType newValue={diff.newValue} />}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const DiffShowDiffType = ({ before, after }: { before?: string; after?: string }) => {
  const classes = useStyles()
  return (
    <>
      <td className={classes.diffBefore}>{before}</td>
      <td className={classes.arrow}>⇒</td>
      <td className={classes.diffAfter}>{after}</td>
    </>
  )
}

const fallbackNameGetter = (record: DbRecord, resource: string) => {
  // eslint-disable-next-line no-console
  console.warn(`No NameGetter defined for resource "${resource}", so we only show the id`)
  return isObject(record) ? record.id : `Entité (${resource}) introuvable ${record}`
}

export const nameGetterPerResource: Record<string, (record: any, resource: string) => string> = {
  [ADDRESS_RESOURCE_NAME]: (record: Address | AddressId) =>
    isObject(record) ? record.full_address : `Adresse introuvable ${record}`,
  [TAG_RESOURCE_NAME]: (record: Tag | TagId) =>
    isObject(record) ? record.name : `Tag introuvable ${record}`,
  [CONTACT_RESOURCE_NAME]: (record: Contact | ContactId) =>
    isObject(record)
      ? `${record.firstname || ''} ${record.lastname || ''}`
      : `Contact introuvable ${record}`,
}

const DiffShowIdListUpdateType = ({
  resource,
  added,
  removed,
}: {
  resource: string
  added: string[]
  removed: string[]
}) => {
  const classes = useStyles()
  const allIds = useMemo(() => [...added, ...removed], [added, removed])

  const { data, loaded } = useGetMany(resource, allIds)

  const [addedEntities, removedEntities] = useMemo(() => {
    if (!loaded) {
      return [[], []]
    }
    const entitiesByIds = keyBy(data, (entity) => entity?.id)
    return [
      added.map((id) => (entitiesByIds[id] || id) as DbRecord | DbRecordId),
      removed.map((id) => (entitiesByIds[id] || id) as DbRecord | DbRecordId),
    ]
  }, [added, data, loaded, removed])

  if (!loaded) {
    return <td colSpan={3}></td>
  }

  const nameGetter = nameGetterPerResource[resource] || fallbackNameGetter

  return (
    <>
      <td colSpan={3} className={classes.message}>
        {addedEntities.map((entity: DbRecord | DbRecordId) => (
          <div key={isString(entity) ? entity : entity.id}>
            <span role="img" aria-label="plus">
              ➕
            </span>{' '}
            {nameGetter(entity, resource)}
          </div>
        ))}
        {removedEntities.map((entity: DbRecord | DbRecordId) => (
          <div key={isString(entity) ? entity : entity.id}>
            <span role="img" aria-label="minus">
              ➖
            </span>{' '}
            {nameGetter(entity, resource)}
          </div>
        ))}
      </td>
    </>
  )
}

const DiffShowIdUpdateType = ({
  resource,
  before,
  after,
}: {
  resource: string
  before: DbRecordId | undefined
  after: DbRecordId | undefined
}) => {
  const classes = useStyles()
  const allIds = useMemo(() => [before, after].filter(Boolean) as DbRecordId[], [before, after])

  const { data, loaded } = useGetMany(resource, allIds)

  const [beforeEntity, afterEntity] = useMemo(() => {
    if (!loaded) {
      return []
    }
    const entitiesByIds = keyBy(data, (entity) => entity?.id)
    return [before && entitiesByIds[before], after && entitiesByIds[after]]
  }, [before, data, loaded, after])

  if (!loaded) {
    return <td colSpan={3}></td>
  }

  const nameGetter = nameGetterPerResource[resource] || fallbackNameGetter
  return (
    <>
      <td className={classes.diffBefore}>
        {beforeEntity ? nameGetter(beforeEntity, resource) : ''}
      </td>
      <td className={classes.arrow}>⇒</td>
      <td className={classes.diffAfter}>{afterEntity ? nameGetter(afterEntity, resource) : ''}</td>
    </>
  )
}

const DiffShowBooleanFlipType = ({ newValue }: { newValue: boolean | undefined }) => {
  const translate = useTranslate()
  return (
    <td colSpan={3}>
      {newValue === true && translate('resources.History.customDiff.booleanFlip.true')}
      {newValue === false && translate('resources.History.customDiff.booleanFlip.false')}
      {newValue === undefined && translate('resources.History.customDiff.booleanFlip.undefined')}
    </td>
  )
}
