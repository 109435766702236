import { makeStyles } from '@material-ui/core'
import { BoxedShowLayout, RaBox } from 'ra-compact-ui'
import React, { useMemo } from 'react'
import { BooleanInput, Create, SimpleForm, TextInput } from 'react-admin'
import { CONTACT_RESOURCE_NAME } from '../../constants'
import { useDomain } from '../../contexts/domain-context'

export interface CreateEntityFormProps {
  // BasePath of the referenced entities. Pass it to the <Create> element.
  basePath: string
  // Should be called when your component has just created an entity.
  onCreated?: (entity: any) => void
}

export const useContactCreateFormStyles = makeStyles({
  raBoxRow: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'flex-start',
    justifyItems: 'flex-start',
    justifyContent: 'flex-start',
    '& > * + *': {
      marginLeft: '1rem',
    },
  },
})

export const ContactCreate = ({ basePath, onCreated }: CreateEntityFormProps) => {
  const classes = useContactCreateFormStyles()
  const domain = useDomain()
  const domainId = domain?.id
  const initialValues = useMemo(
    () => ({
      domain: domainId,
    }),
    [domainId],
  )
  if (!domainId) {
    return null
  }
  return (
    <Create basePath={basePath} resource={CONTACT_RESOURCE_NAME} onSuccess={onCreated}>
      <SimpleForm initialValues={initialValues}>
        <BoxedShowLayout>
          <RaBox className={classes.raBoxRow}>
            <RaBox>
              <TextInput
                variant="outlined"
                source="civility"
                helperText="resources.Contact.helperTexts.civility"
              />
            </RaBox>
            <RaBox>
              <TextInput
                variant="outlined"
                source="title"
                helperText="resources.Contact.helperTexts.title"
              />
            </RaBox>
          </RaBox>
          <RaBox className={classes.raBoxRow}>
            <RaBox>
              <TextInput
                variant="outlined"
                source="lastname"
                helperText="resources.Contact.helperTexts.lastname"
              />
            </RaBox>
            <RaBox>
              <TextInput
                variant="outlined"
                source="firstname"
                helperText="resources.Contact.helperTexts.firstname"
              />
            </RaBox>
          </RaBox>
          <RaBox className={classes.raBoxRow}>
            <RaBox>
              <TextInput
                variant="outlined"
                source="phone"
                helperText="resources.Contact.helperTexts.phone"
              />
            </RaBox>
            <RaBox>
              <TextInput
                variant="outlined"
                source="email"
                type="email"
                helperText="resources.Contact.helperTexts.email"
              />
            </RaBox>
          </RaBox>
          <BooleanInput source="hidden" />
        </BoxedShowLayout>
      </SimpleForm>
    </Create>
  )
}
