import React, { ReactElement, useMemo } from 'react'
import { DbRecord } from '../../../entity-types'

/**
 * Use this to transform the record between a <Edit> and a <Form>. e.g.
 *
 *  <Edit>
 *    <RecordMiddleware<Domain> transform={stripImageUploadFields}>
 *      <SimpleForm>
 *      {...}
 *      </SimpleForm>
 *    </RecordMiddleware>
 *  </Edit>
 *
 * The <SimpleForm will receive the transformed record.
 *
 */

export const RecordMiddleware = <R extends DbRecord>({
  record,
  transform,
  children,
  ...props
}: { record?: R; transform?: (r: R) => R; children: ReactElement } & unknown) => {
  const newRecord = useMemo(() => (record && transform && transform(record)) || record, [
    record,
    transform,
  ])
  return React.cloneElement(children, {
    record: newRecord,
    ...props,
  })
}
