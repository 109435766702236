import { chain, difference, isObject, keyBy, keys, merge, uniq, zipObject } from 'lodash'
import React, { useCallback } from 'react'
import {
  Button,
  DataProvider,
  ExportButtonProps,
  useDataProvider,
  useListContext,
  useResourceContext,
} from 'react-admin'
import { BookType, utils as XlsxUtils, WorkBook, writeFile as writeXlsxFile } from 'xlsx'
import { useToolbarStyles } from './toolbar'

export interface XlsExportFieldOption {
  name: string
  label?: string
  hidden?: boolean
  width?: number
}

interface XlsExportButtonProps extends ExportButtonProps {
  fieldOptions?: XlsExportFieldOption[]
  transform?: (data: any[], dataProvider: DataProvider) => Promise<any[]> | any[]
}

const createWorkbookFromRecords = (
  records: Record<string, unknown>[],
  resource: string,
  fieldOptions: XlsExportFieldOption[] = [{ name: 'id' }],
): WorkBook => {
  const cleanRecords = records.map((record) =>
    chain(record)
      .pickBy((val) => !isObject(val) && val !== undefined && val != null)
      .mapValues((val) => String(val))
      .value(),
  )
  const optionByField = keyBy(fieldOptions, ({ name }) => name)
  const fieldsWithOptions = fieldOptions.filter(({ hidden }) => !hidden).map(({ name }) => name)
  const hiddenFields = fieldOptions.filter(({ hidden }) => hidden).map(({ name }) => name)

  const allKeys = uniq([
    ...keys(merge({}, ...cleanRecords)),
    ...fieldOptions.map(({ name }) => name),
  ])
  const headers = [...fieldsWithOptions, ...difference(allKeys, fieldsWithOptions, hiddenFields)]

  const fieldNameToLabel = zipObject(
    allKeys,
    allKeys.map((name) => optionByField[name]?.label ?? name),
  )

  const relabelizedRecords = cleanRecords.map((record) =>
    chain(record)
      .pickBy((_, fieldName) => optionByField[fieldName]?.hidden !== true)
      .mapKeys((_, fieldName) => fieldNameToLabel[fieldName])
      .value(),
  )
  const relabelizedHeaders = headers.map((name) => fieldNameToLabel[name])
  const sheet = XlsxUtils.json_to_sheet(relabelizedRecords, { header: relabelizedHeaders })
  sheet['!cols'] = headers.map((name) => ({
    width: 25,
    ...(optionByField[name] ? optionByField[name] : {}),
  }))
  const sheetName = resource

  return {
    Sheets: { [sheetName]: sheet },
    SheetNames: [sheetName],
  }
}

export const XlsExportButton = ({
  transform,
  fieldOptions,
  disabled,
  ...props
}: XlsExportButtonProps) => {
  const { maxResults = 10000, label = 'ra.action.export' } = props
  const { filter, filterValues, currentSort, total } = useListContext(props)
  const toolbarClasses = useToolbarStyles()
  const resource = useResourceContext(props)
  const dataProvider = useDataProvider()
  const handleClick = useCallback(async () => {
    const { data } = await dataProvider.getList(resource, {
      sort: currentSort,
      filter: filter ? { ...filterValues, ...filter } : filterValues,
      pagination: { page: 1, perPage: maxResults },
    })

    const transformFun = transform ?? ((x: any[]) => x)
    const transformedRecords = await transformFun(data, dataProvider)
    const bookType: BookType = 'xlsx'
    writeXlsxFile(
      createWorkbookFromRecords(transformedRecords, resource, fieldOptions),
      `${resource}.${bookType}`,
      {
        bookType,
      },
    )
  }, [
    currentSort,
    dataProvider,
    fieldOptions,
    filter,
    filterValues,
    maxResults,
    resource,
    transform,
  ])

  return (
    <Button
      onClick={handleClick}
      className={toolbarClasses.button}
      variant="outlined"
      label={label}
      disabled={disabled || total === 0}
    />
  )
}
