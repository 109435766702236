import React from 'react'
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  Filter,
  List,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin'
import { DefaultEditButton, DefaultShowButton } from '../common/default-buttons'
import EditToolbar from '../common/edit-toolbar'
import Pagination from '../common/pagination'

const AgeGroupListFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Name" source="q" alwaysOn />
  </Filter>
)

export const AgeGroupList = (props: any) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<AgeGroupListFilter />}
    pagination={<Pagination />}
  >
    <Datagrid>
      <TextField source="name" />
      <BooleanField source="visible" />
      <DateField source="createdAt" />
      <DefaultShowButton />
      <DefaultEditButton />
    </Datagrid>
  </List>
)

export const AgeGroupCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <BooleanInput source="visible" initialValue={true} />
    </SimpleForm>
  </Create>
)

export const AgeGroupEdit = (props: any) => (
  <Edit title="AgeGroup edit" {...props} undoable={false}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="name" />
      <BooleanInput source="visible" initialValue={true} />
    </SimpleForm>
  </Edit>
)

export const AgeGroupShow = (props: any) => (
  <Show title="AgeGroup show" {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <BooleanField source="visible" />
      <DateField source="createdAt" />
    </SimpleShowLayout>
  </Show>
)
