import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { BoxedShowLayout, RaBox } from 'ra-compact-ui'
import React from 'react'
import {
  Create,
  ImageField,
  ImageInput,
  ReactAdminComponentProps,
  required,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import { useModelStyles } from '../../../helpers/model-styles'
import EditToolbar from '../../common/edit-toolbar'
import { NonInput } from '../../common/non-input'
import { StatusToggleInput } from '../../common/status-toggle-input'
import { useDomain } from '../../contexts/domain-context'
import { useIsAtLeastDomainRole } from '../../hook/use-is-at-least-domain-role'
import { useDomainHighlightFormStyles } from './domain-highlight'
import { DomainHighlightOptionalDescriptionInput } from './form-components/optional-description-input'
import { DomainHighlightSelectionBuilder } from './form-components/selection-builder'
import { domainHighlightDocCreateTransform, USE_DESCRIPTION_DUMMY_FIELD } from './transform'

export const DomainHighlightCreate = (props: ReactAdminComponentProps) => {
  const domain = useDomain()
  const translate = useTranslate()
  const classes = useDomainHighlightFormStyles()
  const modelClasses = useModelStyles()

  if (!useIsAtLeastDomainRole()) {
    return null
  }

  return (
    <Create
      {...props}
      classes={{ card: modelClasses.card }}
      transform={domainHighlightDocCreateTransform}
    >
      <SimpleForm
        redirect="list"
        toolbar={<EditToolbar disableLink />}
        className={modelClasses.edit}
        initialValues={{
          domain: domain ? domain.id : null,
          disabled: false,
          [USE_DESCRIPTION_DUMMY_FIELD]: false,
        }}
      >
        <BoxedShowLayout>
          <NonInput>
            <Typography component="h1">{translate('resources.DomainHighlight.title')}</Typography>
            <Typography component="h3">
              {translate('resources.DomainHighlight.subtitle')}
            </Typography>
            <Divider />
            <Typography component="h2">{translate('resources.DomainHighlight.tabs.1')}</Typography>
          </NonInput>
          <ImageInput source="preview_img_url" accept="image/*" validate={[required()]}>
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput
            variant="outlined"
            source="name"
            validate={[required()]}
            className={classes.titleInput}
            fullWidth
          />
          <DomainHighlightOptionalDescriptionInput />
          <NonInput>
            <Divider />
            <Typography component="h2">{translate('resources.DomainHighlight.tabs.2')}</Typography>
          </NonInput>
          <RaBox className={classes.raBoxRow}>
            <RaBox>
              <TextInput variant="outlined" source="button_title" />
            </RaBox>
            <RaBox>
              <TextInput variant="outlined" source="button_link" />
            </RaBox>
          </RaBox>
        </BoxedShowLayout>
        <NonInput>
          <Divider />
          <Typography component="h2">{translate('resources.DomainHighlight.tabs.3')}</Typography>
        </NonInput>
        <DomainHighlightSelectionBuilder />
        <NonInput>
          <Divider />
        </NonInput>
        <StatusToggleInput source="disabled" gender="feminine" />
      </SimpleForm>
    </Create>
  )
}
