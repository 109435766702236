import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { BoxedShowLayout } from 'ra-compact-ui'
import React from 'react'
import {
  DateField,
  EmailField,
  ReferenceField,
  Show,
  TextField,
  TopToolbar,
  useTranslate,
} from 'react-admin'
import { DbRecord } from '../../../entity-types'
import { useModelStyles } from '../../../helpers/model-styles'
import { NonInput } from '../../common/non-input'
import { ToolbarEditButton } from '../../common/toolbar'

interface ContactShowProps {
  resource?: string
  basePath?: string
  id?: string
  ownerType?: 'Association' | 'Activity' | 'Mission' | 'Domain'
  edit?: boolean
}

export const ContactShow = ({ resource, basePath, id, ownerType, edit }: ContactShowProps) => {
  const translate = useTranslate()
  const classes = useModelStyles()

  return (
    <Show
      basePath={basePath}
      id={id}
      resource={resource}
      title={translate('resources.Contact.name')}
      actions={<Actions edit={edit} />}
      classes={{ card: classes.card }}
    >
      <BoxedShowLayout className={classes.show}>
        <NonInput>
          <Typography component="h1">{translate(`resources.Contact.title${ownerType}`)}</Typography>
          <Divider />
        </NonInput>
        <TextField source="civility" />
        <TextField source="firstname" />
        <TextField source="lastname" />
        <TextField source="title" />
        <TextField source="phone" />
        <EmailField source="email" />
        <ReferenceField
          label={translate('resources.Contact.fields.association')}
          source="association.id"
          reference="Association"
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" />
      </BoxedShowLayout>
    </Show>
  )
}

const Actions = ({
  basePath,
  data,
  edit,
}: {
  basePath?: string
  data?: DbRecord
  edit?: boolean
}) => {
  return (
    <TopToolbar>
      {edit !== false && <ToolbarEditButton record={data} basePath={basePath} />}
    </TopToolbar>
  )
}
