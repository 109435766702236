import { makeStyles } from '@material-ui/core/styles'
import React, { useMemo } from 'react'
import {
  Datagrid,
  DateField,
  Filter,
  FilterPayload,
  List,
  NumberField,
  TextField,
  TextInput,
  usePermissions,
  useTranslate,
} from 'react-admin'
import { Permission } from '../../../auth-provider'
import { modelStyles } from '../../../helpers/model-styles'
import { DefaultDeleteButton } from '../../common/default-buttons'
import { EditActivityButton } from '../../common/edit-activity-button'
import EmptyPage from '../../common/empty-page'
import Pagination from '../../common/pagination'
import PortailLinkButton from '../../common/portail-link-button'
import { ShowActivityButton } from '../../common/show-activity-button'
import { StatusField } from '../../common/status-field'
import { TagReferenceArrayField } from '../../common/tag-reference-array-field'
import { ToolbarCreateButton, TopToolbar } from '../../common/toolbar'
import { ACTIVITY_RESOURCE_NAME } from '../../constants'
import { useAssociation } from '../../contexts/association-context'
import { useIsAtLeastAdminRole } from '../../hook/use-is-at-least-admin-role'
import { useIsAtLeastAssoRole } from '../../hook/use-is-at-least-asso-role'
import DuplicateActivityButton from './duplicate-activity-button'
import { XlsActivityExportButton } from './xls-activity-export-button'

const useStyles = makeStyles(modelStyles)

const ActivityListFilter = (props: unknown) => {
  const classes = useStyles()
  return (
    <Filter {...props} classes={{ form: classes.filter }} variant="outlined">
      <TextInput source="q" alwaysOn />
    </Filter>
  )
}

export const ActivityListFromAssociation = ({ basePath }: { basePath?: string }) => {
  const { permissions } = usePermissions() as { permissions: Permission }
  const association = useAssociation()
  const isAtLeastAdmin = useIsAtLeastAdminRole()
  const associationId = association?.id
  const filter = useMemo(
    () => ({ association: associationId, ...(isAtLeastAdmin ? {} : { disabled: false }) }),
    [associationId, isAtLeastAdmin],
  )
  if (!permissions || !associationId) {
    return null
  }
  return <ActivityList filter={filter} basePath={basePath} />
}

export const ActivityListFromPermissions = ({ basePath }: { basePath?: string }) => {
  const { permissions } = usePermissions() as { permissions: Permission }
  const activityIds = permissions?.activities
  const filter = useMemo(() => ({ ids: activityIds, disabled: false }), [activityIds])
  if (!permissions || !activityIds) {
    return null
  }
  return <ActivityList filter={filter} basePath={basePath} />
}

const ActivityList = ({ filter, basePath }: { filter: FilterPayload; basePath?: string }) => {
  const classes = useStyles()
  const { permissions } = usePermissions() as { permissions: Permission }

  const isAtLeastAssoRole = useIsAtLeastAssoRole()
  const isAtLeastAdmin = useIsAtLeastAdminRole()

  if (!permissions) {
    return null
  }

  const actions = <Actions basePath={basePath} />

  return (
    <List
      resource={ACTIVITY_RESOURCE_NAME}
      basePath={basePath}
      bulkActionButtons={false}
      filters={<ActivityListFilter />}
      pagination={<Pagination />}
      filter={filter}
      sort={{ field: 'createdAt', order: 'DESC' }}
      empty={<EmptyPage actions={actions} />}
      classes={{ content: classes.content }}
      actions={actions}
    >
      <Datagrid className={classes.list}>
        <TextField source="title" />
        <TextField source="trainer_name" />
        <NumberField source="age_min" />
        <NumberField source="age_max" />
        <TagReferenceArrayField source="tags" />
        <DateField source="updatedAt" />
        {isAtLeastAdmin && <StatusField source="disabled" labelIfFalse="" label="" />}
        {isAtLeastAssoRole && <DuplicateActivityButton />}
        <ShowActivityButton />
        <EditActivityButton />
        {isAtLeastAssoRole && <DefaultDeleteButton />}
      </Datagrid>
    </List>
  )
}

const Actions = ({ basePath }: { basePath?: string }) => {
  const translate = useTranslate()
  const canAddActivity = useIsAtLeastAssoRole()
  return (
    <TopToolbar>
      <XlsActivityExportButton />
      <PortailLinkButton />
      {canAddActivity && (
        <ToolbarCreateButton
          basePath={basePath!}
          label={translate(`resources.Activity.button.create`)}
        />
      )}
    </TopToolbar>
  )
}
