import { Activity } from '../../entity-types'
import { ACTIVITY_RESOURCE_NAME } from '../constants'
import { useGetOneById } from './use-get-one-by-id'
import { useAssociationHierarchy } from './useAssociationHierarchy'

export const useActivityHierarchy = (activityId: string | undefined) => {
  const { data: activity, loading, error: activityError } = useGetOneById<Activity>(
    ACTIVITY_RESOURCE_NAME,
    activityId,
  )
  const {
    association,
    domain,
    loading: associationLoading,
    associationError,
    domainError,
  } = useAssociationHierarchy(activity?.association.id)
  return {
    activity,
    association,
    domain,
    loading: loading && associationLoading,
    activityError,
    associationError,
    domainError,
    error: domainError || activityError || associationError,
  }
}
