import classnames from 'classnames'
import React, { ReactElement, useMemo } from 'react'
import { Datagrid, Filter, List, NumberField, TextField, TextInput } from 'react-admin'
import { useModelStyles } from '../../../helpers/model-styles'
import { ReorderPropsIds } from '../../../helpers/react-admin-hacks/ReorderPropsIds'
import {
  DefaultDeleteButton,
  DefaultEditButton,
  DefaultShowButton,
} from '../../common/default-buttons'
import EmptyPage from '../../common/empty-page'
import ListToolbar from '../../common/list-toolbar'
import Pagination from '../../common/pagination'

const AddressListFilter = (props: unknown) => {
  const classes = useModelStyles()
  return (
    <Filter {...props} classes={{ form: classes.filter }} variant="outlined">
      <TextInput source="q" alwaysOn />
    </Filter>
  )
}
interface AddressListProps {
  filters?: ReactElement
  pagination?: ReactElement | false
  emptyPage?: ReactElement | false
  actions?: ReactElement | false
  disableModify?: boolean
  disableShow?: boolean
  disableDelete?: boolean
  title?: string
  extraColumnsStart?: ReactElement[]
  extraColumnsMid?: ReactElement[]
  extraColumnsEnd?: ReactElement[]
  datagridIdSort?: (ids: string[]) => string[]
  className?: string
  [key: string]: any
}

export const AddressList = ({
  title,
  emptyPage,
  filters,
  pagination,
  actions,
  disableModify,
  disableShow,
  disableDelete,
  extraColumnsStart,
  extraColumnsMid,
  extraColumnsEnd,
  datagridIdSort,
  className,
  ...props
}: AddressListProps) => {
  const modelClasses = useModelStyles()
  const finalClasses = useMemo(() => ({ content: classnames(modelClasses.content, className) }), [
    className,
    modelClasses.content,
  ])
  const defaultActionsToolbar = <ListToolbar disableExport={true} disableLink={true} />
  return (
    <List
      {...props}
      title={title}
      bulkActionButtons={false}
      filters={filters !== undefined ? filters : <AddressListFilter />}
      pagination={pagination !== undefined ? pagination : <Pagination />}
      empty={emptyPage !== undefined ? emptyPage : <EmptyPage actions={defaultActionsToolbar} />}
      classes={finalClasses}
      actions={actions !== undefined ? actions : defaultActionsToolbar}
    >
      <ReorderPropsIds datagridIdSort={datagridIdSort}>
        <Datagrid className={modelClasses.list}>
          {extraColumnsStart}
          <TextField source="full_address" />
          <TextField source="complement" />
          <NumberField source="latitude" />
          <NumberField source="longitude" />
          {extraColumnsMid}
          {!disableShow && <DefaultShowButton />}
          {!disableModify && <DefaultEditButton />}
          {extraColumnsEnd}
          {!disableDelete && <DefaultDeleteButton />}
        </Datagrid>
      </ReorderPropsIds>
    </List>
  )
}
