import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import React, { ReactElement } from 'react'
import { Filter } from 'react-admin'
import { useModelStyles } from '../../../../helpers/model-styles'

export const SelectionEntityListFilter = ({
  children,
  ...props
}: {
  children: ReactElement[]
} & unknown) => {
  const modelClasses = useModelStyles()
  const classes = useStyles()
  return (
    <div className={classes.listFilterRoot}>
      <Filter
        {...props}
        classes={{ form: classnames(modelClasses.filter, classes.listFilterForm) }}
        variant="outlined"
      >
        {children}
      </Filter>
    </div>
  )
}
const useStyles = makeStyles({
  listFilterRoot: {
    marginTop: '0.5rem',
    // The <BulkActionButtons /> is supposed to cover the list's <Filters />,
    // but we want to show both.
    // In order to do that, we make the <Filters /> taller, so <BulkActionButtons /> only
    // appear on the bottom, and don't cover <Filters />.
    // If you change this value, make sure you know what you're doing, and test it on a browser.
    height: '9rem',
  },
  listFilterForm: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
})
