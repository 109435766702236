export const commonShowStyles = {
  paddingLeft: '40px',
  '& h1': {
    fontWeight: 'bold',
    color: '#141414',
    fontSize: '22px',
    letterSpacing: '1.79px',
  },
  '& h2': {
    fontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '1.25px',
    marginTop: '18px',
  },
  '& h3': {
    fontWeight: '400',
    fontSize: '14px',
    letterSpacing: '1.07px',
    marginTop: '6px',
    marginBottom: '20px',
  },
  '& label': {
    marginTop: '8px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#8D8D8D',
    fontSize: '15px',
    letterSpacing: '1.5px',
    fontFamily: 'Roboto',
    lineHeight: '16px',
  },
  '& .ra-field div div': {
    padding: '0',
  },
  '& hr': {
    marginTop: '10px',
  },
  '& .MuiChip-root': {
    marginTop: '10px',
  },
  '& .MuiDivider-root': {
    marginTop: '30px',
    marginBottom: '30px',
  },
}
