import { makeStyles } from '@material-ui/core/styles'
import React, { useMemo } from 'react'
import {
  Datagrid,
  DateField,
  Filter,
  FilterPayload,
  List,
  ReactAdminComponentProps,
  SelectField,
  TextField,
  TextInput,
  usePermissions,
  useTranslate,
} from 'react-admin'
import { Permission } from '../../../auth-provider'
import { modelStyles } from '../../../helpers/model-styles'
import { DefaultDeleteButton } from '../../common/default-buttons'
import { EditMissionButton } from '../../common/edit-mission-button'
import EmptyPage from '../../common/empty-page'
import Pagination from '../../common/pagination'
import PortailLinkButton from '../../common/portail-link-button'
import { ShowMissionButton } from '../../common/show-mission-button'
import { StatusField } from '../../common/status-field'
import { TagReferenceArrayField } from '../../common/tag-reference-array-field'
import { ToolbarCreateButton, TopToolbar } from '../../common/toolbar'
import { XlsExportButton } from '../../common/xls-export-button'
import { MISSION_RESOURCE_NAME } from '../../constants'
import { useAssociation } from '../../contexts/association-context'
import { useIsAtLeastAdminRole } from '../../hook/use-is-at-least-admin-role'
import { useIsAtLeastAssoRole } from '../../hook/use-is-at-least-asso-role'
import {
  MissionsDisabledMessage,
  useAreDomainMissionsEnabled,
} from '../../hook/useAreDomainMissionsEnabled'
import DuplicateMissionButton from './duplicate-mission-button'
import { useMissionRecurrenceChoices } from './mission'

const useStyles = makeStyles(modelStyles)

const MissionListFilter = (props: unknown) => {
  const classes = useStyles()
  return (
    <Filter {...props} classes={{ form: classes.filter }} variant="outlined">
      <TextInput source="q" alwaysOn />
    </Filter>
  )
}

export const MissionListFromAssociation = ({ basePath }: ReactAdminComponentProps) => {
  const { permissions } = usePermissions()
  const association = useAssociation()
  const isAtLeastAdmin = useIsAtLeastAdminRole()
  const associationId = association?.id
  const filter = useMemo(
    () => ({ association: associationId, ...(isAtLeastAdmin ? {} : { disabled: false }) }),
    [associationId, isAtLeastAdmin],
  )
  if (!permissions || !associationId) {
    return null
  }
  return <MissionList filter={filter} basePath={basePath} />
}

export const MissionListFromPermissions = ({ basePath }: ReactAdminComponentProps) => {
  const { permissions } = usePermissions() as { permissions: Permission }
  const missionIds = permissions?.activities
  const filter = useMemo(() => ({ ids: missionIds, disabled: false }), [missionIds])
  if (!permissions || !missionIds) {
    return null
  }
  return <MissionList filter={filter} basePath={basePath} />
}

const MissionList = ({
  filter,
  basePath,
}: { filter: FilterPayload } & ReactAdminComponentProps) => {
  const classes = useStyles()
  const { permissions } = usePermissions() as { permissions: Permission }

  const recurrenceChoices = useMissionRecurrenceChoices()

  const isAtLeastAssoRole = useIsAtLeastAssoRole()
  const isAtLeastAdmin = useIsAtLeastAdminRole()
  const areDomainMissionsEnabled = useAreDomainMissionsEnabled()

  if (!permissions) {
    return null
  }
  if (!areDomainMissionsEnabled) {
    return <MissionsDisabledMessage />
  }
  const actions = <Actions basePath={basePath} />

  return (
    <List
      resource={MISSION_RESOURCE_NAME}
      basePath={basePath}
      bulkActionButtons={false}
      filters={<MissionListFilter />}
      pagination={<Pagination />}
      filter={filter}
      sort={{ field: 'createdAt', order: 'DESC' }}
      empty={<EmptyPage actions={actions} />}
      classes={{ content: classes.content }}
      actions={actions}
    >
      <Datagrid className={classes.list}>
        <TextField source="title" />
        <TagReferenceArrayField source="causes" />
        <TagReferenceArrayField source="skills" />
        <SelectField source="recurrence" choices={recurrenceChoices} />
        <DateField source="updatedAt" />
        {isAtLeastAdmin && <StatusField source="disabled" labelIfFalse="" label="" />}
        {isAtLeastAssoRole && <DuplicateMissionButton />}
        <ShowMissionButton />
        <EditMissionButton />
        {isAtLeastAssoRole && <DefaultDeleteButton />}
      </Datagrid>
    </List>
  )
}

const Actions = ({ basePath }: { basePath?: string }) => {
  const translate = useTranslate()
  const canAddMission = useIsAtLeastAssoRole()
  return (
    <TopToolbar>
      <XlsExportButton />
      <PortailLinkButton />
      {canAddMission && (
        <ToolbarCreateButton
          basePath={basePath}
          label={translate(`resources.Mission.button.create`)}
        />
      )}
    </TopToolbar>
  )
}
