import { useEffect } from 'react'
import { useRedirect } from 'react-admin'
import { DbRecord } from '../../entity-types'

/**
 * Should be used after an API call such as useGetOne.
 */

export const useRedirectIfNotFound = (
  redirectTo: string,
  searchedValue: any,
  data: DbRecord | undefined,
  loading: boolean,
  loaded: boolean,
  error: Error | undefined,
) => {
  const redirect = useRedirect()

  useEffect(() => {
    if (searchedValue && !data && !loading && loaded && !error) {
      // console.log("searchedValue", searchedValue)
      // console.log("data", data)
      // console.log("error", error)
      // console.log("loaded", loaded)
      // console.log("loading", loading)
      // We haven't found an element. Redirect.
      // eslint-disable-next-line no-console
      console.log(`REDIRECT TO ${redirectTo}`)

      redirect(redirectTo)
    }
  }, [searchedValue, data, loading, error, redirect, loaded, redirectTo])
}
