import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { BoxedShowLayout } from 'ra-compact-ui'
import React from 'react'
import { DateField, Show, TextField, useTranslate } from 'react-admin'
import { DbRecord } from '../../../entity-types'
import { modelStyles } from '../../../helpers/model-styles'
import { NonInput } from '../../common/non-input'
import { StatusField } from '../../common/status-field'
import { EnableDisableButton, ToolbarEditButton, TopToolbar } from '../../common/toolbar'
import { useIsAtLeastDomainRole } from '../../hook/use-is-at-least-domain-role'

const useStyles = makeStyles(modelStyles)

export const UsefulLinkShow = (props: unknown) => {
  const translate = useTranslate()
  const classes = useStyles()

  if (!useIsAtLeastDomainRole()) {
    return null
  }

  return (
    <Show
      title={translate('resources.DomainUsefullink.name')}
      actions={<Actions />}
      classes={{ card: classes.card }}
      {...props}
    >
      <BoxedShowLayout className={classes.show}>
        <NonInput>
          <Typography component="h1">{translate('resources.DomainUsefullink.title')}</Typography>
          <Divider />
        </NonInput>
        <TextField source="name" />
        <TextField source="urlText" />
        <TextField source="url" />
        <StatusField source="disabled" />
        <DateField source="createdAt" />
      </BoxedShowLayout>
    </Show>
  )
}

const Actions = ({
  basePath,
  data,
  resource,
}: {
  basePath?: string
  data?: DbRecord
  resource?: string
}) => {
  return (
    <TopToolbar>
      <EnableDisableButton resource={resource} data={data} />
      <ToolbarEditButton record={data} basePath={basePath} />
    </TopToolbar>
  )
}
