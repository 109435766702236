import React from 'react'
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  Filter,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin'
import { idEditTransform } from '../../helpers/id-edit-transform'
import { DefaultEditButton, DefaultShowButton } from '../common/default-buttons'
import EditToolbar from '../common/edit-toolbar'
import Pagination from '../common/pagination'

const AgeSubGroupListFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Name" source="q" alwaysOn />
  </Filter>
)

export const AgeSubGroupList = (props: any) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<AgeSubGroupListFilter />}
    pagination={<Pagination />}
  >
    <Datagrid>
      <TextField source="name" />
      <ReferenceField label="AgeGroup" source="age_group.id" reference="AgeGroup">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="age_min" />
      <NumberField source="age_max" />
      <BooleanField source="visible" />
      <DateField source="createdAt" />
      <DefaultShowButton />
      <DefaultEditButton />
    </Datagrid>
  </List>
)

export const AgeSubGroupCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceInput label="AgeGroup" source="age_group" reference="AgeGroup">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="age_min" />
      <NumberInput source="age_max" />
      <BooleanInput source="visible" initialValue={true} />
    </SimpleForm>
  </Create>
)

export const AgeSubGroupEdit = (props: any) => (
  <Edit
    title="AgeSubGroup edit"
    {...props}
    undoable={false}
    transform={idEditTransform(['age_group'])}
  >
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="name" />
      <ReferenceInput label="AgeGroup" source="age_group.id" reference="AgeGroup">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="age_min" />
      <NumberInput source="age_max" />
      <BooleanInput source="visible" initialValue={true} />
    </SimpleForm>
  </Edit>
)

export const AgeSubGroupShow = (props: any) => (
  <Show title="AgeSubGroup show" {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <ReferenceField label="AgeGroup" source="age_group.id" reference="AgeGroup">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="age_min" />
      <NumberField source="age_max" />
      <BooleanField source="visible" />
      <DateField source="createdAt" />
    </SimpleShowLayout>
  </Show>
)
