import { gql } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import DeployIcon from '@material-ui/icons/CloudUpload'
import React, { useState } from 'react'
import { Button, useTranslate } from 'react-admin'
import { client } from '../../apollo-client'
import { useDomain } from '../contexts/domain-context'

const useStyles = makeStyles({
  flex: {
    display: 'flex',
    'align-items': 'flex-start',
    'flex-direction': 'column',
    margin: '20px',
  },
})

const DeployButton = () => {
  const domain = useDomain()
  const [inDeploy, setInDeploy] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const translate = useTranslate()
  const classes = useStyles()

  async function handleClick() {
    if (domain) {
      setInDeploy(true)

      const DEPLOY_MUTATION = gql`
        mutation {
          deploy(id: "${domain.id}")
        }
      `
      const success = await client.mutate({ mutation: DEPLOY_MUTATION })
      if (success) {
        setMessage(translate('buttons.deploySuccess'))
      } else {
        setMessage(translate('buttons.deployFail'))
      }
    }
  }

  return (
    <div className={classes.flex}>
      <Button onClick={handleClick} label={translate('buttons.deploy')} disabled={inDeploy}>
        <DeployIcon />
      </Button>
      {message}
    </div>
  )
}

export default DeployButton
