import { makeStyles } from '@material-ui/core/styles'
import { ReduxState } from 'ra-core'
import React, { useMemo } from 'react'
import { Layout as RaLayout, Sidebar } from 'react-admin'
import { useSelector } from 'react-redux'
import { ActivityContextFromUrl } from '../contexts/activity-context'
import { AssociationContextFromUrl } from '../contexts/association-context'
import { DomainContextFromUrl } from '../contexts/domain-context'
import { MissionContextFromUrl } from '../contexts/mission-context'
import { useRedirectToLandingPageIfRoot } from '../hook/useRedirectToLandingPageIfRoot'
import { Menu } from '../menu/menu'

const useSidebarStyles = makeStyles((theme) => ({
  drawerPaperOpen: {
    width: '300px',
    [theme.breakpoints.down('md')]: {
      width: '260px',
    },
  },
  drawerPaperClose: {
    width: '55px',
  },
}))

const MySidebar = (props: any) => {
  const classes = useSidebarStyles()
  const open = useSelector<ReduxState, boolean>((state) => state.admin.ui.sidebarOpen)

  const drawerClass = useMemo(
    () => ({ drawerPaper: open ? classes.drawerPaperOpen : classes.drawerPaperClose }),
    [classes, open],
  )

  return <Sidebar classes={drawerClass} {...props} />
}

const useLayoutStyles = makeStyles({
  content: {
    background: 'white',
  },
  contentWithSidebar: {
    background: '#F2F2F2',
  },
})

export const Layout = (props: unknown) => {
  const classes = useLayoutStyles()
  useRedirectToLandingPageIfRoot()
  return (
    <DomainContextFromUrl>
      <AssociationContextFromUrl>
        <ActivityContextFromUrl>
          <MissionContextFromUrl>
            <RaLayout
              {...props}
              classes={classes}
              // appBar={MyAppBar}
              sidebar={MySidebar}
              menu={Menu}
              // notification={MyNotification}
            />
          </MissionContextFromUrl>
        </ActivityContextFromUrl>
      </AssociationContextFromUrl>
    </DomainContextFromUrl>
  )
}
