import React from 'react'
import { CONTACT_RESOURCE_NAME } from '../../constants'
import { ContactShow } from '../contact/show'

export const AssociationContactShow = ({ basePath, id }: { basePath: string; id: string }) => {
  return (
    <ContactShow
      resource={CONTACT_RESOURCE_NAME}
      id={id}
      ownerType="Association"
      basePath={basePath}
      edit={true}
    />
  )
}
