import React from 'react'
import { Loading } from 'react-admin'
import { Permission } from '../../../auth-provider'
import { AddressReferenceSelectOrCreate } from '../../common/address-reference-select-or-create'
import { ASSOCIATION_RESOURCE_NAME } from '../../constants'
import { useAssociation } from '../../contexts/association-context'
import { useThisAssociationUrls } from '../../hook/use-this-association-urls'
import { associationDocTransform } from './association'

export const AssociationAddressCreate = ({
  basePath,
  permissions,
}: {
  basePath: string
  permissions: Permission
}) => {
  const thisAssociationUrls = useThisAssociationUrls()

  const association = useAssociation()
  const associationId = association?.id
  if (!associationId) {
    return null
  }

  if (!permissions) {
    return <Loading />
  }

  return (
    <AddressReferenceSelectOrCreate
      resource={ASSOCIATION_RESOURCE_NAME}
      id={associationId}
      basePath={basePath}
      editTransform={associationDocTransform}
      onSuccessRedirectTo={thisAssociationUrls?.address.list}
    />
  )
}
