import { ResourceProps } from 'react-admin'
import {
  ACTIVITY_RESOURCE_NAME,
  ACTIVITY_SCHEDULE_RESOURCE_NAME,
  ADDRESS_RESOURCE_NAME,
  AGE_GROUP_RESOURCE_NAME,
  AGE_SUBGROUP_RESOURCE_NAME,
  ASSOCIATION_RESOURCE_NAME,
  CONTACT_RESOURCE_NAME,
  DOMAIN_HIGHLIGHT_RESOURCE_NAME,
  DOMAIN_PARTNER_RESOURCE_NAME,
  DOMAIN_RESOURCE_NAME,
  DOMAIN_SEARCH_INTENT_RESOURCE_NAME,
  DOMAIN_TAG_COUNT_RESOURCE_NAME,
  HISTORY_RESOURCE_NAME,
  MISSION_RESOURCE_NAME,
  ROLE_RESOURCE_NAME,
  SCHEDULE_GROUP_RESOURCE_NAME,
  TAG_RESOURCE_NAME,
  USEFUL_LINK_RESOURCE_NAME,
  USER_RESOURCE_NAME,
} from '../constants'
import { ActivityListFromPermissions } from '../models/activity/list'
import { AgeGroupCreate, AgeGroupEdit, AgeGroupList, AgeGroupShow } from '../models/age-group'
import {
  AgeSubGroupCreate,
  AgeSubGroupEdit,
  AgeSubGroupList,
  AgeSubGroupShow,
} from '../models/age-sub-group'
import { AssociationList } from '../models/association/list'
import {
  DomainSearchIntentList,
  DomainSearchIntentShow,
} from '../models/domain/domain-search-intent'
import { DomainTagCountList, DomainTagCountShow } from '../models/domain/domain-tag-count'
import { DomainShow } from '../models/domain/show'
import { RoleCreate, RoleEdit, RoleList, RoleShow } from '../models/role'
import { TagCreate, TagEdit, TagList, TagShow } from '../models/tag'
import { UserCreate, UserEdit, UserList, UserShow } from '../models/user'

/**
 * Hierarchy is:
 * Public < Activity < Asso < Domain < Admin < Dev
 * Each Role inherit from the previous, and can as well override some.
 */

export const publicResources: ResourceProps[] = [
  {
    name: DOMAIN_RESOURCE_NAME,
  },
  { name: SCHEDULE_GROUP_RESOURCE_NAME },
  { name: ASSOCIATION_RESOURCE_NAME },
  { name: CONTACT_RESOURCE_NAME },
  { name: ADDRESS_RESOURCE_NAME },
  { name: ACTIVITY_RESOURCE_NAME },
  { name: MISSION_RESOURCE_NAME },
  { name: ACTIVITY_SCHEDULE_RESOURCE_NAME },
  { name: DOMAIN_HIGHLIGHT_RESOURCE_NAME },
  { name: DOMAIN_PARTNER_RESOURCE_NAME },
  { name: USEFUL_LINK_RESOURCE_NAME },
  {
    name: TAG_RESOURCE_NAME,
  },
  {
    name: AGE_GROUP_RESOURCE_NAME,
  },
  {
    name: AGE_SUBGROUP_RESOURCE_NAME,
  },
]

export const activityRoleResources: ResourceProps[] = [
  { name: ACTIVITY_RESOURCE_NAME, list: ActivityListFromPermissions },
]

export const assoRoleResources: ResourceProps[] = [
  { name: ASSOCIATION_RESOURCE_NAME, list: AssociationList },
]

export const domainRoleResources: ResourceProps[] = [
  { name: HISTORY_RESOURCE_NAME },
  {
    name: DOMAIN_RESOURCE_NAME,
    // list url is declared as customRoute because they mess with compound urls.
    show: DomainShow,
  },
]

export const adminRoleResources: ResourceProps[] = [
  {
    name: DOMAIN_RESOURCE_NAME,
    // list, edit and create urls are declared as customRoute because they mess with compound urls.
    show: DomainShow,
  },
  {
    name: USER_RESOURCE_NAME,
    list: UserList,
    create: UserCreate,
    edit: UserEdit,
    show: UserShow,
  },
  {
    name: ROLE_RESOURCE_NAME,
    list: RoleList,
    create: RoleCreate,
    edit: RoleEdit,
    show: RoleShow,
  },
  {
    name: TAG_RESOURCE_NAME,
    list: TagList,
    create: TagCreate,
    edit: TagEdit,
    show: TagShow,
  },
]
export const devResources: ResourceProps[] = [
  {
    name: DOMAIN_SEARCH_INTENT_RESOURCE_NAME,
    options: { label: 'Domain Search Intents' },
    list: DomainSearchIntentList,
    show: DomainSearchIntentShow,
  },
  {
    name: DOMAIN_TAG_COUNT_RESOURCE_NAME,
    options: { label: 'Domain Tag Count' },
    list: DomainTagCountList,
    show: DomainTagCountShow,
  },
  {
    name: AGE_GROUP_RESOURCE_NAME,
    list: AgeGroupList,
    create: AgeGroupCreate,
    edit: AgeGroupEdit,
    show: AgeGroupShow,
  },
  {
    name: AGE_SUBGROUP_RESOURCE_NAME,
    list: AgeSubGroupList,
    create: AgeSubGroupCreate,
    edit: AgeSubGroupEdit,
    show: AgeSubGroupShow,
  },
]
