import { fade, makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import React, { useCallback } from 'react'
import { Button, useTranslate } from 'react-admin'
import { ConfirmationDialog } from './common/confirmation-dialog'
import { useIconButtonStyles } from './common/default-buttons'
import { DeleteIcon } from './common/icons'
import { useBooleanState } from './hook/use-boolean-state'

/**
 * Just like react-admin's DeleteButton, but with a customized click effect.
 */

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}))

interface CustomDeleteButtonProps {
  confirmationDialogTitle?: string
  confirmationDialogText?: string
  confirmationDialogButtonOkLabel?: string
  confirmationDialogButtonCancelLabel?: string
  onClick?: (record: any) => void
  askForConfirmation?: boolean
  record?: any
}

export const CustomDeleteButton = ({
  onClick,
  askForConfirmation,
  confirmationDialogTitle,
  confirmationDialogText,
  confirmationDialogButtonOkLabel,
  confirmationDialogButtonCancelLabel,
  record,
}: CustomDeleteButtonProps) => {
  const classes = useStyles()
  const translate = useTranslate()
  const iconButtonClasses = useIconButtonStyles()
  const [isDialogOpen, openDialog, closeDialog] = useBooleanState(false)

  const handleAction = useCallback(() => {
    onClick?.(record)
  }, [onClick, record])

  const handleDialogOk = useCallback(() => {
    closeDialog()
    handleAction()
  }, [closeDialog, handleAction])
  return (
    <>
      <Button
        onClick={askForConfirmation !== false ? openDialog : handleAction}
        label=""
        title={translate('buttons.remove')}
        className={classnames('ra-delete-button', classes.deleteButton, iconButtonClasses.root)}
      >
        <DeleteIcon />
      </Button>
      <ConfirmationDialog
        title={confirmationDialogTitle || 'Delete'}
        text={confirmationDialogText || 'Are you sure to delete?'}
        okButtonLabel={confirmationDialogButtonOkLabel}
        okButtonIcon={<DeleteIcon />}
        cancelButtonLabel={confirmationDialogButtonCancelLabel}
        showDialog={isDialogOpen}
        onOk={handleDialogOk}
        onCancel={closeDialog}
      />
    </>
  )
}
