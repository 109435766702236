import React, { useMemo } from 'react'
import { CreateButton, Filter, Loading, SortPayload, TextInput } from 'react-admin'
import EmptyPage from '../../common/empty-page'
import { TopToolbar, useToolbarStyles } from '../../common/toolbar'
import { useDomain } from '../../contexts/domain-context'
import { useIsAtLeastDomainRole } from '../../hook/use-is-at-least-domain-role'
import { useThisDomainUrls } from '../../hook/use-this-domain-urls'
import { ContactList } from '../contact/list'
import { XlsContactExportButton } from '../contact/xls-contact-export-button'

const sortPayload: SortPayload = { field: 'createdAt', order: 'DESC' }

export const DomainContactList = ({ basePath }: { basePath?: string }) => {
  const domainUrls = useThisDomainUrls()
  const domain = useDomain()
  const domainId = domain?.id

  const filter = useMemo(() => ({ domain: domainId }), [domainId])

  if (!useIsAtLeastDomainRole() || !domainId || !domainUrls) {
    return <Loading />
  }

  const actions = <Toolbar />

  return (
    <ContactList
      sort={sortPayload}
      filter={filter}
      basePath={basePath}
      actions={actions}
      emptyPage={<EmptyPage actions={actions} />}
      filters={
        <Filter variant="outlined">
          <TextInput source="q" alwaysOn />
        </Filter>
      }
      disableDelete={true}
    />
  )
}

const Toolbar = ({ basePath }: { basePath?: string }) => {
  const classes = useToolbarStyles()
  return (
    <TopToolbar>
      <XlsContactExportButton />
      <CreateButton
        icon={<></>}
        className={classes.button}
        basePath={basePath!}
        variant="contained"
        label="resources.Domain.Contact.addButtonLabel"
      />
    </TopToolbar>
  )
}
