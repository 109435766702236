import { makeStyles } from '@material-ui/core'
import React from 'react'

interface ColorFieldProps {
  value: string
}
const useStyles = makeStyles({
  root: {
    width: 100,
    height: 30,
    margin: 5,
    borderRadius: 5,
    border: '1px solid grey',
  },
})

export const ColorField = ({ value }: ColorFieldProps) => {
  const classes = useStyles()
  return <div className={classes.root} style={{ background: value }}></div>
}
