import React from 'react'
import {
  Edit,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import { Permission, RoleType } from '../../../auth-provider'
import { useModelStyles } from '../../../helpers/model-styles'
import EditToolbar from '../../common/edit-toolbar'
import { ADDRESS_RESOURCE_NAME } from '../../constants'
import { addressIdEditTransform } from './address'

export const AddressEdit = ({
  permissions,
  basePath,
  id,
}: {
  permissions?: Permission
  basePath: string
  id: string
}) => {
  const translate = useTranslate()
  const classes = useModelStyles()

  return (
    <Edit
      title={translate('resources.Address.name')}
      basePath={basePath}
      id={id}
      undoable={false}
      transform={addressIdEditTransform}
      classes={{ card: classes.card }}
      actions={false}
      resource={ADDRESS_RESOURCE_NAME}
    >
      <SimpleForm toolbar={<EditToolbar />} className={classes.edit}>
        <TextInput
          variant="outlined"
          source="full_address"
          validate={[required()]}
          fullWidth
          helperText="resources.Address.helperTexts.full_address"
        />
        <TextInput
          variant="outlined"
          source="complement"
          helperText="resources.Address.helperTexts.complement"
        />
        <TextInput
          variant="outlined"
          source="street"
          helperText="resources.Address.helperTexts.street"
        />
        <TextInput
          variant="outlined"
          source="zipcode"
          validate={[required()]}
          helperText="resources.Address.helperTexts.zipcode"
        />
        <TextInput
          variant="outlined"
          source="city"
          validate={[required()]}
          helperText="resources.Address.helperTexts.city"
        />
        <TextInput
          variant="outlined"
          source="country"
          validate={[required()]}
          helperText="resources.Address.helperTexts.country"
        />
        <NumberInput
          variant="outlined"
          source="latitude"
          validate={[required()]}
          helperText="resources.Address.helperTexts.latitude"
        />
        <NumberInput
          variant="outlined"
          source="longitude"
          validate={[required()]}
          helperText="resources.Address.helperTexts.longitude"
        />
        {permissions && permissions.type === RoleType.DEV ? (
          <ReferenceInput
            variant="outlined"
            label={translate('resources.Address.fields.association')}
            source="association.id"
            reference="Association"
            helperText="resources.Address.helperTexts.association"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        ) : null}
        {permissions && permissions.type === RoleType.DEV ? (
          <ReferenceInput
            variant="outlined"
            label={translate('resources.Address.fields.association')}
            source="activity.id"
            reference="Activity"
            helperText="resources.Address.helperTexts.activity"
          >
            <SelectInput optionText="title" />
          </ReferenceInput>
        ) : null}
      </SimpleForm>
    </Edit>
  )
}
