import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import React from 'react'
import {
  Button,
  ButtonProps,
  DeleteButton,
  DeleteButtonProps,
  EditButton,
  EditButtonProps,
  ShowButton,
  ShowButtonProps,
  useTranslate,
} from 'react-admin'
import { DeleteIcon, DuplicateIcon, EditIcon, ShowIcon } from './icons'

export const useIconButtonStyles = makeStyles({
  root: {
    minWidth: 'auto',
  },
})

export const DefaultShowButton = ({ className, label, ...props }: ShowButtonProps) => {
  const classes = useIconButtonStyles()
  const translate = useTranslate()
  return (
    <ShowButton
      className={classnames(classes.root, className)}
      icon={<ShowIcon />}
      label={label ?? ''}
      title={translate('buttons.show')}
      {...props}
    />
  )
}

export const DefaultEditButton = ({ className, label, ...props }: EditButtonProps) => {
  const classes = useIconButtonStyles()
  const translate = useTranslate()
  return (
    <EditButton
      className={classnames(classes.root, className)}
      icon={<EditIcon />}
      label={label ?? ''}
      title={translate('buttons.edit')}
      {...props}
    />
  )
}

export const DefaultDeleteButton = ({
  className,
  label,
  undoable,
  ...props
}: DeleteButtonProps) => {
  const classes = useIconButtonStyles()
  const translate = useTranslate()
  return (
    <DeleteButton
      className={classnames(classes.root, className)}
      icon={<DeleteIcon />}
      label={label ?? ''}
      undoable={undoable ?? false}
      title={translate('buttons.delete')}
      {...props}
    />
  )
}

export const DefaultDuplicateButton = ({ className, label, ...props }: ButtonProps) => {
  const classes = useIconButtonStyles()
  const translate = useTranslate()
  return (
    <Button
      title={translate('buttons.duplicate')}
      className={classnames(classes.root, className)}
      label={label ?? ''}
      {...props}
    >
      <DuplicateIcon />
    </Button>
  )
}
