import { makeStyles } from '@material-ui/core'
import React from 'react'
import { Button, useInput } from 'react-admin'

interface ThemedButtonSampleProps {
  record?: any
  bgSource: string
  fgSource: string
}
const useStyles = makeStyles({
  root: {
    marginLeft: 50,
  },
})

export const ThemedButtonSample = ({ bgSource, fgSource, ...props }: ThemedButtonSampleProps) => {
  const classes = useStyles()
  const {
    input: { value: bgValue },
  } = useInput({ ...props, source: bgSource })
  const {
    input: { value: fgValue },
  } = useInput({ ...props, source: fgSource })
  if (!bgValue || !fgValue) {
    return null
  }
  return (
    <Button
      variant="contained"
      className={classes.root}
      style={{ background: bgValue, color: fgValue }}
      label="Exemple de button"
    />
  )
}
