import { makeStyles } from '@material-ui/core/styles'
import { composeFunctions } from '../../../helpers/compose-functions'
import { idEditTransform } from '../../../helpers/id-edit-transform'
import { nullifyUndefinedFields } from '../../../helpers/nullify-undefined-fields'
import { ASSOCIATION_ADMINISTRATIVE_TYPES } from '../../constants'

export const associationDocTransform = composeFunctions(
  nullifyUndefinedFields,
  idEditTransform(
    ['domain', 'main_tag', 'primary_contact', 'primary_address'],
    ['contacts', 'addresses', 'tags'],
  ),
)

export const ADMINISTRATIVE_TYPE_SELECT_CHOICES = ASSOCIATION_ADMINISTRATIVE_TYPES.map((type) => ({
  id: type,
  name: type,
}))

export const useColumnStyles = makeStyles({
  columnWithLeftMargin: {
    marginLeft: '2rem',
  },
})
