import React from 'react'
import { ArrayField, ChipField, ReferenceField, SingleFieldList } from 'react-admin'
import { TAG_RESOURCE_NAME } from '../constants'

interface tagReferenceArrayFieldProps {
  source: string
  addLabel?: boolean // Used by ReactAdmin
  isLink?: boolean
}

export const TagReferenceArrayField = ({ source, isLink }: tagReferenceArrayFieldProps) => {
  return (
    <ArrayField source={source}>
      <SingleFieldList linkType={false}>
        <ReferenceField source="id" reference={TAG_RESOURCE_NAME} link={isLink || false}>
          <ChipField source="name" />
        </ReferenceField>
      </SingleFieldList>
    </ArrayField>
  )
}

TagReferenceArrayField.defaultProps = {
  addLabel: true,
}
