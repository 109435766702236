import { createContext } from 'react'
import { matchPath } from 'react-router-dom'
import { Domain } from '../../entity-types'
import { DOMAIN_RESOURCE_NAME } from '../constants'
import { useGetOneByName } from '../hook/use-get-one-by-name'
import { createContextFromUrl } from './create-context-from-url'

interface DomainContextValue {
  domain?: Domain
  isLoading: boolean
  isMatch: boolean
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const Context = createContext<DomainContextValue>(null!)

const getIdFromPathName = (pathname: string) => {
  const match = matchPath(pathname, { path: '/Domain/:domain_name' })
  return (match?.params as { domain_name?: string })?.domain_name
}

const useCustomLoader = (resource: string, domainName?: string) => {
  return useGetOneByName<Domain>(resource, domainName)
}

const { useEntityContext, useEntity, EntityContextFromUrl } = createContextFromUrl<Domain>(
  DOMAIN_RESOURCE_NAME,
  Context,
  getIdFromPathName,
  {
    idParamsBlacklist: ['create'],
    useCustomLoader,
  },
)

export const useDomainContext = () => {
  const { entity, ...others } = useEntityContext()
  return {
    domain: entity,
    ...others,
  }
}

export const useDomain = useEntity

export const DomainContextFromUrl = EntityContextFromUrl
