import { useInput } from 'react-admin'

// Use multiple inputs at once.
// Important: `sources` must be a fixed-size array, because useInput must always be called
// the same number of times.
const useInputs = (sources: string[]) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return sources.map((source) => useInput({ source }))
}

// Use multiple inputs at once.
// Important: `sources` must be a fixed-size array, because useInput must always be called
// the same number of times.
export const useInputsOnChange = (sources: string[]) => {
  return useInputs(sources).map(({ input }) => input.onChange)
}
