import { values } from 'lodash'
import { useMemo } from 'react'
import { usePermissions } from 'react-admin'
import { Permission } from '../../auth-provider'
import { Association } from '../../entity-types'
import { ASSOCIATION_RESOURCE_NAME } from '../constants'
import { usePrintErrorOnConsoleIfDefined } from './use-print-on-console-if-defined'
import { useStableGetList } from './use-stable-get-list'

// Get the list of associations permitted in the user's role.

export const useAssociationsFromPermissions = () => {
  const { permissions } = usePermissions() as { permissions: Permission }
  const associationIds = permissions?.associations

  const assoFilter = useMemo(() => ({ ids: associationIds }), [associationIds])
  const assoOptions = useMemo(
    () => ({
      enabled: Boolean(associationIds),
    }),
    [associationIds],
  )

  const { data, error, loading } = useStableGetList<Association>(
    ASSOCIATION_RESOURCE_NAME,
    undefined,
    undefined,
    assoFilter,
    assoOptions,
  )

  usePrintErrorOnConsoleIfDefined(error)

  const associations = useMemo(() => values(data), [data])
  return {
    loading,
    associations,
  }
}
