import { useMemo } from 'react'
import { DbRecord } from '../../entity-types'
import { useGetOneByFilter } from './use-get-one-by-filter'

export const useGetOneByName = <R extends DbRecord>(resource: string, name?: string) => {
  const enabled = Boolean(name)

  const filter = useMemo(
    () => ({
      name,
    }),
    [name],
  )
  // console.log('filter', filter)
  const options = useMemo(
    () => ({
      enabled,
    }),
    [enabled],
  )
  // console.log('options', options)
  const { data, loading, loaded, error } = useGetOneByFilter<R>(resource, filter, options)
  // console.log('useGetOneByName domain', data)
  // console.log('useGetOneByName loading', loading)
  // console.log('useGetOneByName error', error)
  // console.log('useGetOneByName loaded', loaded)
  return {
    data,
    loading: enabled && loading, // Fixes https://github.com/marmelab/react-admin/issues/6236
    loaded: loaded && enabled,
    error,
  }
}
