import { idEditTransform } from '../../../helpers/id-edit-transform'
import { XlsExportFieldOption } from '../../common/xls-export-button'

export const addressIdEditTransform = idEditTransform(['domain'])

export const addressExportFieldOptions: XlsExportFieldOption[] = [
  { name: 'city', label: 'Ville' },
  { name: 'street', label: 'Adresse' },
  { name: 'zipcode', label: 'Code postal' },
  { name: 'full_address', label: 'Adresse visible sur le portail', width: 50 },
  { name: 'latitude', label: 'Latitude' },
  { name: 'longitude', label: 'Longitude' },
  { name: 'domain', label: 'Domaine' },
  { name: 'id', hidden: true },
  { name: 'country', hidden: true },
  { name: 'createdAt', hidden: true },
  { name: 'updatedAt', hidden: true },
  { name: 'domain.id', hidden: true },
]
