import React from 'react'
import { Loading, usePermissions } from 'react-admin'
import { AddressListInput } from '../../common/address-list-input'
import { ASSOCIATION_RESOURCE_NAME } from '../../constants'
import { useAssociation } from '../../contexts/association-context'
import { useThisAssociationUrls } from '../../hook/use-this-association-urls'
import { associationDocTransform } from './association'

export const AssociationAddressList = (props: { basePath: string }) => {
  const { basePath } = props
  const permissions = usePermissions().permissions
  const associationUrls = useThisAssociationUrls()
  const association = useAssociation()
  const associationId = association?.id
  // console.log('AssociationAddressList');
  if (!associationId || !associationUrls) {
    return null
  }

  if (!permissions) {
    return <Loading />
  }

  return (
    <AddressListInput
      resource={ASSOCIATION_RESOURCE_NAME}
      id={associationId}
      basePath={basePath}
      addressBasePath={associationUrls.address.basePath}
      editTransform={associationDocTransform}
    />
  )
}
