import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { BoxedShowLayout, RaBox } from 'ra-compact-ui'
import React from 'react'
import {
  Create,
  ImageField,
  ImageInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import { Permission, RoleType } from '../../../auth-provider'
import { useModelStyles } from '../../../helpers/model-styles'
import EditToolbar from '../../common/edit-toolbar'
import { NonInput } from '../../common/non-input'
import { TagReferenceArrayInput } from '../../common/tag-reference-array-input'
import { TagReferenceInput } from '../../common/tag-reference-input'
import { useDomain } from '../../contexts/domain-context'
import { useIsAtLeastDomainRole } from '../../hook/use-is-at-least-domain-role'
import { TagType } from '../tag'
import { ADMINISTRATIVE_TYPE_SELECT_CHOICES, useColumnStyles } from './association'

const tagTypes = [TagType.Main, TagType.Normal]

export const AssociationCreate = ({ permissions, ...props }: { permissions?: Permission }) => {
  const columnClasses = useColumnStyles()
  const domain = useDomain()
  const translate = useTranslate()
  const classes = useModelStyles()

  const isAtLeastDomainRole = useIsAtLeastDomainRole()

  if (!permissions) {
    return null
  }

  return (
    <Create {...props} classes={{ card: classes.card }}>
      <SimpleForm
        redirect="list"
        toolbar={<EditToolbar disableLink />}
        className={classes.edit}
        initialValues={{ domain: domain ? domain.id : null, disabled: false }}
      >
        <BoxedShowLayout>
          <NonInput>
            <Typography component="h1">{translate('resources.Association.title')}</Typography>
            <Typography component="h3">{translate('resources.Association.subtitle')}</Typography>
            <Divider />
            <Typography component="h2">{translate('resources.Association.tabs.1')}</Typography>
          </NonInput>
          <RaBox display="flex">
            <RaBox flex="30">
              <ImageInput
                source="logo_url"
                accept="image/*"
                helperText="resources.Association.helperTexts.logo_url"
                {...props}
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            </RaBox>
            <RaBox flex="70" className={columnClasses.columnWithLeftMargin}>
              <ImageInput
                source="header_url"
                accept="image/*"
                helperText="resources.Association.helperTexts.header_url"
                {...props}
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            </RaBox>
          </RaBox>
          <NonInput>
            <Divider />
            <Typography component="h2">{translate('resources.Association.tabs.2')}</Typography>
          </NonInput>
          {permissions && permissions.type === RoleType.DEV ? (
            <ReferenceInput
              variant="outlined"
              label={translate('resources.Association.fields.domain')}
              source="domain"
              reference="Domain"
              validate={[required()]}
            >
              <SelectInput optionText="domain_name" />
            </ReferenceInput>
          ) : null}
          <TextInput
            variant="outlined"
            source="name"
            validate={[required()]}
            helperText="resources.Association.helperTexts.name"
          />
          <TextInput
            variant="outlined"
            multiline
            source="description_text"
            fullWidth
            helperText="resources.Association.helperTexts.description_text"
          />
          <TextInput
            variant="outlined"
            source="email"
            type="email"
            helperText="resources.Association.helperTexts.email"
          />
          <TextInput
            variant="outlined"
            source="phone"
            helperText="resources.Association.helperTexts.phone"
          />
          <TagReferenceInput
            source="main_tag"
            label="resources.Association.fields.main_tag"
            tagTypes={TagType.Main}
            fullWidth
            disabled={!isAtLeastDomainRole}
            validate={[required()]}
          />
          {permissions &&
            (permissions.type === RoleType.DEV || permissions.type === RoleType.ADMIN) && (
              <TagReferenceArrayInput
                source="tags"
                maxTagCount={6}
                fullWidth
                label="resources.Association.fields.tags"
                tagTypes={tagTypes}
              />
            )}
          <NonInput>
            <Divider />
            <Typography component="h2">{translate('resources.Association.tabs.3')}</Typography>
          </NonInput>
          <RaBox display="flex">
            <RaBox flex="30">
              <TextInput
                variant="outlined"
                source="website_url"
                helperText="resources.Association.helperTexts.website_url"
              />
            </RaBox>
            <RaBox flex="30">
              <TextInput
                variant="outlined"
                source="facebook_url"
                helperText="resources.Association.helperTexts.facebook_url"
              />
            </RaBox>
            <RaBox flex="30">
              <TextInput
                variant="outlined"
                source="instagram_url"
                helperText="resources.Association.helperTexts.instagram_url"
              />
            </RaBox>
          </RaBox>
          <RaBox display="flex">
            <RaBox flex="30">
              <TextInput
                variant="outlined"
                source="twitter_url"
                helperText="resources.Association.helperTexts.twitter_url"
              />
            </RaBox>
            <RaBox flex="30">
              <TextInput
                variant="outlined"
                source="youtube_url"
                helperText="resources.Association.helperTexts.youtube_url"
              />
            </RaBox>
            <RaBox flex="30">
              <TextInput
                variant="outlined"
                source="linkedin_url"
                helperText="resources.Association.helperTexts.linkedin_url"
              />
            </RaBox>
          </RaBox>
          <NonInput>
            <Divider />
            <Typography component="h2">{translate('resources.Association.tabs.4')}</Typography>
          </NonInput>
          <RaBox display="flex">
            <RaBox flex="30">
              <TextInput variant="outlined" source="administrative_id" fullWidth />
            </RaBox>
            <RaBox flex="30" className={columnClasses.columnWithLeftMargin}>
              <SelectInput
                variant="outlined"
                source="administrative_id_type"
                choices={ADMINISTRATIVE_TYPE_SELECT_CHOICES}
                fullWidth
              />
            </RaBox>
          </RaBox>
        </BoxedShowLayout>
      </SimpleForm>
    </Create>
  )
}
