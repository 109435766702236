/**
 * Return the first defined value out of the provided ones.
 *  e.g. useFirstDefinedValue(undefined, '32', 'foo', 5)
 *  => '32'
 */

export const useFirstDefinedValue = <T>(...values: T[]) => {
  for (const value of values) {
    if (value !== undefined) {
      return value
    }
  }
  return undefined
}
