import { useEffect } from 'react'
import { useRedirect } from 'react-admin'
import { useThisActivityUrls } from '../../hook/use-this-activity-urls'

// At the moment we disallow editing an address from an activity.
// So if the user ever get here, he is redirected to the activity.show.

export const ActivityAddressEdit = () => {
  const activityUrl = useThisActivityUrls()
  const redirect = useRedirect()
  useEffect(() => {
    if (activityUrl) {
      redirect(activityUrl.show)
    }
  }, [activityUrl, redirect])
  return null
}
