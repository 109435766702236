export const USER_RESOURCE_NAME = 'User'
export const ROLE_RESOURCE_NAME = 'Role'
export const DOMAIN_RESOURCE_NAME = 'Domain'
export const DOMAIN_HIGHLIGHT_RESOURCE_NAME = 'DomainHighlight'
export const DOMAIN_PARTNER_RESOURCE_NAME = 'DomainPartner'
export const ASSOCIATION_RESOURCE_NAME = 'Association'
export const CONTACT_RESOURCE_NAME = 'Contact'
export const ACTIVITY_RESOURCE_NAME = 'Activity'
export const MISSION_RESOURCE_NAME = 'Mission'
export const ACTIVITY_SCHEDULE_RESOURCE_NAME = 'ActivitySchedule'
export const ADDRESS_RESOURCE_NAME = 'Address'
export const TAG_RESOURCE_NAME = 'Tag'
export const DOMAIN_SEARCH_INTENT_RESOURCE_NAME = 'DomainSearchIntent'
export const DOMAIN_TAG_COUNT_RESOURCE_NAME = 'DomainTagCount'
export const AGE_GROUP_RESOURCE_NAME = 'AgeGroup'
export const AGE_SUBGROUP_RESOURCE_NAME = 'AgeSubGroup'
export const SCHEDULE_GROUP_RESOURCE_NAME = 'ScheduleGroup'
export const USEFUL_LINK_RESOURCE_NAME = 'DomainUsefullink'
export const HISTORY_RESOURCE_NAME = 'History'

export const ASSOCIATION_ADMINISTRATIVE_TYPES = ['RNA', 'SIREN', 'SIRET', 'Autre']

export const MISSION_RECURRENCE_CHOICES = ['Punctual', 'Recurrent']
