import { isFunction, isPlainObject, isString, mapValues } from 'lodash'

/**
 * Helper allowing to nest url creators.
 *
 * @param prefix Prefix to prepend to every nested available urls.
 * @param obj The url map you want to prefix.
 * @returns A url map with the same structure as `obj` but prefixed with the provided prefix.
 */

const prefixAllPaths = <T extends Record<string, any>>(prefix: string, obj: T): T => {
  return mapValues(obj, (val) => {
    if (isString(val)) {
      return `${prefix}${val}` as any
    }
    if (isFunction(val)) {
      // A function is also an object, so we need to prefix the result of that function,
      // but also its own fields.
      return Object.assign(
        (...args: any) => prefixAllPaths(prefix, val(...args)),
        prefixAllPaths(prefix, val),
      )
    }
    if (isPlainObject(val)) {
      return prefixAllPaths(prefix, obj)
    }
    throw Error(`val type not supported ${typeof val}`)
  })
}

export const defaultIdUnspecifiedUrlMap = {
  basePath: '',
  list: '',
  create: `/create`,
}

export const defaultIdSpecifiedUrlMap = {
  basePath: '',
  show: `/show`,
  edit: '',
}

type UrlMap = Record<string, UrlCreator | string>

type UrlCreator = (id: string) => UrlMap

export const createUrlCreator = <IURM extends UrlMap, ISRM extends UrlMap>(
  basePath: string,
  idUnspecifiedUrlMap: IURM, // Paths without specified id e.g. /Domain/Create
  idSpecifiedUrlMap: ISRM, // Paths with specified id e.g. /Domain/123/Edit
) => {
  return Object.assign(
    (id: string) => prefixAllPaths(`${basePath}/${id}`, idSpecifiedUrlMap),
    prefixAllPaths(basePath, idUnspecifiedUrlMap),
  )
}

export const stripLastWordFromUrl = (url: string): string => {
  const lastSlashIndex = url.lastIndexOf('/')
  if (lastSlashIndex < 0) {
    return url
  }
  return url.substr(0, lastSlashIndex)
}
