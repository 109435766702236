import classnames from 'classnames'
import React, { ReactElement, useMemo } from 'react'
import { Datagrid, EmailField, Filter, List, TextField, TextInput } from 'react-admin'
import { useModelStyles } from '../../../helpers/model-styles'
import { ReorderPropsIds } from '../../../helpers/react-admin-hacks/ReorderPropsIds'
import BooleanFieldIntext from '../../common/boolean-field'
import {
  DefaultDeleteButton,
  DefaultEditButton,
  DefaultShowButton,
} from '../../common/default-buttons'
import EmptyPage from '../../common/empty-page'
import ListToolbar from '../../common/list-toolbar'
import Pagination from '../../common/pagination'

const ContactListFilter = (props: unknown) => {
  const classes = useModelStyles()
  return (
    <Filter {...props} classes={{ form: classes.filter }} variant="outlined">
      <TextInput source="q" alwaysOn />
    </Filter>
  )
}
interface ContactListProps {
  filters?: ReactElement | undefined
  pagination?: ReactElement | false
  emptyPage?: ReactElement | false
  actions?: ReactElement | false
  disableModify?: boolean
  disableShow?: boolean
  disableDelete?: boolean
  title?: string
  extraColumnsStart?: ReactElement[]
  extraColumnsMid?: ReactElement[]
  extraColumnsEnd?: ReactElement[]
  datagridIdSort?: (ids: string[]) => string[]
  className?: string
  [key: string]: any
}
const ContactActionsToolbar = () => <ListToolbar disableExport={true} disableLink={true} />

export const ContactEmptyPage = () => <EmptyPage actions={<ContactActionsToolbar />} />

export const ContactList = ({
  title,
  emptyPage,
  filters,
  pagination,
  actions,
  disableModify,
  disableShow,
  disableDelete,
  extraColumnsStart,
  extraColumnsMid,
  extraColumnsEnd,
  datagridIdSort,
  className,
  ...props
}: ContactListProps) => {
  const modelClasses = useModelStyles()
  const finalClasses = useMemo(() => ({ content: classnames(modelClasses.content, className) }), [
    modelClasses.content,
    className,
  ])
  return (
    <div>
      <List
        {...props}
        title={title}
        bulkActionButtons={false}
        filters={filters !== undefined ? filters : <ContactListFilter />}
        pagination={pagination !== undefined ? pagination : <Pagination />}
        empty={emptyPage !== undefined ? emptyPage : <ContactEmptyPage />}
        classes={finalClasses}
        actions={actions !== undefined ? actions : <ContactActionsToolbar />}
      >
        <ReorderPropsIds datagridIdSort={datagridIdSort}>
          <Datagrid className={modelClasses.list}>
            {extraColumnsStart}
            <TextField source="civility" />
            <TextField source="firstname" />
            <TextField source="lastname" />
            <TextField source="title" />
            <TextField source="phone" />
            <EmailField source="email" />
            <BooleanFieldIntext source="hidden" />
            {extraColumnsMid}
            {!disableShow && <DefaultShowButton />}
            {!disableModify && <DefaultEditButton />}
            {extraColumnsEnd}
            {!disableDelete && <DefaultDeleteButton />}
          </Datagrid>
        </ReorderPropsIds>
      </List>
    </div>
  )
}
