/**
 * Allow to query with an ids-array filter with the guarantee
 * that all the ONCE-PAGINATED ids will result in a document.
 *
 * See `paginatedIdsCriteriaMiddleware` on the backend for more details.
 */

export const PAGINATION_FILTER_IDS_SORT_FIELD = '__paginated_filter_ids'

export const PAGINATION_FILTER_IDS_SORT = {
  field: PAGINATION_FILTER_IDS_SORT_FIELD,
  order: 'ASC',
} as const
