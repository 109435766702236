import {
  Identifier,
  PaginationPayload,
  Record,
  RecordMap,
  SortPayload,
  UseDataProviderOptions,
  useGetList,
} from 'react-admin'
import { useHasChangedSinceLastRender } from './use-has-changed-since-last-render'

/**
 * Just like useGetList, but fixes a nasty bug.
 *
 * In the original useGetList, when an input has changed, there is one tick where the result is
 *
 * { data = {}, error = undefined, loading = false, loaded = true }
 *
 * This misleads the user because it seems to mean that the request was successful and loaded, but there was no data loaded.
 * This is wrong.
 * When an input changed, the result of the hook should be immediately
 * { data = {}, error = undefined, loading = true, loaded = false }
 *
 * This hook simply overrides `loading` and `loaded`.
 */

export const useStableGetList = <RecordType extends Record = Record>(
  resource: string,
  pagination?: PaginationPayload,
  sort?: SortPayload,
  filter?: any,
  options?: UseDataProviderOptions,
): {
  data: RecordMap<RecordType>
  ids: Identifier[]
  total?: number
  error?: any
  loading: boolean
  loaded: boolean
} => {
  let someInputHasChanged = false

  if (useHasChangedSinceLastRender(resource)) {
    someInputHasChanged = true
  }
  if (useHasChangedSinceLastRender(pagination)) {
    someInputHasChanged = true
  }
  if (useHasChangedSinceLastRender(sort)) {
    someInputHasChanged = true
  }
  if (useHasChangedSinceLastRender(filter)) {
    someInputHasChanged = true
  }
  if (useHasChangedSinceLastRender(options)) {
    someInputHasChanged = true
  }

  const { data, ids, error, loading, loaded } = useGetList<RecordType>(
    resource,
    pagination,
    sort,
    filter,
    options,
  )

  return {
    data,
    ids,
    error,
    loading: loading || someInputHasChanged,
    loaded: loaded && !someInputHasChanged,
  }
}
