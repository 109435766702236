import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { Button, useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'
import { Domain } from '../../entity-types'
import { URLS } from '../../routes/url-creators'
import { ShowIcon } from './icons'

const useStyles = makeStyles({
  button: {
    textTransform: 'capitalize',
    fontSize: '11px',
  },
})

interface Props {
  record?: Domain
}

export const ShowDomainButton = ({ record: domain }: Props) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Button
      label={translate('resources.Domain.button.open')}
      component={Link}
      to={URLS.domain(domain?.domain_name || '').dashboard}
      className={classes.button}
    >
      <ShowIcon />
    </Button>
  )
}
