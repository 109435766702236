import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import {
  Edit,
  HandleSubmitWithRedirect,
  required,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import { UsefulLink } from '../../../entity-types'
import { useModelStyles } from '../../../helpers/model-styles'
import { NonInput } from '../../common/non-input'
import { StatusToggleInput } from '../../common/status-toggle-input'
import { ToolbarSaveButton, TopToolbar } from '../../common/toolbar'
import { useIsAtLeastDomainRole } from '../../hook/use-is-at-least-domain-role'
import { useCreateEditFormsStyles, usefulLinkDocEditTransform } from './useful-link'

export const UsefulLinkEdit = ({ id, ...props }: { id?: string }) => {
  const translate = useTranslate()
  const modelClasses = useModelStyles()
  const classes = useCreateEditFormsStyles()

  if (!useIsAtLeastDomainRole()) {
    return null
  }

  return (
    <Edit
      id={id}
      title={translate('resources.DomainUsefullink.name')}
      {...props}
      undoable={false}
      transform={usefulLinkDocEditTransform}
      classes={{ card: modelClasses.card }}
      actions={false}
    >
      <SimpleForm redirect="show" toolbar={<Actions />} className={modelClasses.edit}>
        <NonInput>
          <Typography component="h1">{translate('resources.DomainUsefullink.title')}</Typography>
          <Divider />
        </NonInput>
        <TextInput variant="outlined" source="name" validate={[required()]} />
        <TextInput
          variant="outlined"
          multiline
          source="descriptionText"
          fullWidth
          InputProps={{ classes: { input: classes.descriptionTextArea } }}
        />
        <TextInput variant="outlined" source="urlText" validate={[required()]} />
        <TextInput variant="outlined" source="url" validate={[required()]} type="url" />
        <StatusToggleInput source="disabled" />
      </SimpleForm>
    </Edit>
  )
}

const Actions = ({
  basePath,
  data,
  handleSubmitWithRedirect,
}: {
  basePath?: string
  data?: UsefulLink
  handleSubmitWithRedirect?: HandleSubmitWithRedirect
}) => {
  return (
    <TopToolbar layoutType="action">
      <ToolbarSaveButton
        record={data}
        basePath={basePath}
        handleSubmitWithRedirect={handleSubmitWithRedirect}
      />
    </TopToolbar>
  )
}
