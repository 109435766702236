import { Typography } from '@material-ui/core'
import React from 'react'
import { useTranslate } from 'react-admin'
import { useHistoryTargetResource } from './entity-name-field'

export const EntityTypeField = (_props: any) => {
  const translate = useTranslate()
  const resource = useHistoryTargetResource()

  return (
    <Typography component="span" variant="body2">
      {translate(`resources.${resource}.name`)}
    </Typography>
  )
}
