import { useActivity } from '../contexts/activity-context'
import { useThisAssociationUrls } from './use-this-association-urls'

/**
 * Get an url creator associated to the provided activity.
 */
export const useActivityUrls = (activityId?: string) => {
  const thisAssociationUrls = useThisAssociationUrls()
  return activityId ? thisAssociationUrls?.activity(activityId) : undefined
}

/**
 * Get an url creator associated to the activity provided by the ActivityContext.
 * Useful to express urls relative to the current activity.
 */

export const useThisActivityUrls = () => {
  const activity = useActivity()
  return useActivityUrls(activity?.id)
}
