import { makeStyles } from '@material-ui/core'
import React, { ReactElement } from 'react'

// This is what MaterialUi uses
const transition = 'margin-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'

const useStyles = makeStyles({
  open: {
    marginLeft: '4rem',
    transition,
  },
  closed: {
    marginLeft: 0,
    transition,
  },
})
export const IndentMenuItemContainer = ({
  indent,
  children,
  isSidebarOpen,
}: {
  indent?: boolean
  children: ReactElement | ReactElement[]
  isSidebarOpen: boolean
}) => {
  const classes = useStyles()
  return <div className={isSidebarOpen && indent ? classes.open : classes.closed}>{children}</div>
}
