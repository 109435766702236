import React, { useMemo } from 'react'
import {
  Datagrid,
  DateField,
  Filter,
  List,
  SelectField,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import { daysOfWeek } from '../../../helpers/day-of-week'
import { useModelStyles } from '../../../helpers/model-styles'
import {
  DefaultDeleteButton,
  DefaultEditButton,
  DefaultShowButton,
} from '../../common/default-buttons'
import EmptyPage from '../../common/empty-page'
import Pagination from '../../common/pagination'
import PortailLinkButton from '../../common/portail-link-button'
import { ToolbarCreateButton, TopToolbar } from '../../common/toolbar'
import { XlsExportButton } from '../../common/xls-export-button'
import { useActivity } from '../../contexts/activity-context'

const ActivityScheduleListFilter = (props: unknown) => {
  return (
    <Filter {...props} variant="outlined">
      <TextInput source="q" alwaysOn />
    </Filter>
  )
}

export const ActivityScheduleList = (props: { basePath?: string }) => {
  const activity = useActivity()
  const translate = useTranslate()
  const classes = useModelStyles()

  const activityId = activity?.id

  const filter = useMemo(() => ({ activity: activityId }), [activityId])

  if (!activityId) {
    return null
  }

  const actions = <Actions basePath={props.basePath} />

  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<ActivityScheduleListFilter />}
      pagination={<Pagination />}
      filter={filter}
      empty={<EmptyPage actions={actions} />}
      classes={{ content: classes.content }}
      actions={actions}
    >
      <Datagrid className={classes.list}>
        <TextField source="name" />
        <SelectField source="day_name" choices={daysOfWeek(translate)} />
        <TextField source="start_time" />
        <TextField source="end_time" />
        <DateField source="createdAt" />
        <DefaultShowButton />
        <DefaultEditButton />
        <DefaultDeleteButton />
      </Datagrid>
    </List>
  )
}

const Actions = ({ basePath }: { basePath?: string }) => {
  const translate = useTranslate()
  return (
    <TopToolbar>
      <XlsExportButton />
      <PortailLinkButton />
      <ToolbarCreateButton
        basePath={basePath}
        label={translate(`resources.ActivitySchedule.button.create`)}
      />
    </TopToolbar>
  )
}
