import React from 'react'
import { Button, useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'
import { Activity } from '../../entity-types'
import { URLS } from '../../routes/url-creators'
import { useAssociationHierarchy } from '../hook/useAssociationHierarchy'
import { ShowIcon } from './icons'

/**
 * This component is just like a <ShowButton />.
 * However, because it is used from different paths:
 * - ActivityRole landing page with path = /Activity
 * - Association's Activities with path = /Domain/123/Association/456/Activity
 * it cannot simply rely on the props-provided basePath.
 * Instead we fetch the domain/association in order to build a complete url to the given activity.
 */

export const ShowActivityButton = ({ record }: { record?: Activity }) => {
  const translate = useTranslate()
  const { association, domain } = useAssociationHierarchy(record?.association.id)

  if (!record || !association || !domain) {
    return null
  }

  return (
    <Button
      component={Link}
      label=""
      to={URLS.domain(domain.domain_name).association(association.id).activity(record?.id).show}
      title={translate('buttons.show')}
    >
      <ShowIcon />
    </Button>
  )
}
