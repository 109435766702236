import { keys, reduce } from 'lodash'
import React, { ComponentType, ReactElement, useMemo } from 'react'
import { ResourceContextProvider, WithPermissions } from 'react-admin'
import { match, Route } from 'react-router-dom'
import { stripLastWordFromUrl } from './url-creator-helpers'

export enum PathKind {
  basePath, // e.g. "/Domain"
  idPath, // e.g. "/Domain/123" or "/Domain/123/Association"
  actionPath, // e.g. "/Domain/create"
}

/**
 * Create a custom route that plays well with React Admin.
 *
 * e.g. /Domain/:mydomain/Associations
 *
 * @param component        Component to render e.g. AssociationList.
 * @param path             In the above example, "/Domain".
 * @param pathKind         Type of path. Will affect the component's basePath.
 * @param options.wrapInResourceContext Wrap `component` into a <ResourceContextProvider>.
 * @returns                             A route.
 */

export const buildCustomRoute = (
  component: ComponentType<any>,
  path: string,
  pathKind: PathKind,
  options: {
    wrapInResourceContext?: string
    exact?: boolean
  } = {},
) => {
  return (
    <Route
      key={path}
      exact={options.exact ?? true}
      path={path}
      render={(unstableRouteProps) =>
        wrapInResourceContext(
          options.wrapInResourceContext,
          <StableRouteRender
            resource={options.wrapInResourceContext}
            component={component}
            pathKind={pathKind}
            {...unstableRouteProps}
          />,
        )
      }
    />
  )
}

const getBasePathAndIdFromPath = ({ params, path }: match<any>) => {
  // e.g. /Domain/12315/Association/34951/Activity/9289845/Show
  //      basePath = "/Domain/12315/Association/34951/Activity"
  //      id = 9289845
  let maxIndex = -1
  let maxIndexKey: string | undefined
  for (const key of keys(params)) {
    const index = path.indexOf(key)
    if (index > maxIndex) {
      maxIndex = index
      maxIndexKey = key
    }
  }
  if (!maxIndexKey) {
    throw Error('Could not deduct id from path.')
  }

  const id = params[maxIndexKey]
  const basePath = reduce(
    params,
    (path, val, key) => {
      return path.replace(`/:${key}`, `/${val}`)
    },
    path.substr(0, maxIndex - 2), // e.g. "/Domain/:domain/Show" => "/Domain"
  )
  return { basePath, id }
}

const StableRouteRender = ({
  pathKind,
  ...routeProps
}: {
  component: ComponentType<any>
  pathKind: PathKind
  resource?: string
  match: match<any>
  [key: string]: unknown
}) => {
  switch (pathKind) {
    case PathKind.basePath:
      return <StableBasePathRouteRender {...routeProps} />
    case PathKind.actionPath:
      return <StableActionPathPathRouteRender {...routeProps} />
    case PathKind.idPath:
      return <StableIdPathRouteRender {...routeProps} />
  }
}

const StableBasePathRouteRender = ({
  match,
  ...routeProps
}: {
  match: match<any>
  [key: string]: unknown
}) => {
  return <WithPermissions basePath={match.url} {...routeProps} />
}

const StableIdPathRouteRender = ({
  match,
  ...routeProps
}: {
  match: match<any>
  [key: string]: unknown
}) => {
  const basePathAndId = useMemo(() => getBasePathAndIdFromPath(match), [match])
  return <WithPermissions basePath={basePathAndId.basePath} id={basePathAndId.id} {...routeProps} />
}

const StableActionPathPathRouteRender = ({
  match,
  ...routeProps
}: {
  match: match<any>
  [key: string]: unknown
}) => {
  return <WithPermissions basePath={stripLastWordFromUrl(match.url)} {...routeProps} />
}

const wrapInResourceContext = (resource: string | undefined, element: ReactElement): ReactElement =>
  resource ? <ResourceContextProvider value={resource}>{element}</ResourceContextProvider> : element
