import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  fade,
  makeStyles,
} from '@material-ui/core'
import IconCancel from '@material-ui/icons/Cancel'
import classnames from 'classnames'
import React, { ReactElement } from 'react'
import { Button } from 'react-admin'

interface ConfirmationDialogProps {
  title: string
  text: string
  okButtonLabel?: string
  okButtonIcon?: ReactElement
  cancelButtonLabel?: string
  showDialog: boolean
  onOk?: () => void
  onCancel?: () => void
}

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}))

export const ConfirmationDialog = ({
  title,
  text,
  okButtonLabel,
  okButtonIcon,
  cancelButtonLabel,
  showDialog,
  onOk,
  onCancel,
}: ConfirmationDialogProps) => {
  const classes = useStyles()
  return (
    <Dialog fullWidth open={showDialog} onClose={onCancel} aria-label="Are you sure?">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{text}</DialogContent>
      <DialogActions>
        <Button
          onClick={onOk}
          className={classnames('ra-delete-button', classes.deleteButton)}
          key="button"
          label={okButtonLabel || 'ra.boolean.true'}
        >
          {okButtonIcon}
        </Button>
        <Button label={cancelButtonLabel || 'ra.action.cancel'} onClick={onCancel}>
          <IconCancel />
        </Button>
      </DialogActions>
    </Dialog>
  )
}
