import { Button, Card, Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { values } from 'lodash'
import React, { useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { UsefulLink } from '../../entity-types'
import { modelStyles } from '../../helpers/model-styles'
import { USEFUL_LINK_RESOURCE_NAME } from '../constants'
import { useAssociationsFromPermissions } from '../hook/use-associations-from-permissions'
import { usePrintErrorOnConsoleIfDefined } from '../hook/use-print-on-console-if-defined'
import { useStableGetList } from '../hook/use-stable-get-list'

const useModelStyles = makeStyles(modelStyles)

const linkSort = { field: 'createdAt', order: 'DESC' }

const usePageStyle = makeStyles({
  noLinkMessage: {
    marginTop: '2rem',
  },
})

const useUsefulLinksFromAssociationFromPermissions = () => {
  const { associations, loading: isAssociationListLoading } = useAssociationsFromPermissions()
  // This makes the assumption that all associations belong to the same domain.
  const domainId = associations[0]?.domain.id

  const linkFilter = useMemo(() => ({ domain: domainId }), [domainId])
  const linkOptions = useMemo(
    () => ({
      enabled: Boolean(domainId),
    }),
    [domainId],
  )

  const { data, error, loading } = useStableGetList<UsefulLink>(
    USEFUL_LINK_RESOURCE_NAME,
    undefined,
    linkSort,
    linkFilter,
    linkOptions,
  )
  usePrintErrorOnConsoleIfDefined(error)

  const usefulLinks = values(data)

  return {
    loading: isAssociationListLoading || loading,
    usefulLinks,
  }
}

export const AssoRoleHome = () => {
  const modelClasses = useModelStyles()
  const classes = usePageStyle()
  const translate = useTranslate()
  const { loading, usefulLinks } = useUsefulLinksFromAssociationFromPermissions()
  if (loading) {
    return null
  }
  return (
    <div className={modelClasses.show}>
      <Typography component="h2">{translate('assoRoleLandingPage.title')}</Typography>
      <Typography component="h3">{translate('assoRoleLandingPage.subtitle')}</Typography>
      <Divider />
      {usefulLinks.length === 0 && (
        <Typography className={classes.noLinkMessage}>
          {translate('assoRoleLandingPage.noLinkMessage')}
        </Typography>
      )}
      {usefulLinks.map((link) => (
        <UsefulLinkThumbnail key={link.id} link={link} />
      ))}
    </div>
  )
}

const useLinkStyle = makeStyles({
  root: {
    marginTop: '2rem',
    marginBottom: '2rem',
    padding: '1rem',
    background: '#fafafa',
  },
  title: {
    paddingTop: 0,
    marginTop: '0 !important',
    marginBottom: '1rem',
  },
  button: {
    marginTop: '1rem',
  },
})

const UsefulLinkThumbnail = ({ link }: { link: UsefulLink }) => {
  const classes = useLinkStyle()
  return (
    <Card className={classes.root}>
      <Typography component="h1" className={classes.title}>
        {link.name}
      </Typography>
      <Typography>{link.descriptionText}</Typography>
      <Button
        href={link.url}
        target="_blank"
        variant="outlined"
        color="primary"
        className={classes.button}
      >
        {link.urlText}
      </Button>
    </Card>
  )
}
