import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import { Activity } from '../../entity-types'
import { useActivity } from '../contexts/activity-context'
import { useActivityUrls } from '../hook/use-this-activity-urls'
import { useThisAssociationUrls } from '../hook/use-this-association-urls'
import { LinkMenuItem } from './menu-item'

export const useStyles = makeStyles({
  root: {
    '& > *': {
      minHeight: '36px',
    },
  },
})

export const ActivityMenu = ({
  className,
  activity: activityFromProps,
}: {
  className?: string
  activity?: Activity
}) => {
  const classes = useStyles()
  const activityFromContext = useActivity()
  const activity = activityFromProps || activityFromContext
  const thisAssociationUrls = useThisAssociationUrls()
  const thisActivityUrls = useActivityUrls(activity?.id)

  if (!activity || !thisAssociationUrls || !thisActivityUrls) {
    return null
  }

  const thisActivityUrl = thisAssociationUrls.activity(activity.id)
  return (
    <div className={classNames(className, classes.root)}>
      <LinkMenuItem to={thisActivityUrls.show} primaryText={activity.title} subItem={true} />
      <LinkMenuItem
        to={thisActivityUrl.contact.list}
        primaryTextKey="menu.activityMenu.Contact"
        subItem={true}
      />
      <LinkMenuItem
        to={thisActivityUrl.address.list}
        exact={false}
        primaryTextKey="menu.activityMenu.Address"
        subItem={true}
      />
      <LinkMenuItem
        to={thisActivityUrl.schedule.list}
        primaryTextKey="menu.activityMenu.ActivitySchedule"
        subItem={true}
        exact={false}
      />
    </div>
  )
}
