export type DbRecordId = string

export interface DbRecord {
  id: DbRecordId
}

export type DomainId = DbRecordId
export type AssociationId = DbRecordId
export type ActivityId = DbRecordId
export type DomainHighlightId = DbRecordId
export type UserId = DbRecordId
export type RoleId = DbRecordId
export type TagId = DbRecordId
export type UsefulLinkId = DbRecordId
export type AddressId = DbRecordId
export type ContactId = DbRecordId

export interface LooseDbRecord extends DbRecord {
  [key: string]: any
}

export interface Tag extends DbRecord {
  name: string
}

export interface Domain extends DbRecord {
  domain_name: string
  title: string
  logo_url: string
  home_about_image: string
  enable_mission?: boolean
  theme?: DomainTheme
}

export enum DomainThemeFont {
  // These font must be available on the webfront
  RobotoCondensed = 'Roboto Condensed',
  Nunito = 'Nunito Sans',
}

export type HtmlColor = string

export interface DomainTheme {
  font: DomainThemeFont
  text_color: HtmlColor
  header_bg_url?: string
  header_fg_color: HtmlColor
  footer_bg_color: HtmlColor
  footer_fg_color: HtmlColor
  primary_color: HtmlColor
  primary_text_color: HtmlColor
}

export interface DomainHighlight extends DbRecord {
  domain: { id: string }
  description_text?: string
  preview_img_url?: string

  // Selection by criteria
  age_sub_groups?: { id: string }[]
  schedule_groups?: { id: string }[]
  days?: string[]
  tags?: { id: string }[]

  // Selection by associations
  associations?: { id: string }[]

  // Selection by activities
  activities?: { id: string }[]

  // Selection by missions
  missions?: { id: string }[]
}

export interface Contact extends DbRecord {
  civility: string
  firstname: string
  lastname: string
}

export interface Address extends DbRecord {
  full_address: string
  street: string
  zipcode: string
  city: string
  country: string
  latitude: number
  longitude: number
  complement?: string
}

export interface Association extends DbRecord {
  name: string
  logo_url: string
  header_url: string
  domain: { id: string }
  primary_contact?: { id: string }
  contacts?: { id: string }[]
  primary_address?: { id: string }
  addresses?: { id: string }[]
  tags?: { id: string }[]
  main_tag?: { id: string }
}

export interface Activity extends DbRecord {
  title: string
  association: { id: string }
  primary_contact?: { id: string }
  contacts?: { id: string }[]
  tags?: { id: string }[]
  primary_address?: { id: string }
  addresses?: { id: string }[]
}

export interface Mission extends DbRecord {
  title: string
  association: { id: string }
  primary_contact?: { id: string }
  contacts?: { id: string }[]
  causes?: { id: string }[]
  skills?: { id: string }[]
}

export interface UsefulLink extends DbRecord {
  name: string
  descriptionText: string
  url: string
  urlText: string
}

export interface DomainHistory extends DbRecord {
  target_type: string
  target_id: DbRecordId
  before: DbRecord
  after: DbRecord
  associationId?: AssociationId
}
