import { RoleType } from '../../auth-provider'
import { URLS } from '../../routes/url-creators'

export const getLandingPageForRoleType = (roleType: RoleType): string => {
  switch (roleType) {
    case RoleType.ACTIVITY:
      return URLS.activity.list
    case RoleType.ASSO:
      return URLS.assoRoleHome
    case RoleType.DOMAIN:
      return URLS.domain.list
    case RoleType.ADMIN:
      return URLS.domain.list
    case RoleType.DEV:
      return URLS.domain.list
    case RoleType.NONE:
      throw new Error('Unauthorized')
  }
}
