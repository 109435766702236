import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { BoxedShowLayout } from 'ra-compact-ui'
import React from 'react'
import {
  BooleanField,
  DateField,
  ImageField,
  SelectField,
  Show,
  TextField,
  UrlField,
  useTranslate,
} from 'react-admin'
import { useModelStyles } from '../../../helpers/model-styles'
import DeployButton from '../../common/deploy-button'
import { NonInput } from '../../common/non-input'
import ShowToolbar from '../../common/show-toolbar'
import { useDomain } from '../../contexts/domain-context'
import { DomainType } from './domain'

export const DomainShow = (props: any) => {
  const translate = useTranslate()
  const classes = useModelStyles()

  const domain = useDomain()

  if (!domain) {
    return null
  }

  return (
    <Show
      title={translate('resources.Domain.name')}
      {...props}
      id={domain.id}
      actions={<ShowToolbar />}
      classes={{ card: classes.card }}
    >
      <BoxedShowLayout className={classes.show}>
        <NonInput>
          <Typography component="h1">{translate('resources.Domain.title')}</Typography>
          <Typography component="h3">{translate('resources.Domain.subtitle')}</Typography>
          <Divider />
          <Typography component="h2">{translate('resources.Domain.tabs.1')}</Typography>
        </NonInput>
        <ImageField source="logo_url" title="logo" />
        <TextField source="domain_name" />
        <TextField source="title" />
        <SelectField source="type" choices={DomainType} />
        <UrlField source="outlink_url" emptyText="-" />
        <UrlField source="facebook_url" emptyText="-" />
        <UrlField source="twitter_url" emptyText="-" />
        <UrlField source="instagram_url" emptyText="-" />
        <UrlField source="legal_notice_url" emptyText="-" />
        <UrlField source="contact_form_url" emptyText="-" />
        <BooleanField source="published" />
        <DateField source="createdAt" />
        <NonInput>
          <Divider />
          <Typography component="h2">{translate('resources.Domain.tabs.2')}</Typography>
        </NonInput>
        <TextField source="home_title" />
        <TextField source="home_subtitle" />
        <NonInput>
          <Divider />
          <Typography component="h2">{translate('resources.Domain.tabs.3')}</Typography>
        </NonInput>
        <TextField source="seo_description" />
        <TextField source="seo_keywords" />
        <TextField source="ga_key" />
        <NonInput>
          <Divider />
          <Typography component="h2">{translate('resources.Domain.tabs.4')}</Typography>
        </NonInput>
        <DeployButton />
      </BoxedShowLayout>
    </Show>
  )
}
