import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import React from 'react'
import { useMenuItemBackLinkStyles } from './menu'
import { LinkMenuItem, LinkMenuItemProps } from './menu-item'

export const MenuItemBack = ({ to, primaryText, primaryTextKey, leftIcon }: LinkMenuItemProps) => {
  const classes = useMenuItemBackLinkStyles()
  return (
    <LinkMenuItem
      classes={classes}
      to={to}
      primaryText={primaryText}
      primaryTextKey={primaryTextKey}
      leftIcon={leftIcon || <ArrowLeftIcon />}
    />
  )
}
