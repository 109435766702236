import { useMemo } from 'react'
import { UseDataProviderOptions } from 'react-admin'
import { DbRecord } from '../../entity-types'
import { useStableGetOne } from './use-stable-get-one'

export const useGetOneById = <R extends DbRecord>(
  resource: string,
  id: string | undefined,
  options?: UseDataProviderOptions,
) => {
  const enabled = Boolean(id)

  const finalOptions = useMemo(
    () => ({
      enabled,
      ...options,
    }),
    [enabled, options],
  )
  const { data, loading, loaded, error } = useStableGetOne<R>(resource, id || '', finalOptions)
  return {
    data,
    loading: enabled && loading, // Fixes https://github.com/marmelab/react-admin/issues/6236
    loaded,
    error,
  }
}
