import { gql, useQuery } from '@apollo/client'
import { Box, Button, Card, makeStyles, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import React, { Dispatch, ReactElement, useCallback, useEffect } from 'react'
import { TextInput, useInput, useNotify, useTranslate } from 'react-admin'
import { useDomain } from '../../contexts/domain-context'
import { useBooleanState } from '../../hook/use-boolean-state'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4),
    padding: theme.spacing(2),
  },
  existantComplementsContainer: {
    display: 'flex',
    justifyContent: 'center',
    background: theme.palette.background.paper,
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  complementButton: {
    margin: theme.spacing(1),
  },
}))

export const AdressComplementInput = (): ReactElement | null => {
  const translate = useTranslate()
  const classes = useStyles()
  const notify = useNotify()
  const {
    input: { value: fullAddress },
  } = useInput({ source: 'full_address' })
  const domain = useDomain()

  const { data, error } = useQuery<{ addressComplements: string[] }>(complementQuery, {
    variables: {
      domain: domain?.id,
      fixedAddress: fullAddress,
    },
    fetchPolicy: 'network-only', // Don't cache result, or it may miss newly-added addresses
    skip: !fullAddress || !domain?.id,
  })

  const existingComplements = data?.addressComplements

  useEffect(() => {
    if (error) {
      notify(error.message, 'error')
    }
  }, [error, notify])

  if (!fullAddress) {
    return null
  }
  return (
    <Card className={classes.root}>
      <Typography variant="h2">{translate('resources.Address.complementPicker.title')}</Typography>
      {!error && existingComplements && <ComplementInput choices={existingComplements} />}
    </Card>
  )
}

const complementQuery = gql`
  query GetAddressComplements($domain: String!, $fixedAddress: String!) {
    addressComplements(domain: $domain, fixed_address: $fixedAddress)
  }
`

const ComplementInput = ({ choices }: { choices: string[] }): ReactElement => {
  const translate = useTranslate()
  const classes = useStyles()
  const [creationBoxIsOpen, openCreationBox, closeCreationBox] = useBooleanState(
    choices.length === 0,
  )
  return (
    <>
      {choices.length > 0 && (
        <Box className={classes.existantComplementsContainer}>
          {choices.map((choice) => (
            <ComplementButton key={choice} value={choice} onSelected={closeCreationBox} />
          ))}
          {!creationBoxIsOpen && (
            <Button
              startIcon={<AddIcon />}
              variant="outlined"
              className={classes.complementButton}
              onClick={openCreationBox}
            >
              {translate('resources.Address.complementPicker.createNewButton')}
            </Button>
          )}
        </Box>
      )}
      {creationBoxIsOpen && (
        <TextInput
          fullWidth
          source="complement"
          label={translate('resources.Address.complementPicker.createNew')}
          helperText={translate('resources.Address.helperTexts.complement')}
        />
      )}
    </>
  )
}

const ComplementButton = ({
  value,
  onSelected,
}: {
  value: string
  onSelected: Dispatch<string>
}): ReactElement => {
  const classes = useStyles()
  const {
    input: { onChange, value: currentValue },
  } = useInput({ source: 'complement' })
  const handleClick = useCallback(() => {
    onChange(value)
    onSelected(value)
  }, [onChange, value, onSelected])
  return (
    <Button
      variant="contained"
      className={classes.complementButton}
      color={currentValue === value ? 'primary' : undefined}
      onClick={handleClick}
    >
      {value}
    </Button>
  )
}
