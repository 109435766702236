import React, { FC, useEffect } from 'react'
import { Pagination as RAPagination, useListContext } from 'react-admin'

const Pagination: FC = (props: any) => {
  const { perPage, setPerPage } = useListContext()

  useEffect(() => {
    const localPerPage = localStorage.getItem('perPage')
    if (localPerPage) {
      const newPerPage = +localPerPage
      if (newPerPage !== perPage) {
        setPerPage(newPerPage)
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    localStorage.setItem('perPage', String(perPage))
  }, [perPage])

  if (props.total === undefined) {
    return null
  }

  return <RAPagination rowsPerPageOptions={[10, 20, 50, 200]} {...props} />
}

export default Pagination
