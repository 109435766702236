import { values } from 'lodash'
import { useMemo } from 'react'
import { Record, UseDataProviderOptions } from 'react-admin'
import { useStableGetList } from './use-stable-get-list'

/**
 * Just like useGetOne but allows to search by a custom filter rather than by an id.
 */

const pagination = { page: 1, perPage: 1 }

export const useGetOneByFilter = <RecordType extends Record = Record>(
  resource: string,
  filter: { [key: string]: unknown },
  options?: UseDataProviderOptions,
): {
  data: RecordType
  error?: any
  loading: boolean
  loaded: boolean
} => {
  const { data, error, loading, loaded } = useStableGetList<RecordType>(
    resource,
    pagination,
    undefined,
    filter,
    options,
  )
  const record = useMemo(() => values(data)[0], [data])
  return {
    data: record,
    error,
    loading,
    loaded,
  }
}
