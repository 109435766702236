import React, { useMemo } from 'react'
import { Loading } from 'react-admin'
import { Permission } from '../../../auth-provider'
import { AddressReferenceSelectOrCreate } from '../../common/address-reference-select-or-create'
import { ASSOCIATION_RESOURCE_NAME, MISSION_RESOURCE_NAME } from '../../constants'
import { useAssociation } from '../../contexts/association-context'
import { useMission } from '../../contexts/mission-context'
import { useThisMissionUrls } from '../../hook/use-this-mission-urls'
import {
  MissionsDisabledMessage,
  useAreDomainMissionsEnabled,
} from '../../hook/useAreDomainMissionsEnabled'
import { missionDocEditTransform } from './mission'

export const MissionAddressSelectOrCreate = ({
  basePath,
  permissions,
}: {
  basePath: string
  permissions: Permission
}) => {
  const areDomainMissionsEnabled = useAreDomainMissionsEnabled()
  const thisMissionUrls = useThisMissionUrls()
  const association = useAssociation()
  const mission = useMission()
  const missionId = mission?.id

  const sourceAssociationIds = useMemo(() => [association?.id], [association])

  if (!association) {
    return null
  }
  if (!missionId) {
    return null
  }
  if (!areDomainMissionsEnabled) {
    return <MissionsDisabledMessage />
  }

  if (!permissions) {
    return <Loading />
  }

  return (
    <AddressReferenceSelectOrCreate
      resource={MISSION_RESOURCE_NAME}
      id={missionId}
      basePath={basePath}
      editTransform={missionDocEditTransform}
      onSuccessRedirectTo={thisMissionUrls?.address.list}
      existingAddressSourceResource={ASSOCIATION_RESOURCE_NAME}
      existingAddressSourceIds={sourceAssociationIds as string[]}
    />
  )
}
