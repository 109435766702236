import React from 'react'
import { ADDRESS_RESOURCE_NAME } from '../../constants'
import { AddressShow } from '../address/show'

export const ActivityAddressShow = ({ basePath, id }: { basePath: string; id: string }) => {
  return (
    <AddressShow
      resource={ADDRESS_RESOURCE_NAME}
      id={id}
      ownerType="Activity"
      basePath={basePath}
      edit={false}
    />
  )
}
