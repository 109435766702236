import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { BoxedShowLayout } from 'ra-compact-ui'
import React from 'react'
import {
  Button,
  EditButtonProps,
  ImageField,
  Show,
  TextField,
  UrlField,
  useTranslate,
} from 'react-admin'
import { Link } from 'react-router-dom'
import { DbRecord } from '../../../entity-types'
import { useModelStyles } from '../../../helpers/model-styles'
import { NonInput } from '../../common/non-input'
import PortailLinkButton from '../../common/portail-link-button'
import { TopToolbar, useToolbarStyles } from '../../common/toolbar'
import { useDomain } from '../../contexts/domain-context'
import { useIsAtLeastDomainRole } from '../../hook/use-is-at-least-domain-role'
import { useThisDomainUrls } from '../../hook/use-this-domain-urls'

export const EditoShow = (props: any) => {
  const translate = useTranslate()
  const classes = useModelStyles()

  const domain = useDomain()

  if (!useIsAtLeastDomainRole() || !domain) {
    return null
  }

  return (
    <Show
      title={translate('resources.Domain.name')}
      {...props}
      id={domain.id}
      actions={<Actions />}
      classes={{ card: classes.card }}
    >
      <BoxedShowLayout className={classes.show}>
        <NonInput>
          <Typography component="h1">{translate('resources.Domain.Edito.title')}</Typography>
          <Typography component="h3">{translate('resources.Domain.Edito.subtitle')}</Typography>
          <Divider />
        </NonInput>
        <TextField source="home_about_title" />
        <TextField source="home_about_text" />
        <ImageField source="home_about_image" title="home_about" />
        <UrlField source="home_about_url" emptyText="-" />
      </BoxedShowLayout>
    </Show>
  )
}

const Actions = ({ basePath, data }: { basePath?: string; data?: DbRecord }) => {
  return (
    <TopToolbar>
      <PortailLinkButton />
      <EditButton record={data} basePath={basePath} />
    </TopToolbar>
  )
}

const EditButton = (props: EditButtonProps) => {
  const classes = useToolbarStyles()
  const domainUrls = useThisDomainUrls()
  return (
    <Button
      {...props}
      to={domainUrls?.edito.edit}
      component={Link}
      icon={<></>}
      className={classes.button}
      variant="contained"
      color="primary"
      label="ra.action.edit"
    />
  )
}
