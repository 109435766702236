import AnimationIcon from '@material-ui/icons/LineStyle'
import ParameterIcon from '@material-ui/icons/Settings'
import React from 'react'
import { URLS } from '../../routes/url-creators'
import {
  AddressIcon,
  AssociationIcon,
  ContactIcon,
  DomainIcon,
  DomainThemeIcon,
  EditoIcon,
  HighlightIcon,
  HomeIcon,
  ImportExportIcon,
  PartnerIcon,
  UsefulLinkIcon,
} from '../common/icons'
import { useDomain } from '../contexts/domain-context'
import { useIsAtLeastAdminRole } from '../hook/use-is-at-least-admin-role'
import { LinkMenuItem, StaticMenuItem } from './menu-item'
import { MenuItemBack } from './menu-item-back'

export const DomainMenu = () => {
  const domain = useDomain()
  const isAtLastAdmin = useIsAtLeastAdminRole()

  if (!domain) {
    return null
  }
  const thisDomainUrl = URLS.domain(domain.domain_name)
  return (
    <>
      <MenuItemBack to={URLS.domain.list} primaryTextKey="menu.domainMenu.Domain" />
      <LinkMenuItem
        to={thisDomainUrl.dashboard}
        primaryTextKey="menu.domainMenu.Dashboard"
        leftIcon={<HomeIcon />}
      />

      <StaticMenuItem primaryTextKey="menu.domainMenu.Animation" leftIcon={<AnimationIcon />} />
      <LinkMenuItem
        to={thisDomainUrl.edito.show}
        primaryTextKey="menu.domainMenu.edito"
        exact={false}
        leftIcon={<EditoIcon />}
        subItem={true}
      />
      <LinkMenuItem
        to={thisDomainUrl.highlight.list}
        primaryTextKey="menu.domainMenu.DomainHighlight"
        exact={false}
        leftIcon={<HighlightIcon />}
        subItem={true}
      />
      <LinkMenuItem
        to={thisDomainUrl.usefulLink.list}
        exact={false}
        primaryTextKey="menu.associationMenu.UsefulLink"
        leftIcon={<UsefulLinkIcon />}
        subItem={true}
      />
      <LinkMenuItem
        to={thisDomainUrl.association.list}
        primaryTextKey="menu.domainMenu.Association"
        exact={false}
        leftIcon={<AssociationIcon />}
      />
      <LinkMenuItem
        to={thisDomainUrl.contact.list}
        primaryTextKey="menu.domainMenu.Contact"
        exact={false}
        leftIcon={<ContactIcon />}
      />
      <LinkMenuItem
        to={thisDomainUrl.address.list}
        primaryTextKey="menu.domainMenu.Address"
        exact={false}
        leftIcon={<AddressIcon />}
      />

      <StaticMenuItem primaryTextKey="menu.domainMenu.Parameters" leftIcon={<ParameterIcon />} />
      <LinkMenuItem
        to={thisDomainUrl.show}
        primaryTextKey="menu.domainMenu.GeneralParameters"
        leftIcon={<DomainIcon />}
        subItem={true}
      />
      <LinkMenuItem
        to={thisDomainUrl.theme.show}
        primaryTextKey="menu.domainMenu.theme"
        leftIcon={<DomainThemeIcon />}
        subItem={true}
      />
      <LinkMenuItem
        to={thisDomainUrl.missionSettings}
        primaryTextKey="menu.domainMenu.MissionSettings"
        exact={false}
        leftIcon={<ParameterIcon />}
        subItem={true}
      />
      <LinkMenuItem
        to={thisDomainUrl.partner.list}
        primaryTextKey="menu.domainMenu.DomainPartner"
        exact={false}
        leftIcon={<PartnerIcon />}
        subItem={true}
      />
      {isAtLastAdmin && (
        <LinkMenuItem
          to={thisDomainUrl.importExport}
          primaryTextKey="menu.domainMenu.ImportExport"
          leftIcon={<ImportExportIcon />}
          subItem={true}
        />
      )}
    </>
  )
}
