import { get, set } from 'lodash'
import { CREATE, UPDATE } from 'react-admin'

export const convertFileToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file.rawFile)

    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })
}

export const uploadDataProvider = (
  dataProvider: any,
  references: Record<string, string | string[]>[],
) => async (fetchType: any, resource: any, params: any) => {
  if (fetchType === CREATE || fetchType === UPDATE) {
    for (const reference of references) {
      if (resource === reference.resource) {
        const { data, ...rest_params } = params
        for (const path of reference.fieldnames) {
          const payload = get(data, path)
          if (payload?.src) {
            set(data, path, await convertFileToBase64(payload))
          }
        }
        return dataProvider(fetchType, resource, {
          ...rest_params,
          data,
        })
      }
    }
  }
  return dataProvider(fetchType, resource, params)
}
