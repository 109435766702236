import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import {
  BooleanInput,
  Create,
  ImageField,
  ImageInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import { useModelStyles } from '../../../helpers/model-styles'
import EditToolbar from '../../common/edit-toolbar'
import { NonInput } from '../../common/non-input'
import { DomainType } from './domain'

export const DomainCreate = (props: unknown) => {
  const translate = useTranslate()
  const classes = useModelStyles()

  return (
    <Create {...props} classes={{ card: classes.card }}>
      <SimpleForm redirect="list" toolbar={<EditToolbar disableLink />} className={classes.edit}>
        <NonInput>
          <Typography component="h1">{translate('resources.Domain.title')}</Typography>
          <Typography component="h3">{translate('resources.Domain.subtitle')}</Typography>
          <Divider />
          <Typography component="h2">{translate('resources.Domain.tabs.1')}</Typography>
        </NonInput>
        <ImageInput
          source="logo_url"
          accept="image/*"
          validate={[required()]}
          helperText="resources.Domain.helperTexts.logo_url"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput
          variant="outlined"
          source="domain_name"
          validate={[required()]}
          helperText="resources.Domain.helperTexts.domain_name"
        />
        <TextInput
          variant="outlined"
          source="title"
          validate={[required()]}
          helperText="resources.Domain.helperTexts.title"
        />
        <SelectInput
          variant="outlined"
          source="type"
          choices={DomainType}
          helperText="resources.Domain.helperTexts.type"
        />
        <TextInput
          variant="outlined"
          source="outlink_url"
          validate={[required()]}
          helperText="resources.Domain.helperTexts.outlink_url"
        />
        <BooleanInput
          source="published"
          initialValue={false}
          helperText="resources.Domain.helperTexts.published"
        />
        <NonInput>
          <Divider />
          <Typography component="h2">{translate('resources.Domain.tabs.2')}</Typography>
        </NonInput>
        <TextInput
          variant="outlined"
          source="home_title"
          validate={[required()]}
          helperText="resources.Domain.helperTexts.home_title"
        />
        <TextInput
          variant="outlined"
          source="home_subtitle"
          validate={[required()]}
          helperText="resources.Domain.helperTexts.home_subtitle"
        />
        <TextInput
          variant="outlined"
          source="home_about_title"
          helperText="resources.Domain.helperTexts.home_about_title"
        />
        <TextInput
          variant="outlined"
          multiline
          source="home_about_text"
          helperText="resources.Domain.helperTexts.home_about_text"
        />
        <ImageInput
          source="home_about_image"
          accept="image/*"
          helperText="resources.Domain.helperTexts.home_about_image"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput
          variant="outlined"
          source="home_about_url"
          helperText="resources.Domain.helperTexts.home_about_url"
        />
        <NonInput>
          <Divider />
          <Typography component="h2">{translate('resources.Domain.tabs.3')}</Typography>
        </NonInput>
        <TextInput
          variant="outlined"
          multiline
          source="seo_description"
          fullWidth
          helperText="resources.Domain.helperTexts.seo_description"
        />
        <TextInput
          variant="outlined"
          source="seo_keywords"
          helperText="resources.Domain.helperTexts.seo_keywords"
        />
        <TextInput
          variant="outlined"
          source="ga_key"
          helperText="resources.Domain.helperTexts.ga_key"
        />
      </SimpleForm>
    </Create>
  )
}
