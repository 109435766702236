import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { ReactElement } from 'react'
import {
  BooleanInput,
  Edit,
  EditProps,
  NumberInput,
  RedirectionSideEffect,
  required,
  SimpleForm,
  TextInput,
  usePermissions,
  useTranslate,
} from 'react-admin'
import { Permission } from '../../../auth-provider'
import { Activity } from '../../../entity-types'
import { modelStyles } from '../../../helpers/model-styles'
import { CustomButtonArrayInput } from '../../common/custom-button'
import EditToolbar from '../../common/edit-toolbar'
import { NonInput } from '../../common/non-input'
import { TagReferenceArrayInput } from '../../common/tag-reference-array-input'
import { RecordMiddleware } from '../domain/record-middleware'
import { TagType } from '../tag'
import { activityDocTransform } from './activity'

const useModelStyles = makeStyles(modelStyles)

const tagTypes = [TagType.Main, TagType.Normal]

const createInitialFormRecord = ({ tags, ...otherFields }: Activity): Activity => {
  const res = ({
    ...otherFields,
    tags: (tags || []).map((tag) => tag.id),
  } as any) as Activity
  return res
}

export const ActivityEditForm = ({
  id,
  children,
  redirect,
  toolbar,
  ...props
}: {
  children: ReactElement | ReactElement[]
  toolbar?: ReactElement
  redirect?: RedirectionSideEffect
} & EditProps) => {
  const translate = useTranslate()
  const modelClasses = useModelStyles()
  const { permissions } = usePermissions() as { permissions: Permission }

  if (!permissions) {
    return null
  }

  return (
    <Edit
      id={id}
      title={translate('resources.Activity.name')}
      {...props}
      undoable={false}
      transform={activityDocTransform}
      classes={{ card: modelClasses.card }}
      actions={false}
    >
      <RecordMiddleware<Activity> transform={createInitialFormRecord}>
        <SimpleForm redirect={redirect} toolbar={toolbar} className={modelClasses.edit}>
          {children}
        </SimpleForm>
      </RecordMiddleware>
    </Edit>
  )
}

export const ActivityEdit = (props: { id?: string }) => {
  const translate = useTranslate()
  return (
    <ActivityEditForm
      {...props}
      redirect="show"
      toolbar={<EditToolbar showDisableButton={false} />}
    >
      <NonInput>
        <Typography component="h1">{translate('resources.Activity.title')}</Typography>
        <Typography component="h3">{translate('resources.Activity.subtitle')}</Typography>
        <Divider />
      </NonInput>
      <TextInput
        variant="outlined"
        source="title"
        validate={[required()]}
        helperText="resources.Activity.helperTexts.title"
      />
      <TextInput
        variant="outlined"
        multiline
        source="description_text"
        fullWidth
        helperText="resources.Activity.helperTexts.description_text"
      />
      <TextInput
        variant="outlined"
        multiline
        source="pricing_text"
        helperText="resources.Activity.helperTexts.pricing_text"
      />
      <BooleanInput
        source="disabled"
        initialValue={false}
        helperText="resources.Activity.helperTexts.disabled"
      />
      <TextInput
        variant="outlined"
        source="trainer_name"
        helperText="resources.Activity.helperTexts.trainer_name"
      />
      <NumberInput
        variant="outlined"
        source="age_min"
        helperText="resources.Activity.helperTexts.age_min"
      />
      <NumberInput
        variant="outlined"
        source="age_max"
        helperText="resources.Activity.helperTexts.age_max"
      />
      <TagReferenceArrayInput
        source="tags"
        maxTagCount={6}
        helperText="resources.Activity.helperTexts.tags"
        tagTypes={tagTypes}
      />
      <CustomButtonArrayInput source="customButtons" />
    </ActivityEditForm>
  )
}
