import { some } from 'lodash'
import React, { FC, ReactElement, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { DbRecord } from '../../entity-types'
import { useRedirectIfNotFound } from '../hook/use-redirect-if-not-found'
import { useStableGetOne } from '../hook/use-stable-get-one'

/**
 * Create a ContextProvider which maps an id in the URL to an entity made
 * available to the code with a context.
 */

type EntityId = string

interface EntityContextValue<E extends DbRecord> {
  // The entity if it was found.
  entity?: E
  // If true, it means that the url matches an id, but the corresponding entity is still loading.
  // If the url doesn't match an id, `isLoading` stays `false`.
  isLoading: boolean
  // If true, it means that the url matches an id, even if the entity is not loaded yet.
  isMatch: boolean
}

type DataLoaderHook<E> = (
  resource: string,
  id?: EntityId,
) => { data: E; loading: boolean; loaded: boolean; error?: Error }

const useDefaultLoaderHook = <E extends DbRecord>(resource: string, id?: EntityId) => {
  return useStableGetOne<E>(resource, id || '', {
    enabled: Boolean(id),
  })
}

interface EntityContextProps {
  children: ReactElement
}

interface CreateContextFromUrlReturn<E extends DbRecord> {
  // Return the full context. Useful if you need the `isLoading` information.
  useEntityContext: () => EntityContextValue<E>
  // Return the entity in the context. Can be undefined if not loaded yet.
  useEntity: () => E | undefined
  // ContextProvider with which you should wrap your app.
  EntityContextFromUrl: FC<EntityContextProps>
}

export const createContextFromUrl = <E extends DbRecord>(
  // Resource from which it should load the entity.
  resource: string,
  // React Context on which the context must be bound.
  reactContext: React.Context<EntityContextValue<E>>,
  // Guess the entity id from the url. You can use `matchPath` from react-router-dom
  getIdFromPathName: (pathname: string) => EntityId | undefined,
  options: {
    // Blacklist of the strings that are not real ids and should be ignored.
    // e.g. in /Domain/123, the id is `123`, but in /Domain/Create, `Create` is not an id.
    idParamsBlacklist?: string[]
    // By default, the entity is searched by id using useGetOne().
    // If you need to search the entity by another field (e.g. by name), you can provide your own name.
    useCustomLoader?: DataLoaderHook<E>
  } = {},
): CreateContextFromUrlReturn<E> => {
  const useEntityContext = (): EntityContextValue<E> => {
    return useContext(reactContext)
  }

  const useEntity = (): E | undefined => {
    const ctx = useEntityContext()
    if (!ctx) {
      throw Error(`Trying to use useEntity (resource = ${resource}) out of a EntityContext.`)
    }
    return ctx.entity
  }

  const paramsBlacklistRegexps = (options.idParamsBlacklist || []).map(
    (val) => new RegExp(`^${val}$`, 'i'),
  )

  const EntityContextFromUrl = ({ children }: EntityContextProps): ReactElement | null => {
    const { pathname } = useLocation()
    const paramsEntityId = getIdFromPathName(pathname)
    const entityId =
      paramsEntityId && some(paramsBlacklistRegexps, (regexp) => paramsEntityId.match(regexp))
        ? undefined
        : paramsEntityId

    const { data: entity, loading, loaded, error } = (
      options.useCustomLoader || useDefaultLoaderHook
    )<E>(resource, entityId)

    useRedirectIfNotFound('/', entityId, entity, loading, loaded, error)

    useEffect(() => {
      if (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }, [error])

    return (
      <reactContext.Provider
        value={{
          entity,
          isLoading: entityId !== undefined && !entity,
          isMatch: entityId !== undefined || entity !== undefined,
        }}
      >
        {children}
      </reactContext.Provider>
    )
  }

  return {
    useEntityContext,
    useEntity,
    EntityContextFromUrl,
  }
}
