import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { BoxedShowLayout, RaBox } from 'ra-compact-ui'
import React from 'react'
import {
  Edit,
  ImageField,
  ImageInput,
  ReactAdminComponentProps,
  required,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import { DomainHighlight } from '../../../entity-types'
import { useModelStyles } from '../../../helpers/model-styles'
import EditToolbar from '../../common/edit-toolbar'
import { NonInput } from '../../common/non-input'
import { StatusToggleInput } from '../../common/status-toggle-input'
import { useIsAtLeastDomainRole } from '../../hook/use-is-at-least-domain-role'
import { RecordMiddleware } from '../domain/record-middleware'
import { useDomainHighlightFormStyles } from './domain-highlight'
import { DomainHighlightOptionalDescriptionInput } from './form-components/optional-description-input'
import { DomainHighlightSelectionBuilder } from './form-components/selection-builder'
import {
  domainHighlightDocEditTransform,
  domainHighlightGuessSelectionTypeFromRecord,
  DOMAIN_HIGHLIGHT_SELECTION_TYPE_FIELD,
  USE_DESCRIPTION_DUMMY_FIELD,
} from './transform'

export const DomainHighlightEdit = (props: ReactAdminComponentProps) => {
  const modelClasses = useModelStyles()
  const translate = useTranslate()
  const classes = useDomainHighlightFormStyles()

  if (!useIsAtLeastDomainRole()) {
    return null
  }

  return (
    <Edit
      {...props}
      undoable={false}
      classes={{ card: modelClasses.card }}
      transform={domainHighlightDocEditTransform}
    >
      <RecordMiddleware<DomainHighlight> transform={createInitialFormRecord}>
        <SimpleForm
          redirect="list"
          toolbar={<EditToolbar disableLink />}
          className={modelClasses.edit}
        >
          <BoxedShowLayout>
            <NonInput>
              <Typography component="h1">{translate('resources.DomainHighlight.title')}</Typography>
              <Typography component="h3">
                {translate('resources.DomainHighlight.subtitle')}
              </Typography>
              <Divider />
              <Typography component="h2">
                {translate('resources.DomainHighlight.tabs.1')}
              </Typography>
            </NonInput>
            <ImageInput source="preview_img_url" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
            <TextInput
              variant="outlined"
              source="name"
              validate={[required()]}
              className={classes.titleInput}
              fullWidth
            />
            <DomainHighlightOptionalDescriptionInput />
            <NonInput>
              <Divider />
              <Typography component="h2">
                {translate('resources.DomainHighlight.tabs.2')}
              </Typography>
            </NonInput>
            <RaBox className={classes.raBoxRow}>
              <RaBox>
                <TextInput variant="outlined" source="button_title" />
              </RaBox>
              <RaBox>
                <TextInput variant="outlined" source="button_link" />
              </RaBox>
            </RaBox>
          </BoxedShowLayout>
          <NonInput>
            <Divider />
            <Typography component="h2">{translate('resources.DomainHighlight.tabs.3')}</Typography>
          </NonInput>
          <DomainHighlightSelectionBuilder />
          <NonInput>
            <Divider />
          </NonInput>
          <StatusToggleInput source="disabled" gender="feminine" />
        </SimpleForm>
      </RecordMiddleware>
    </Edit>
  )
}

const createInitialFormRecord = (record: DomainHighlight): DomainHighlight => {
  const { preview_img_url: _, ...otherFields } = record
  return ({
    ...otherFields,
    [USE_DESCRIPTION_DUMMY_FIELD]: Boolean(record && record?.description_text),
    [DOMAIN_HIGHLIGHT_SELECTION_TYPE_FIELD]: domainHighlightGuessSelectionTypeFromRecord(record),
    // The backend sends us objects instead of ids... so we convert it manually for now...
    associations: (record?.associations || []).map((record) => record.id),
    activities: (record?.activities || []).map((record) => record.id),
    missions: (record?.missions || []).map((record) => record.id),
    age_sub_groups: (record?.age_sub_groups || []).map((record) => record.id),
    schedule_groups: (record?.schedule_groups || []).map((record) => record.id),
    tags: (record?.tags || []).map((record) => record.id),
  } as any) as DomainHighlight
}
