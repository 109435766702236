import { Typography } from '@material-ui/core'
import React, { useMemo } from 'react'
import { Link, useRecordContext } from 'react-admin'
import {
  Activity,
  Address,
  Association,
  AssociationId,
  Contact,
  DbRecord,
  DomainHistory,
  Mission,
} from '../../../../entity-types'
import { URLS } from '../../../../routes/url-creators'
import {
  ACTIVITY_RESOURCE_NAME,
  ADDRESS_RESOURCE_NAME,
  ASSOCIATION_RESOURCE_NAME,
  CONTACT_RESOURCE_NAME,
  MISSION_RESOURCE_NAME,
} from '../../../constants'
import { useGetOneByFilter } from '../../../hook/use-get-one-by-filter'
import { useThisDomainUrls } from '../../../hook/use-this-domain-urls'
import { useActivityHierarchy } from '../../../hook/useActivityHierarchy'
import { useAssociationHierarchy } from '../../../hook/useAssociationHierarchy'
import { useMissionHierarchy } from '../../../hook/useMissionHierarchy'

export enum HistoryTargetType {
  ASSOCIATION = 'ASSOCIATION',
  ACTIVITY = 'ACTIVITY',
  MISSION = 'MISSION',
  ADDRESS = 'ADDRESS',
  CONTACT = 'CONTACT',
}

export const ALL_HISTORY_TARGET_TYPES = [
  HistoryTargetType.ASSOCIATION,
  HistoryTargetType.ACTIVITY,
  HistoryTargetType.MISSION,
  HistoryTargetType.ADDRESS,
  HistoryTargetType.CONTACT,
]

export const resourcePerHistoryTargetType: Record<HistoryTargetType, string> = {
  [HistoryTargetType.ASSOCIATION]: ASSOCIATION_RESOURCE_NAME,
  [HistoryTargetType.ACTIVITY]: ACTIVITY_RESOURCE_NAME,
  [HistoryTargetType.MISSION]: MISSION_RESOURCE_NAME,
  [HistoryTargetType.ADDRESS]: ADDRESS_RESOURCE_NAME,
  [HistoryTargetType.CONTACT]: CONTACT_RESOURCE_NAME,
}

const useHistoryTargetId = () => {
  const record = useRecordContext() as DomainHistory
  const entityId = record.target_id
  if (!entityId) {
    throw Error(`Missing target_id on record ${JSON.stringify(record)}`)
  }
  return entityId
}

const useHistoryTargetType = () => {
  const record = useRecordContext() as DomainHistory
  const entityType = record.target_type as HistoryTargetType
  if (!entityType) {
    throw Error(`Missing target_type on record ${JSON.stringify(record)}`)
  }
  return entityType
}

export const useHistoryTargetResource = () => {
  const entityType = useHistoryTargetType()
  const resource = resourcePerHistoryTargetType[entityType]
  if (!resource) {
    throw Error(`Unknown history target type ${entityType}`)
  }
  return resource
}

export const EntityNameField = (_props: any) => {
  const diffRecord = useRecordContext() as DomainHistory
  const entityId = useHistoryTargetId()
  const resource = useHistoryTargetResource()
  const entityType = useHistoryTargetType()
  const filter = useMemo(
    () => ({
      ids: [entityId],
    }),
    [entityId],
  )
  const { data, loading } = useGetOneByFilter(resource, filter)

  if (loading) {
    return null
  }

  // If the `data` (referenced record) no longer exist, we use the last known value.
  const record = (data || { ...diffRecord.before, id: entityId }) as DbRecord | undefined

  if (!record) {
    return <span>(Entité untrouvable ({entityType}))</span>
  }

  switch (entityType) {
    case HistoryTargetType.ASSOCIATION:
      return <AssociationNameField associationId={(record as Association).id} />
    case HistoryTargetType.ACTIVITY:
      return <ActivityNameField activity={record as Activity} />
    case HistoryTargetType.MISSION:
      return <MissionNameField mission={record as Mission} />
    case HistoryTargetType.CONTACT:
      return <ContactNameField contact={record as Contact} />
    case HistoryTargetType.ADDRESS:
      return <AddressNameField address={record as Address} />
    default:
      return <span>(Entité non supportée ({entityType}))</span>
  }
}

export const AssociationNameField = ({ associationId }: { associationId: AssociationId }) => {
  const { domain, association } = useAssociationHierarchy(associationId)
  if (!domain || !association) {
    return null
  }
  return (
    <Link to={URLS.domain(domain.domain_name).association(associationId).show}>
      {association.name}
    </Link>
  )
}

const ActivityNameField = ({ activity }: { activity: Activity }) => {
  const { domain, association, loading } = useActivityHierarchy(activity.id)

  if (loading) {
    return null
  }

  const url =
    domain &&
    association &&
    URLS.domain(domain.domain_name).association(association.id).activity(activity.id).show

  const name = activity.title

  if (!url) {
    return <Typography>{name}</Typography>
  } else {
    return <Link to={url}>{name}</Link>
  }
}

const MissionNameField = ({ mission }: { mission: Mission }) => {
  const { domain, association } = useMissionHierarchy(mission.id)
  if (!domain || !association) {
    return null
  }
  return (
    <Link to={URLS.domain(domain.domain_name).association(association.id).mission(mission.id).show}>
      {mission.title}
    </Link>
  )
}

const ContactNameField = ({ contact }: { contact: Contact }) => {
  const domainUrls = useThisDomainUrls()
  if (!domainUrls) {
    return null
  }
  return (
    <Link to={domainUrls.contact(contact.id).show}>
      {`${contact.firstname || ''} ${contact.lastname || ''}`}
    </Link>
  )
}

const AddressNameField = ({ address }: { address: Address }) => {
  const domainUrls = useThisDomainUrls()
  if (!domainUrls) {
    return null
  }
  return <Link to={domainUrls.address(address.id).show}>{address.full_address}</Link>
}
