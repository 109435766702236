import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import React, { FC, useEffect } from 'react'
import {
  HandleSubmitWithRedirect,
  SaveButton,
  TopToolbar,
  useMutation,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin'
import PortailLinkButton from './portail-link-button'

const useStyles = makeStyles({
  toolbar: {
    position: 'absolute',
    top: '56px',
    right: '24px',
    paddingTop: '10px',
    paddingRight: '10px',
  },
  button: {
    margin: '5px',
  },
})

interface Props {
  basePath?: string
  disableLink?: boolean
  showDisableButton?: boolean
  record?: Record<string, any>
  resource?: string
  handleSubmitWithRedirect?: HandleSubmitWithRedirect
}

const EditToolbar: FC<Props> = (props: Props) => {
  const translate = useTranslate()
  const notify = useNotify()
  const refresh = useRefresh()
  const classes = useStyles()

  const { record = {}, resource, showDisableButton } = props
  const disableMapping: Record<string, string> = {
    Association: 'disabled',
    Activity: 'disabled',
    Contact: 'hidden',
  }
  const fieldname = resource && disableMapping[resource]

  const payload: { id: string; data: Record<string, string | boolean> } = {
    id: record.id,
    data: { id: record.id },
  }

  if (record.domain) {
    payload.data.domain = record.domain.id
  }

  if (record.association) {
    payload.data.association = record.association.id
  }

  if (record.activity) {
    payload.data.activity = record.activity.id
  }

  if (fieldname) {
    payload.data[fieldname] = !record[fieldname]
  }

  const [toogleDisabled, { loading, data: success, error }] = useMutation({
    type: 'update',
    resource,
    payload,
  })

  useEffect(() => {
    if (success) {
      if (fieldname && success[fieldname]) {
        notify(`${translate(`resources.${resource}.name`)} ${translate('texts.disable')}`, 'info')
      } else {
        notify(`${translate(`resources.${resource}.name`)} ${translate('texts.enable')}`, 'info')
      }
      refresh()
    }
    if (error) {
      notify(error, 'warning')
    }
    // eslint-disable-next-line
  }, [success, error])

  return (
    <TopToolbar className={classes.toolbar}>
      {showDisableButton !== false && fieldname ? (
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          onClick={toogleDisabled}
          disabled={loading}
        >
          {record[fieldname]
            ? translate(`resources.${resource}.button.enable`)
            : translate(`resources.${resource}.button.disable`)}
        </Button>
      ) : null}
      {props.disableLink ? null : (
        <PortailLinkButton className={classes.button} variant="outlined" color="primary" />
      )}
      <SaveButton
        className={classes.button}
        variant="contained"
        icon={<></>}
        handleSubmitWithRedirect={props.handleSubmitWithRedirect}
      />
    </TopToolbar>
  )
}

export default EditToolbar
