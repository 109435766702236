import { makeStyles, Typography } from '@material-ui/core'
import React, { ReactElement, useMemo } from 'react'
import { Filter, TextInput, TopToolbar, useTranslate } from 'react-admin'
import { CONTACT_RESOURCE_NAME } from '../constants'
import { ContactList } from '../models/contact/list'
import {
  contactXlsExportFieldOptions,
  contactXlsExportTransform,
} from '../models/contact/xls-contact-export-button'
import { DefaultShowButton } from './default-buttons'
import {
  PrimarySecondaryReferenceListInput,
  ReferencedEntityListComponentProps,
} from './primary-secondary-reference-list-input'

/**
 * Contact list editing for an entity that holds a unique `primary_contact` and multiple `contacts`.
 */

interface ContactListInputProps {
  resource: string
  id: string
  basePath?: string
  contactBasePath: string
  editTransform?: (val: any) => any
  editContact?: boolean
}

export const ContactListInput = ({
  resource,
  id,
  basePath,
  contactBasePath,
  editTransform,
  editContact,
}: ContactListInputProps) => {
  return (
    <PrimarySecondaryReferenceListInput
      parentResource={resource}
      referencedEntityResource={CONTACT_RESOURCE_NAME}
      id={id}
      basePath={basePath}
      referencedEntitiesBasePath={contactBasePath}
      primaryReferencedEntityField="primary_contact"
      allReferencedEntityField="contacts"
      editTransform={editTransform}
      referencedEntityListComponent={MyContactList}
      xlsExportFieldOptions={contactXlsExportFieldOptions}
      xlsExportTransform={contactXlsExportTransform}
      editReference={editContact}
    />
  )
}

const useContactListStyles = makeStyles({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
  },
})

const MyContactList = ({
  referencedEntitiesBasePath,
  sort,
  filter,
  actions,
  resource,
  extraColumnsMid,
  extraColumnsEnd,
  datagridIdSort,
  title,
  edit,
}: ReferencedEntityListComponentProps) => {
  const classes = useContactListStyles()
  const finalExtraColumnsMid = useMemo(
    () => [
      ...(extraColumnsMid || []),
      <DefaultShowButton key="show" basePath={referencedEntitiesBasePath} />,
    ],
    [referencedEntitiesBasePath, extraColumnsMid],
  )
  return (
    <ContactList
      className={classes.root}
      sort={sort}
      filter={filter}
      basePath={referencedEntitiesBasePath}
      resource={resource}
      datagridIdSort={datagridIdSort}
      actions={actions || false}
      emptyPage={<EmptyPage resource={resource} actions={actions} />}
      filters={
        <Filter variant="outlined">
          <TextInput source="q" alwaysOn />
        </Filter>
      }
      disableModify={edit === false}
      disableDelete={true}
      disableShow={true}
      extraColumnsMid={finalExtraColumnsMid}
      extraColumnsEnd={extraColumnsEnd}
      title={title}
    />
  )
}

const EmptyPage = ({ resource, actions }: { resource: string; actions?: ReactElement | false }) => {
  const translate = useTranslate()
  return (
    <>
      <TopToolbar>{actions}</TopToolbar>
      <Typography component="h2">{translate(`resources.${resource}.emptyTitle`)}</Typography>
      <Typography component="h3">{translate(`resources.${resource}.emptySubtitle`)}</Typography>
    </>
  )
}
