import React from 'react'
import { Loading } from 'react-admin'
import { Permission } from '../../../auth-provider'
import { ContactListInput } from '../../common/contact-list-input'
import { ASSOCIATION_RESOURCE_NAME } from '../../constants'
import { useAssociation } from '../../contexts/association-context'
import { useThisAssociationUrls } from '../../hook/use-this-association-urls'
import { associationDocTransform } from './association'

export const AssociationContactList = ({
  basePath,
  permissions,
}: {
  basePath?: string
  permissions?: Permission
}) => {
  const associationUrls = useThisAssociationUrls()
  const association = useAssociation()
  const associationId = association?.id

  if (!associationId || !associationUrls) {
    return null
  }

  if (!permissions) {
    return <Loading />
  }

  return (
    <ContactListInput
      resource={ASSOCIATION_RESOURCE_NAME}
      id={associationId}
      basePath={basePath}
      contactBasePath={associationUrls.contact.basePath}
      editTransform={associationDocTransform}
    />
  )
}
