import React from 'react'
import { Permission } from '../../../auth-provider'
import { ContactReferenceSelectOrCreate } from '../../common/contact-reference-select-or-create'
import { ASSOCIATION_RESOURCE_NAME } from '../../constants'
import { useAssociation } from '../../contexts/association-context'
import { useThisAssociationUrls } from '../../hook/use-this-association-urls'
import { associationDocTransform } from './association'

export const AssociationContactSelectOrCreate = ({
  basePath,
  permissions,
}: {
  basePath: string
  permissions: Permission
}) => {
  const thisAssociationUrls = useThisAssociationUrls()
  const association = useAssociation()
  const associationId = association?.id

  if (!associationId || !permissions) {
    return null
  }

  return (
    <ContactReferenceSelectOrCreate
      resource={ASSOCIATION_RESOURCE_NAME}
      id={associationId}
      basePath={basePath}
      editTransform={associationDocTransform}
      onSuccessRedirectTo={thisAssociationUrls?.contact.list}
    />
  )
}
