import React from 'react'
import { ReferenceField, TextField } from 'react-admin'
import { TAG_RESOURCE_NAME } from '../constants'

interface tagReferenceFieldProps {
  source: string
  isLink?: boolean
}

export const TagReferenceField = ({ source, isLink }: tagReferenceFieldProps) => {
  return (
    <ReferenceField source={`${source}.id`} reference={TAG_RESOURCE_NAME} link={isLink || false}>
      <TextField source="name" />
    </ReferenceField>
  )
}

TagReferenceField.defaultProps = {
  addLabel: true,
}
