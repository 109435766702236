import polyglotI18nProvider from 'ra-i18n-polyglot'
import englishMessages from 'ra-language-english'
import frenchMessages from 'ra-language-french'
import { DOMAIN_HIGHLIGHT_SELECTION_TYPE_FIELD } from '../components/models/domain-highlight/transform'
import { PAGINATION_FILTER_IDS_SORT_FIELD } from '../helpers/paginatedFilterIdsSort'

const customFrenchMessages = {
  resources: {
    Domain: {
      name: 'Domaine',
      fields: {
        domain_name: 'Nom de domaine',
        title: 'Titre',
        type: 'Type',
        logo_url: 'Logo',
        home_title: 'Titre du portail',
        home_subtitle: 'Sous-titre du portail',
        home_about_title: "Titre de l'édito de la ville",
        home_about_text: 'Édito de la ville',
        home_about_image: "Illustration de l'édito",
        home_about_url: "Lien sur le bouton de l'édito",
        outlink_url: 'Lien vers le site de la ville',
        facebook_url: 'Lien vers la page Facebook',
        twitter_url: 'Lien vers la page Twitter',
        instagram_url: 'Lien vers la page Instagram',
        legal_notice_url: 'Lien vers mentions légales',
        contact_form_url: 'Lien vers formulaire de contact',
        published: 'Publié',
        seo_description: 'Description SEO du portail',
        seo_keywords: 'Mot-clés SEO du portail',
        ga_key: 'Clé API de Google Analytics',
        createdAt: 'Date de création',
        q: 'Rechercher',
        contacts: 'Contacts',
        addresses: 'Adresses',
        primary_contact: 'Contact principal',
        primary_address: 'Adresse principale',
        mission_description: 'Message',
        partner_banner_title: 'Titre du bandeau de liens',
        theme: {
          font: 'Police',
          header_bg_url: "URL de l'image d'arrière-plan",
          footer_bg_color: 'Couleur de fond',
          footer_fg_color: 'Couleur de text',
          primary_color: 'Couleur primaire',
          primary_text_color: 'Texte des boutons',
          text_color: 'Couleur de texte',
          header_fg_color: 'Couleur de texte',
        },
      },
      helperTexts: {
        domain_name: '',
        title: '',
        type: '',
        theme: {
          header_bg_url: 'PNG, JPG ou SVG - hauteur minimum 120px',
        },
        logo_url: 'PNG, JPG ou SVG - hauteur minimum 120px',
        home_title: '',
        home_subtitle: '',
        home_about_title: '',
        home_about_text: '',
        home_about_image: '',
        home_about_url: '',
        outlink_url: '',
        published: '',
        seo_description: '',
        seo_keywords: '',
        ga_key: '',
      },
      title: 'Domaine',
      subtitle:
        "C'est ici que je renseigne les informations principales du domaine dont je m'occupe.",
      emptyTitle: 'Pas encore de domaine',
      emptySubtitle: 'Voulez-vous en créer un ?',
      button: {
        create: 'Ajouter un domaine',
        open: 'Ouvrir',
      },
      tabs: {
        1: '1. Informations générales',
        2: "2. Page d'accueil",
        3: '3. SEO',
        4: '4. Déploiement',
      },
      Dashboard: {
        Cards: {
          Association: 'Associations',
          Activity: 'Activités',
          Contact: 'Contacts',
          Mission: 'Missions bénévolat',
        },
      },
      MissionSettings: {
        enableToggle: {
          enabledMessage: 'Bénévolat activé.',
          disabledMessage: 'Bénévolat désactivé.',
          frontendShowMessage: 'Bénévolat visible sur le portail.',
          frontendHideMessage: 'Bénévolat non-visible sur le portail.',
          enableButtonLabel: 'Activer',
          disableButtonLabel: 'Désactiver',
          enabledPlaceholder:
            'Les utilisateurs du back-office peuvent éditer les missions de bénévolat.',
          disabledPlaceholder:
            'Les utilisateurs du back-office ne peuvent pas éditer les missions de bénévolat.',
        },
        mission_description: "Personnaliser le message sur l'accueil du portail bénévolat",
      },
      Edito: {
        title: 'Édito',
        subtitle: 'C’est ici que je renseigne l’édito de la page d’accueil',
      },
      Contact: {
        addButtonLabel: 'Ajouter contact',
      },
      Address: {
        addButtonLabel: 'Ajouter adresse',
      },
    },
    History: {
      name: 'Historique',
      title: 'Historique des mises à jour',
      fields: {
        target_type: 'Type du contenu',
        name: 'Nom du contenu',
        'user.id': 'Utilisateur',
        createdAt: 'Date',
        diff: '',
        entity_name: 'Nom du contenu',
        role_type: 'Modifié par',
        datetime_min: 'Date de début',
        datetime_max: 'Date de fin',
        q: 'Rechercher',
        association: 'Association',
      },
      actionTypes: {
        CREATE: 'Création',
        UPDATE: 'Modification',
        DELETE: 'Suppression',
        DUPLICATE: 'Duplication',
      },
      targetTypes: {
        ASSOCIATION: 'Association',
        ACTIVITY: 'Activité',
        MISSION: 'Bénévolat',
        ADDRESS: 'Adresse',
        CONTACT: 'Contact',
      },
      userTypes: {
        Assolib: 'Assolib',
      },
      customDiff: {
        booleanFlip: {
          true: 'Oui',
          false: 'Non',
          undefined: '-',
        },
      },
    },
    User: {
      name: 'Utilisateur',
      fields: {
        email: 'Adresse email',
        firstname: 'Prénom',
        lastname: 'Nom',
        password: 'Mot de passe',
        role: 'Role',
        last_login_at: 'Dernière connexion',
        createdAt: 'Date de création',
        q: 'Rechercher',
      },
      helperTexts: {
        email: '',
        firstname: '',
        lastname: '',
        password: '',
        role: '',
        last_login_at: '',
      },
      title: 'Utilisateur',
      subtitle: "Je configure ici les paramètres de l'utilisateur.",
      emptyTitle: "Pas encore d'utilisateur",
      emptySubtitle: 'Voulez-vous en créer un ?',
      button: {
        create: 'Ajouter un utilisateur',
      },
    },
    Role: {
      name: 'Role',
      fields: {
        user: 'Utilisateur',
        type: 'Type',
        domain: 'Domaine',
        association: 'Association',
        activity: 'Activité',
        createdAt: 'Date de création',
      },
      helperTexts: {
        user: '',
        type: '',
        domain: '',
        association: '',
        activity: '',
      },
      title: 'Role',
      subtitle: "C'est ici que je renseigne le rôle de l'utilisateur.",
      emptyTitle: 'Pas encore de rôle',
      emptySubtitle: 'Voulez-vous en créer un ?',
      button: {
        create: 'Ajouter un rôle',
      },
    },
    Association: {
      name: 'Association',
      fields: {
        logo_url: 'Le logo',
        header_url: "Une image d'en-tête",
        name: 'Nom',
        administrative_id: 'Identifiant administratif de l’association',
        administrative_id_type: 'Type d’identifiant administratif de l’association',
        main_tag: "Domaine d'activité principal",
        tags: 'Mots-clés',
        disabled: 'Désactivé',
        createdAt: 'Date de création',
        description_text: 'Description',
        email: 'Email',
        phone: 'Téléphone',
        facebook_url: 'Facebook',
        twitter_url: 'Twitter',
        instagram_url: 'Instagram',
        website_url: 'Site Web',
        youtube_url: 'Youtube',
        linkedin_url: 'Linkedin',
        domain: 'Domaine',
        q: 'Rechercher',
        contacts: 'Contacts',
        addresses: 'Adresses',
        primary_contact: 'Contact principal',
        primary_address: 'Adresse principale',
      },
      Contact: {
        title: 'Contact(s) de l’association',
        subtitle: 'C’est ici que je précise le contact référent de l’association',
        removeButtonLabel: 'Retirer',
        addReferencedEntityButtonLabel: 'Ajouter contact',
        alreadyReferenced: 'Contact déjà référencé !',
        setAsPrimaryButtonLabel: 'Secondaire',
        setAsSecondaryButtonLabel: 'Primaire',
        delete: {
          actionButtonLabel: 'Retirer',
          title: 'Retirer un contact',
          text: "Êtes-vous sûr(e) de voulor retirer ce contact de l'association ?",
          okButtonLabel: 'Retirer',
        },
        selectOrCreate: {
          select: {
            comboBoxPlaceholder: "Rechercher un contact d'une activité",
            saveButtonLabel: `Ajouter ce contact`,
          },
          create: {
            headerIfSelectEnabled: 'Ou je crée un nouveau contact',
            headerIfSelectDisabled: 'Je crée un nouveau contact',
          },
        },
      },
      Address: {
        title: 'Adresse(s) de l’association',
        subtitle:
          'C’est ici que je renseigne les adresses utiles de mon association (mais pas les lieux de pratique des activités, que je renseignerai dans chaque activité).',
        addReferencedEntityButtonLabel: 'Ajouter adresse',
        alreadyReferenced: 'Adresse déjà référencée !',
        setAsPrimaryButtonLabel: 'Secondaire',
        setAsSecondaryButtonLabel: 'Primaire',
        delete: {
          actionButtonLabel: 'Retirer',
          title: 'Retirer une adresse',
          text: "Êtes-vous sûr(e) de voulor retirer cette adresse de l'association ?",
          okButtonLabel: 'Retirer',
        },
        selectOrCreate: {
          select: {
            header: 'Je choisis une adresse parmi les adresses de l’association',
            comboBoxPlaceholder: 'Rechercher une adresse de l’association',
            saveButtonLabel: `Ajouter cette adresse`,
          },
          create: {
            headerIfSelectEnabled: 'Ou je crée une nouvelle adresse',
            headerIfSelectDisabled: 'Je crée une nouvelle adresse',
          },
        },
      },
      helperTexts: {
        logo_url: 'PNG, JPG ou SVG - minimum 240x240px',
        header_url: 'PNG, JPG ou SVG - largeur recommandée 2880px',
        name: "Nom de l'association",
        main_tag: "Je choisis un domaine d'activité pricipal dans la liste déroulante",
        tags:
          "J'évite de renseigner les mots-clés ici. Je les renseigne plutôt dans chaque activité.",
        description_text: "Je décris l'association, ses missions, son esprit...",
        email: "Je saisis l'adresse mail principale de l'association",
        phone: "Je saisis le numéro de téléphone principal de l'association",
        facebook_url: 'Adresse de la page Facebook',
        twitter_url: 'Adresse du compte Twitter',
        instagram_url: 'Adresse du compte Instagram',
        website_url: 'Adresse du site Web',
        youtube_url: 'Adresse de la chaîne Youtube',
        linkedin_url: 'Adresse de la page Linkedin',
      },
      title: 'Mon association',
      subtitle: 'Je renseigne ici les principales informations de ma page association.',
      emptyTitle: "Pas encore d'association",
      emptySubtitle: 'Voulez-vous en créer une ?',
      button: {
        create: 'Ajouter une association',
        disable: 'Désactiver mon asso',
        enable: 'Activer mon asso',
      },
      tabs: {
        1: '1. Je télécharge les visuels de mon association',
        2: '2. Je décris mon association',
        3: '3. Je renseigne les adresses Internet de mon association',
        4: '4. Je renseigne l’identifiant administratif de mon association',
      },
    },
    DomainHighlight: {
      name: 'Sélection',
      fields: {
        preview_img_url: 'Illustration',
        name: 'Titre de la sélection',
        type: 'Type',
        domain: 'Domaine',
        description_text: 'Description',
        button_title: 'Intitulé du bouton',
        button_link: 'Cible du bouton (URL)',
        age_sub_groups: 'Âges',
        schedule_groups: 'Horaires',
        tags: 'Mots-clés',
        days: 'Jour(s)',
        createdAt: 'Date de création',
        q: 'Rechercher',
        [DOMAIN_HIGHLIGHT_SELECTION_TYPE_FIELD]: 'Type de sélection',
      },
      helperTexts: {
        preview_img_url: 'PNG, JPG ou SVG - largeur minimum 800px',
        description_text:
          'Si je le souhaite je peux ajouter un texte décrivant la mise en avant dans la rubrique Notre sélection',
      },
      title: 'Sélection',
      subtitle: 'Je décris ici le contenu à mettre en Sélection du portail.',
      emptyTitle: 'Pas encore de contenu en Sélection',
      emptySubtitle: 'Voulez-vous en créer un ?',
      button: {
        create: 'Ajouter un contenu de Sélection',
        disable: 'Désactiver cette Sélection',
        enable: 'Activer cette Sélection',
      },
      descriptionToggleSwitchLabel:
        'Souhaitez-vous rédiger un petit édito à paraître en début de liste réponse ?',
      tabs: {
        1: '1. Je configure l’affichage',
        2: '2. Je configure un bouton (optionnel)',
        3: '3. Je choisis la sélection',
      },
      selectionTypes: {
        criteria: 'Critères de recherche',
        associations: 'Associations',
        activities: 'Activités',
        missions: 'Missions de bénévolat',
      },
      AssociationListInput: {
        addButton: 'Ajouter des associations',
        listTitle: 'Associations sélectionnées',
        dialogTitle: 'Je désigne manuellement les éléments de la sélection',
      },
      ActivityListInput: {
        addButton: 'Ajouter des activités',
        listTitle: 'Activités sélectionnées',
        dialogTitle: 'Je désigne manuellement les éléments de la sélection',
      },
      MissionListInput: {
        addButton: 'Ajouter des missions',
        listTitle: 'Missions sélectionnées',
        dialogTitle: 'Je désigne manuellement les éléments de la sélection',
      },
    },
    DomainPartner: {
      name: 'Bandeau de liens',
      fields: {
        logo_url: 'Image',
        name: 'Nom',
        url: 'URL',
        createdAt: 'Date de création',
        domain: 'Domaine',
        q: 'Rechercher',
      },
      helperTexts: {
        logo_url: 'PNG, JPG ou SVG - largeur minimum 200px',
        name: '',
        domain: '',
      },
      title: 'Bandeau de liens',
      subtitle:
        'Je renseigne ici les principales informations qui apparaîtront sur le bandeau de liens.',
      emptyTitle: 'Pas encore de liens',
      emptySubtitle: 'Voulez-vous en ajouter un ?',
      button: {
        create: 'Ajouter un lien',
      },
    },
    Contact: {
      name: 'Contact',
      fields: {
        civility: 'Civilité',
        email: 'Email',
        firstname: 'Prénom',
        lastname: 'Nom',
        phone: 'Téléphone',
        title: 'Titre',
        hidden: 'Caché',
        createdAt: 'Date de création',
        association: 'Association',
        q: 'Rechercher',
        [PAGINATION_FILTER_IDS_SORT_FIELD]: 'Primaire / Secondaire',
      },
      helperTexts: {
        civility: '',
        email: '',
        firstname: '',
        lastname: '',
        phone: '',
        title: '',
        hidden: '',
        association: '',
      },
      titleDomain: 'Contact du domaine',
      subtitleDomain: 'C’est ici que je précise les contact référents du domaine',
      titleAssociation: "Contact de l'association",
      subtitleAssociation:
        'Je renseigne ici un responsables ou point de contact utile de mon association.',
      titleActivity: "Contact de l'activité",
      subtitleActivity:
        "Je renseigne ici un responsable ou point de contact spécifique de l'activité.",
      emptyTitle: 'Pas encore de contact',
      emptySubtitle: 'Voulez-vous en créer un ?',
      button: {
        create: 'Ajouter un contact',
        disable: 'Cacher ce contact',
        enable: 'Ne plus cacher ce contact',
      },
    },
    Address: {
      name: 'Adresse',
      fields: {
        country: 'Pays',
        full_address: 'Adresse complète',
        latitude: 'Latitude',
        longitude: 'Longitude',
        street: 'Nom et numéro de rue',
        zipcode: 'Code postal',
        city: 'Ville',
        createdAt: 'Date de création',
        association: 'Association',
        activity: 'Activité',
        complement: `Complément d'adresse`,
        q: 'Rechercher',
        [PAGINATION_FILTER_IDS_SORT_FIELD]: 'Primaire / Secondaire',
      },
      helperTexts: {
        country: '',
        full_address: '',
        latitude: '',
        longitude: '',
        street:
          'Je peux modifier cette ligne à la main pour ajouter un nom de lieu (salle, gymnase, etc.)',
        zipcode: '',
        city: '',
        association: '',
        activity: '',
        complement: `ex: Salle A, Chez M. Dupont, etc`,
      },
      create: {
        selectedAddress: 'Adresse sélectionnée',
        fullAddressPlaceholder: '',
      },
      titleDomain: 'Adresse du domaine',
      subtitleDomain: 'C’est ici que je précise les adresses référentes du domaine',
      titleAssociation: "Adresse de l'association",
      subtitleAssociation:
        "Je renseigne ici l'adresse de mon association (mais pas un lieu de pratique d'activité, que je renseignerai au niveau de l'activité elle-même).",
      titleActivity: "Adresse de l'activité",
      subtitleActivity:
        "Je renseigne ici l'adresse du lieu de pratique de l'activité, si différente de celle de l'association.",
      titleMission: 'Adresse de la mission',
      subtitleMission:
        "Je renseigne ici l'adresse du lieu de pratique de la mission, si différente de celle de l'association.",
      emptyTitle: "Pas encore d'adresse",
      emptySubtitle: 'Voulez-vous en créer une ?',
      button: {
        create: 'Ajouter une adresse',
      },
      placeholder: {
        addressSearch: 'Rechercher une adresse',
        addressNotFoundError: 'Addresse non trouvée',
        addressParsingError: `L'addresse ne semble pas être au format français`,
      },
      complementPicker: {
        title: "Si besoin, je choisis ou je crée un complément d'adresse",
        createNew: "Créer un nouveau complément d'adresse",
        createNewButton: 'Créer nouveau',
      },
    },
    CustomButton: {
      header: 'Bouton(s) personnalisé(s)',
      description:
        'Liste des boutons personnalisés qui renvoient vers une page de mon choix (inscription en ligne, paiement, brochure...)',
      addButtonLabel: 'Ajouter un bouton personnalisé',
      fields: {
        title: 'Intitulé du bouton',
        url: 'Adresse de la page cible (http…)',
      },
      helperTexts: {
        url: 'Je précise l’adresse de la page vers laquelle le bouton doit renvoyer',
      },
    },
    Activity: {
      name: 'Activité',
      fields: {
        disabled: 'Désactivée',
        tags: 'Mots-clés',
        age_max: 'Age maximum',
        age_min: 'Age minimum',
        description_text: 'Description',
        pricing_text: 'Tarifs',
        title: 'Nom',
        trainer_name: 'Géré par',
        createdAt: 'Date de création',
        updatedAt: 'Date de mise à jour',
        association: 'Association',
        q: 'Rechercher',
        contacts: 'Contacts',
        addresses: 'Adresses',
        primary_contact: 'Contact principal',
        primary_address: 'Adresse principale',
      },
      helperTexts: {
        disabled: '',
        tags:
          "Je choisis maximum 6 mots-clés pour trouver l'activité (domaine, activité, place disponible, visio...)",
        age_max: "Jusqu'à quel âge peut-on pratiquer cette activité ?",
        age_min: 'À partir de quel âge peut-on pratiquer cette activité ?',
        description_text: "Je décris l'activité, ses modalités, son esprit...",
        pricing_text: 'Je précise les éventuelles conditions tarifaires (montants, réductions...)',
        title: '',
        trainer_name: "Nom de l'animateur, du moniteur, de l'enseignant...",
        association: '',
      },
      title: "Description de l'activité",
      subtitle: "Je décris ici l'activité le plus précisément possible.",
      emptyTitle: "Pas encore d'activité",
      emptySubtitle: 'Voulez-vous en créer une ?',
      button: {
        create: 'Ajouter une activité',
        disable: 'Désactiver cette activité',
        enable: 'Activer cette activité',
      },
      Contact: {
        title: 'Contact(s) de l’activité',
        subtitle: 'C’est ici que je précise le contact référent de l’activité',
        addReferencedEntityButtonLabel: 'Ajouter contact',
        alreadyReferenced: 'Contact déjà référencé !',
        setAsPrimaryButtonLabel: 'Secondaire',
        setAsSecondaryButtonLabel: 'Primaire',
        delete: {
          actionButtonLabel: 'Retirer',
          title: 'Retirer un contact',
          text: "Êtes-vous sûr(e) de voulor retirer ce contact de l'activité ?",
          okButtonLabel: 'Retirer',
        },
        selectOrCreate: {
          select: {
            header: 'Je choisis un contact parmi les contacts de l’association',
            comboBoxPlaceholder: 'Rechercher un contact de l’association',
            saveButtonLabel: `Ajouter ce contact`,
          },
          create: {
            headerIfSelectEnabled: 'Ou je crée un nouveau contact',
            headerIfSelectDisabled: 'Je crée un nouveau contact',
          },
        },
      },
      Address: {
        title: 'Adresse(s) de l’activité',
        subtitle:
          'C’est ici que je renseigne le(s) lieu(x) de pratique de l’activité s’ils sont différents de l’adresse de l’association.',
        addReferencedEntityButtonLabel: 'Ajouter adresse',
        alreadyReferenced: 'Adresse déjà référencée !',
        setAsPrimaryButtonLabel: 'Secondaire',
        setAsSecondaryButtonLabel: 'Primaire',
        delete: {
          actionButtonLabel: 'Retirer',
          title: 'Retirer une adresse',
          text: "Êtes-vous sûr(e) de voulor retirer cette adresse de l'activité ?",
          okButtonLabel: 'Retirer',
        },
        selectOrCreate: {
          select: {
            header: 'Je choisis une adresse parmi les adresses de l’association',
          },
          create: {
            headerIfSelectEnabled: 'Ou je crée une nouvelle adresse',
            headerIfSelectDisabled: 'Je crée une nouvelle adresse',
          },
        },
      },
    },
    ActivitySchedule: {
      name: "Horaire d'activité",
      fields: {
        day_name: 'Jour',
        end_time: 'Heure de fin',
        name: "Précision sur l'horaire",
        start_time: 'Heure de début',
        createdAt: 'Date de création',
        activity: 'Activité',
        q: 'Rechercher',
      },
      helperTexts: {
        day_name: '',
        end_time: 'À renseigner au format hh:mm',
        name: "Ce texte apparaît entre parenthèses derrière l'horaire",
        start_time: 'À renseigner au format hh:mm',
        activity: '',
      },
      title: "Horaire d'activité",
      subtitle: "Je renseigne ici les horaires de l'activité.",
      emptyTitle: "Pas encore d'horaire d'activité",
      emptySubtitle: 'Voulez-vous en créer un ?',
      button: {
        create: "Ajouter un horaire d'activité",
      },
    },
    Tag: {
      name: 'Mots-clés',
      fields: {
        name: 'Nom',
        type: 'Type',
        visible: 'Visible',
        createdAt: 'Date de création',
        q: 'Rechercher',
      },
      helperTexts: {
        name: '',
        type: '',
        visible: '',
      },
      title: 'Mots-clés',
      subtitle: "Je choisis jusqu'à 5 mots-clés dans la liste déroulante.",
      emptyTitle: 'Pas encore de mot-clé',
      emptySubtitle: 'Voulez-vous en créer un ?',
      button: {
        create: 'Ajouter un mot-clé',
      },
      values: {
        MAIN: "Domaine d'activité principal",
        NORMAL: 'Normal',
        MISSION_CAUSE: 'Mission - Cause',
        MISSION_SKILL: 'Mission - Compétence',
      },
      status: {
        visible: 'Visible',
        hidden: 'Caché',
      },
    },
    ScheduleGroup: {
      name: 'ScheduleGroup',
    },
    AgeSubGroup: {
      name: 'AgeSubGroup',
    },
    AgeGroup: {
      name: 'AgeGroup',
    },
    Mission: {
      name: 'Mission',
      fields: {
        title: 'Nom de la mission',
        description_text: 'Description',
        recurrence: 'Récurrence',
        start_date: 'Date de début',
        end_date: 'Date de fin',
        causes: "Domaine d'action",
        skills: 'Domaine de compétence',
        createdAt: 'Date de création',
        updatedAt: 'Date de mise à jour',
        association: 'Association',
        q: 'Rechercher',
        disabled: 'Désactivée',
        contacts: 'Contacts',
        addresses: 'Adresses',
        primary_contact: 'Contact principal',
        primary_address: 'Adresse principale',
      },
      recurrence: {
        Punctual: 'Mission ponctuelle',
        Recurrent: 'Mission permanente',
      },
      helperTexts: {
        description_text: 'Je décris la mission, ses modalités, son esprit…',
        causes: "Je choisis les mots-clés décrivant le mieux le domaine d'action de la mission",
        skills:
          'Je choisis les mots-clés décrivant le mieux les compétences nécessaires pour mener la mission',
        disabled: 'Je coche si je ne souhaite pas activer cette mission pour le moment',
        recurrence:
          'Je précise si la mission est permanente ou ponctuelle, ainsi que les dates de début et de fin',
      },
      title: 'Description de la mission',
      subtitle: 'C’est ici que je décris la mission le plus précisément possible.',
      emptyTitle: 'Pas encore de mission',
      emptySubtitle: 'Voulez-vous en créer une ?',
      button: {
        create: 'Ajouter une mission',
        disable: 'Désactiver cette mission',
        enable: 'Activer cette mission',
      },
      Contact: {
        title: 'Contact(s) de la mission',
        subtitle: 'C’est ici que je précise le contact référent de la mission',
        addReferencedEntityButtonLabel: 'Ajouter contact',
        alreadyReferenced: 'Contact déjà référencé !',
        setAsPrimaryButtonLabel: 'Secondaire',
        setAsSecondaryButtonLabel: 'Primaire',
        delete: {
          actionButtonLabel: 'Retirer',
          title: 'Retirer un contact',
          text: 'Êtes-vous sûr(e) de voulor retirer ce contact de la mission ?',
          okButtonLabel: 'Retirer',
        },
        selectOrCreate: {
          select: {
            header: 'Je choisis un contact parmi les contacts de l’association',
            comboBoxPlaceholder: 'Rechercher un contact de l’association',
            saveButtonLabel: `Ajouter ce contact`,
          },
          create: {
            headerIfSelectEnabled: 'Ou je crée un nouveau contact',
            headerIfSelectDisabled: 'Je crée un nouveau contact',
          },
        },
      },
      Address: {
        title: 'Adresse(s) de la mission',
        subtitle:
          'C’est ici que je renseigne le(s) lieu(x) de pratique de la mission s’ils sont différents de l’adresse de l’association.',
        addReferencedEntityButtonLabel: 'Ajouter adresse',
        alreadyReferenced: 'Adresse déjà référencée !',
        setAsPrimaryButtonLabel: 'Secondaire',
        setAsSecondaryButtonLabel: 'Primaire',
        delete: {
          actionButtonLabel: 'Retirer',
          title: 'Retirer une adresse',
          text: 'Êtes-vous sûr(e) de voulor retirer cette adresse de la mission ?',
          okButtonLabel: 'Retirer',
        },
        selectOrCreate: {
          select: {
            header: 'Je choisis une adresse parmi les adresses de l’association',
          },
          create: {
            headerIfSelectEnabled: 'Ou je crée une nouvelle adresse',
            headerIfSelectDisabled: 'Je crée une nouvelle adresse',
          },
        },
      },
    },
    DomainUsefullink: {
      name: 'Liens Utiles',
      fields: {
        name: 'Nom',
        descriptionText: 'Description',
        urlText: 'Texte de lien',
        url: 'URL',
        q: 'Rechercher',
        disabled: 'Statut',
        createdAt: 'Date de création',
      },
      helperTexts: {},
      title: 'Liens utiles',
      subtitle: '',
      button: {
        create: 'Ajouter un lien',
        disable: 'Désactiver ce lien',
        enable: 'Activer ce lien',
      },
      emptyTitle: 'Pas encore de liens',
      emptySubtitle: 'Voulez-vous en créer un ?',
    },
  },
  daysOfWeek: {
    SUNDAY: 'Dimanche',
    MONDAY: 'Lundi',
    TUESDAY: 'Mardi',
    WEDNESDAY: 'Mercredi',
    THURSDAY: 'Jeudi',
    FRIDAY: 'Vendredi',
    SATURDAY: 'Samedi',
  },
  login: {
    conditionPrefix: "J'accepte les ",
    conditionSuffix: "conditions générales d'utilisations.",
    conditionHelperText: "Vous devez accepter les conditions générales d'utilisations",
  },
  assoRoleLandingPage: {
    title: 'Bienvenue sur votre portail associatif !',
    subtitle:
      'Découvrez ici les dernières informations et liens utiles mis à disposition par votre mairie',
    noLinkMessage: "Pas de liens pour l'instant.",
  },
  menu: {
    rootMenu: {
      Domain: 'Domaine',
      User: 'Utilisateur',
      Role: 'Role',
      Tag: 'Mots-clés',
    },
    domainMenu: {
      Domain: 'Retour aux domaines',
      Dashboard: 'Accueil',
      Animation: 'Animation',
      edito: 'Édito',
      DomainHighlight: 'Notre sélection',
      DomainPartner: 'Bandeau de liens',
      Association: 'Associations',
      Contact: 'Contacts',
      Address: 'Adresses',
      ImportExport: 'Import / Export',
      MissionSettings: 'Bénévolat',
      Parameters: 'Paramètres',
      GeneralParameters: 'Général',
      theme: 'Thème',
    },
    associationMenu: {
      BackToAssociations: 'Retour aux associations',
      BackToActivities: 'Retour aux activités',
      MyAssociation: 'Mon association',
      Contact: "Contact(s) de l'association",
      Address: "Adresse(s) de l'association",
      Activity: 'Tableau de bord activités',
      Mission: 'Tableau de bord bénévolat',
      UsefulLink: 'Liens utiles',
      AssoHome: 'Accueil',
    },
    activityMenu: {
      Contact: "Contact(s) de l'activité",
      Address: "Adresse(s) de l'activité",
      ActivitySchedule: "Horaires de l'activité",
    },
    missionMenu: {
      Contact: 'Contact(s) de la mission',
      Address: 'Adresse(s) de la mission',
      ActivitySchedule: 'Horaires de la mission',
    },
  },
  buttons: {
    select: 'Sélectionner',
    deploy: 'Déployer',
    import: 'Importer',
    duplicate: 'Dupliquer',
    modify: 'Modifier',
    show: 'Afficher',
    edit: 'Modifier',
    delete: 'Supprimer',
    remove: 'Retirer',
    deploySuccess:
      'Déploiement lancé, merci de patienter. (Le déploiement peut mettre entre 10 minutes et 1 heure)',
    deployFail:
      'Une erreur est survenue pendant le déploiement, veuillez rééssayer ultérieuement ou contacter le support.',
    importAssociations: 'Importer des associations',
    importActivities: 'Importer des activités',
    importActivityTags: 'Affecter les mots-clés aux activités',
    exportAssociations: 'Exporter des associations',
    exportActivities: 'Exporter des activités',
    showAssoLink: 'Prévisualiser sur le portail',
    showActivityLink: 'Prévisualiser sur le portail',
    showMissionLink: 'Prévisualiser sur le portail',
    showDomainLink: 'Voir le portail',
    showDomainUsefulLink: 'Voir le lien sur le portail',
  },
  texts: {
    import: 'Importer',
    export: 'Exporter',
    importAssociationAdded: 'nouvelle(s) association(s) importée(s)',
    importAssociationUpdated: 'association(s) mise à jour',
    importAssociationIgnored: 'association(s) ignorée(s)',
    importActivityAdded: 'nouvelle(s) activité(s) importée(s)',
    importActivityUpdated: 'activité(s) mise à jour',
    importActivityIgnored: 'activité(s) ignorée(s)',
    importError: 'Détails des erreurs :',
    duplicateSuccess: 'Duplication réussie',
    enable: 'activé',
    disable: 'désactivé',
    maximumTags: 'tags maximum',
  },
  StatusToggleInput: {
    masculine: {
      enabled: 'Activé',
      disabled: 'Désactivé',
    },
    feminine: {
      enabled: 'Activée',
      disabled: 'Désactivée',
    },
  },
  EntityReferenceListInput: {
    tagFilterPlaceholder: 'Choisir un mot-clé',
    missionCauseFilterPlaceholder: 'Choisir une cause',
    missionSkillFilterPlaceholder: 'Choisir une compétence',
  },
  Unauthorized: 'Non autorisé',
  roleTypes: {
    dev: 'Dev',
    admin: 'Admin',
    domain: 'Collectivité',
    asso: 'Association',
    activity: 'Activité',
  },
  historyRoleTypes: {
    dev: 'Dev',
    admin: 'Admin',
    domain: 'Collectivité',
    asso: 'Association',
    association: 'Association',
    activity: 'Activité',
    assolib: 'Assolib',
    mission: 'Mission',
  },
}

frenchMessages.ra.action.edit = 'Modifier'

const i18nProvider = polyglotI18nProvider(
  (locale) => (locale === 'fr' ? { ...frenchMessages, ...customFrenchMessages } : englishMessages),
  'fr',
)

export default i18nProvider
