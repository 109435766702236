import { gql } from '@apollo/client'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { FC, useState } from 'react'
import { Button, useNotify, useTranslate } from 'react-admin'
import { client } from '../../../apollo-client'
import { convertFileToBase64 } from '../../../helpers/upload-data-provider'
import { useDomain } from '../../contexts/domain-context'

const useStyles = makeStyles({
  flex: {
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    textAlign: 'center',
    padding: 16,
    margin: 20,
    minHeight: 100,
    flexDirection: 'column',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    margin: 5,
  },
})

export const ImportExport: FC = () => {
  const domain = useDomain()
  const [loading, setLoading] = useState(false)
  const notify = useNotify()
  const [importResult, setImportResult] = useState<{
    added: number
    updated: number
    ignored: number
    error: string[]
  }>()
  const [type, setType] = useState<string>()
  const translate = useTranslate()
  const classes = useStyles()

  async function handleClickImport(type: string) {
    if (domain) {
      try {
        setLoading(true)
        setType(type)

        const input = document.querySelector<HTMLInputElement>(`#import-${type}`)
        const content = await convertFileToBase64({
          rawFile: input && input.files && input.files[0],
        })

        const mutationName =
          type === 'association'
            ? 'importAssociations'
            : type === 'activity'
            ? 'importActivities'
            : 'importActivityTags'

        const MUTATION = gql`
        mutation {
          ${mutationName}(domain: "${domain.id}", content: "${content}") {
            added
            updated
            ignored
            error
          }
        }`

        const success = await client.mutate({ mutation: MUTATION })
        if (success) {
          setImportResult(success.data[mutationName])
        }
        setLoading(false)
        if (input) {
          input.value = ''
        }
      } catch (error) {
        notify(error instanceof Error ? error.message : String(error), 'warning')
        setLoading(false)
      }
    }
  }

  // Create a file on-the-fly and trigger a download.
  // https://stackoverflow.com/a/33542499/1541141
  function createFileAndDownload(filename: string, fileType: string, data: string) {
    const blob = new Blob([data], { type: fileType })
    if ((window.navigator as any).msSaveOrOpenBlob) {
      ;(window.navigator as any).msSaveBlob(blob, filename)
    } else {
      const elem = window.document.createElement('a')
      elem.href = window.URL.createObjectURL(blob)
      elem.download = filename
      document.body.appendChild(elem)
      elem.click()
      document.body.removeChild(elem)
    }
  }

  async function handleClickExport(type: 'association' | 'activity') {
    if (domain) {
      try {
        setLoading(true)

        const queryName = { association: 'exportAssociations', activity: 'exportActivities' }[type]

        const QUERY = gql`
        query {
          ${queryName}(domain: "${domain.id}")
        }`
        const success = await client.query({ query: QUERY })
        createFileAndDownload(`${queryName}.csv`, 'text/csv', success.data[queryName])
        setLoading(false)
      } catch (error) {
        notify(error instanceof Error ? error.message : String(error), 'warning')
        setLoading(false)
      }
    }
  }

  return (
    <>
      <div className={classes.flex}>
        <Card className={classes.card}>
          <Typography variant="h5" component="h2" color="textSecondary">
            {translate('texts.import')}
          </Typography>
          <hr />
          <div className={classes.buttonContainer}>
            <Button
              className={classes.button}
              variant="outlined"
              color="secondary"
              component="label"
              label={translate('buttons.importAssociations')}
              disabled={loading}
            >
              <input
                id="import-association"
                type="file"
                accept=".csv"
                hidden
                onChange={() => handleClickImport('association')}
              />
            </Button>

            <Button
              className={classes.button}
              variant="outlined"
              color="secondary"
              component="label"
              label={translate('buttons.importActivities')}
              disabled={loading}
            >
              <input
                id="import-activity"
                type="file"
                accept=".csv"
                hidden
                onChange={() => handleClickImport('activity')}
              />
            </Button>

            <Button
              className={classes.button}
              variant="outlined"
              color="secondary"
              component="label"
              label={translate('buttons.importActivityTags')}
              disabled={loading}
            >
              <input
                id="import-activity-tag"
                type="file"
                accept=".csv"
                hidden
                onChange={() => handleClickImport('activity-tag')}
              />
            </Button>
          </div>
        </Card>
        <Card className={classes.card}>
          <Typography variant="h5" component="h2" color="textSecondary">
            {translate('texts.export')}
          </Typography>
          <hr />
          <div className={classes.buttonContainer}>
            <Button
              className={classes.button}
              variant="outlined"
              color="secondary"
              onClick={() => handleClickExport('association')}
              label={translate('buttons.exportAssociations')}
              disabled={loading}
            ></Button>
            <Button
              className={classes.button}
              variant="outlined"
              color="secondary"
              onClick={() => handleClickExport('activity')}
              label={translate('buttons.exportActivities')}
              disabled={loading}
            ></Button>
          </div>
        </Card>
      </div>
      {importResult ? (
        <Card className={classes.card}>
          <Typography variant="h6" component="h2" color="textSecondary">
            {importResult.added}{' '}
            {type === 'association'
              ? translate('texts.importAssociationAdded')
              : translate('texts.importActivityAdded')}
          </Typography>
          <Typography variant="h6" component="h2" color="textSecondary">
            {importResult.updated}{' '}
            {type === 'association'
              ? translate('texts.importAssociationUpdated')
              : translate('texts.importActivityUpdated')}
          </Typography>
          <Typography variant="h6" component="h2" color="textSecondary">
            {importResult.ignored}{' '}
            {type === 'association'
              ? translate('texts.importAssociationIgnored')
              : translate('texts.importActivityIgnored')}
          </Typography>
          <br />
          {importResult.error && importResult.error.length && importResult.error.length > 0 ? (
            <>
              <Typography variant="inherit" component="p" color="textSecondary">
                {translate('texts.importError')}
              </Typography>
              {importResult.error.map((error) => (
                <Typography key={error} variant="inherit" component="p" color="textSecondary">
                  {error}
                </Typography>
              ))}
            </>
          ) : null}
        </Card>
      ) : null}
    </>
  )
}
