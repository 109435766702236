import { makeStyles } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import {
  Edit,
  HandleSubmitWithRedirect,
  ImageField,
  ImageInput,
  SaveButton,
  SimpleForm,
  TextInput,
  TopToolbar,
  useTranslate,
} from 'react-admin'
import { Domain } from '../../../entity-types'
import { modelStyles } from '../../../helpers/model-styles'
import { NonInput } from '../../common/non-input'
import { useDomain } from '../../contexts/domain-context'
import { useIsAtLeastDomainRole } from '../../hook/use-is-at-least-domain-role'
import { useThisDomainUrls } from '../../hook/use-this-domain-urls'
import { RecordMiddleware } from './record-middleware'

export const useStyles = makeStyles(modelStyles)

const stripImageUploadFields = (record: Domain): Domain => {
  // The ImageInput exepects a File object, but the server sends us a URL.
  // We simply remove the fields, so we don't get a warning from ImageInput.
  const { logo_url: _, home_about_image: __, ...otherFields } = record
  return otherFields as Domain
}

export const EditoEdit = (props: any) => {
  const domain = useDomain()
  const translate = useTranslate()
  const classes = useStyles()

  if (!useIsAtLeastDomainRole() || !domain) {
    return null
  }

  return (
    <Edit
      title={translate('resources.Domain.name')}
      {...props}
      id={domain.id}
      undoable={false}
      classes={{ card: classes.card }}
      actions={false}
    >
      <RecordMiddleware<Domain> transform={stripImageUploadFields}>
        <SimpleForm redirect="show" toolbar={<Toolbar />} className={classes.edit}>
          <NonInput>
            <Typography component="h1">{translate('resources.Domain.Edito.title')}</Typography>
            <Typography component="h3">{translate('resources.Domain.Edito.subtitle')}</Typography>
            <Divider />
          </NonInput>
          <TextInput
            variant="outlined"
            source="home_about_title"
            helperText="resources.Domain.helperTexts.home_about_title"
          />
          <TextInput
            variant="outlined"
            multiline
            source="home_about_text"
            helperText="resources.Domain.helperTexts.home_about_text"
          />
          <ImageInput
            source="home_about_image"
            accept="image/*"
            helperText="resources.Domain.helperTexts.home_about_image"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput
            variant="outlined"
            source="home_about_url"
            helperText="resources.Domain.helperTexts.home_about_url"
          />
        </SimpleForm>
      </RecordMiddleware>
    </Edit>
  )
}

const useToolbarStyles = makeStyles({
  toolbar: {
    position: 'absolute',
    top: '56px',
    right: '24px',
    paddingTop: '10px',
    paddingRight: '10px',
  },
  button: {
    margin: '5px',
  },
})

interface Props {
  disableLink?: boolean
  resource?: string
  handleSubmitWithRedirect?: HandleSubmitWithRedirect
}

const Toolbar = ({ handleSubmitWithRedirect }: Props) => {
  const classes = useToolbarStyles()
  const domainUrls = useThisDomainUrls()

  return (
    <TopToolbar className={classes.toolbar}>
      <SaveButton
        className={classes.button}
        variant="contained"
        icon={<></>}
        handleSubmitWithRedirect={handleSubmitWithRedirect}
        redirect={domainUrls?.edito.show}
      />
    </TopToolbar>
  )
}
